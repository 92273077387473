import * as yup from 'yup'

export const validationIntegrationRequest = yup.object({
  phone: yup.string().required('common:errors.required'),
  email: yup
    .string()
    .email('common:errors.email')
    .test((email) => !/[а-яА-ЯЁё]/.test(email || ''))
    .required('common:errors.required'),
  message: yup.string(),
})
