import { ProjectIntegrationDrawer } from '../../ProjectIntegrationDrawer'
import { ProjectCardHeaderProps, ProjectDrawerType } from './ProjectCardHeader.types'
import { ModuleIntegrationIconWrapper, ProjectCardFullNameWrapper, StyledProjectCardHeader } from './styles'
import { Box, Stack, Typography } from '@mui/material'
import { ModuleIntegrationIcon } from 'assets/icons/ModuleIntegrationIcon'
import { Settings as SettingsIcon } from '@mui/icons-material'
import { ColoredTitle } from 'components/ColoredTitle'
import { projectStatusesColors, projectStatusesForCard } from 'core/types/project'
import React, { useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const ProjectCardHeader: React.FC<ProjectCardHeaderProps> = ({ data }) => {
  const [openedDrawer, setOpenedDrawer] = useState<ProjectDrawerType | null>(null)
  const { id, status, shortName, fullName } = data

  const onDrawerOpen = (drawerType: ProjectDrawerType) => {
    setOpenedDrawer(drawerType)
  }

  const onDrawerClose = useCallback(() => {
    setOpenedDrawer(null)
  }, [])

  const onProjectIntegrationDrawerOpen = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    onDrawerOpen('integration')
  }, [])

  return (
    <>
      <StyledProjectCardHeader
        title={
          <Stack spacing={1}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' height='21px'>
              <ColoredTitle body={projectStatusesForCard[status]} color={projectStatusesColors[status]} />
              <ModuleIntegrationIconWrapper onClick={onProjectIntegrationDrawerOpen}>
                <SettingsIcon fontSize='medium' color='primary' />
                {/* <ModuleIntegrationIcon fill={theme.palette.primary.main} /> */}
              </ModuleIntegrationIconWrapper>
            </Stack>
            <Box>
              <Typography variant='subtitle1'>{shortName}</Typography>
            </Box>
          </Stack>
        }
        subheader={
          <ProjectCardFullNameWrapper>
            <Typography variant='body2'>{fullName}</Typography>
          </ProjectCardFullNameWrapper>
        }
      />
      <ProjectIntegrationDrawer
        isOpen={openedDrawer === 'integration'}
        onClose={onDrawerClose}
        // onClose={(dirty, immediately) => onDrawerClose('exit', dirty, immediately)}
        project={{ id, shortName }}
      />
    </>
  )
}
