import { PrescriptionStatisticalStats } from 'api/analytics/types'
import { LegendColors } from 'core/types/global'
import { TPrescriptionStatus } from 'core/types/prescription'
import { UserRoles } from 'core/types/user'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'

export interface StatisticalTableProps {
  data: PrescriptionStatisticalStats | null
  role: UserRoles
}

export const statisticalTableTitles = [
  'Статус',
  'Всего',
  'Просрочено',
  'Замечания в предписаниях',
  'Первичные замечания',
  'Повторные замечания',
]

export const prescriptionStatusColorByStatus: Record<TPrescriptionStatus, LegendColors> = {
  CREATED: PRESCRIPTION_CREATED_STATUS_COLOR,
  SENT: PRESCRIPTION_SENT_STATUS_COLOR,
  DISCARDED: PRESCRIPTION_DISCARDED_STATUS_COLOR,
  COMPLETE: PRESCRIPTION_COMPLETE_STATUS_COLOR,
}
