import { FC, useEffect, useRef, useState } from 'react'
import { isTextOverflowed, ITooltipCellProps } from './TooltipCell.types'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'

export const TooltipCell: FC<ITooltipCellProps> = ({ value, column }) => {
  const textRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const columnWidth = column?.getActualWidth()

  useEffect(() => {
    setShowTooltip(isTextOverflowed(textRef))
  }, [textRef, value, columnWidth])

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          style: {
            padding: '8px 12px',
            marginTop: 6,
            backgroundColor: theme.palette.bg.white,
            boxShadow: '0px 2px 15px 0px rgba(46, 77, 187, 0.25)',
            width: `${columnWidth}px`,
            maxWidth: 'unset',
          },
        },
        arrow: {
          style: {
            color: theme.palette.bg.white,
          },
        },
      }}
      title={showTooltip ? value : ''}
      textColor={theme.palette.text.dark}
    >
      <div ref={textRef}>{value}</div>
    </Tooltip>
  )
}
