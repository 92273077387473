import { UserFormData } from '../UserForm'
import { ROLE_SELECT_WIDTH } from '../UserRoleSelection'
import { ContractorAccessItem } from './components/ContractorAccessItem/ContractorAccessItem'
import { ProjectSelect } from './components/ProjectSelect'
import { Stack } from '@mui/material'
import { useFormikContext } from 'formik'
import { FC } from 'react'

export const ContractorAccessesSelection: FC = () => {
  const { values: formValues } = useFormikContext<UserFormData>()
  const isProjectsSelected = !!formValues.projects?.length

  return (
    <>
      {!isProjectsSelected ? (
        <Stack style={{ width: '100%' }} direction='row' justifyContent='space-between' alignItems='center'>
          <ProjectSelect />
        </Stack>
      ) : (
        <>
          {formValues.projects.map((project) => (
            <ContractorAccessItem project={project} />
          ))}

          <ProjectSelect />
        </>
      )}
    </>
  )
}
