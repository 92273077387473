import { EliminationFormData } from '../EliminationDrawer/components/EliminationForm/EliminationForm.types'
import { remarkModalNotifyInfo } from '../RemarkForm/RemarkForm.types'
import { StyledRejectionWindow } from './RejectionWindow.styles'
import { RejectionWindowProps } from './RejectionWindow.types'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { FieldForm, StyledCheckbox } from 'components/FieldForm'
import { Tooltip } from 'components/Tooltip'
import { useFormikContext } from 'formik'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const RejectionWindow: FC<RejectionWindowProps> = ({ isOpen, setIsShouldNotify, onClose }) => {
  const { values, submitForm } = useFormikContext<EliminationFormData>()
  const isDirtyWindow = !!values.engineerReason.trim()

  return (
    <Dialog open={isOpen} onClose={() => onClose(isDirtyWindow)}>
      <StyledRejectionWindow>
        <Typography variant='subtitle1' color={theme.palette.primary.main}>
          Замечание не устранено?
        </Typography>

        <Typography variant='body2' color={theme.palette.text.dark}>
          Замечание будет отправлено на повторное устранение.
        </Typography>

        <FieldForm
          version='project'
          name='engineerReason'
          placeholder='Укажите обоснование отклонения'
          multiline
          helperText=''
          inputProps={{
            style: {
              height: '89px',
              overflow: 'auto',
            },
          }}
        />

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack direction='row' alignItems='center'>
            <StyledCheckbox id='notification' onChange={(e) => setIsShouldNotify(e.target.checked)} />
            <label htmlFor='notification' style={{ cursor: 'pointer' }}>
              <Typography variant='body2' color={theme.palette.text.dark} marginRight={1}>
                Отправить уведомление
              </Typography>
            </label>
          </Stack>

          <Tooltip title={remarkModalNotifyInfo} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
            <InfoIcon fontSize='inherit' color='action' />
          </Tooltip>
        </Stack>

        <Stack direction='row' spacing={1}>
          <Button
            onClick={() => submitForm()}
            disabled={!values.engineerReason.trim()}
            size='medium'
            color='error'
            fullWidth
          >
            Отклонить
          </Button>
          <Button size='medium' fullWidth onClick={() => onClose(isDirtyWindow)}>
            Закрыть
          </Button>
        </Stack>
      </StyledRejectionWindow>
    </Dialog>
  )
}
