import { ImportDrawer } from '../ReferenceDrawers/ImportDrawer'
import { IDefectsProps, TDefectsDialogTrigger, TDefectsDrawerType } from './Defects.types'
import { DefectManagment } from './components/DefectManagment'
import { Stack } from '@mui/material'
import {
  defectsApi,
  useClearDefectsByProjectMutation,
  useClearDefectsMutation,
  useDeleteDefectByProjectMutation,
  useDeleteDefectMutation,
  useGetDefectsByProjectQuery,
  useGetDefectsQuery,
  useLazyExportDefectsByProjectQuery,
  useLazyExportDefectsQuery,
  useLazyGetDefectsExportResultByProjectQuery,
  useLazyGetDefectsExportResultQuery,
} from 'api/references/defects'
import { IDefect } from 'api/references/defects/types'
import { Table } from 'components/Table'
import { CustomColumnDef } from 'components/Table/Table.types'
import { TableManagment } from 'components/TableManagment'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSearch } from 'hooks/useSearch'
import { useSnackbar } from 'notistack'
import { ObjectsTable } from 'pages/Objects/ObjectsTable'
import { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector, profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { downloadMedia } from 'utils/downloadMedia'

export const Defects: FC<IDefectsProps> = () => {
  const { projectId: projectIdString } = useParams()
  const { searchValue } = useSearch()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const navigate = useNavigate()
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const [openedDrawer, setOpenedDrawer] = useState<TDefectsDrawerType | null>(null)
  const [selectedDefect, setSelectedDefect] = useState<IDefect | null>(null)
  const tableDataQuery = isProjectMode ? useGetDefectsByProjectQuery : useGetDefectsQuery
  const queryArgs = isProjectMode ? { projectId, query: searchValue } : { query: searchValue }
  const { enqueueSnackbar } = useSnackbar()

  const [clearDefects, { isLoading: isClearing, ...clearDefectsResponse }] = useClearDefectsMutation()
  const [clearDefectsByProject, { isLoading: isClearingByProject, ...clearDefectsByProjectResponse }] =
    useClearDefectsByProjectMutation()

  const [exportDefects, exportDefectsResult] = useLazyExportDefectsQuery()
  const [getDefectsExportResult, defectsExportResult] = useLazyGetDefectsExportResultQuery()

  const [exportDefectsByProject, exportDefectsResultByProject] = useLazyExportDefectsByProjectQuery()
  const [getDefectsExportResultByProject, defectsExportResultByProject] = useLazyGetDefectsExportResultByProjectQuery()

  const onRowClick = (representative: IDefect) => {
    setSelectedDefect(representative)
    setOpenedDrawer('managment')
  }

  const columns: CustomColumnDef<IDefect>[] = [
    {
      accessorKey: 'code',
      header: 'Код дефекта',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'title',
      header: 'Наименование',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'type',
      header: 'Вид дефекта',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'assignmentType.title',
      header: 'Вид работ',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'measures',
      header: 'Мероприятия по устранению',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'basis',
      header: 'Основание',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
    {
      accessorKey: 'note',
      header: 'Примечание',
      textAlign: 'left',
      size: 200,
      minSize: 150,
    },
  ]

  const onDrawerOpen = useCallback((drawerType: TDefectsDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback((dialogType: TDefectsDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) {
      setOpenedDrawer(null)
      setSelectedDefect(null)
    } else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const handleCloseManagment = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
      setSelectedDefect(null)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TDefectsDialogTrigger>('closeManagment')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeManagment: { handleConfirm: handleCloseManagment },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onClearReference = useCallback(() => {
    isProjectMode ? clearDefectsByProject({ projectId }) : clearDefects()
  }, [])

  const onReturnClick = useCallback(() => {
    projectId ? navigate(`/project/${projectId}/references`) : navigate(`/administration/references`)
  }, [projectId])

  const onExportClick = () => {
    isProjectMode ? exportDefectsByProject({ projectId }) : exportDefects()
  }

  useMutationHandlers(isProjectMode ? exportDefectsResultByProject : exportDefectsResult, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    isProjectMode
      ? getDefectsExportResultByProject({ projectId, id: data.data })
      : getDefectsExportResult({ id: data.data })
  })

  useMutationHandlers(isProjectMode ? defectsExportResultByProject : defectsExportResult, (data) => {
    downloadMedia(data.link)
    enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
  })

  useMutationHandlers(isProjectMode ? clearDefectsByProjectResponse : clearDefectsResponse, () => {
    enqueueSnackbar('Справочник успешно очищен.', { variant: 'success' })
  })

  return (
    <Stack flex={1} maxWidth='100%' height='100%'>
      <Stack paddingRight='20px'>
        <TableManagment
          onReturnClick={onReturnClick}
          onClearClick={onClearReference}
          onImportClick={!viewingOnly ? () => onDrawerOpen('import') : undefined}
          onAddClick={!viewingOnly ? () => onDrawerOpen('managment') : undefined}
          onExportClick={onExportClick}
          buttonManagmentTitle='Управление'
        />
      </Stack>

      <Table
        api={defectsApi}
        columns={columns}
        query={tableDataQuery}
        tags={[{ type: 'References', id: 'DEFECT' }]}
        onRowClick={onRowClick}
        queryArgs={queryArgs}
      />

      <DefectManagment
        isOpen={openedDrawer === 'managment'}
        defect={selectedDefect}
        onClose={(dirty, immediately) => onDrawerClose('closeManagment', dirty, immediately)}
      />

      <ImportDrawer isOpen={openedDrawer === 'import'} onClose={() => onDrawerClose('closeManagment', false, false)} />

      <ConfirmDialog />
    </Stack>
  )
}
