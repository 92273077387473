import { RemarkAuditUser } from 'api/remarks/types'
import { AuditMails, LegendColors, OtherPaginationProps } from 'core/types/global'
import {
  REMARK_CREATED_STATUS_COLOR,
  REMARK_SENT_STATUS_COLOR,
  REMARK_DISCARDED_STATUS_COLOR,
  REMARK_COMPLETE_STATUS_COLOR,
} from 'utils/constants'
import { TCallStatus } from './types'
import { TInspectionStatus } from 'core/types/call'

export interface GetCallAuditRequest extends OtherPaginationProps {
  projectId: number
  callId: number
}

export interface GetCallAuditResponse {
  data: CallAudit[]
}

export type CallAuditAction = 'CREATE' | 'STATUS' | 'INSPECTION_STATUS' | 'INSPECTION_RESPONSIBLE' | 'INSPECTION_REPEAT'

export interface CallAudit {
  action: CallAuditAction
  inspectionResponsible: IAuditInspectionResponsible | null
  inspectionStatus: IAuditInspectionStatus | null
  mailRecipients: AuditMails[] | null
  previous: TCallStatus | null
  timestamp: string
  user: RemarkAuditUser
  value: TCallStatus
}

export const callStatusesColor: Record<keyof typeof callStatusesBackend, LegendColors> = {
  CREATED: REMARK_CREATED_STATUS_COLOR,
  SENT: REMARK_SENT_STATUS_COLOR,
  CANCELLED: REMARK_DISCARDED_STATUS_COLOR,
  COMPLETE: REMARK_COMPLETE_STATUS_COLOR,
}

export enum callStatusesClient {
  'Создан' = 'Создан',
  'Направлен' = 'Направлен',
  'Отменен' = 'Отменен',
  'Завершен' = 'Завершен',
}

export enum callStatusesBackend {
  'CREATED' = 'CREATED',
  'SENT' = 'SENT',
  'CANCELLED' = 'CANCELLED',
  'COMPLETE' = 'COMPLETE',
}

export const callStatusesData: Record<callStatusesBackend, callStatusesClient> = {
  [callStatusesBackend.CREATED]: callStatusesClient.Создан,
  [callStatusesBackend.SENT]: callStatusesClient.Направлен,
  [callStatusesBackend.CANCELLED]: callStatusesClient.Отменен,
  [callStatusesBackend.COMPLETE]: callStatusesClient.Завершен,
}

export interface IAuditInspectionStatus {
  current: TInspectionStatus | null
  previous: TInspectionStatus | null
  description: string | null
}

export interface IAuditInspectionResponsible {
  current: IAuditInspectionResponsibleItem | null
  previous: IAuditInspectionResponsibleItem | null
}

export interface IAuditInspectionResponsibleItem {
  name: string
  company: string
}
