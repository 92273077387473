import { useSnackbar } from 'notistack'
import { ButtonWrapper } from './AddMediaButton.styles'
import { AddMediaButtonProps } from './AddMediaButton.types'
import { ChangeEvent, FC } from 'react'
import { TFileExtension } from 'core/types/file'
import { MAX_FILE_SIZE_IN_BYTES, MAX_FILE_SIZE_IN_MB } from 'utils/constants'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'

export const AddMediaButton: FC<AddMediaButtonProps> = ({
  id,
  onChange,
  acceptedExtensions = [],
  children,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const onChangeDecorator = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    if (file.size > MAX_FILE_SIZE_IN_BYTES) {
      enqueueSnackbar(`Файл не должен весить больше ${MAX_FILE_SIZE_IN_MB} МБ.`, {
        variant: 'error',
        autoHideDuration: 3000,
      })

      return
    }

    const fileExtension = `.${file.name.split('.').slice(-1)[0].toLocaleLowerCase()}` as TFileExtension

    if (acceptedExtensions.includes(fileExtension) || !acceptedExtensions.length) onChange(e)
    else enqueueSnackbar('Неверный формат файла.', { variant: 'error' })
  }

  return (
    <Tooltip title='Добавить файл' bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
      <label htmlFor={id}>
        <input
          accept={acceptedExtensions.join(',')}
          id={id}
          type='file'
          onChange={onChangeDecorator}
          onClick={(e: React.BaseSyntheticEvent) => (e.target.value = '')}
          style={{ display: 'none' }}
          disabled={disabled}
        />
        {disabled ? children : <ButtonWrapper>{children}</ButtonWrapper>}
      </label>
    </Tooltip>
  )
}
