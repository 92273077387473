import { MediaTextarea } from './MediaDescription.styled'
import { MediaDescriptionProps } from './MediaDescription.types'
import { Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { EliminationFormData } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.types'
import { RemarkFormData } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { FC, useCallback } from 'react'
import { theme } from 'styles/theme'

export const MediaDescription: FC<MediaDescriptionProps> = ({ media, viewingOnly }) => {
  const { values, setFieldValue } = useFormikContext<RemarkFormData | EliminationFormData>()
  const { mediaCommentById, newMediaCommentById } = values || {}
  const isNewMedia = !media.id
  const descriptionFieldName = isNewMedia
    ? `newMediaCommentById[${media.temporaryId}]`
    : `mediaCommentById[${media.id}]`
  const descriptionFieldValue = isNewMedia ? newMediaCommentById?.[media.temporaryId] : mediaCommentById?.[media.id]

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    isNewMedia
      ? setFieldValue('newMediaCommentById', { ...newMediaCommentById, [media.temporaryId]: e.target.value })
      : setFieldValue('mediaCommentById', { ...mediaCommentById, [media.id]: e.target.value })
  }

  return (
    <Stack spacing={0.5}>
      <Typography variant='body2' color={theme.palette.secondary.dark} textAlign='start'>
        Описание:
      </Typography>

      <MediaTextarea
        value={descriptionFieldValue}
        onChange={onFieldChange}
        placeholder={viewingOnly ? '' : 'Укажите описание'}
        readOnly={viewingOnly}
      />

      {/* 
      <FieldForm
        version='project'
        name={descriptionFieldName}
        onChange={onFieldChange}
        placeholder={viewingOnly ? '' : 'Укажите описание'}
        multiline
        helperText=''
        inputProps={{
          readOnly: viewingOnly,
          style: {
            height: '86px',
            overflow: 'auto'
          }
        }}
      /> */}
    </Stack>
  )
}
