import { DocumentManagment } from './components/DocumentManagment'
import { DocumentsTable } from './components/DocumentsTable'
import { Stack } from '@mui/material'
import { Header } from 'components/Header'
import { TableManagment } from 'components/TableManagment'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { InnerContainer, MainContainer } from 'layouts/MainLayout'
import { Document } from 'pages/Document/Document'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { clearChosenTypesIdsIdLocal, submitFilter } from 'store/slices/documents'
import { setShouldResetPage } from 'store/slices/infiniteScroll'
import { profileSelector } from 'store/slices/profile'
import { useAppDispatch, useTypedSelector } from 'store/store'

export const Documents: FC = () => {
  const navigate = useNavigate()
  const { projectId: projectIdString, docId: docIdString } = useParams()
  const dispatch = useAppDispatch()
  const projectId = Number(projectIdString)
  const docId = Number(docIdString)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { role } = useTypedSelector(profileSelector)
  const viewingOnly: boolean = role === 'client' || role === 'contractor'

  useEffect(() => {
    return () => {
      dispatch(clearChosenTypesIdsIdLocal())
      dispatch(submitFilter())
    }
  }, [])

  useEffect(() => {
    dispatch(clearChosenTypesIdsIdLocal())
    dispatch(submitFilter())
    dispatch(setShouldResetPage({ table: 'documents', shouldResetPage: true }))
  }, [projectId])

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/documents',
  })

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Документы' },
    ],
    [isMenuOpen, project, docId],
    isFetching,
  )

  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const onReturnClick = useCallback(() => {
    navigate(`/project/${projectId}`)
  }, [projectId])

  return (
    <>
      {docId ? (
        <Document />
      ) : (
        <MainContainer>
          <Header />
          <InnerContainer>
            <Outlet />
            <Stack flex={1} maxWidth='100%'>
              <Stack pr={2.5}>
                <TableManagment onReturnClick={onReturnClick} onAddClick={!viewingOnly ? onDrawerOpen : undefined} />
              </Stack>

              <DocumentsTable key={projectId} />

              <DocumentManagment open={isDrawerOpen} onClose={onDrawerClose} />

              {ProjectsMenu}
            </Stack>
          </InnerContainer>
        </MainContainer>
      )}
    </>
  )
}
