import { ICellRendererParams } from 'ag-grid-community'
import { ICallFull } from 'api/calls/types'
import { MutableRefObject } from 'react'

export interface ITooltipCellProps extends ICellRendererParams<ICallFull> {}

export const isTextOverflowed = (ref: MutableRefObject<any>) => {
  const { scrollWidth = 0, clientWidth = 0, scrollHeight = 0, clientHeight = 0 } = ref.current || {}

  return scrollHeight > clientHeight || scrollWidth > clientWidth
}
