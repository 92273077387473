import { uiContext } from 'contexts/ui'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { FC, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { ResetButton, SettingsWrapper, TabsWrapper } from './AdminSettings.styles'
import { IntegrationSettings } from './components/IntegrationSettings'
import useSearchParams from 'hooks/useSearchParams'
import {
  adminSettingsTabs,
  adminSettingsTabsData,
  TAdminSettingsDialogTrigger,
  TAdminSettingsTab,
} from './AdminSettings.types'
import { StatusSettings } from './components/StatusSettings'
import { Tabs } from 'components/Tabs'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { Close as CloseIcon } from '@mui/icons-material'
import { useResetStatusSettingsMutation } from 'api/settings'

export const AdminSettings: FC = () => {
  const cUI = useContext(uiContext)
  const searchParams = useSearchParams()
  const initialTab: TAdminSettingsTab = 'status'
  const currentTab = searchParams.get('tab') as TAdminSettingsTab
  const [resetStatusSettings, resetStatusSettingsResponse] = useResetStatusSettingsMutation()

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => cUI.setShowSearch(true)
  }, [])

  useBreadcrumbs([{ title: 'Настройки' }])

  useEffect(() => {
    if (!adminSettingsTabs.includes(currentTab)) searchParams.set('tab', initialTab)
  }, [currentTab])

  const contentByTab = useMemo(() => {
    const data: Record<TAdminSettingsTab, ReactNode> = {
      integration: <IntegrationSettings />,
      status: <StatusSettings resetStatusSettingsResponse={resetStatusSettingsResponse} />,
    }

    return data[currentTab]
  }, [currentTab, resetStatusSettingsResponse])

  const handleExitConfirm = (confirm: boolean, tabValue: TAdminSettingsTab) => {
    if (confirm) searchParams.set('tab', tabValue)
  }

  const handleResetStatusSettings = (confirm: boolean, tabValue: TAdminSettingsTab) => {
    if (confirm) resetStatusSettings()
  }

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TAdminSettingsDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    resetStatusSettings: {
      handleConfirm: handleResetStatusSettings,
      title: 'Сбросить все настройки статусов?',
      body: 'Настройки статусов будут восстановлены.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: TAdminSettingsTab) => {
    const isDirtyForm = !!localStorage.getItem('dirtyForm')

    if (isDirtyForm) openConfirm(tabValue)
    else searchParams.set('tab', tabValue)
  }, [])

  const onResetStatusSettingsClick = () => {
    setConfirmDialogTrigger('resetStatusSettings')
    openConfirm()
  }

  return (
    <SettingsWrapper>
      <TabsWrapper>
        <Tabs<TAdminSettingsTab>
          currentTab={currentTab}
          onTabChange={onTabChange}
          tabsData={adminSettingsTabsData}
          tabProps={{ sx: { p: 1.25, minHeight: 60 } }}
        />

        {currentTab === 'status' && (
          <ResetButton
            onClick={onResetStatusSettingsClick}
            variant='text'
            startIcon={<CloseIcon color='error' />}
            color='error'
          >
            Сбросить все настройки
          </ResetButton>
        )}
      </TabsWrapper>

      {contentByTab}

      <ConfirmDialog />
    </SettingsWrapper>
  )
}
