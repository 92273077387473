import { ProjectIntegrationWrapper } from './ProjectIntegrationDrawer.styles'
import {
  ProjectDialogTrigger,
  ProjectIntegrationDrawerProps,
  TProjectIntegrationSliderType,
  TProjectSettingsDrawerTab,
} from './ProjectIntegrationDrawer.types'
import { Drawer, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'
import { TabData } from 'components/Tabs'
import { ToggleButtonGroup } from 'components/ToggleButtonGroup'
import { ProjectIntegrationSettings } from './components/ProjectIntegrationSettings'
import { ProjectNotificationSettings } from './components/ProjectNotificationSettings'

export const ProjectIntegrationDrawer: FC<ProjectIntegrationDrawerProps> = ({ isOpen, onClose, project }) => {
  const initialTab: TProjectSettingsDrawerTab = 'integration'
  const [currentTab, setCurrentTab] = useState<TProjectSettingsDrawerTab>(initialTab)
  const [dirty, setDirty] = useState<boolean>(false)
  const [openedSlider, setOpenedSlider] = useState<TProjectIntegrationSliderType | null>(null)

  useEffect(() => {
    if (isOpen) setCurrentTab(initialTab)
  }, [isOpen])

  const tabsData: TabData<TProjectSettingsDrawerTab>[] = [
    { value: 'integration', label: 'Интеграции по API' },
    { value: 'notification', label: 'Уведомления' },
  ]

  const onChangeTab = (tab: TProjectSettingsDrawerTab, immediately?: boolean) => {
    if (tab === currentTab || openedSlider) return

    if (!dirty || immediately) setCurrentTab(tab)
    else {
      setConfirmDialogTrigger('changeTab')
      openConfirm(tab)
    }
  }

  const onSliderOpen = (sliderType: TProjectIntegrationSliderType) => {
    setOpenedSlider(sliderType)
  }

  const onSliderClose = useCallback((dialogType: ProjectDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) setOpenedSlider(null)
    else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const onResetNotificationSettings = (confirmAction: () => void) => {
    setConfirmDialogTrigger('resetNotificationSettings')
    openConfirm(confirmAction)
  }

  const handleCloseDrawerConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
      setOpenedSlider(null)
      setDirty(false)
    }
  }, [])

  const handleCloseSliderConfirm = useCallback((confirm: boolean) => {
    if (confirm) setOpenedSlider(null)
  }, [])

  const handleChangeTab = useCallback((confirm: boolean, tab: TProjectSettingsDrawerTab) => {
    if (confirm) {
      setCurrentTab(tab)
      setDirty(false)
    }
  }, [])

  const handleResetNotificationSettings = useCallback((confirm: boolean, confirmAction: () => void) => {
    if (confirm) confirmAction()
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<ProjectDialogTrigger>('closeDrawer')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeDrawer: { handleConfirm: handleCloseDrawerConfirm },
    closeSlider: { handleConfirm: handleCloseSliderConfirm },
    changeTab: {
      handleConfirm: handleChangeTab,
      title: 'Сменить режим формы?',
      body: 'Режим формы будет изменён, а все введенные данные безвозвратно утеряны.',
    },
    resetNotificationSettings: {
      handleConfirm: handleResetNotificationSettings,
      title: 'Удалить e-mail?',
      body: 'Почта будет удалена, а настройка уведомлений по вызовам деактивирована.',
    },
  }

  const { ConfirmDialog, openConfirm, isConfirmDialogOpened } = useConfirmDialog(
    dataForConfirmDialog[confirmDialogTrigger],
  )

  const contentByTab = useMemo(() => {
    const data: Record<TProjectSettingsDrawerTab, ReactNode> = {
      integration: (
        <ProjectIntegrationSettings
          project={project}
          isConfirmDialogOpened={isConfirmDialogOpened}
          openedSlider={openedSlider}
          onSliderOpen={onSliderOpen}
          onSliderClose={onSliderClose}
        />
      ),
      notification: (
        <ProjectNotificationSettings
          project={project}
          setDirty={setDirty}
          onResetSettings={onResetNotificationSettings}
        />
      ),
    }

    return data[currentTab]
  }, [currentTab, openedSlider, isConfirmDialogOpened])

  const onDrawerClose = useCallback(() => {
    if (isConfirmDialogOpened) return

    if (dirty) {
      setConfirmDialogTrigger('closeDrawer')
      openConfirm()
    } else {
      onClose()
      setOpenedSlider(null)
      setDirty(false)
    }
  }, [isConfirmDialogOpened, openedSlider, dirty])

  return (
    <Drawer onClick={(e) => e.stopPropagation()} anchor='right' open={isOpen} onClose={onDrawerClose}>
      <ProjectIntegrationWrapper width='400px' height='100%'>
        <Stack height='100%'>
          <DrawerTopBar>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Настройки проекта
            </Typography>
          </DrawerTopBar>

          <Divider />

          <Stack padding={2.5}>
            <ToggleButtonGroup<TProjectSettingsDrawerTab>
              size={36}
              onToggleButtonChange={(e: React.SyntheticEvent, toggleValue: TProjectSettingsDrawerTab) =>
                onChangeTab(toggleValue)
              }
              toggleButtonData={tabsData
                .filter((tab) => !tab.hidden)
                .map((tab) => ({ value: tab.value, label: tab.label }))}
              currentValue={currentTab}
            />
          </Stack>

          {contentByTab}
        </Stack>
        <FormButtonWrapper padding={2.5} spacing={1}>
          <Button
            size='medium'
            style={{
              maxWidth: '100%',
            }}
            fullWidth
            onClick={onDrawerClose}
          >
            Закрыть
          </Button>
        </FormButtonWrapper>
      </ProjectIntegrationWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
