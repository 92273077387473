import { Reference, basicReferences } from '../../References.types'
import { TableHead, Row, Cell, Head, TableBodyWrapper, TableBody, Body, TableHeadWrapper } from './Table.styles'
import { TableProps } from './Table.types'
import { TableItem } from './TableItem'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useGetReferenceDataQuery } from 'api/references'
import { EmptyPage } from 'components/EmptyPage'
import { EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { Tooltip } from 'components/Tooltip'
import { useSearch } from 'hooks/useSearch'
import { getEmptyPageData } from 'pages/Projects'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const Table: FC<TableProps> = ({ viewingOnly }) => {
  const { projectId, referenceId } = useParams()
  const { searchValue } = useSearch()

  const getCurrentReference = useMemo(
    (): Reference => basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0],
    [referenceId],
  )

  const { data: referenceData, isLoading } = useGetReferenceDataQuery({
    url: projectId
      ? getCurrentReference.api.getList.projectUrl.replace('{project}', projectId)
      : getCurrentReference.api.getList.url,
    page: 1,
    num: 99999,
  })

  const filteredBySearch = useMemo(() => {
    return searchValue && referenceData?.data?.length
      ? referenceData.data?.filter((item: any) => {
          for (let key in item) {
            if (key !== 'id' && String(item[key]).toLowerCase().includes(searchValue.toLowerCase())) return item
          }
        })
      : referenceData?.data
  }, [referenceData, searchValue, viewingOnly])

  const emptyPageData: EmptyPageData = getEmptyPageData(<>Данные в справочнике отсутствуют.</>)

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют позиции справочника, соответствующие результатам запроса.</>,
  )

  const renderColumns = useMemo(() => {
    const widthCell = ['18.56%', '16.88%', '20.56%', '44%']
    if (!getCurrentReference.doubleRow?.length) {
      const columns = getCurrentReference.columns
      return (
        <Row>
          {columns.map((column) => (
            <Cell key={column.id} cellType={getCurrentReference.id}>
              {column.name}
            </Cell>
          ))}
        </Row>
      )
    }
    const columns = getCurrentReference.doubleRow || []

    return columns.map((column, index) => {
      return (
        <Row key={index}>
          {column.map((column, cellIndex) => {
            const colSpan = column.name === 'Нумерация документа' ? 2 : undefined
            const rowSpan = index === 0 && column.name !== 'Нумерация документа' ? 2 : undefined
            const width =
              getCurrentReference.id !== 'PROJECT_MEMBER' && index === 0
                ? widthCell[cellIndex]
                : getCurrentReference.id !== 'PROJECT_MEMBER' && index > 0
                  ? 'auto'
                  : undefined
            return (
              <Cell
                widthCell={width}
                colSpan={colSpan}
                rowSpan={rowSpan}
                key={column.id}
                cellType={getCurrentReference.id}
              >
                <Stack direction='row' justifyContent='center' alignItems='center'>
                  {column.name}
                  {column.tooltipTxt && (
                    <Tooltip
                      title={column.tooltipTxt}
                      bgColor={theme.palette.bg.white}
                      textColor={theme.palette.text.dark}
                      style={{ marginLeft: '4px' }}
                    >
                      <InfoIcon fontSize='inherit' />
                    </Tooltip>
                  )}
                </Stack>
              </Cell>
            )
          })}
        </Row>
      )
    })
  }, [getCurrentReference])

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!referenceData?.data?.length && (
        <>
          <TableHeadWrapper>
            <TableHead>
              <Head>{renderColumns}</Head>
            </TableHead>
          </TableHeadWrapper>
          <TableBodyWrapper>
            {filteredBySearch?.length ? (
              <TableBody>
                <Body>
                  {filteredBySearch?.map((item: any) => (
                    <TableItem key={item.id} item={item} viewingOnly={viewingOnly} />
                  ))}
                </Body>
              </TableBody>
            ) : (
              <Stack height='100%' flexDirection='row'>
                <EmptyPage data={emptyFilteredData} forFilter />
              </Stack>
            )}
          </TableBodyWrapper>
        </>
      )}

      {!isLoading && !referenceData?.data?.length && (
        <Stack height='100%' flexDirection='row'>
          <EmptyPage data={emptyPageData} fullPage />
        </Stack>
      )}
    </>
  )
}
