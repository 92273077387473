import {
  EliminationResultDrawerWrapper,
  EliminationResultTabItem,
  EliminationResultTabs,
} from './EliminationResultDrawer.styles'
import {
  EliminationResultDialogTrigger,
  EliminationResultDrawerProps,
  EliminationResultDrawerTab,
} from './EliminationResultDrawer.types'
import { DocumentCreationForm } from './components/DocumentCreationForm'
import { DocumentViewingForm } from './components/DocumentViewingForm'
import { Drawer, Stack, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { TabData } from 'components/Tabs'
import { ToggleButtonGroup } from 'components/ToggleButtonGroup'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FC, useCallback, useEffect, useState } from 'react'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const EliminationResultDrawer: FC<EliminationResultDrawerProps> = ({
  isOpen,
  onClose,
  prescriptionDateStart,
  prescriptionStatus,
}) => {
  const [dirty, setDirty] = useState<boolean>(false)
  const initialTab: EliminationResultDrawerTab = 'view'
  const [currentTab, setCurrentTab] = useState<EliminationResultDrawerTab>(initialTab)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess

  useEffect(() => {
    isOpen && setCurrentTab(initialTab)
  }, [isOpen])

  const tabsData: TabData<EliminationResultDrawerTab>[] = [
    { value: 'view', label: 'Документы по устранению' },
    {
      value: 'create',
      label: 'Создание документа',
      hidden: prescriptionStatus === 'CREATED' || prescriptionStatus === 'DISCARDED' || viewingOnly,
    },
  ]

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  const onChangeTab = (tab: EliminationResultDrawerTab, immediately?: boolean) => {
    if (tab === currentTab) return

    if (!dirty || immediately) setCurrentTab(tab)
    else {
      setConfirmDialogTrigger('changeTab')
      openConfirm(tab)
    }
  }

  const handleChangeTab = useCallback((confirm: boolean, tab: EliminationResultDrawerTab) => {
    if (confirm) {
      setCurrentTab(tab)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<EliminationResultDialogTrigger>('changeTab')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    changeTab: {
      handleConfirm: handleChangeTab,
      title: 'Сменить режим формы?',
      body: 'Режим формы будет изменён, а все введенные данные безвозвратно утеряны.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onClose(dirty)}>
      <EliminationResultDrawerWrapper>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Результат устранения
          </Typography>
        </DrawerTopBar>

        <Divider />

        <Stack padding={2.5}>
          <ToggleButtonGroup<EliminationResultDrawerTab>
            size={36}
            onToggleButtonChange={(e: React.SyntheticEvent, toggleValue: EliminationResultDrawerTab) =>
              onChangeTab(toggleValue)
            }
            toggleButtonData={tabsData
              .filter((tab) => !tab.hidden)
              .map((tab) => ({ value: tab.value, label: tab.label }))}
            currentValue={currentTab}
          />
        </Stack>

        {currentTab === 'view' && (
          <DocumentViewingForm viewingOnly={viewingOnly} onFormChange={onFormChange} onClose={onClose} />
        )}

        {currentTab === 'create' && (
          <DocumentCreationForm
            onFormChange={onFormChange}
            onClose={onClose}
            prescriptionDateStart={prescriptionDateStart}
            onChangeTab={onChangeTab}
          />
        )}
      </EliminationResultDrawerWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
