import { StatisticalWidgetWrapper } from './StatisticalWidget.styles'
import { StatisticalWidgetProps } from './StatisticalWidget.types'
import { StatisticalChart } from './components/StatisticalChart'
import { StatisticalChartData } from './components/StatisticalChart/StatisticalChart.types'
import { StatisticalTable } from './components/StatisticalTable'
import { Stack } from '@mui/material'
import { useGetPrescriptionStatisticalStatsQuery } from 'api/analytics'
import { Progress } from 'components/Progress'
import { TPrescriptionStatus } from 'core/types/prescription'
import { AnalyticsWidgetItem } from 'pages/Analytics/Analytics.styles'
import { WidgetHeader } from 'pages/Analytics/components/WidgetHeader'
import { FC } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const StatisticalWidget: FC<StatisticalWidgetProps> = ({ analyticsQueryData }) => {
  const { role } = useTypedSelector(profileSelector)
  const { queryBody, skip } = analyticsQueryData
  const { data: prescriptionStatisticalStats, isFetching } = useGetPrescriptionStatisticalStatsQuery(queryBody, skip)
  const { data: prescriptionStatisticalData } = prescriptionStatisticalStats || {}

  const getStatisticalChartData = (): StatisticalChartData => {
    const prescriptionStatuses: TPrescriptionStatus[] = ['CREATED', 'DISCARDED', 'SENT', 'COMPLETE']
    const outerData: number[] = []
    const innerData: number[] = []

    prescriptionStatuses.forEach((status) => {
      const statusKey = status.toLowerCase() as TPrescriptionStatus

      if (status === 'CREATED' && role === 'contractor') {
        outerData.push(0)
        innerData.push(0, 0)

        return
      }

      const { total, expired } = prescriptionStatisticalData || {}
      const expiredByStatus = expired?.[statusKey]?.data || 0
      const activeByStatus = (total?.[statusKey]?.data || 0) - expiredByStatus

      outerData.push(total?.[statusKey]?.data || 0)
      innerData.push(expiredByStatus, activeByStatus)
    })

    return { outerData, innerData }
  }

  return (
    <AnalyticsWidgetItem width='100%' spacing={1.5} padding='20px 30px !important' minHeight='246px'>
      <WidgetHeader
        title='Статистические показатели'
        tooltipTitle='В статистике учитываются предписания с датой предписания в выбранном временном интервале.'
        showLegend={false}
      />

      {isFetching ? (
        <Progress />
      ) : (
        <Stack style={{ overflow: 'auto hidden' }}>
          <StatisticalWidgetWrapper>
            <StatisticalTable data={prescriptionStatisticalData || null} role={role} />
            <StatisticalChart chartData={getStatisticalChartData()} />
          </StatisticalWidgetWrapper>
        </Stack>
      )}
    </AnalyticsWidgetItem>
  )
}
