import { Divider, Stack, Typography } from '@mui/material'
import styled from 'styled-components'

interface StatisticsCardItemWrapperProps {
  disabled?: boolean
}

interface IStatisticsTabItemProps {
  actived: boolean
}

export const StatisticsCardItemWrapper = styled(Stack)<StatisticsCardItemWrapperProps>`
  border: ${(props) => `1px solid ${props.theme.palette.bg.gray}`};
  border-radius: 20px;

  &:hover {
    border-color: ${(props) => !props.disabled && props.theme.palette.primary.main};
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  padding-bottom: 4px;
  pointer-events: ${(props) => props.disabled && 'none'};

  & > .MuiTypography-root {
    margin-bottom: 8px;
  }
`

export const StatisticsWrapper = styled(Stack)``

export const StatisticsWrapperColumnTitle = styled(Typography)`
  font-weight: 500;
  font-size: 11px;
  line-height: normal;
  color: ${(props) => props.theme.palette.secondary.main};
`

export const StatisticsTabsWrapper = styled(Stack)`
  align-items: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 2px;
  border-radius: 6px;
`

export const StatisticsTabItem = styled.div<IStatisticsTabItemProps>`
  padding: 6px;
  background-color: ${(props) => (props.actived ? props.theme.palette.bg.white : 'transparent')};
  opacity: ${(props) => (props.actived ? 1 : 0.8)};
  border-radius: 6px;
  min-width: 48px;
`

export const StatisticsTabDivider = styled(Divider)`
  height: 16px;
  background-color: ${(props) => props.theme.palette.legends.paleBlue};
`
