import { ColumnsDrawer } from '../../../Prescriptions/components/ColumnsDrawer'
import { ReturnIconButton } from './RemarksLegend.styles'
import { ColumnSettings, RemarksLegendProps } from './RemarksLegend.types'
import { Badge, Button, Stack } from '@mui/material'
import { UseQuery } from 'api/api.types'
import {
  useDownloadPrescriptionsTableMutation,
  useGetColumnSettingsQuery,
  useGetPrescriptionsHavingStatusQuery,
  useImportPrescriptionFilesMutation,
  useImportPrescriptionsMutation,
  useLazyGetImportPrescriptionFilesResultQuery,
  useLazyGetImportPrescriptionsResultQuery,
  useLazyGetPrescriptionsLinkExampleQuery,
  useLazyGetPrescriptionsTableExportResultQuery,
} from 'api/prescriptions'
import { useGetRemarksColumnSettingsQuery, useGetRemarksHavingStatusQuery } from 'api/remarks'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import React, { FC, useState, MouseEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Add as AddIcon, ArrowBack as ArrowBackIcon, MoreVert as MenuIcon } from '@mui/icons-material'
import { Menu } from 'components/Menu'
import { IMenuData } from 'components/Menu/Menu.types'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { downloadMedia } from 'utils/downloadMedia'
import { UploadDrawer } from 'components/UploadDrawer'
import { getTimestamp } from 'utils/dates/getTimestamp'
import { MAX_FILE_SIZE_IN_MB } from 'utils/constants'

export const RemarksLegend: FC<RemarksLegendProps> = ({
  accessToAdd,
  createButtonData,
  children,
  columnSettings,
  sortableColumn = null,
  filterData = null,
  managmentButton = true,
}) => {
  const navigate = useNavigate()
  const { projectId: projectIdString } = useParams()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const projectId = Number(projectIdString)
  const { role } = useTypedSelector(profileSelector)
  const [downloadPrescriptionsTable, downloadPrescriptionsTableResponse] = useDownloadPrescriptionsTableMutation()
  const [getPrescriptionsTableExportResult, prescriptionsTableExportResult] =
    useLazyGetPrescriptionsTableExportResultQuery()
  const { enqueueSnackbar } = useSnackbar()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isImportDataDrawerOpen, setIsImportDataDrawerOpen] = useState(false)
  const [isImportFilesDrawerOpen, setIsImportFilesDrawerOpen] = useState(false)
  const [importPrescriptions, importPrescriptionsResponse] = useImportPrescriptionsMutation()
  const [importPrescriptionFiles, importPrescriptionFilesResponse] = useImportPrescriptionFilesMutation()
  const { data: isRemarksExist } = useGetRemarksHavingStatusQuery(projectId, { skip: !managmentButton })
  const { data: isPrescriptionsExist } = useGetPrescriptionsHavingStatusQuery(projectId, { skip: !managmentButton })

  const [columnsDrawerOpen, setColumnsDrawerOpen] = useState<boolean>(false)
  const onColumnsDrawerOpen = () => {
    setColumnsDrawerOpen(true)
  }

  const columnSettingsQueries: Record<ColumnSettings, UseQuery<any, any>> = {
    prescriptions: useGetColumnSettingsQuery,
    remarks: useGetRemarksColumnSettingsQuery,
  }

  const query = columnSettings && columnSettingsQueries[columnSettings]

  // confirm close
  const handleExitConfirm = (confirm: boolean) => {
    if (confirm) {
      setColumnsDrawerOpen(false)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleExitConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onColumnsDrawerClose = (dirty: boolean) => {
    dirty ? openConfirm() : setColumnsDrawerOpen(false)
  }

  const onAddClick = () => {
    navigate(createButtonData.href, createButtonData?.hrefState || undefined)
  }

  const onReturnClick = () => {
    navigate(`/project/${projectId}`)
  }

  const onOpenManagment = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseManagment = () => {
    setAnchorEl(null)
  }

  const onDownloadClick = () => {
    setIsDownloading(true)
    downloadPrescriptionsTable({
      projectId,
      date: getTimestamp(),
      sort: sortableColumn,
      filter: filterData,
    })
  }

  const { data: columnsData } = query?.({ projectId }, { skip: !columnSettings }) || {}

  const onImportDataDrawerClick = () => {
    setIsImportDataDrawerOpen((p) => !p)
  }

  const onImportFilesDrawerClick = () => {
    setIsImportFilesDrawerOpen((p) => !p)
  }

  const onImportClick = (file: File) => {
    importPrescriptions({ projectId, file })
  }

  const onImportFilesClick = (file: File) => {
    importPrescriptionFiles({ projectId, file })
  }

  const managmentData: IMenuData[] = [
    {
      label: 'Импорт данных',
      action: onImportDataDrawerClick,
      tooltipTitle: 'Добавление предписаний и замечаний при помощи Excel файла',
      hidden: role !== 'admin',
    },
    {
      label: 'Импорт файлов',
      action: onImportFilesDrawerClick,
      tooltipTitle: 'Множественное добавление файлов в список предписаний и замечаний',
      hidden: role !== 'admin' || (!isRemarksExist && !isPrescriptionsExist),
    },
    {
      label: 'Экспорт данных',
      action: onDownloadClick,
      tooltipTitle: 'Экспорт предписаний и замечаний в Excel файл',
      notCloseAfterClick: true,
      loading: isDownloading,
      hidden: false,
    },
  ]

  useMutationHandlers(downloadPrescriptionsTableResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    getPrescriptionsTableExportResult({ projectId, exportId: data.data })
  })

  useMutationHandlers(prescriptionsTableExportResult, (data) => {
    downloadMedia(data.link)
    enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
    setIsDownloading(false)
    setAnchorEl(null)
  })

  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ py: 1.5, pr: 2.5 }}>
        <Stack direction='row' alignItems='center' spacing={1.5}>
          <ReturnIconButton onClick={onReturnClick} color='secondary'>
            <ArrowBackIcon color='primary' />
          </ReturnIconButton>
          {accessToAdd && (
            <Button
              // component={Link}
              // to={createButtonData.href, }
              onClick={onAddClick}
              variant='outlined'
              color='secondary'
              startIcon={<AddIcon />}
            >
              {createButtonData.text}
            </Button>
          )}
          {children}
        </Stack>

        <Stack direction='row' spacing={2}>
          {columnSettings && (
            <Badge
              invisible={!!columnsData?.default}
              color='primary'
              badgeContent=''
              variant='dot'
              componentsProps={{ badge: { style: { height: 10, width: 10, borderRadius: '50%' } } }}
            >
              <Button onClick={onColumnsDrawerOpen} variant='text' color='secondary'>
                Настройки отображения
              </Button>
            </Badge>
          )}

          {managmentButton && (
            <>
              <Button
                onClick={onOpenManagment}
                color='secondary'
                variant='outlined'
                endIcon={<MenuIcon />}
                style={{
                  maxWidth: '100%',
                  padding: '8px 8px 6px 16px',
                  justifyContent: 'space-between',
                  minWidth: '250px',
                }}
              >
                Управление
              </Button>

              <Menu anchorEl={anchorEl} title='Управление' onClose={onCloseManagment} menuData={managmentData} />
            </>
          )}
        </Stack>
      </Stack>
      {columnSettings && (
        <ColumnsDrawer
          open={columnsDrawerOpen}
          columnsData={columnsData}
          columnSettings={columnSettings}
          onClose={onColumnsDrawerClose}
        />
      )}
      <ConfirmDialog />

      <UploadDrawer
        open={isImportDataDrawerOpen}
        mode='single'
        onSubmit={onImportClick}
        importResponse={importPrescriptionsResponse}
        onClose={onImportDataDrawerClick}
        title='Импорт данных'
        text='Вы можете добавить предписания и замечания при помощи exсel-документа.'
        attentionText='При импорте учитывается наличие указываемых значений в связанных справочниках проекта. При совпадении
      номера сущности из файла с существующими в системе обновление проекта не будет произведено.'
        uploadData={{
          title: 'Загрузить xls(х)',
          text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
          formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
        }}
        getExampleLinkQuery={useLazyGetPrescriptionsLinkExampleQuery}
        getExampleLinkQueryParams={{ projectId }}
        getImportResultQuery={useLazyGetImportPrescriptionsResultQuery}
        getImportResultQueryParams={{ projectId }}
        showUpdatedItems={false}
        addedItemsText='Добавлено новых предписаний'
      />

      <UploadDrawer
        open={isImportFilesDrawerOpen}
        mode='single'
        onSubmit={onImportFilesClick}
        importResponse={importPrescriptionFilesResponse}
        onClose={onImportFilesDrawerClick}
        title='Импорт файлов'
        resultDrawerTitle='Информация об импорте'
        text={
          <Stack spacing={1}>
            <span>Вы можете добавить в замечания и предписания один или несколько файлов, используя ZIP-архив.</span>
            <span>
              Названия папок в архиве должны быть указаны в формате: Замечание/Предписание №N, где N — номер замечания
              или предписания.
            </span>
            <span>Для замечаний допустимы форматы: JPEG, PNG, PDF. Добавить файлы в повторные замечания нельзя.</span>
            <span>В предписания можно добавлять файлы в форматах: PDF, doc(x).</span>
          </Stack>
        }
        attentionText={
          <Stack spacing={1}>
            <span>
              Если номер предписания содержит косую черту (/), то её нужно заменить на подчеркивание (_) в названии
              папки.
            </span>
            <span>Размер архива и каждого файла не должен превышать {MAX_FILE_SIZE_IN_MB} МБ.</span>
          </Stack>
        }
        uploadData={{
          title: 'Загрузить файл',
          text: 'Чтобы загрузить файлы, выберите их из папки на компьютере или просто перетяните в эту область.',
          formats: ['application/zip', 'application/x-zip-compressed'],
        }}
        getImportResultQuery={useLazyGetImportPrescriptionFilesResultQuery}
        onlySuccess
      />
    </>
  )
}
