import {
  ArrowButton,
  CarouselViewerWrapper,
  CloseButton,
  PreviewWrapper,
  RemarkNumberWrapper,
} from './CarouselViewer.styles'
import { CarouselViewerProps } from './CarouselViewer.types'
import { ArrowBackIos as ArrowIcon } from '@mui/icons-material'
import { Person as PersonIcon, CancelOutlined as CloseIcon } from '@mui/icons-material'
import { Dialog, Stack, Typography } from '@mui/material'
import { SpecialZoomLevel } from '@react-pdf-viewer/core'
import { PdfViewerProps } from 'components/PdfViewer'
import PdfViewer from 'components/PdfViewer/PdfViewer'
import { MediaNumber } from 'pages/Remarks/components/RemarkForm/Media/components/RemarkMediaItem/RemarkMediaItem.styles'
import { FC, useCallback, useEffect, useState } from 'react'
import { theme } from 'styles/theme'
import { downloadMedia } from 'utils/downloadMedia'

export const CarouselViewer: FC<CarouselViewerProps> = ({
  isOpen,
  itemInfo,
  isDisableArrows,
  onPrevClick,
  onNextClick,
  onClose,
  remarkNumber,
}) => {
  const { mediaIndex, name, src, format, isNewMedia, date, author, description } = itemInfo || {}
  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)
  const isImage = format?.includes('image')

  useEffect(() => {
    if (!isImage) {
      setOpenedPdfData({
        fileUrl: src || '',
        title: name || '',
        onDownload: downloadFile,
        onDelete: () => {},
        disableDelete: true,
      })
    } else setOpenedPdfData(null)
  }, [format, itemInfo])

  const downloadFile = () => {
    if (isNewMedia) {
      const link = document.createElement('a')
      link.href = src || ''
      link.download = name || ''
      link.click()
      URL.revokeObjectURL(src || '')
      link.remove()
    } else downloadMedia(src || '')
  }

  const onViewerClose = useCallback(() => {
    setOpenedPdfData(null)
    onClose()
  }, [])

  return (
    <Dialog open={isOpen} onClose={onViewerClose} maxWidth={false}>
      <CarouselViewerWrapper>
        <CloseButton onClick={onViewerClose}>
          <CloseIcon fontSize='large' color='secondary' />
        </CloseButton>

        <PreviewWrapper>
          {!isDisableArrows && (
            <ArrowButton onClick={onPrevClick}>
              <ArrowIcon fontSize='large' color='primary' />
            </ArrowButton>
          )}

          {isImage ? (
            <img src={src} alt='Файл' />
          ) : (
            openedPdfData !== null && (
              <PdfViewer
                {...openedPdfData}
                disableMultiView
                rootViewerProps={{ defaultScale: SpecialZoomLevel.ActualSize }}
              />
            )
          )}

          {!isDisableArrows && (
            <ArrowButton onClick={onNextClick} isRight={true}>
              <ArrowIcon fontSize='large' color='primary' />
            </ArrowButton>
          )}
        </PreviewWrapper>

        <Stack spacing={1} overflow='auto'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={2}>
              <MediaNumber>
                <Typography variant='body2' color={theme.palette.text.disabled} textAlign='center'>
                  {(mediaIndex || 0) + 1}
                </Typography>
              </MediaNumber>

              <Typography variant='body2' color={theme.palette.secondary.dark}>
                {date}
              </Typography>

              <Stack direction='row' spacing={0.5}>
                <PersonIcon fontSize='medium' color='secondary' />
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  {author}
                </Typography>
              </Stack>
            </Stack>

            {remarkNumber && (
              <RemarkNumberWrapper>
                <Typography variant='body2' color={theme.palette.secondary.dark}>
                  Замечание № {remarkNumber}
                </Typography>
              </RemarkNumberWrapper>
            )}
          </Stack>

          <Stack spacing={0.5} overflow='auto'>
            <Typography variant='body2' fontSize={12}>
              Описание:
            </Typography>
            <Stack overflow='auto'>
              <Typography variant='body2' color={theme.palette.text.dark}>
                {description || '—'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CarouselViewerWrapper>
    </Dialog>
  )
}
