import {
  CalendarHead,
  CalendarLink,
  CalendarWrapper,
  Cell,
  CellHead,
  DashboardCalendarCard,
} from './CalendarCard.styles'
import { CalendarCardProps } from './CalendarCard.types'
import { format, addDays, startOfWeek, startOfMonth, endOfMonth, isSameDay, isAfter, isBefore } from 'date-fns'

/**
 * Русская локаль для date-fns
 */
import ruLocale from 'date-fns/locale/ru'
import { FC, useMemo, useRef } from 'react'
import { getMonthName } from 'utils/getMonthName'

export const CalendarCard: FC<CalendarCardProps> = () => {
  /**
   * Текущая дата
   */
  const { current: date } = useRef(new Date())

  /**
   * Генератор ячеек
   */
  const renderCalendarCells = useMemo(() => {
    /* поменял начало недели на понедельник (по стандарту идет воскресенье) */
    const startDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 })
    const startMonth = startOfMonth(date)
    const endMonth = endOfMonth(date)
    const cells = []
    let day = startDate
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        const isBeforeCurrentMonth = isBefore(day, startMonth)
        const isAfterCurrentMonth = isAfter(day, endMonth)
        cells.push(
          <Cell
            color={isSameDay(day, date) ? '#6D9ADC' : undefined}
            disable={isAfterCurrentMonth || isBeforeCurrentMonth}
            key={format(day, 'yyyy-MM-dd')}
          >
            <span>{format(day, 'd')}</span>
            <div />
          </Cell>,
        )
        day = addDays(day, 1)
      }
    }

    return cells
  }, [date])

  return (
    <DashboardCalendarCard>
      <CalendarLink>Календарь {/*<ArrowBackIcon color='primary'/>*/}</CalendarLink>
      <CalendarHead>
        {getMonthName(date)}, {date.getFullYear()}
      </CalendarHead>
      <CalendarWrapper>
        <CellHead>ПН</CellHead>
        <CellHead>ВТ</CellHead>
        <CellHead>СР</CellHead>
        <CellHead>ЧТ</CellHead>
        <CellHead>ПТ</CellHead>
        <CellHead>СБ</CellHead>
        <CellHead>ВС</CellHead>
        {renderCalendarCells}
      </CalendarWrapper>
    </DashboardCalendarCard>
  )
}
