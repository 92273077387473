import { FC, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { CountCellWrapper, CountItem } from './CountCell.styles'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'
import { ICountCellProps } from './CountCell.types'

export const CountCell: FC<ICountCellProps> = ({ data, stackProps }) => {
  const tooltipText = useMemo(
    () => (
      <Stack>
        {data.map((item) => (
          <Typography variant='body2' fontSize={12} color={theme.palette.text.dark}>
            {item.tooltipText}: {item.value || '—'}
          </Typography>
        ))}
      </Stack>
    ),
    [data],
  )

  return (
    <Tooltip title={tooltipText} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
      <CountCellWrapper className='display-cell' {...stackProps}>
        {data.map((item) => (
          <CountItem color={item.color}>
            {item.icon}
            <Typography variant='subtitle2' style={{ color: item.color }}>
              {item.value || '—'}
            </Typography>
          </CountItem>
        ))}
      </CountCellWrapper>
    </Tooltip>
  )
}
