import { FileItemProps } from './FileItem.types'
import { InsertDriveFile as FileIcon, Download as DownloadIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { PdfViewerProps } from 'components/PdfViewer'
import { PdfViewerPopup } from 'pages/Prescriptions/components/PrescriptionsForm/components/PdfViewerPopup'
import { RemarkMediaItemButton } from 'pages/Remarks/components/RemarkForm/Media/components/RemarkMediaItem/RemarkMediaItem.styles'
import { FC, useState } from 'react'
import { theme } from 'styles/theme'

export const FileItem: FC<FileItemProps> = ({ name, format, link, onDownload, onRemoveFile, viewingOnly }) => {
  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)

  const openViewer = (fileUrl: string, title: string, onDownload: () => void, onDelete: () => void) => {
    const onDeleteWithClose = () => {
      onDelete()
      setOpenedPdfData(null)
    }

    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      onDelete: onDeleteWithClose,
      disableDelete: viewingOnly,
    })
  }

  const onViewerOpen = () => {
    openViewer(link, name, onDownload, onRemoveFile)
  }

  const onViewerClose = () => {
    setOpenedPdfData(null)
  }

  return (
    <Stack direction='row' justifyContent='space-between' width='100%'>
      <Stack
        onClick={format === 'pdf' ? onViewerOpen : undefined}
        style={{ cursor: format === 'pdf' ? 'pointer' : 'initial' }}
        direction='row'
        alignItems='center'
        spacing={0.5}
      >
        <FileIcon fontSize='small' color='primary' />
        <Typography variant='body2' color={theme.palette.primary.main} lineHeight='inherit'>
          {name}
        </Typography>
      </Stack>

      <Stack spacing={1} direction='row'>
        <RemarkMediaItemButton onClick={onDownload} type='button'>
          <DownloadIcon color='primary' fontSize='medium' />
        </RemarkMediaItemButton>

        <RemarkMediaItemButton onClick={onRemoveFile} type='button'>
          <DeleteIcon color='error' fontSize='medium' />
        </RemarkMediaItemButton>
      </Stack>

      <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={onViewerClose} />
    </Stack>
  )
}
