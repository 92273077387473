import { GetDropdownInspectionResponsibleDataData, IGetDropdownInspectionResponsibleData } from 'api/calls/api.types'
import { ICallFull, IInspectionResponsible } from 'api/calls/types'
import { PrescriptionSenderUser } from 'api/prescriptions/types'
import { SelectedValue } from 'components/CustomSelect/CustomSelect.types'
import { TInspectionStatus } from 'core/types/call'
import { connectNames } from 'utils/connectNames'

export interface IInspectionFormProps {
  callData: ICallFull | null
  onFormChange: (dirty: boolean) => void
  onClose: (immediately?: boolean) => void
}

export interface IInspectionFormData {
  callCreatedDate: Date | null
  status: TInspectionStatus | null
  responsible: SelectedValue<GetDropdownInspectionResponsibleDataData> | null
  phone: string
  planDate: Date | null
  planTime: Date | null
  factDate: Date | null
  description: string | null
}

export const getInitialResponsible = (info?: IInspectionResponsible | null) => {
  if (!info) return null

  const data: SelectedValue<GetDropdownInspectionResponsibleDataData> = {
    id: info.representative?.id || info.user?.id || 0,
    value: info.representative?.fullName || connectNames(info.user),
    subtext: info.company || '',
    type: {
      role: info.representative ? 'representative' : 'user',
    },
  }

  return data
}
