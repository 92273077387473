import { TCallStatus } from 'api/calls/types'
import { remarkStatusRuByEn, TRemarkStatus } from 'api/remarks/types'
import { useGetStatusSettingsQuery } from 'api/settings'
import { callStatusRuByEn } from 'core/types/call'
import { TPrescriptionStatus } from 'core/types/prescription'

export const useCallStatus = (): Record<TCallStatus, string> => {
  const { data: statusSettings } = useGetStatusSettingsQuery()
  const { callStatuses } = statusSettings || {}

  return {
    CREATED: callStatuses?.created || callStatusRuByEn.CREATED,
    SENT: callStatuses?.sent || callStatusRuByEn.SENT,
    CANCELLED: callStatuses?.cancelled || callStatusRuByEn.CANCELLED,
    COMPLETE: callStatuses?.complete || callStatusRuByEn.COMPLETE,
  }
}

export const usePrescriptionStatus = (): Record<TPrescriptionStatus | TRemarkStatus, string> => {
  const { data: statusSettings } = useGetStatusSettingsQuery()
  const { remarkAndPrescriptionStatuses } = statusSettings || {}

  return {
    CREATED: remarkAndPrescriptionStatuses?.created || remarkStatusRuByEn.CREATED,
    SENT: remarkAndPrescriptionStatuses?.sent || remarkStatusRuByEn.SENT,
    DISCARDED: remarkAndPrescriptionStatuses?.discarded || remarkStatusRuByEn.DISCARDED,
    COMPLETE: remarkAndPrescriptionStatuses?.complete || remarkStatusRuByEn.COMPLETE,
  }
}

export const useRemarkStatus = usePrescriptionStatus
