import { alpha, Stack } from '@mui/system'
import styled from 'styled-components'

export const DeviationWrapper = styled(Stack)`
  margin: 0 auto;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

export const DeviationElement = styled(Stack)`
  position: absolute;
  left: calc(100% + 8px);

  place-content: center;
  height: 20px;
  padding: 5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  color: ${(props) => props.theme.palette.error.main};
  background-color: ${(props) => alpha(props.theme.palette.table.orange, 0.1)};
  border-radius: 8px;
`
