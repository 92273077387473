import { Typography } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { TCallTableColumn } from 'api/calls/types'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import styled from 'styled-components'

export const StyledAgGrid = styled(AgGridReact)`
  .ag-root-wrapper {
    border: none;
    background-color: transparent;
  }

  .ag-header {
    &-container {
      background-color: ${(props) => props.theme.palette.secondary.gray};
    }

    &-cell {
      background-color: ${(props) => props.theme.palette.secondary.gray};

      &-label {
        justify-content: center;
        color: ${(props) => props.theme.palette.text.light};
        letter-spacing: 0.17px;
        line-height: 143%;
      }

      &-text {
        text-align: center;
        white-space: normal;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .ag-body-vertical-scroll {
    background-color: ${(props) => props.theme.palette.bg.white};
  }

  .ag-row {
    border-bottom: none;

    &-hover {
      background-color: ${(props) => props.theme.palette.bg.shades};
      cursor: pointer;

      &::before {
        content: none;
      }
    }

    &-inspection-rejected {
      background-color: ${(props) => props.theme.palette.legends.lightRed};

      &:hover {
        background-color: #f6e4e4;
      }
    }
  }

  .ag-cell {
    box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;
    padding: 8px;

    &-wrapper {
      height: 100%;
    }

    &-value {
      color: ${(props) => props.theme.palette.text.dark};
      line-height: 143%;
      letter-spacing: 0.17px;
    }
  }

  .test {
    opacity: 0.5;
  }
`

export const StyledCallTable = styled(StyledAgGrid)`
  [col-id='${'TYPE' as TCallTableColumn}'],
  [col-id='${'WORK_DONE_BY' as TCallTableColumn}'],
  [col-id='${'DESCRIPTION' as TCallTableColumn}'],
  [col-id='${'AUTHOR' as TCallTableColumn}'],
  [col-id='${'RESPONSIBLE_COMPANY' as TCallTableColumn}'],
  [col-id='${'RESPONSIBLE_USER' as TCallTableColumn}'],
  [col-id='${'INSPECTION_STATUS' as TCallTableColumn}'],
  [col-id='${'INSPECTION_RESPONSIBLE' as TCallTableColumn}'] {
    text-align: start;
  }

  [col-id='status'],
  [col-id='audit'] {
    padding: 0;

    .ag-cell-value {
      height: 100%;
    }
  }

  [col-id='${'TYPE' as TCallTableColumn}'] .ag-cell-value,
  [col-id='${'WORK_DONE_BY' as TCallTableColumn}'] .ag-cell-value,
  [col-id='${'DESCRIPTION' as TCallTableColumn}'] .ag-cell-value,
  [col-id='${'RESPONSIBLE_COMPANY' as TCallTableColumn}'] .ag-cell-value {
    white-space: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  // [col-id='${'WORK_DONE_BY' as TCallTableColumn}'] {
  //   &:hover {
  //     top: 1px;
  //     height: auto;
  //     min-height: ${TABLE_CELL_HEIGHT}px;
  //     background-color: ${(props) => props.theme.palette.bg.shades};
  //     z-index: 999999;
  //     display: flex;
  //     align-items: center;

  //     .ag-cell-value {
  //       display: block;
  //       white-space: pre-wrap;
  //     }
  //   }
  // }
`

export const TypographyEllipsis = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`
