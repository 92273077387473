import { ReferenceCard } from './ReferenceCard'
import { ReferenceContent } from './ReferenceContent'
import { CardsFullWrapper, Cards } from './References.styles'
import { Reference, basicReferences } from './References.types'
import { Stack } from '@mui/material'
import { EmptyPageData } from 'components/EmptyPage'
import { EmptyPage } from 'components/EmptyPage'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useSearch } from 'hooks/useSearch'
import { getEmptyPageData } from 'pages/Projects'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { filterByFieldNames } from 'utils/filterByFieldNames'

export const References: FC = () => {
  const { referenceId } = useParams()
  const { role } = useTypedSelector(profileSelector)

  useBreadcrumbs(
    referenceId
      ? [
          { title: 'Управление справочниками' },
          { title: basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0].name },
        ]
      : [{ title: 'Управление справочниками' }],
    [referenceId],
  )

  const renderReferenceCard = (item: Reference) => {
    const access = item?.access ? item?.access === role : true

    return access ? <ReferenceCard key={item.id} item={item} /> : null
  }

  const { searchValue } = useSearch()

  const filteredBySearch = useMemo(() => {
    return searchValue ? filterByFieldNames<Reference>(basicReferences, ['name'], searchValue) : basicReferences
  }, [basicReferences, searchValue])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют справочники, соответствующие результатам запроса.</>,
  )

  return (
    <Stack flex={1} overflow='auto'>
      {!referenceId ? (
        filteredBySearch?.length ? (
          <CardsFullWrapper>
            <Cards>{filteredBySearch.map((item) => renderReferenceCard(item))}</Cards>
          </CardsFullWrapper>
        ) : (
          <EmptyPage data={emptyFilteredData} fullPage />
        )
      ) : (
        <ReferenceContent />
      )}
    </Stack>
  )
}
