import { FC } from 'react'
import { StatusSettingsWrapper } from './StatusSettings.styles'
import { StatusSettingsForm } from './components/StatusSettingsForm'
import { IStatusSettingsProps } from './StatusSettings.types'

export const StatusSettings: FC<IStatusSettingsProps> = ({ resetStatusSettingsResponse }) => {
  return (
    <StatusSettingsWrapper>
      <StatusSettingsForm resetStatusSettingsResponse={resetStatusSettingsResponse} />
    </StatusSettingsWrapper>
  )
}
