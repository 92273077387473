import { EliminationFormDialogTrigger, EliminationWindowType } from '../../EliminationDrawer.types'
import { EliminationMedia } from '../EliminationMedia'
import { EliminationMenu, StatusMarker, EliminationStatusWrapper } from './EliminationForm.styles'
import { EliminationFormData, EliminationFormProps, getDataForRequest } from './EliminationForm.types'
import { eliminationValidation } from './validation'
import {
  ErrorOutline as EliminationStatusIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Person as PersonIcon,
  EventAvailable as DateIcon,
} from '@mui/icons-material'
import { Stack, IconButton, Typography } from '@mui/material'
import {
  remarksApi,
  useChangeRemarkEliminationMutation,
  useDeleteMediaFromEliminationMutation,
  useGetRemarkEliminationQuery,
  useGetResponsibleEliminationQuery,
  useLazyEditRemarkStatusQuery,
  useUploadMediaToEliminationMutation,
} from 'api/remarks'
import { EliminationShort, RemarkMediaComments } from 'api/remarks/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { Divider } from 'components/Divider'
import { FieldForm } from 'components/FieldForm'
import { Progress } from 'components/Progress'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import {
  EliminationStatus,
  eliminationStatusEnByRu,
  eliminationStatusRuByEn,
  eliminationStatuses,
  eliminationStatusesColor,
} from 'core/types/elimination'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { RejectionWindow } from 'pages/Remarks/components/RejectionWindow'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useMemo, useState, MouseEvent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from 'store'
import { profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const EliminationForm: FC<EliminationFormProps> = ({
  onFormChange,
  onClose,
  remarkStatus,
  remarkDateStart,
  primaryId,
}) => {
  const { projectId: projectIdString, remarkId: remarkIdString } = useParams()
  const projectId = Number(projectIdString)
  const remarkId = Number(remarkIdString)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const { data: responsigleElimination } = useGetResponsibleEliminationQuery({ projectId, remarkId })
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { role } = useTypedSelector(profileSelector)
  // const role: UserRoles = 'contractor'
  const [isShouldNotify, setIsShouldNotify] = useState(false)
  const isSecondary = !!primaryId
  const [savedAddedMediaIds, setSavedAddedMediaIds] = useState<number[] | null>(null)

  const { data: remarkElimination, isLoading: isRemarkEliminationLoading } = useGetRemarkEliminationQuery({
    projectId,
    remarkId: remarkId!,
  })
  const [editRemarkStatus, { isLoading: isEditRemarkStatusLoading, ...editRemarkStatusResponse }] =
    useLazyEditRemarkStatusQuery()
  const [changeRemarkElimination, { isLoading: isChangeRemarkEliminationLoading, ...changeRemarkEliminationResponse }] =
    useChangeRemarkEliminationMutation()
  const [uploadMediaToElimination, { isLoading: isMediaUploading, ...uploadMediaToEliminationResponse }] =
    useUploadMediaToEliminationMutation()
  const [deleteMediaFromElimination, { isLoading: isMediaDeleting, ...deleteMediaFromEliminationResponse }] =
    useDeleteMediaFromEliminationMutation()

  const isQuerying = isChangeRemarkEliminationLoading || isMediaUploading || isMediaDeleting
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { data } = remarkElimination || {}

  const { status, responsible, contractorReason, engineerReason, media, measures, period } = data || {}
  const { dateDone } = period || {}

  const getMediaCommentById = useMemo(() => {
    let mediaCommentById: RemarkMediaComments | null = null

    ;(media || []).forEach((item: any) => {
      if (!mediaCommentById) mediaCommentById = {}

      mediaCommentById[item.id] = item.comment
    })

    return mediaCommentById
  }, [media])

  const viewingOnly =
    // (remarkStatus !== 'SENT' && (remarkStatus !== 'CREATED' || !isSecondary)) ||
    remarkStatus !== 'SENT' ||
    (role === 'contractor' && (status === 'COMPLETE' || status === 'DISCARDED')) ||
    role === 'client' ||
    (role === 'engineer_qc' &&
      (status === 'CREATED' || status === 'SENT' || status === 'IN_PROGRESS' || status === 'RETURNED'))

  const isEngineerMode =
    (role === 'engineer_qc' || role === 'admin') &&
    (status === 'COMPLETE' || status === 'DISCARDED') &&
    (remarkStatus === 'SENT' || (remarkStatus === 'CREATED' && isSecondary))

  const initialValues: EliminationFormData = useMemo(
    () => ({
      status: status || 'CREATED',
      responsible: responsible
        ? {
            id: responsible.id,
            value: responsible.fullName,
          }
        : null,
      contractorReason: contractorReason || '',
      engineerReason: engineerReason || '',
      measures: measures || '',
      dateDone: parseResponseDate(dateDone || '').date,
      remarkDateStart,

      media: media || [],
      mediaForCreate: [],
      mediaIdsToDelete: [],

      mediaCommentById: getMediaCommentById,
      newMediaCommentById: null,
    }),
    [remarkElimination, remarkId],
  )

  const onSubmit = useCallback(
    (values: EliminationFormData, addedMediaId?: number[]) => {
      const { mediaForCreate, mediaIdsToDelete } = values

      if (mediaForCreate.length) {
        uploadMediaToElimination({ projectId, remarkId: primaryId || remarkId, files: mediaForCreate })
      }

      if (mediaIdsToDelete.length) {
        deleteMediaFromElimination({ projectId, remarkId, mediaIds: values.mediaIdsToDelete })
      }

      if (mediaForCreate.length || mediaIdsToDelete.length) return

      onChangeRemarkElimination(values)
    },
    [remarkId, remarkElimination, isShouldNotify],
  )

  const onChangeRemarkElimination = (values: EliminationFormData, addedMediaIds?: number[]) => {
    changeRemarkElimination({
      projectId,
      remarkId: primaryId || remarkId,
      status: values.engineerReason && initialValues.status !== 'RETURNED' ? 'RETURNED' : values.status,
      body: getDataForRequest(values, addedMediaIds || savedAddedMediaIds),
      notification: isShouldNotify || initialValues.responsible?.id !== values.responsible?.id,
    })

    setSavedAddedMediaIds(null)
  }

  const { formik } = useForm({
    validationSchema: eliminationValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useEffect(() => {
    if (values.status !== 'DISCARDED') setFieldValue('contractorReason', '')

    if (values.status !== 'COMPLETE' && values.dateDone && !initialValues.dateDone) setFieldValue('dateDone', null)

    if (values.status == 'COMPLETE' && !values.dateDone) setFieldValue('dateDone', new Date())
  }, [values.status])

  const onStatusClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (!viewingOnly && !isEngineerMode) setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback((status: EliminationStatus) => {
    setMenuAnchor(null)
    setFieldValue('status', status)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedWindow(null)
      setFieldValue('engineerReason', '')
    }
  }, [])

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      switch (values.status) {
        case 'COMPLETE': {
          editRemarkStatus({
            projectId,
            remarkId: primaryId || remarkId,
            secondaryId: primaryId ? remarkId : null,
            status: 'COMPLETE',
            dateDone: formatDateForServer(new Date()),
            notification: confirm,
          })

          break
        }
        case 'DISCARDED': {
          editRemarkStatus({
            projectId,
            remarkId: primaryId || remarkId,
            secondaryId: primaryId ? remarkId : null,
            status: 'DISCARDED',
            notification: confirm,
          })

          break
        }
      }
    },
    [values.status, projectId, remarkId, primaryId],
  )

  const [openedWindow, setOpenedWindow] = useState<EliminationWindowType | null>(null)
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<EliminationFormDialogTrigger>('rejectionExit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    rejectionExit: { handleConfirm: handleExitConfirm },
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onWindowOpen = useCallback((drawerType: EliminationWindowType) => {
    setOpenedWindow(drawerType)
  }, [])

  const onWindowClose = useCallback(
    (dialogType: EliminationFormDialogTrigger, dirty: boolean, immediately?: boolean) => {
      if (!dirty || immediately) {
        setOpenedWindow(null)
        setFieldValue('engineerReason', '')
      } else {
        setConfirmDialogTrigger(dialogType)
        openConfirm()
      }
    },
    [],
  )

  const onAcceptElimination = () => {
    setConfirmDialogTrigger('notification')
    openConfirm()
  }

  const handleError = () => {
    enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }

  useMutationHandlers(
    changeRemarkEliminationResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      enqueueSnackbar(
        values.engineerReason && initialValues.status !== 'RETURNED'
          ? 'Статус устранения изменен на "Не принято".'
          : 'Статус устранения успешно изменён.',
        { variant: 'success' },
      )
      onClose(false, true)

      dispatch(
        remarksApi.util.invalidateTags([
          { type: 'Remarks', id: 'REMARK_BY_ID' },
          { type: 'Remarks', id: 'REMARKS_ELIMINATION' },
        ]),
      )
    },
    () => handleError(),
  )

  useMutationHandlers(
    editRemarkStatusResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      enqueueSnackbar('Статус замечания успешно изменен.', { variant: 'success' })
      onClose(false, true)
    },
    () => handleError(),
  )

  useMutationHandlers(
    uploadMediaToEliminationResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (!isMediaDeleting) onChangeRemarkElimination(values, data.data.addedMediaId)
      else setSavedAddedMediaIds(data.data.addedMediaId)
    },
    () => handleError(),
  )

  useMutationHandlers(
    deleteMediaFromEliminationResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      if (!isMediaUploading) onChangeRemarkElimination(values)
    },
    () => handleError(),
  )

  return (
    <Stack height='100%' overflow='auto'>
      {isRemarkEliminationLoading ? (
        <Progress />
      ) : (
        <FormikProvider value={formik}>
          <Stack component={Form} height='100%' justifyContent='space-between' overflow='auto'>
            <Stack spacing={2.5} height='100%' overflow='auto'>
              <Stack spacing={2.5} padding='20px 20px 0 20px'>
                <FieldItem
                  title='Статус устранения:'
                  icon={<EliminationStatusIcon fontSize='medium' color='secondary' />}
                >
                  <Stack>
                    <EliminationStatusWrapper onClick={onStatusClick} isReadOnly={viewingOnly || isEngineerMode}>
                      <Stack direction='row' alignItems='center'>
                        <StatusMarker markerColor={eliminationStatusesColor[values.status]} />
                        <Typography variant='body2' lineHeight='100%'>
                          {eliminationStatusRuByEn[values.status]}
                        </Typography>
                      </Stack>

                      <IconButton>
                        <KeyboardArrowDownIcon fontSize='small' />
                      </IconButton>
                    </EliminationStatusWrapper>

                    <EliminationMenu
                      anchorEl={menuAnchor}
                      open={!!menuAnchor}
                      onClick={onMenuClick}
                      onClose={onMenuClose}
                    >
                      {eliminationStatuses.map((eliminationStatus) => {
                        const valueForOnClick = eliminationStatusEnByRu[eliminationStatus]
                        const checked = values.status === valueForOnClick

                        return (
                          <StyledSelectMenuItem
                            onClick={() => onMenuItemClick(valueForOnClick)}
                            style={{ width: '100%', maxWidth: '100%' }}
                            value={eliminationStatus}
                            checked={checked}
                            key={eliminationStatus}
                          >
                            <StatusMarker markerColor={eliminationStatusesColor[valueForOnClick]} />
                            {eliminationStatus}
                          </StyledSelectMenuItem>
                        )
                      })}
                    </EliminationMenu>
                  </Stack>
                </FieldItem>

                <FieldItem title='Ответственный:' icon={<PersonIcon fontSize='medium' color='secondary' />}>
                  <CustomSelect
                    name='responsible'
                    list={responsigleElimination?.data || []}
                    placeholder={!viewingOnly && !isEngineerMode ? 'Выберите из списка' : '—'}
                    readOnly={viewingOnly || isEngineerMode}
                    isSearch={true}
                    width={330}
                    style={{
                      textAlign: 'start',
                    }}
                    isSubtext={true}
                  />
                </FieldItem>
                <Stack spacing={1.5}>
                  <FieldItem
                    title={
                      (values.status === 'DISCARDED' && 'Обоснование отклонения замечания') ||
                      (values.status === 'RETURNED' && 'Обоснование возврата замечания на устранение') ||
                      'Выполненные мероприятия'
                    }
                    isRequired={values.status === 'DISCARDED' || values.status === 'COMPLETE'}
                  >
                    <></>
                  </FieldItem>

                  <Divider />

                  {values.status === 'DISCARDED' && (
                    <FieldForm
                      version='project'
                      name='contractorReason'
                      placeholder={!viewingOnly && !isEngineerMode ? 'Укажите обоснование' : ''}
                      multiline
                      helperText=''
                      inputProps={{
                        readOnly: viewingOnly || isEngineerMode,
                        style: {
                          minHeight: '140px',
                        },
                      }}
                    />
                  )}

                  {values.status === 'RETURNED' && (
                    <FieldForm
                      version='project'
                      name='engineerReason'
                      placeholder={!viewingOnly && !isEngineerMode ? 'Укажите обоснование' : ''}
                      multiline
                      helperText=''
                      inputProps={{
                        readOnly: true,
                        style: {
                          minHeight: '140px',
                        },
                      }}
                    />
                  )}
                  {values.status !== 'DISCARDED' && values.status !== 'RETURNED' && (
                    <FieldForm
                      version='project'
                      name='measures'
                      placeholder={!viewingOnly && !isEngineerMode ? 'Укажите выполненные мероприятия' : ''}
                      multiline
                      helperText=''
                      inputProps={{
                        readOnly: viewingOnly || isEngineerMode,
                        style: {
                          minHeight: '140px',
                        },
                      }}
                    />
                  )}
                </Stack>

                <FieldItem
                  title='Фактическая дата устранения'
                  icon={<DateIcon fontSize='medium' color='secondary' />}
                  isRequired={values.status === 'COMPLETE'}
                >
                  <FieldForm
                    version='date'
                    name='dateDone'
                    placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                    helperText=''
                    dataValue={values.dateDone}
                    onDataChange={(value: Date | null) => setFieldValue('dateDone', value)}
                    style={{ maxWidth: '144px', width: '100%' }}
                    dateFieldProps={{
                      disabled: values.status !== 'COMPLETE',
                      minDate: values.remarkDateStart || undefined,
                      maxDate: new Date() || undefined,
                      readOnly: viewingOnly || isEngineerMode,
                    }}
                  />
                </FieldItem>
              </Stack>

              <EliminationMedia viewingOnly={viewingOnly || isEngineerMode} />
            </Stack>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width='100%' justifyContent='flex-end'>
                {!viewingOnly && !isEngineerMode && (
                  <Button
                    type='submit'
                    disabled={!dirty || !isValid}
                    loading={isFormLoading}
                    color='success'
                    size='medium'
                    fullWidth
                    style={{
                      maxWidth: '100%',
                    }}
                  >
                    Сохранить
                  </Button>
                )}

                {isEngineerMode && (
                  <>
                    <Button
                      onClick={onAcceptElimination}
                      size='medium'
                      color='success'
                      fullWidth
                      style={{
                        maxWidth: !viewingOnly ? '100%' : '',
                      }}
                    >
                      Принять
                    </Button>
                    <Button
                      onClick={() => onWindowOpen('rejection')}
                      size='medium'
                      color='error'
                      fullWidth
                      style={{
                        maxWidth: '100%',
                      }}
                    >
                      Отклонить
                    </Button>
                  </>
                )}

                <Button
                  onClick={() => onClose(dirty)}
                  size='medium'
                  fullWidth
                  style={{
                    maxWidth: !viewingOnly ? '100%' : '',
                  }}
                >
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>

            <RejectionWindow
              isOpen={openedWindow === 'rejection'}
              onClose={(dirty, immediately) => onWindowClose('rejectionExit', dirty, immediately)}
              setIsShouldNotify={setIsShouldNotify}
            />
          </Stack>
        </FormikProvider>
      )}

      <ConfirmDialog />
    </Stack>
  )
}
