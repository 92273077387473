import { PrescriptionFormData } from '../../PrescriptionsForm.types'
import { RemarkList, RemarkWarning, Wrapper } from './Remarks.styles'
import { RemarksProps } from './Remarks.types'
import { RemarkItem } from './components/RemarkItem'
import { Add as AddIcon, Check as CheckIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { RemarkFull } from 'api/remarks/types'
import { Button } from 'components/Button'
import { CarouselViewer } from 'components/CarouselViewer'
import { VieweredItemInfo } from 'components/CarouselViewer/CarouselViewer.types'
import { generateNextVieweredItemInfo } from 'components/CarouselViewer/utils/generateNextVieweredItemInfo'
import { generateVieweredItemInfo } from 'components/CarouselViewer/utils/generateVieweredItemInfo'
import { Divider } from 'components/Divider'
import { Media } from 'core/types/global'
import { FieldArray, useFormikContext } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const Remarks: FC<RemarksProps> = ({ viewingOnly, onBindDrawerOpen, currentPage, savedLocationState }) => {
  const { initialValues, values } = useFormikContext<PrescriptionFormData>()
  const navigate = useNavigate()
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const { sortableColumn, filterData, backUrl, backState } = savedLocationState || {}
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [vieweredItemInfo, setVieweredItemInfo] = useState<VieweredItemInfo | null>(null)
  const [filtredMedia, setFiltredMedia] = useState<Media[][] | null>(null)
  const [vieweredRemark, setVieweredRemark] = useState<RemarkFull | null>(null)

  const onCreateClick = useCallback(() => {
    navigate(`/project/${projectId}/remarks/add`, {
      state: {
        sortableColumn,
        filterData,
        prescriptionValues: { ...values, id: prescriptionId },
        prescriptionsPage: currentPage,
        backUrl,
        backState,
      },
    })
  }, [projectId, values, prescriptionId, savedLocationState])

  // Viewer
  const filterMediaByType = (mediaList: Media[], mediaId: number) => {
    const imgList: Media[] = []
    const pdfList: Media[] = []
    let listIndex = 0
    let mediaIndex = 0

    let filtredMedia: Media[][] = []

    mediaList.forEach((media, index) => {
      const [format] = media.link.split('.').slice(-1)

      if (format === 'pdf') {
        pdfList.push(media)
      } else {
        imgList.push(media)
      }
    })

    filtredMedia = [imgList, pdfList].filter((innerList) => innerList.length)

    filtredMedia.forEach((innerList, innerListIndex) =>
      innerList.forEach((media, index) => {
        if (media.id === mediaId) {
          mediaIndex = index
          listIndex = innerListIndex
        }
      }),
    )

    setFiltredMedia(filtredMedia)

    return {
      listIndex,
      mediaIndex,
    }
  }

  const filtredRemarksByMediaExistence = useMemo(() => {
    return values.remarks.filter((remark) => remark.media.length)
  }, [values.remarks])

  const onViewerOpen = useCallback((remark: RemarkFull, media: Media) => {
    const { listIndex, mediaIndex } = filterMediaByType(remark.media, media.id)
    setVieweredRemark(remark)

    const localVieweredItemInfo = generateVieweredItemInfo(media, mediaIndex, listIndex)
    setVieweredItemInfo(localVieweredItemInfo)
    setIsViewerOpen(true)
  }, [])

  const onViewerClose = useCallback(() => {
    setIsViewerOpen(false)
    setVieweredItemInfo(null)
  }, [])

  const onArrowClick = useCallback(
    (step: number) => {
      if (!filtredMedia) return

      const nextVieweredItemInfo = generateNextVieweredItemInfo(step, vieweredItemInfo, filtredMedia)
      if (step === 1 && nextVieweredItemInfo.listIndex === 0 && nextVieweredItemInfo.mediaIndex === 0) {
        const remarksWithMedia = filtredRemarksByMediaExistence
        let nextRemarkIndex = remarksWithMedia.findIndex((remark) => remark.id === vieweredRemark!.id) + 1
        if (nextRemarkIndex > remarksWithMedia.length - 1) nextRemarkIndex = 0

        onViewerOpen(remarksWithMedia[nextRemarkIndex], remarksWithMedia[nextRemarkIndex].media[0])
        return
      }

      if (
        step === -1 &&
        nextVieweredItemInfo.listIndex === filtredMedia.length - 1 &&
        nextVieweredItemInfo.mediaIndex === filtredMedia[nextVieweredItemInfo.listIndex].length - 1
      ) {
        const remarksWithMedia = filtredRemarksByMediaExistence
        let nextRemarkIndex = remarksWithMedia.findIndex((remark) => remark.id === vieweredRemark!.id) - 1
        if (nextRemarkIndex < 0) nextRemarkIndex = filtredRemarksByMediaExistence.length - 1
        const prevRemark = remarksWithMedia[nextRemarkIndex]

        onViewerOpen(prevRemark, prevRemark.media[prevRemark.media.length - 1])
        return
      }

      setVieweredItemInfo(nextVieweredItemInfo)
    },
    [filterMediaByType, vieweredItemInfo, filtredMedia, vieweredRemark],
  )

  return (
    <Wrapper>
      <Stack direction='row' justifyContent='space-between' marginBottom={1.5} alignItems='center'>
        <Typography variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
          Замечания
        </Typography>
        {!viewingOnly && (
          <Stack direction='row' spacing={1.25}>
            <Button
              onClick={onCreateClick}
              variant='outlined'
              color='secondary'
              startIcon={<AddIcon />}
              disabled={initialValues.status !== 'CREATED'}
            >
              Создать
            </Button>
            <Button
              onClick={onBindDrawerOpen}
              variant='outlined'
              color='secondary'
              startIcon={<CheckIcon />}
              disabled={initialValues.status !== 'CREATED'}
            >
              Выбрать
            </Button>
          </Stack>
        )}
      </Stack>
      <Divider />
      <RemarkList>
        <FieldArray
          name='remarks'
          render={({ remove }) => (
            <>
              {values.remarks.length ? (
                values.remarks.map((remark, i) => (
                  <RemarkItem
                    key={remark.id}
                    remark={remark}
                    remove={() => remove(i)}
                    currentPage={currentPage}
                    savedLocationState={savedLocationState}
                    onViewerOpen={(media) => onViewerOpen(remark, media)}
                  />
                ))
              ) : (
                <RemarkWarning variant='body1'>Отсутствуют связанные замечания.</RemarkWarning>
              )}
            </>
          )}
        />
      </RemarkList>

      <CarouselViewer
        isOpen={isViewerOpen}
        itemInfo={vieweredItemInfo}
        isDisableArrows={filtredRemarksByMediaExistence.flatMap((remark) => remark.media).length === 1}
        onPrevClick={() => onArrowClick(-1)}
        onNextClick={() => onArrowClick(1)}
        onClose={onViewerClose}
        remarkNumber={vieweredRemark?.number}
      />
    </Wrapper>
  )
}
