import { MultiSelectTotalProps } from './MultiSelectTotal.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const MultiSelectTotal: FC<MultiSelectTotalProps> = ({ selectedList, dropdownList }) => {
  const allSelected = selectedList.length === dropdownList?.length

  return (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <Typography variant='body2'>Выбрано:</Typography>
      <Typography variant='subtitle2' fontSize={14} color={theme.palette.text.dark}>
        {allSelected ? 'Все' : selectedList.length}
      </Typography>
    </Stack>
  )
}
