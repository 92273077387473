import { TabsEntities, TabsProps } from './Tabs.types'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import React, { ReactNode } from 'react'
import { theme } from 'styles/theme'

export const Tabs = <T extends TabsEntities>({
  currentTab,
  onTabChange,
  tabsData,
  tabProps,
}: TabsProps<T> & { children?: ReactNode }) => {
  return (
    <Box>
      <MuiTabs
        value={currentTab}
        onChange={onTabChange}
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
            borderBottom: `2px solid ${theme.palette.bg.gray}`,
          },
        }}
      >
        {tabsData.map(({ value, label, icon }) => (
          <Tab label={label} value={value} sx={{ p: 1 }} key={value} {...tabProps} icon={icon} iconPosition='start' />
        ))}
      </MuiTabs>
    </Box>
  )
}
