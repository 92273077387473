import { Stack, Typography } from '@mui/material'
import { SimpleHeader } from 'components/SimpleHeader'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'
import { FormItemsWrapper, FormWrapper, FullWrapper, Wrapper } from './CallForm.styles'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { GeneralInfo } from './components/GeneralInfo'
import { Button } from 'components/Button'
import { Documents } from './components/Documents'
import {
  getDataForRequest,
  getInitialResponsibleUser,
  ICallFormData,
  TCallDrawerType,
  TCallFormDialogTrigger,
} from './CallForm.types'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useNavigate, useParams } from 'react-router-dom'
import { callValidationSchema } from './CallForm.validation'
import {
  useCreateCallMutation,
  useDeleteCallMutation,
  useDeleteFilesFromCallMutation,
  useEditCallMutation,
  useGetCallByIdQuery,
  useGetNextCallNumberQuery,
  useImportFilesToCallMutation,
  useLazyCheckCallForEmailNotificationInFormQuery,
} from 'api/calls'
import { useTypedSelector } from 'store/store'
import { profileSelector } from 'store/slices/profile'
import { Details } from './components/Details'
import { Inspection } from './components/Inspection'
import { addDays } from 'date-fns'
import { callTypeRuByEn, ICallFull, ICallShort } from 'api/calls/types'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Progress } from 'components/Progress'
import { InspectionDrawer } from '../InspectionDrawer'
import { IAuditData } from '../CallTable/cells/CallAuditCell/CallAuditCell.types'
import { AuditDrawer } from 'pages/Remarks/components/AuditDrawer'
import { ReinspectionWindow } from './components/ReinspectionWindow'
import { getInitialSenderCompany } from 'pages/Prescriptions/components/PrescriptionsForm/PrescriptionsForm.types'
import { AuditButton } from 'pages/Remarks/components/AuditDrawer/components/AuditButton'
import { useSnackbar } from 'hooks/useSnackbar'

export const CallForm: FC = () => {
  const navigate = useNavigate()
  const profile = useTypedSelector(profileSelector)
  const { role } = profile || {}
  const isContractor: boolean = role === 'contractor'
  const isAdmin: boolean = role === 'admin'
  const { projectId: projectIdString, callId: callIdString } = useParams()
  const projectId = Number(projectIdString)
  const callId = Number(callIdString) || undefined
  const [openedDrawer, setOpenedDrawer] = useState<TCallDrawerType | null>(null)
  const [shouldCheckNotification, setShouldCheckNotification] = useState<boolean>(true)
  const [callNotification, setCallNotification] = useState<boolean>(false)

  const { data: nextNumber, isFetching: isNextNumberFetching } = useGetNextCallNumberQuery(projectId, {
    skip: !!callId,
  })

  const { data: callData, isFetching: isCallDataFetching } = useGetCallByIdQuery(
    { projectId, callId: callId! },
    { skip: !callId },
  )

  const {
    status,
    number,
    createdDate,
    responsibleCompany,
    responsibleUser,
    phone,
    attachments,
    type,
    // assignmentType,
    workDoneBy,
    description,
    author,
    expectedDate,
  } = callData?.data || {}

  const parsedCreatedDate = parseResponseDate(createdDate).date
  const parsedExpectedDate = parseResponseDate(expectedDate).date

  const [checkCallForEmailNotification, checkCallForEmailNotificationResponse] =
    useLazyCheckCallForEmailNotificationInFormQuery()
  const [createCall, { isLoading: isCreating, ...createCallResponse }] = useCreateCallMutation()
  const [editCall, { isLoading: isEditing, ...editCallResponse }] = useEditCallMutation()
  const [deleteCall, { isLoading: isDeleting, ...deleteCallResponse }] = useDeleteCallMutation()

  const [importFilesToCall, { isLoading: isFilesImporting, ...importFilesToCallResponse }] =
    useImportFilesToCallMutation()
  const [deleteFilesFromCall, { isLoading: isFilesDeleting, ...deleteFilesFromCallResponse }] =
    useDeleteFilesFromCallMutation()
  const isQuerying = isCreating || isFilesImporting || isEditing || isFilesDeleting
  const [isFormLoading, setIsFormLoading] = useState(false)
  const { showSnackbar, enqueueSnackbar } = useSnackbar({
    snackbarTrigger: !isFormLoading,
    fullSuccessText: callId ? 'Вызов успешно изменен.' : 'Вызов успешно добавлен.',
  })

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const initialValues = useMemo(() => {
    const data: ICallFormData = {
      status: status || 'CREATED',
      number: number || nextNumber || '',
      createdDate: parsedCreatedDate || new Date(),
      responsibleCompany: getInitialSenderCompany(profile, responsibleCompany, callId),
      responsibleUser: getInitialResponsibleUser(profile, responsibleUser, callId),
      phone: phone || profile.phone || '',

      type: type ? { id: type, value: callTypeRuByEn[type] } : null,
      workDoneBy: workDoneBy || '',
      // assignmentType: getInitialAssignmentType(assignmentType),
      description: description || '',
      expectedDate: parsedExpectedDate || addDays(new Date(), 3),

      files: attachments || [],
      filesForCreate: [],
      filesIdsToDelete: [],
    }

    return data
  }, [nextNumber, profile, callData])

  const onSubmit = useCallback(
    (values: ICallFormData, filesUpdated?: boolean) => {
      const { filesForCreate, filesIdsToDelete } = values

      const dataForRequest: ICallShort = getDataForRequest(values)

      if (shouldCheckNotification && callId && !filesUpdated) {
        checkCallForEmailNotification({ projectId, callId, body: dataForRequest })
        setShouldCheckNotification(false)

        return
      }

      if (!callId) {
        console.log('create call start')
        createCall({ projectId, body: dataForRequest, shouldUpdateCache: !filesForCreate.length })
        return
      }

      if (filesForCreate.length && !filesUpdated) {
        console.log('import files start')
        importFilesToCall({
          projectId,
          callId: callId!,
          files: [...values.filesForCreate].reverse(),
          shouldUpdateCache: false,
        })
      }

      if (filesIdsToDelete.length && !filesUpdated) {
        console.log('delete files start')
        deleteFilesFromCall({ projectId, callId: callId!, filesIds: values.filesIdsToDelete })
      }

      if ((filesForCreate.length || filesIdsToDelete.length) && !filesUpdated) return

      console.log('edit call start')

      editCall({
        projectId,
        callId,
        notification: callNotification,
        body: dataForRequest,
      })
    },
    [shouldCheckNotification, callNotification],
  )

  const readOnly: boolean =
    !!callId &&
    !(
      role === 'admin' ||
      (isContractor && initialValues.status !== 'COMPLETE') ||
      (role === 'engineer_qc' && profile.company.userCompanyName === author?.company.userCompanyName)
    )

  const { formik } = useForm({
    validationSchema: callValidationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values),
  })

  const { values, dirty, isValid, submitForm, resetForm } = formik

  const [auditData, setAuditData] = useState<IAuditData | null>(null)

  const onAuditOpen = () => {
    setAuditData({ openedAuditId: callId!, openedAuditCallNumber: callData!.data.number })
  }

  const onAuditClose = () => {
    setAuditData(null)
  }

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) onReturnClick(true)
  }, [])

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm) deleteCall({ projectId, callId: callId! })
  }, [])

  const handleCloseDrawerConfirm = useCallback((confirm: boolean) => {
    if (confirm) setOpenedDrawer(null)
  }, [])

  const handleCallNotification = useCallback((confirm: boolean) => {
    setCallNotification(confirm)
    submitForm()
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TCallFormDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить вызов?',
      body: 'Вызов будет удален.',
    },
    closeDrawer: { handleConfirm: handleCloseDrawerConfirm },
    callNotification: {
      handleConfirm: handleCallNotification,
      title: 'Отправить уведомление?',
      body: 'Уведомление о смене статуса будет направлено на e-mail представителей участников вызова.',
      denyButtonText: 'Нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onDrawerOpen = useCallback((drawerType: TCallDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback((dialogType: TCallFormDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) setOpenedDrawer(null)
    else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const onDeleteClick = () => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }

  const onReturnClick = (immediately?: boolean) => {
    const id = callId ? `?id=${callId}` : ''

    if (immediately || !dirty) navigate(`/project/${projectId}/calls` + id)
    else {
      setConfirmDialogTrigger('exit')
      openConfirm()
    }
  }

  useMutationHandlers(
    createCallResponse,
    (data) => {
      console.log('create call end')
      enqueueSnackbar('Вызов успешно добавлен.', { variant: 'success' })

      if (values.filesForCreate.length) {
        console.log('import files start')
        importFilesToCall({
          projectId,
          callId: data.data.id,
          files: [...values.filesForCreate].reverse(),
          shouldUpdateCache: true,
          newCall: data.data,
        })

        return
      }

      setIsFormLoading(false)
      setTimeout(() => onReturnClick(true))
    },
    () => {
      showSnackbar('Произошла ошибка при создании вызова.', { variant: 'error' })
      setIsFormLoading(false)
    },
  )

  useMutationHandlers(
    importFilesToCallResponse,
    (data) => {
      console.log('import files end')
      enqueueSnackbar('Файлы успешно добавлены.', { variant: 'success' })

      if (callId && !isFilesDeleting) {
        onSubmit(values, true)
        return
      }

      if (isFilesDeleting) return

      setIsFormLoading(false)
      setTimeout(() => onReturnClick(true))
    },
    () => {
      enqueueSnackbar('Произошла ошибка при прикреплении файлов.', { variant: 'error' })

      if (!isFilesDeleting) {
        if (!callId) {
          setIsFormLoading(false)
          setTimeout(() => onReturnClick(true))
        } else onSubmit(values, true)
      }
    },
  )

  useMutationHandlers(
    editCallResponse,
    (data) => {
      console.log('edit call end')
      enqueueSnackbar('Вызов успешно изменён.', { variant: 'success' })

      setIsFormLoading(false)
      setTimeout(() => onReturnClick(true))
    },
    () => {
      enqueueSnackbar('Произошла ошибка при редактировании вызова.', { variant: 'error' })

      setIsFormLoading(false)
      resetForm()
      // setTimeout(() => onReturnClick(true))
    },
  )

  useMutationHandlers(
    deleteFilesFromCallResponse,
    (data) => {
      console.log('delete files end')
      enqueueSnackbar('Файлы успешно удалены.', { variant: 'success' })

      if (!isFilesImporting) onSubmit(values, true)
    },
    () => {
      enqueueSnackbar('Произошла ошибка при удалении файлов.', { variant: 'error' })

      if (!isFilesImporting) onSubmit(values, true)
    },
  )

  useMutationHandlers(
    deleteCallResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      showSnackbar('Вызов успешно удалён.', { variant: 'success' })
      onReturnClick(true)
      setIsFormLoading(false)
    },
    () => {
      showSnackbar('Произошла ошибка при удалении вызова.', { variant: 'error' })
      setIsFormLoading(false)
    },
  )

  useMutationHandlers(
    checkCallForEmailNotificationResponse,
    (data) => {
      if (data.data) {
        setConfirmDialogTrigger('callNotification')
        openConfirm()
      } else submitForm()
    },
    () => {
      showSnackbar('Произошла ошибка.', { variant: 'error' })
      setIsFormLoading(false)
    },
  )

  if (isNextNumberFetching || isCallDataFetching) return <Progress />

  return (
    <Stack width='100%' bgcolor={theme.palette.bg.white}>
      <SimpleHeader
        title={readOnly ? 'Просмотр вызова' : callId ? 'Редактирование вызова' : 'Создание вызова'}
        onClose={() => onReturnClick()}
        repeat={callData?.data.inspection.repeated}
        repeatText='Повторный'
      >
        {!!callId && <AuditButton onButtonClick={onAuditOpen} />}
      </SimpleHeader>

      <FormWrapper>
        <FormikProvider value={formik}>
          <Stack component={Form}>
            <FullWrapper>
              <FormItemsWrapper>
                <Wrapper spacing={2.5}>
                  <GeneralInfo readOnly={readOnly} callData={callData?.data || null} />
                  <Documents readOnly={readOnly} />
                </Wrapper>

                <Details readOnly={readOnly} />

                <Inspection readOnly={readOnly} callData={callData?.data || null} onDrawerOpen={onDrawerOpen} />
              </FormItemsWrapper>

              <Stack direction='row' spacing={2} justifyContent='flex-end' paddingRight={1.5}>
                {isAdmin && (
                  <Button
                    onClick={onDeleteClick}
                    icon={true}
                    size='medium'
                    color='error'
                    variant='outlined'
                    style={{ maxWidth: '100%' }}
                  >
                    <DeleteIcon style={{ fill: theme.palette.error.main }} />
                    <Typography color={theme.palette.error.main} marginLeft={1}>
                      Удалить вызов
                    </Typography>
                  </Button>
                )}
                {!readOnly && (
                  <Button
                    type='submit'
                    disabled={!dirty || !isValid}
                    loading={isFormLoading}
                    color='success'
                    size='medium'
                  >
                    Сохранить
                  </Button>
                )}
                <Button size='medium' onClick={() => onReturnClick()}>
                  Закрыть
                </Button>
              </Stack>
            </FullWrapper>
          </Stack>
        </FormikProvider>
      </FormWrapper>

      <ConfirmDialog />

      <InspectionDrawer
        callData={callData?.data || null}
        isOpen={openedDrawer === 'inspection'}
        onClose={onDrawerClose}
      />

      <ReinspectionWindow
        isOpen={openedDrawer === 'reinspection'}
        onClose={(dirty) => onDrawerClose('closeDrawer', dirty)}
        callData={callData?.data || null}
      />

      <AuditDrawer
        variant='call'
        openedAuditId={auditData?.openedAuditId || null}
        openedAuditCallNumber={auditData?.openedAuditCallNumber || null}
        onClose={onAuditClose}
      />
    </Stack>
  )
}
