import { FC, useEffect, useRef, useState } from 'react'
import { isTextOverflowed, ITypographyProps } from './Typography.types'
import { StyledTypography } from './Typography.styles'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'

export const Typography: FC<ITypographyProps> = ({ lines = 1, children, ...props }) => {
  const typographyRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {
    setShowTooltip(isTextOverflowed(typographyRef))
  }, [typographyRef, children])

  return (
    <Tooltip title={showTooltip ? children : ''} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
      <StyledTypography ref={typographyRef} lines={lines} {...props}>
        {children}
      </StyledTypography>
    </Tooltip>
  )
}
