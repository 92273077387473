import { Checkbox, Switch, TextField, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyledTextFieldTitle = styled(Typography)`
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  text-align: left;
  color: ${(props) => props.theme.palette.secondary.gray};
  text-transform: none;
`

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiFilledInput-input {
    padding: 10px 16px;
    color: ${(props) => props.theme.palette.secondary.gray};
    text-overflow: ellipsis;
    border-radius: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: ${(props) => `0 0 0 30px ${props.theme.palette.bg.shades} inset !important`};
      -webkit-text-fill-color: ${(props) => props.theme.palette.secondary.gray};
    }
  }
`

export const ProjectFormTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);

    border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};
  }

  & .MuiFilledInput-input {
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-transform: ${(props) => props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;
    border-radius: 8px;

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & .MuiInputBase-inputMultiline {
    padding: 12px 16px;
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
    letter-spacing: 0.17px;
    text-transform: ${(props) => props.theme.typography.body2.textTransform};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

interface DatePickerRenderInputProps {
  height?: string | number
}

export interface IStyledSwitchProps {
  _disabled?: boolean
}

export const DatePickerRenderInput = styled(TextField)<DatePickerRenderInputProps>`
  width: 100%;

  & .MuiOutlinedInput-root {
    width: 100%;
    // height: 32px;
    height: ${(props) => props.height || '32px'};
    padding-right: 20px;
    background-color: ${(props) => props.theme.palette.bg.shades};
    border-radius: 8px;
    border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};

    & .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline {
      border: 1px solid rgba(92, 110, 140, 0.1);
    }
  }

  & .MuiOutlinedInput-input {
    padding: 6px 0 6px 10px;
    font-weight: 400;
    font-size: ${(props) => props.theme.typography.subtitle2.fontSize};
    line-height: ${(props) => props.theme.typography.subtitle2.lineHeight};
    letter-spacing: 0.17px;

    text-transform: ${(props) => props.theme.typography.subtitle2.textTransform};

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    text-align: right;
  }

  & .MuiIconButton-edgeEnd {
    padding: 0 4px;
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiInputAdornment-root {
    margin-left: 0;

    & .MuiSvgIcon-root {
      font-size: 18px !important;
      color: ${(props) => props.theme.palette.secondary.dark};
    }
  }

  &.out-of-project-range {
    & .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.palette.table.yellow};
    }
  }
`

export const StyledSelectField = styled(TextField)`
  background-color: ${(props) => props.theme.palette.bg.white};

  & .MuiSelect-select {
    padding: 5px 16px;
    padding-right: 32px !important;
    padding-left: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    text-align: right;
    color: ${(props) => props.theme.palette.text.dark};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(92, 110, 140, 0.1);
  }

  & .MuiSelect-icon {
    right: 8px;
    color: ${(props) => props.theme.palette.text.dark};
    font-size: 16px !important;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: ${(props) => props.theme.palette.disabled};
    transform: translate(14px, 5px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    margin-left: 0;
    text-align: right;
  }
`

export const SmallFormTextField = styled(ProjectFormTextField)`
  align-items: flex-end;

  & .MuiFilledInput-root {
    max-width: 122px;
    border-radius: 4px;
  }

  & .MuiFilledInput-input {
    padding: 5px 12px;
    font-weight: ${(props) => props.theme.typography.body2.fontWeight};
    font-size: ${(props) => props.theme.typography.body2.fontSize};
    line-height: ${(props) => props.theme.typography.body2.lineHeight};
    letter-spacing: 0.17px;
    text-transform: ${(props) => props.theme.typography.body2.textTransform};
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & .MuiFormHelperText-root {
    margin-right: 0;
    text-align: right;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-right: 6px;
    color: ${(props) => props.theme.palette.primary.main};
  }

  & .MuiSvgIcon-root {
    font-size: 24px !important;
  }
`

export const StyledSwitch = styled(Switch)<IStyledSwitchProps>`
  margin-right: 0;

  & .MuiSwitch-track {
    background-color: ${(props) => props.theme.palette.legends.gray} !important;
  }

  & .MuiSwitch-thumb {
    background-color: ${(props) => props.theme.palette.disabled};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    & .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }

  ${(props) =>
    props._disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
  `}
`
