import { placeholderNoData } from '../../../../index'
import { MapHeight } from '../AboutTabImage'
import { RowItem } from '../RowItem'
import { AboutTabContentProps } from './AboutTabContent.types'
import { projectTypeShort } from 'core/types/project'
import { FC, useMemo } from 'react'
import { ScrollableContainer } from 'styles/global/ScrollableContainer'

export const AboutTabContent: FC<AboutTabContentProps> = ({ project, addressData }) => {
  /**
   * деструктуризация пропсов
   */
  const { address, fullName, status, type, finType, startDate, endDate } = useMemo(() => {
    // if (addressData&&project) {
    const { fullName, status, type, finType, startDate, endDate } = project || {}
    const address = addressData?.address || ''
    return { address, fullName, status, type, finType, startDate, endDate }
    // }
    // return { address:null,fullName:null, status:null, type:null, finType:null, startDate:null, endDate:null };
  }, [addressData])
  return (
    <ScrollableContainer minWidth='200px' maxHeight={MapHeight} alignItems='start' pt={1} flex={1} spacing={2}>
      <RowItem title='Полное наименование' rowStrings={[fullName]} />
      <RowItem title='Адрес проекта' rowStrings={[address]} emptyDataText='Адрес проекта не указан' />
      <RowItem title='Статус проекта' rowStrings={[status]} />
      <RowItem title='Тип проекта' rowStrings={[projectTypeShort[type]]} />
      <RowItem title='Вид проекта' rowStrings={[finType]} />
      <RowItem
        title='Срок реализации'
        rowStrings={[`${startDate} — ${endDate}`]}
        emptyDataText='Срок реализации не указан'
      />
    </ScrollableContainer>
  )
}
