export const connectNames = (data: any = {}, short: boolean = false) => {
  if (!data) return ''

  const { firstName, lastName, middleName } = data

  if (short)
    return `${lastName || ''} ${firstName ? `${firstName?.[0]}.` : ''}${middleName ? ` ${middleName?.[0]}.` : ''}`

  return `${lastName || ''} ${firstName || ''}${middleName ? ` ${middleName}` : ''}`
}
