import { FormButtonWrapper, FormWrapper } from './DocumentManagmentForm.styles'
import { DocumentFormData, DocumentManagmentFormProps } from './DocumentManagmentForm.types'
import { DocumentFieldItem } from './components/DocumentFieldItem'
import { validationDocument } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  useCreateRegulationMutation,
  useEditRegulationMutation,
  useUploadFileToRegulationMutation,
  useDeleteRegulationMutation,
} from 'api/regulations'
import { RegulationShort } from 'api/regulations/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { Divider } from 'components/Divider'
import { FieldForm } from 'components/FieldForm'
import { FileUpload } from 'components/FileUpload'
import {
  regulationBodies,
  regulationBodyEnByRu,
  regulationBodyRuByEn,
  RegulationStatus,
  regulationStatusEnByRu,
  regulationStatuses,
  regulationStatusRuByEn,
  regulationTypeEnByRu,
  regulationTypeRuByEn,
  regulationTypes,
} from 'core/types/regulation'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { RegulationLocationState } from 'pages/Regulations/Regulations.types'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const DocumentManagmentForm: FC<DocumentManagmentFormProps> = ({
  onClose,
  onFormChange,
  regulation,
  sectionId,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [createRegulation, createRegulationResponse] = useCreateRegulationMutation()
  const [editRegulation, editRegulationResponse] = useEditRegulationMutation()
  const [uploadFileToRegulation, uploadFileToRegulationResponse] = useUploadFileToRegulationMutation()
  const [deleteRegulation, deleteRegulationResponse] = useDeleteRegulationMutation()
  const [loading, setLoading] = useState<boolean>(false)

  const { id, name, identifier, section, type, body, accepted, status, comment, link, file } = regulation || {}

  const initialValues: DocumentFormData = useMemo(
    () => ({
      name: name || '',
      type: type
        ? {
            id: type.id || 0,
            value: type.id ? regulationTypeRuByEn[type.id] : type.custom || '',
            type: type.custom ? 'custom' : '',
          }
        : null,
      identifier: identifier || '',
      body: body
        ? {
            id: body.id || 0,
            value: body.id ? regulationBodyRuByEn[body.id] : body.custom || '',
            type: body.custom ? 'custom' : '',
          }
        : null,
      accepted: parseResponseDate(accepted || '').date,
      status: status
        ? {
            id: status,
            value: regulationStatusRuByEn[status],
          }
        : null,
      comment: comment || '',
      link: link || '',
      file: file || null,
      fileForCreate: null,
    }),
    [regulation],
  )

  const onSubmit = useCallback(
    (values: DocumentFormData) => {
      setLoading(true)

      const { name, type, identifier, body, accepted, comment, link, status } = values

      const dataForRequest: RegulationShort = {
        accepted: formatDateForServer(accepted) || null,
        body: body
          ? {
              custom: body?.type === 'custom' ? body?.value : null,
              id: body?.type === 'custom' ? null : String(body?.id),
            }
          : null,
        comment: comment || null,
        // file: file?.[0],
        identifier: identifier || null,
        link: link || null,
        name,
        section: sectionId!,
        status: status!.id as RegulationStatus,
        type: {
          custom: type?.type === 'custom' ? type?.value : null,
          id: type?.type === 'custom' ? null : String(type?.id),
        },
      }

      if (regulation) {
        editRegulation({ sectionId: section!.id, docId: regulation!.id, body: dataForRequest })
      } else {
        createRegulation({ sectionId: sectionId!, body: dataForRequest })
      }
    },
    [regulation, sectionId],
  )

  const { formik } = useForm({
    validationSchema: validationDocument,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(createRegulationResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (values.fileForCreate?.[0]) {
      uploadFileToRegulation({
        sectionId: sectionId || 1,
        docId: data.data.id,
        file: values.fileForCreate[0],
      })
      return
    }

    setLoading(false)
    enqueueSnackbar('Документ успешно добавлен.', { variant: 'success' })
    onClose(false, false)
  })

  useMutationHandlers(uploadFileToRegulationResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    setLoading(false)
    enqueueSnackbar(regulation ? 'Документ успешно изменён.' : 'Документ успешно добавлен.', { variant: 'success' })
    onClose(false, false)
  })

  useMutationHandlers(editRegulationResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (!file && values.fileForCreate?.[0]) {
      uploadFileToRegulation({
        sectionId: section!.id,
        docId: data.data.id,
        file: values.fileForCreate[0],
      })
      return
    }

    enqueueSnackbar('Документ успешно изменён.', { variant: 'success' })
    onClose(false, false)
  })

  useMutationHandlers(deleteRegulationResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    enqueueSnackbar('Документ успешно удалён.', { variant: 'success' })
    navigate('/regulations')
    onClose(false, false)
  })

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        deleteRegulation({ sectionId: section!.id, docId: regulation!.id })
      }
    },
    [values],
  )

  const dataForDeleteConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleDeleteConfirm,
    title: 'Удалить документ?',
    body: 'Документ будет удалён.',
  }

  const { ConfirmDialog: DeleteConfirmDialog, openConfirm: openDeleteConfirm } =
    useConfirmDialog(dataForDeleteConfirmDialog)

  return (
    <Stack height='100%' overflow='auto'>
      <FormikProvider value={formik}>
        <Stack component={Form} height='100%' justifyContent='space-between' overflow='auto'>
          <FormWrapper spacing={2.5}>
            <FieldForm
              version='project'
              name='name'
              placeholder='Название документа'
              multiline
              helperText=''
              minRows={2}
              inputProps={{
                // readOnly: viewingOnly,
                style: {
                  overflow: 'auto',
                },
              }}
            />
            <Stack spacing={2.5}>
              <Divider color={theme.palette.bg.gray} />
              <Stack spacing={1.25}>
                <DocumentFieldItem title='Тип документа'>
                  <CustomSelect
                    name='type'
                    list={regulationTypes.map((type) => ({ id: regulationTypeEnByRu[type], text: type })) || []}
                    placeholder='Выберите из списка'
                    // readOnly={viewingOnly}
                    isSearch={true}
                    width={216}
                    free={true}
                  />
                </DocumentFieldItem>
                <DocumentFieldItem title='Идентификатор'>
                  <FieldForm
                    version='project'
                    placeholder='Введите значение'
                    name='identifier'
                    dataValue={values.identifier}
                    fullWidth={false}
                    inputProps={{
                      // readOnly: !numberEditingAccess,
                      style: { padding: '4px 8px', width: '198px', textAlign: 'end' },
                    }}
                  />
                </DocumentFieldItem>
                <DocumentFieldItem title='Принявший орган'>
                  <CustomSelect
                    name='body'
                    list={regulationBodies.map((body) => ({ id: regulationBodyEnByRu[body], text: body })) || []}
                    placeholder='Выберите из списка'
                    // readOnly={viewingOnly}
                    isSearch={true}
                    width={216}
                    free={true}
                  />
                </DocumentFieldItem>
                <DocumentFieldItem title='Введен в действие'>
                  <FieldForm
                    version='date'
                    name='accepted'
                    placeholder='Выберите дату'
                    helperText=''
                    dataValue={values.accepted}
                    onDataChange={(value: Date | null) => setFieldValue('accepted', value)}
                    style={{ maxWidth: '215px', width: '100%' }}
                    inputProps={{
                      style: { textAlign: 'end', height: '30px' },
                    }}
                  />
                </DocumentFieldItem>
                <DocumentFieldItem title='Статус'>
                  <CustomSelect
                    name='status'
                    list={
                      regulationStatuses.map((status) => ({
                        id: regulationStatusEnByRu[status],
                        text: status,
                      })) || []
                    }
                    placeholder='Выберите из списка'
                    // readOnly={viewingOnly}
                    isSearch={true}
                    width={216}
                  />
                </DocumentFieldItem>
              </Stack>
              <Divider color={theme.palette.bg.gray} />
            </Stack>
            <Stack spacing={0.5}>
              <Typography variant='body2' lineHeight='inherit' paddingLeft={1.25}>
                Комментарий:
              </Typography>
              <FieldForm
                version='project'
                name='comment'
                placeholder='Комментарий'
                multiline
                helperText=''
                minRows={2}
                inputProps={{
                  // readOnly: viewingOnly,
                  style: {
                    overflow: 'auto',
                    // height: '56px'
                  },
                }}
              />
            </Stack>
            <DocumentFieldItem title='Ссылка'>
              <FieldForm
                version='project'
                placeholder='Укажите ссылку'
                helperText=''
                name='link'
                dataValue={values.identifier}
                fullWidth={false}
                inputProps={{
                  // readOnly: !numberEditingAccess,
                  style: { padding: '4px 8px', width: '198px', textAlign: 'end' },
                }}
              />
            </DocumentFieldItem>
            {!file && (
              <FileUpload
                mode='single'
                name='fileForCreate'
                uploadData={{
                  title: 'Загрузить PDF',
                  text: 'Чтобы добавить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
                  formats: ['application/pdf'],
                }}
                loading={isSubmitting || loading}
              />
            )}
          </FormWrapper>

          <FormButtonWrapper padding={2.5} spacing={1}>
            <Stack direction='row' spacing={2} width='100%'>
              <Button
                type='submit'
                disabled={isSubmitting || !dirty || !isValid || loading}
                color='success'
                size='medium'
                fullWidth
              >
                Сохранить
              </Button>
              <Button size='medium' fullWidth onClick={() => onClose(dirty, true)}>
                Закрыть
              </Button>
            </Stack>
            {regulation && (
              <Button onClick={openDeleteConfirm} icon={true} size='medium' color='error' variant='text'>
                <DeleteIcon style={{ fill: theme.palette.error.main }} />
                <Typography color={theme.palette.error.main} marginLeft={1}>
                  Удалить документ
                </Typography>
              </Button>
            )}
          </FormButtonWrapper>
        </Stack>
        <DeleteConfirmDialog />
      </FormikProvider>
    </Stack>
  )
}
