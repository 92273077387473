import { TypographyProps } from '@mui/material'
import { MutableRefObject, ReactNode } from 'react'

export interface ITypographyProps extends TypographyProps {
  lines?: number
  children: ReactNode
}

export const isTextOverflowed = (ref: MutableRefObject<any>) => {
  const { scrollWidth = 0, clientWidth = 0, scrollHeight = 0, clientHeight = 0 } = ref.current || {}

  return scrollHeight > clientHeight || scrollWidth > clientWidth
}
