import { Stack } from '@mui/material'
import styled from 'styled-components'

export const CallAuditCellWrapper = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;

  &:hover {
    & .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.primary.light};
    }
  }
`
