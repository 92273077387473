import { MAX_CUSTOM_STATUS_LENGTH, MIN_CUSTOM_STATUS_LENGTH } from 'core/types/settings'
import * as yup from 'yup'

export const statusSettingsValidationSchema = yup.object({
  callStatuses: yup.object({
    created: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    sent: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    cancelled: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    complete: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
  }),
  remarkAndPrescriptionStatuses: yup.object({
    created: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    sent: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    complete: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
    discarded: yup
      .string()
      .min(MIN_CUSTOM_STATUS_LENGTH, `Минимальное кол-во символов — ${MIN_CUSTOM_STATUS_LENGTH}.`)
      .max(MAX_CUSTOM_STATUS_LENGTH, `Максимальное кол-во символов — ${MAX_CUSTOM_STATUS_LENGTH}.`),
  }),
})
