import { GetDropdownResponsibleUsersDataData } from 'api/calls/api.types'
import { ICallFullAssignmentType, ICallShort, TCallStatus, TCallType } from 'api/calls/types'
import { PrescriptionSenderUser } from 'api/prescriptions/types'
import { PublicUserProfile } from 'api/profile/types'
import { CustomSelectValue, SelectedValue } from 'components/CustomSelect/CustomSelect.types'
import { Media } from 'core/types/global'
import { CustomSelectValueWithType } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { connectNames } from 'utils/connectNames'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export type TCallFormDialogTrigger = 'exit' | 'delete' | 'closeDrawer' | 'callNotification'

export interface ICallFormData {
  status: TCallStatus
  number: string
  createdDate: Date | null
  responsibleCompany: CustomSelectValueWithType | null
  // responsibleUser: CustomSelectValueWithType | null
  responsibleUser: SelectedValue<GetDropdownResponsibleUsersDataData> | null
  phone: string

  type: CustomSelectValue | null
  workDoneBy: string
  // assignmentType: CustomSelectValueWithType | null
  description: string
  expectedDate: Date | null

  files: Media[]
  filesForCreate: File[]
  filesIdsToDelete: number[]
}

export type TCallDrawerType = 'inspection' | 'reinspection'

export interface IRepeatInspectionFormData {
  description: string
  shouldNotify: boolean
}

export const getInitialAssignmentType = (info?: ICallFullAssignmentType) => {
  let data = null

  if (!info) return data

  data = {
    id: info?.id,
    value: info?.title,
    type: info?.id ? '' : 'custom',
  }

  return data as CustomSelectValueWithType
}

export const getInitialResponsibleUser = (
  profile: PublicUserProfile,
  info?: PrescriptionSenderUser,
  itemId?: number,
) => {
  let data: SelectedValue<GetDropdownResponsibleUsersDataData> | null = null

  if (!info?.representative && !info?.user && !profile.company.userCompanyName) return data

  if (itemId) {
    data = {
      id: info?.representative?.id || info?.user?.id,
      value: info?.representative?.fullName || connectNames(info?.user),
      type: {
        role: info?.representative ? 'representative' : 'engineerQC',
      },
      subtext: info?.representative?.position || info?.user?.position,
    }

    return data
  }

  data = {
    id: profile?.id,
    value: connectNames(profile),
    type: {
      role: 'engineerQC',
    },
    subtext: profile.company.userPosition,
  }

  return data
}

export const getDataForRequest = (values: ICallFormData) => {
  const { status, responsibleCompany, responsibleUser, phone, type, workDoneBy, description, expectedDate } = values

  const dataForRequest: ICallShort = {
    status,
    responsibleCompany: {
      company: responsibleCompany?.type === 'company' ? responsibleCompany.value : null,
      projectMember: responsibleCompany?.type === 'projectMember' ? Number(responsibleCompany.id) : null,
    },
    responsibleUser: {
      engineerQC: responsibleUser?.type?.role === 'engineerQC' ? Number(responsibleUser?.id) : null,
      representative: responsibleUser?.type?.role === 'representative' ? Number(responsibleUser?.id) : null,
    },
    // assignmentType: {
    //   assignmentTypeId: assignmentType?.type !== 'custom' ? Number(assignmentType?.id) : null,
    //   custom: assignmentType?.type === 'custom' ? assignmentType?.value : null,
    // },
    workDoneBy,
    description,
    expectedDate: formatDateForServer(expectedDate),
    phone,
    type: type?.id as TCallType,
  }

  return dataForRequest
}
