import { AnalyticsState } from './analytics.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AnalyticsSettings } from 'api/analytics/types'

let initialState: AnalyticsState = {
  analyticsSettings: {
    filters: {
      after: null,
      before: null,
      deviation: null,
      projects: null,
      contractors: null,
      directions: null,
    },
    widgets: [],
  },
  isAnalyticsSettingsSynchronized: false,
}

const analyticsSlice = createSlice({
  name: 'analyticsState',
  initialState,
  reducers: {
    setAnalyticsSettings: (state, { payload }: PayloadAction<AnalyticsSettings>) => {
      state.analyticsSettings = payload
      state.isAnalyticsSettingsSynchronized = true
    },
  },
})

export const { reducer: analyticsReducer } = analyticsSlice
export const { setAnalyticsSettings } = analyticsSlice.actions
