import { ChartDivider, ChartDividerWrapper, GeneralChartWrapper } from './GeneralWidget.styles'
import { GeneralWidgetProps } from './GeneralWidget.types'
import { GeneralWidgetChart } from './components/GeneralWidgetChart'
import { Stack, Typography } from '@mui/material'
import { AnalyticsWidgetItem } from 'pages/Analytics/Analytics.styles'
import { WidgetHeader } from 'pages/Analytics/components/WidgetHeader'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const GeneralWidget: FC<GeneralWidgetProps> = ({ analyticsQueryData }) => {
  return (
    <AnalyticsWidgetItem width='41%'>
      <Stack spacing={1.25}>
        <WidgetHeader
          title='Общие показатели'
          tooltipTitle='В статистике учитываются предписания с датой предписания в выбранном временном интервале.'
        />

        <GeneralChartWrapper>
          <GeneralWidgetChart analyticsQueryData={analyticsQueryData} />

          <ChartDividerWrapper translateY='174px'>
            <ChartDivider />
            <Typography variant='body2' fontSize={12} color={theme.palette.secondary.gray}>
              Тип предписания
            </Typography>
          </ChartDividerWrapper>

          <ChartDividerWrapper translateY='350px'>
            <ChartDivider />
          </ChartDividerWrapper>
        </GeneralChartWrapper>
      </Stack>
    </AnalyticsWidgetItem>
  )
}
