import { FC, useState } from 'react'
import { IDocumentsProps } from './Documents.types'
import { Stack, Typography } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import { theme } from 'styles/theme'
import { PrescriptionFormData } from '../../PrescriptionsForm.types'
import { Button } from 'components/Button'
import { downloadMedia } from 'utils/downloadMedia'
import { ImgViewerProps, PdfViewerProps } from 'components/PdfViewer'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  InsertDriveFile as FileIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material'
import { PdfViewerPopup } from '../PdfViewerPopup'
import { Tooltip } from 'components/Tooltip'
import { Dialog } from 'components/Dialog'
import { AddMediaButton } from 'pages/Remarks/components/RemarkForm/Media/components/AddMediaButton'

export const Documents: FC<IDocumentsProps> = ({
  viewingOnly,
  addDocumentButtonDisabled,
  acceptedExtensions = [],
  tooltipTitle,
}) => {
  const { values, setFieldValue } = useFormikContext<PrescriptionFormData>()
  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)
  const [openedImgData, setOpenedImgData] = useState<ImgViewerProps>({ fileUrl: null, isViewerOpen: false })

  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>, insert: (index: number, value: File) => void) => {
    const file = e.target.files![0]

    insert(0, file)
  }

  const downloadFile = (file?: File | null, link?: string) => {
    if (file) {
      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.download = file.name
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
    if (link) downloadMedia(link)
  }

  const openPdfViewer = (fileUrl: string, title: string, onDownload: () => void, onDelete: () => void) => {
    const onDeleteWithClose = () => {
      onDelete()
      setOpenedPdfData(null)
    }

    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      onDelete: onDeleteWithClose,
      disableDelete: viewingOnly,
    })
  }

  const closePfdViewer = () => {
    setOpenedPdfData(null)
  }

  const closeImgViewer = () => {
    setOpenedImgData((p) => ({ ...p, isViewerOpen: false }))
  }

  const openImgViewer = (fileUrl: string) => {
    setOpenedImgData({ fileUrl, isViewerOpen: true })
  }

  const fileBlock = (name: string, onDownload: () => void, onDelete: () => void, onClick: (format: string) => void) => {
    const splittedName = name.split('.')
    const format = splittedName[splittedName.length - 1]
    const clickable: boolean = ['jpeg', 'jpg', 'png', 'pdf'].includes(format)

    return (
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Stack
          onClick={clickable ? () => onClick(format) : undefined}
          style={{ cursor: clickable ? 'pointer' : 'initial' }}
          direction='row'
          alignItems='center'
          spacing={0.5}
        >
          <FileIcon fontSize='medium' color='primary' />
          <Typography variant='body2' color={theme.palette.primary.main} textAlign='start' lineHeight='inherit'>
            {name}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Tooltip title='Скачать файл' bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
            <DownloadIcon
              onClick={onDownload}
              style={{ fill: theme.palette.primary.main, cursor: 'pointer', marginTop: '1px' }}
            />
          </Tooltip>

          {!viewingOnly && (
            <Tooltip title='Удалить файл' bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
              <DeleteIcon onClick={onDelete} style={{ fill: theme.palette.error.main, cursor: 'pointer' }} />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <FieldArray
        name='filesForCreate'
        render={({ insert, remove }) => (
          <>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Tooltip title={tooltipTitle} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
                  <InfoIcon fontSize='inherit' color='action' />
                </Tooltip>

                <Typography variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
                  Документы
                </Typography>
              </Stack>

              {!viewingOnly && (
                <AddMediaButton
                  id='prescripion_media'
                  onChange={(e) => onAddFile(e, insert)}
                  acceptedExtensions={acceptedExtensions}
                  disabled={addDocumentButtonDisabled}
                >
                  <Button
                    icon={true}
                    color='secondary'
                    variant='outlined'
                    style={{
                      pointerEvents: 'none',
                    }}
                    disabled={addDocumentButtonDisabled}
                  >
                    <AddIcon />
                  </Button>
                </AddMediaButton>
              )}
            </Stack>
            <Stack spacing={1.5} marginTop={3} alignItems='flex-start'>
              {values.filesForCreate.length || values.files.length ? (
                <>
                  {values.filesForCreate.map((item, index) => {
                    const url = URL.createObjectURL(item)

                    const onDownload = () => downloadFile(item)
                    const onDelete = () => remove(index)
                    const onDrawerOpen = (format: string) => {
                      if (format === 'pdf') openPdfViewer(url, item.name, onDownload, onDelete)
                      else openImgViewer(url)
                    }

                    return fileBlock(item.name, onDownload, onDelete, onDrawerOpen)
                  })}
                  {values.files.length ? (
                    <FieldArray
                      name='files'
                      render={({ insert, remove }) => (
                        <>
                          {values.files.map((item, index) => {
                            const name = item.link.split('/').slice(-1)[0]
                            const onDownload = () => downloadFile(null, item.link)
                            const onDelete = () => {
                              setFieldValue('filesIdsToDelete', [...values.filesIdsToDelete, item.id])
                              remove(index)
                            }
                            const onDrawerOpen = (format: string) => {
                              if (format === 'pdf') openPdfViewer(item.link, name, onDownload, onDelete)
                              else openImgViewer(item.link)
                            }

                            return fileBlock(name, onDownload, onDelete, onDrawerOpen)
                          })}
                        </>
                      )}
                    />
                  ) : null}
                </>
              ) : (
                '—'
              )}
            </Stack>
          </>
        )}
      />

      <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={closePfdViewer} />

      <Dialog open={openedImgData.isViewerOpen} onClose={closeImgViewer}>
        <img src={openedImgData.fileUrl || ''} alt='Файл замечания' />
      </Dialog>
    </Stack>
  )
}
