/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { BtnIcon, Colontitul, Item, ListWrapper, WrapperDrower } from './ChoreDrawer.style'
import BuildingControl from './components/BuildingControl'
import CommonInformation from './components/CommonInformation'
import WorkInformation from './components/WorkInformation'
import { generateRequestData, getInitialValues } from './helper'
import { validationWork } from './validate'
import DeleteIcon from '@mui/icons-material/Delete'
import { Drawer, Typography } from '@mui/material'
import { useCreateWorkMutation, useDeleteWorkMutation, useEditWorkMutation } from 'api/ksg'
import { WorkFull } from 'api/ksg/types'
import { Button } from 'components/Button'
import { FormikProvider } from 'formik'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

/**
 * Боковое меню для создания и редактирования работ
 * @param  open - флаг отображения панели
 * @param  onClose - функция управления флагом панели
 * @param  data - данные о работе
 * @constructor
 */
const ChoreDrawer: FC<{ open: boolean; onClose: (value: boolean) => void; data: WorkFull | null }> = ({
  open,
  onClose,
  data,
}) => {
  const { role } = useTypedSelector(profileSelector)
  const viewingOnly: boolean = role === 'client' || role === 'contractor'
  const { projectId: projectString } = useParams()
  const projectId = Number(projectString)

  /* Блок инициализации запросов к БД */

  const [createdWork, createdWorkResponse] = useCreateWorkMutation()
  const [editWork, editWorkResponse] = useEditWorkMutation()
  const [deleteWork, deleteWorkResponse] = useDeleteWorkMutation()

  /* -------------------------------- */

  const { enqueueSnackbar } = useSnackbar()

  /* Инициализация формика и парсинг необходимых полей из него */

  const initialValues = useMemo(() => {
    return getInitialValues(data)
  }, [data, open])

  const { formik } = useForm({
    validationSchema: validationWork,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const payload = generateRequestData(values)
      if (data) {
        editWork({ projectId, body: payload, workId: data.id })
      } else {
        createdWork({ projectId, body: payload })
      }
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty: isDirty, isSubmitting, isValid, setFieldValue, resetForm } = formik

  /* --------------------------------------------------------- */

  /* Блок инициализации функций, конфигов и модалок */

  /* подтверждение удаления */
  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && data) {
        deleteWork({ projectId, workId: data.id })
      }
    },
    [data, projectId],
  )

  /* Подтверждение закрытия панели */
  const handleCloseConfirm = (confirm: boolean) => {
    if (confirm) {
      onClose(false)
    }
  }

  /**
   * Вызывается по закрытию панели
   * Если данные вводились, откроется модалка для подтверждения закрытия панели
   * Иначе просто закроет панель
   */
  const onAbort = useCallback((dirty: boolean) => {
    !dirty ? onClose(false) : openCloseConfirm()
  }, [])

  /* Конфиг для модалки удаления данных */
  const dataForDeleteConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleDeleteConfirm,
    title: 'Удалить работу?',
    body: 'Данные о работе будут безвозратно удалены.',
  }

  /* Конфиг для модалки закрытия панели */
  const dataForCloseConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleCloseConfirm,
  }

  /* Модалка удаления */
  const { ConfirmDialog: DeleteConfirmDialog, openConfirm: openDeleteConfirm } =
    useConfirmDialog(dataForDeleteConfirmDialog)

  /* Модалка закрытия */
  const { ConfirmDialog: CloseConfirmDialog, openConfirm: openCloseConfirm } =
    useConfirmDialog(dataForCloseConfirmDialog)

  /* ---------------------------------------------- */

  /* Блок эффектов */

  /* При закрытии панели очищается форма */
  useEffect(() => {
    resetForm()
  }, [data, open])

  /* При смене статуса и установки на EMPTY или REJECT сбрасывается поле qcInfo.date */
  useEffect(() => {
    if (values.qcInfo.status === 'EMPTY' || values.qcInfo.status === 'REJECT') {
      setFieldValue('qcInfo.date', null)
    }
  }, [values.qcInfo.status])

  /* Прослушиватель создания работы */
  useMutationHandlers(
    createdWorkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Работа успешно добавлена.', { variant: 'success' })
      onClose(false)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar(`Такой ID уже существует: ”${values.identifier?.trim()}”.`, { variant: 'error' })
        // resetForm()
      }
    },
  )

  /* Прослушиватель удаления работы */
  useMutationHandlers(deleteWorkResponse, (data) => {
    if (!data.success) {
      return
    }
    enqueueSnackbar('Работа успешно удалена.', { variant: 'success' })
    onClose(false)
  })

  /* Прослушиватель редактирования работы */
  useMutationHandlers(
    editWorkResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Работа успешно обновлена.', { variant: 'success' })
      onClose(false)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar(`Работа с данным ID уже существует.`, { variant: 'error' })
        // resetForm()
      }
    },
  )

  /* ------------- */

  return (
    <Drawer anchor='right' open={open} onClose={() => onClose(isDirty)}>
      <FormikProvider value={formik}>
        <WrapperDrower>
          <Colontitul top={true}>
            <Typography variant='h2' fontWeight={500}>
              {(viewingOnly && 'Просмотр ') || (data ? 'Редактирование ' : 'Создание ')}работы
            </Typography>
          </Colontitul>
          <ListWrapper>
            <Item>
              <CommonInformation viewingOnly={viewingOnly} />
              <BuildingControl viewingOnly={viewingOnly} />
            </Item>
            <Item>
              <WorkInformation viewingOnly={viewingOnly} />
            </Item>
          </ListWrapper>
          <Colontitul top={false}>
            {!viewingOnly && (
              <Button width='160px' color='success' disabled={isSubmitting || !isDirty || !isValid} type='submit'>
                Сохранить
              </Button>
            )}
            <Button width='160px' color='primary' onClick={() => onAbort(isDirty)}>
              Закрыть
            </Button>
            {data && !viewingOnly && (
              <BtnIcon width='160px' variant='outlined' color='error' onClick={openDeleteConfirm}>
                <DeleteIcon />
                Удалить
              </BtnIcon>
            )}
          </Colontitul>
        </WrapperDrower>
        <DeleteConfirmDialog />
        <CloseConfirmDialog />
      </FormikProvider>
    </Drawer>
  )
}

export default ChoreDrawer
