import { Stack } from '@mui/material'
import styled from 'styled-components'

interface IntegrationModuleStatusWrapperProps {
  bgColor: string
}

export const IntegrationModuleCard = styled(Stack)`
  border-radius: 8px;
  width: 172px;
  height: 160px;
  background-color ${(props) => props.theme.palette.bg.shades};
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color ${(props) => props.theme.palette.bg.gray};
  }
`

export const IntegrationModuleStatusWrapper = styled.div<IntegrationModuleStatusWrapperProps>`
  margin-top: -8px;
  padding: 2px 8px;
  background-color ${(props) => props.bgColor};
  border-radius: 16px;
`
