import { IDefectFormData, IDefectFormProps, TDefectFormDialogTrigger } from './DefectForm.types'
import { defectValidation } from './DefectForm.validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useGetProjectReferencesQuery } from 'api/references'
import {
  defectsApi,
  useCreateDefectByProjectMutation,
  useCreateDefectMutation,
  useDeleteDefectByProjectMutation,
  useDeleteDefectMutation,
  useEditDefectByProjectMutation,
  useEditDefectMutation,
  useGetDropdownAssignmentTypesByProjectQuery,
  useGetDropdownAssignmentTypesQuery,
} from 'api/references/defects'
import { IDefectShort } from 'api/references/defects/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSearch } from 'hooks/useSearch'
import { useSnackbar } from 'notistack'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'

export const DefectForm: FC<IDefectFormProps> = ({ onFormChange, defect, onClose }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const { searchValue } = useSearch({ preventClearOnUnmount: true })
  const dispatch = useAppDispatch()
  const { data: referencesData } = useGetProjectReferencesQuery({ projectId: projectId }, { skip: !projectId })
  const isModified = !!referencesData?.DEFECT

  const [createDefect, { isLoading: isCreating, ...createDefectResponse }] = useCreateDefectMutation()
  const [createDefectByProject, { isLoading: isCreatingByProject, ...createDefectByProjectResponse }] =
    useCreateDefectByProjectMutation()

  const [editDefect, { isLoading: isEditing, ...editDefectResponse }] = useEditDefectMutation()
  const [editDefectByProject, { isLoading: isEditingByProject, ...editDefectByProjectResponse }] =
    useEditDefectByProjectMutation()

  const [deleteDefect, { isLoading: isDeleting, ...deleteDefectResponse }] = useDeleteDefectMutation()
  const [deleteDefectByProject, { isLoading: isDeletingByProject, ...deleteDefectByProjectResponse }] =
    useDeleteDefectByProjectMutation()

  const { enqueueSnackbar } = useSnackbar()

  const { data: dropdownAssignmentType, isLoading: isDropdownAssignmentTypeLoading } =
    useGetDropdownAssignmentTypesQuery(undefined, { skip: isProjectMode })

  const { data: dropdownAssignmentTypeByProject, isLoading: isDropdownProjectMembersByProjectLoading } =
    useGetDropdownAssignmentTypesByProjectQuery({ projectId }, { skip: !isProjectMode })

  const dropdownAssignmentTypeList = isProjectMode
    ? dropdownAssignmentTypeByProject?.data
    : dropdownAssignmentType?.data

  const isQuerying = isCreating || isCreatingByProject || isEditing || isEditingByProject
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { code, title, type, assignmentType, measures, basis, note } = defect || {}

  const initialValues = useMemo(() => {
    const data: IDefectFormData = {
      code: code || '',
      title: title || '',
      type: type || '',
      assignmentType: assignmentType
        ? {
            id: assignmentType.id || '',
            value: assignmentType.title || '',
          }
        : null,
      measures: measures || '',
      basis: basis || '',
      note: note || '',
    }

    return data
  }, [defect])

  const onSubmit = useCallback(
    (values: IDefectFormData) => {
      const dataForRequest: IDefectShort = { ...values, assignmentType: Number(values.assignmentType?.id) }

      if (defect) {
        isProjectMode
          ? editDefectByProject({
              projectId,
              defectId: defect.id,
              body: dataForRequest,
              query: searchValue,
              isModified,
            })
          : editDefect({ defectId: defect.id, body: dataForRequest, query: searchValue })
      } else {
        isProjectMode
          ? createDefectByProject({ projectId, body: dataForRequest, query: searchValue, isModified })
          : createDefect({ body: dataForRequest, query: searchValue })
      }
    },
    [projectId, defect, searchValue, isModified],
  )

  const { formik } = useForm({
    validationSchema: defectValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const onDeleteClick = () => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }

  const handleDeleteDefect = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        isProjectMode
          ? deleteDefectByProject({ projectId, defectId: defect!.id, isModified })
          : deleteDefect({ defectId: defect!.id })
      }
    },
    [defect, projectId, isProjectMode, isModified],
  )

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<TDefectFormDialogTrigger>('delete')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteDefect,
      title: 'Удалить дефект?',
      body: 'Дефект будет удалён.',
      confirmButtonLoading: isDeleting || isDeletingByProject,
      denyButtonDisabled: isDeleting || isDeletingByProject,
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  const handleDuplicateError = useCallback((duplicateField: string[]) => {
    let duplicates = 'с данным кодом и наименованием'
    if (duplicateField.length == 1) {
      duplicates = duplicateField[0] === 'code' ? 'с данным кодом' : 'с данным наименованием'
    }

    enqueueSnackbar(`Дефект ${duplicates} уже существует.`, { variant: 'error' })
  }, [])

  useMutationHandlers(
    isProjectMode ? createDefectByProjectResponse : createDefectResponse,
    (data) => {
      dispatch(defectsApi.util.invalidateTags([{ type: 'References', id: 'DEFECT' }]))
      enqueueSnackbar('Дефект успешно добавлен.', { variant: 'success' })
      onClose(dirty, true)
    },
    (data) => {
      // @ts-ignore
      const errorStatus: number = data?.status

      // @ts-ignore
      errorStatus === 409 && handleDuplicateError(data?.data)

      handleError(errorStatus !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? editDefectByProjectResponse : editDefectResponse,
    (data) => {
      dispatch(defectsApi.util.invalidateTags([{ type: 'References', id: 'DEFECT' }]))
      enqueueSnackbar('Дефект успешно изменён.', { variant: 'success' })
      onClose(dirty, true)
    },
    (data) => {
      // @ts-ignore
      const errorStatus: number = data?.status

      // @ts-ignore
      errorStatus === 409 && handleDuplicateError(data?.data)

      handleError(errorStatus !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? deleteDefectByProjectResponse : deleteDefectResponse,
    (data) => {
      dispatch(defectsApi.util.invalidateTags([{ type: 'References', id: 'DEFECT' }]))
      enqueueSnackbar('Дефект успешно удалён.', { variant: 'success' })
      onClose(dirty, true)
    },
    (data) => handleError(),
  )

  return (
    <Stack height='100%' overflow='auto'>
      <FormikProvider value={formik}>
        <Stack component={Form} height='100%' justifyContent='space-between' overflow='auto'>
          <Stack spacing={2.5} height='100%' overflow='auto'>
            <FormWrapper spacing={1.5} height='100%'>
              <FieldForm
                version='project'
                name='code'
                placeholder='Код дефекта'
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='title'
                placeholder='Наименование'
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='type'
                placeholder='Вид дефекта'
                helperText=''
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <CustomSelect
                name='assignmentType'
                list={dropdownAssignmentTypeList || []}
                placeholder='Вид работ'
                // readOnly={viewingOnly}
                isSearch={true}
                style={{
                  textAlign: 'start',
                }}
                // width={216}
              />

              <FieldForm
                version='project'
                name='measures'
                placeholder='Мероприятия по устранению'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                    padding: '5px 8px',
                  },
                }}
              />

              <FieldForm
                version='project'
                name='basis'
                placeholder='Основание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                    padding: '5px 8px',
                  },
                }}
              />

              <FieldForm
                version='project'
                name='note'
                placeholder='Примечание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                    padding: '5px 8px',
                  },
                }}
              />
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width='100%'>
                {defect?.id && (
                  <Button
                    onClick={onDeleteClick}
                    size='medium'
                    color='error'
                    width='fit-content'
                    loading={isDeleting || isDeletingByProject}
                    icon
                  >
                    <DeleteIcon fontSize='medium' style={{ fill: theme.palette.bg.white }} />
                  </Button>
                )}
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isFormLoading}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>

      <ConfirmDialog />
    </Stack>
  )
}
