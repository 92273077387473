import { TCallStatusLowercase, TCallStatusRu } from 'api/calls/types'
import { TRemarkStatusLowercase, TRemarkStatusRu } from 'api/remarks/types'
import { LegendColors } from './global'

export interface IStatusSettingsData {
  title: string
  statusData: TStatusSetting[]
  sectionName: TStatusSettingsSection
}

export interface IStatusSetting<T, K> {
  name: T
  label: K
  color: LegendColors
  description: string
}

export type TStatusSetting = IStatusSetting<
  TCallStatusLowercase | TRemarkStatusLowercase,
  TCallStatusRu | TRemarkStatusRu
>

export type TStatusSettingsSection = keyof IStatusSettings

export interface IStatusSettings {
  callStatuses: Record<TCallStatusLowercase, string | null>
  remarkAndPrescriptionStatuses: Record<TRemarkStatusLowercase, string | null>
}

export const MIN_CUSTOM_STATUS_LENGTH = 3
export const MAX_CUSTOM_STATUS_LENGTH = 15
