import { TableWrapper } from './Regulations.styles'
import { RegulationLocationState } from './Regulations.types'
import { DocumentManagment } from './RegulationsDrawers/DocumentManagment'
import { RegulationsTable } from './components/RegulationsTable'
import { SectionsMenu } from './components/SectionsMenu'
import AddIcon from '@mui/icons-material/Add'
import { Button, Stack, Tab, Tabs } from '@mui/material'
import { useGetRegulationSectionsQuery } from 'api/regulations'
import { TabData } from 'components/Tabs'
import { RegulationStatusTab } from 'core/types/regulation'
import { useBreadcrumbMenu } from 'hooks/useBreadcrumbMenu'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const Regulations: FC = () => {
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)

  // Location
  const location = useLocation()
  const locationState: RegulationLocationState = (location?.state as RegulationLocationState) || {}

  const savedLocationState = useMemo(() => locationState, [])

  const [width, setWidth] = useState(window.innerWidth)

  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const { currentTab: locationCurrentTab } = savedLocationState || {}

  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null)
  const [regulationDrawerOpen, setRegulationDrawerOpen] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<RegulationStatusTab>(locationCurrentTab || 'all')

  const tabsData: TabData<RegulationStatusTab>[] = [
    { value: 'all', label: 'Все' },
    { value: 'ACTUAL', label: 'Действующие' },
    { value: 'PROJECT', label: 'Проекты' },
    { value: 'PAUSED', label: 'Приостановлены' },
    { value: 'DISCARDED', label: 'Утратили силу' },
  ]

  const onSectionSelect = (sectionId: number | null) => {
    setSelectedSectionId(sectionId)
    localStorage.setItem('selectedSectionId', `${sectionId}`)
  }

  const onRegulationDrawerOpen = () => {
    setRegulationDrawerOpen(true)
  }

  const onTabChange = (e: React.SyntheticEvent, tab: RegulationStatusTab) => {
    if (tab !== currentTab) {
      setCurrentTab(tab)
    }
  }

  const { data: sectionsData } = useGetRegulationSectionsQuery({ page: 1, num: 99999 })
  const { data: sections = [] } = sectionsData || {}
  // const sections: any[] = []

  const { onItemBreadClick, item, breadcrumbMenu, isMenuOpen } = useBreadcrumbMenu({
    itemId: selectedSectionId || 0,
    onMenuItemClick: (item) => onSectionSelect(item.id),
    list: sections,
    emptyTxt: 'Разделы отсутствуют',
  })

  useBreadcrumbs(
    [
      { title: 'НТД' },
      {
        title: item?.name || '',
        onClick: (e) => onItemBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
        hidden: width > 1310,
      },
    ],
    [selectedSectionId, isMenuOpen, width],
  )

  // const getBreadCrumbsData = () => {
  //   return [
  //     { title: 'НТД' },
  //     {
  //       title: item?.name || '',
  //       onClick: (e: MouseEvent<HTMLSpanElement>) => onItemBreadClick(e),
  //       MenuOpen: isMenuOpen,
  //       type: 'menu',
  //     },
  //   ]
  // }

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) setRegulationDrawerOpen(false)
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onRegulationDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    dirty || immediately ? openConfirm() : setRegulationDrawerOpen(false)
  }, [])

  return (
    <Stack direction='row' flex={1} gap={2.5} pt={1.5} sx={{ overflowX: 'auto' }}>
      <SectionsMenu sections={sections} selectedSectionId={selectedSectionId} onSectionSelect={onSectionSelect} />

      <TableWrapper spacing={1}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} width='100%' pr={2}>
          <Tabs
            sx={{
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap',
              },
            }}
            value={currentTab}
            onChange={onTabChange}
          >
            {tabsData.map((tabData) => (
              <Tab {...tabData} />
            ))}
          </Tabs>

          {isUserHighAccess && (
            <Button
              onClick={onRegulationDrawerOpen}
              variant='outlined'
              color='secondary'
              startIcon={<AddIcon />}
              disabled={!sections.length}
              sx={{ minWidth: '223px' }}
            >
              Добавить документ
            </Button>
          )}
        </Stack>

        {selectedSectionId && (
          <RegulationsTable currentTab={currentTab} sectionId={selectedSectionId} key={selectedSectionId} />
        )}
      </TableWrapper>

      <DocumentManagment
        open={regulationDrawerOpen}
        onClose={(dirty, immediately) => onRegulationDrawerClose(dirty, immediately)}
        sectionId={selectedSectionId}
      />

      <ConfirmDialog />

      {breadcrumbMenu}
    </Stack>
  )
}
