import { ICallFull } from 'api/calls/types'
import { inspectionStatusColorByEn, inspectionStatusRuByEn, TInspectionStatus } from 'core/types/call'
import { INSPECTION_EMPTY_STATUS_COLOR } from 'utils/constants'
import { TCallDrawerType } from '../../CallForm.types'

export interface IInspectionProps {
  readOnly: boolean
  callData: ICallFull | null
  onDrawerOpen: (drawerType: TCallDrawerType) => void
}

export const getInspectionStatusData = (status?: TInspectionStatus | null) => {
  return {
    body: status ? inspectionStatusRuByEn[status] : 'Не установлен',
    color: status ? inspectionStatusColorByEn[status] : INSPECTION_EMPTY_STATUS_COLOR,
  }
}
