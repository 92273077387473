import { ButtonData, UserButtonsProps } from './UserButtons.types'
import EditIcon from '@mui/icons-material/Edit'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Stack } from '@mui/material'
import { AnalyticsIcon } from 'assets/icons/AnalyticsIcon'
import { Button } from 'components/Button'
import React, { MouseEvent } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HelpOutline as HelpIcon } from '@mui/icons-material'
import { Tooltip } from 'components/Tooltip'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'

export const UserButtons: React.FC<UserButtonsProps> = ({ role }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isAdminPage: boolean = location.pathname.split('/')[1] === 'administration'

  const adminButtonData: ButtonData = {
    text: 'Администрирование',
    icon: <EditIcon />,
    href: '/administration/users',
  }

  const viewButtonData: ButtonData = {
    text: 'Режим просмотра',
    icon: <VisibilityIcon />,
    href: '/projects',
  }

  const buttonData = isAdminPage ? viewButtonData : adminButtonData

  const handleExitConfirm = (confirm: boolean, link: string) => {
    if (confirm) {
      navigate(link)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleExitConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onLinkClick = (e: MouseEvent<HTMLAnchorElement>, link: string) => {
    const isDirtyForm = !!localStorage.getItem('dirtyForm')

    if (isDirtyForm) {
      e.preventDefault()
      openConfirm(link)
    }
  }

  return (
    <Box>
      <Stack direction='row' spacing={1.5}>
        <Tooltip title='Аналитика'>
          <Link to='/analytics' onClick={(e) => onLinkClick?.(e, '/analytics')}>
            <Button icon>
              <AnalyticsIcon />
            </Button>
          </Link>
        </Tooltip>

        <Tooltip title='НТД'>
          <Link to='/regulations' onClick={(e) => onLinkClick?.(e, '/regulations')}>
            <Button icon>
              <MenuBookIcon />
            </Button>
          </Link>
        </Tooltip>

        <Tooltip title='Руководство пользователя'>
          <a href='https://qc.pragma.info/files/qc_user_guide.pdf' target='_blank'>
            <Button icon>
              <HelpIcon />
            </Button>
          </a>
        </Tooltip>

        {role === 'admin' ? (
          <Link to={buttonData.href} onClick={(e) => onLinkClick?.(e, buttonData.href)}>
            <Button customSize='medium' startIcon={buttonData.icon}>
              {buttonData.text}
            </Button>
          </Link>
        ) : null}
      </Stack>

      <ConfirmDialog />
    </Box>
  )
}
