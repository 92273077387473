import { InfoItem } from './AuditInfoItem.styles'
import { AuditInfoItemProps } from './AuditInfoItem.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const AuditInfoItem: FC<AuditInfoItemProps> = ({ infoItem }) => {
  const { icon, text, value, hidden, textWidth } = infoItem || {}

  return !hidden ? (
    <InfoItem>
      {icon || <Stack minWidth={20} />}
      <Typography
        variant='body1'
        fontSize={14}
        color={theme.palette.secondary.gray}
        marginLeft={1}
        minWidth={textWidth || '109px'}
        maxWidth={textWidth || '109px'}
      >
        {text}:
      </Typography>
      <Typography variant='body1' fontSize={14} color={theme.palette.text.dark} marginLeft={2}>
        {value || '—'}
      </Typography>
    </InfoItem>
  ) : null
}
