import { InfoRow } from '../InfoRow'
import { Progress } from '../Progress'
import {
  DragWrapper,
  ReLoadButton,
  ShowErrorButton,
  ShowResultsButton,
  UploadText,
  UploadTitle,
  UploadWrapper,
} from './FileUpload.styles'
import { FileUploadProps } from './FileUpload.types'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, FormHelperText, Stack } from '@mui/material'
import { ExcelUploadResponse, FileUploadResponse, isBlockError } from 'core/types/global'
import { useField } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { MAX_FILE_SIZE_IN_BYTES, MAX_FILE_SIZE_IN_MB, MAX_FILES_UPLOAD } from 'utils/constants'
import { getWordWithEnding } from 'utils/getWordWithEnding'

// export const FileUpload = <T extends TomRdDocument | TomPdDocument | TomIiDocument | null>({
export const FileUpload = <T extends null>({
  mode,
  responseData,
  uploadData,
  placeholder,
  loading,
  onResultsDrawerOpen,
  fieldProps,
  showUpdatedItems,
  addedItemsText = 'Добавлено новых позиций',
  updatedItemsText = 'Обновлено позиций',
  onlySuccess,
  ...props
}: FileUploadProps<T> & { children?: React.ReactNode }) => {
  const { t } = useTranslation()
  const { title, text, formats } = uploadData
  const [successUpload, setSuccessUpload] = useState<boolean>(false)
  const location = useLocation()

  const [{ value: formValue }, { touched }, { setValue, setTouched }] = useField({
    name: props.name,
    ...fieldProps,
  })

  const [error, setError] = useState<undefined | string>(undefined)

  const isError = touched && !!error
  // const blockClickUpload = responseData && mode === 'single'
  //   ? isExcelResponse(responseData as SingleResponse<T>) && !!((responseData as ExcelUploadResponse)?.success || (responseData as ExcelUploadResponse)?.error?.length)
  //   : false

  useEffect(() => {
    if (responseData?.success) {
      setSuccessUpload(true)
    }
  }, [responseData])

  // const handleSetValue = useCallback(
  //   (files: File[]) => {
  //     setValue('')
  //     const reader = new FileReader()
  //     reader.onload = (props) => {
  //       // setValue(new File([target!.result as ArrayBuffer], file.name, { type: file.type }))
  //     }
  //     if (files[0]) {
  //       reader.readAsArrayBuffer(files[0])
  //     }
  //   },
  //   [setValue]
  // )

  const onDropFile = useCallback(
    (files: File[]) => {
      setTouched(true)
      setError(undefined)
      if (files?.length) {
        setValue(files)
      }
    },
    [setValue],
  )

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
    onDrop: onDropFile,
    // noClick: blockClickUpload,
    accept: formats.reduce(
      (result, currentFormat) => {
        result[currentFormat] = []
        return result
      },
      {} as Record<string, any[]>,
    ),
    maxFiles: mode === 'single' ? 1 : MAX_FILES_UPLOAD,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    disabled: loading || successUpload || !(responseData?.success ?? true),
  })

  const reloadButton = (
    <Box sx={{ mt: 3 }}>
      <label htmlFor='upload-file2'>
        <ReLoadButton onClick={open} spacing={1} direction='row' alignItems='center'>
          <AutorenewIcon fontSize='medium' color='primary' />
          <UploadText>Загрузить еще раз</UploadText>
        </ReLoadButton>
      </label>
    </Box>
  )

  const getSingleUploadResponse = useCallback((response: FileUploadResponse<T>) => {
    if (response.success) return

    return (
      <>
        <WarningAmberIcon color='error' sx={{ fontSize: '48px !important' }} />
        <Stack spacing={1} sx={{ px: 2.5, py: 1, width: '100%' }} alignItems='center'>
          <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
            Произошла ошибка при загрузке файла!
          </UploadText>
          <UploadText variant='body2'>{response.description}</UploadText>
        </Stack>

        {reloadButton}
      </>
    )
  }, [])

  const getExcelUploadResponse = useCallback((response: ExcelUploadResponse) => {
    if (response.success && !response.error.length) {
      return (
        <>
          <CheckCircleIcon sx={{ fontSize: '48px !important', color: theme.palette.legends.lightgreen }} />
          <Stack spacing={2} sx={{ px: 2.5, py: 2, width: '100%' }}>
            <UploadText variant='body2'>Успешно обработано!</UploadText>
            <InfoRow
              // addedItemsText
              rowData={{
                title: addedItemsText,
                icon: InsertDriveFileOutlinedIcon,
                value: response.create.toString(),
                iconProps: {
                  fontSize: 'small',
                  color: 'primary',
                },
              }}
            />
            {showUpdatedItems && (
              <InfoRow
                rowData={{
                  title: updatedItemsText,
                  icon: InsertDriveFileRoundedIcon,
                  value: response.update.toString(),
                  color: theme.palette.error.main,
                  iconProps: {
                    fontSize: 'small',
                    color: 'error',
                  },
                }}
              />
            )}
          </Stack>
        </>
      )
    }

    return (
      <>
        <WarningAmberIcon color='error' sx={{ fontSize: '48px !important' }} />
        <Stack spacing={1} sx={{ px: 2.5, py: 1, width: '100%' }} alignItems='center'>
          {isBlockError(response.error[0].type) ? (
            <>
              <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
                Произошла ошибка при загрузке файла!
              </UploadText>
              <UploadText variant='body2'>{response.error[0].message}</UploadText>
            </>
          ) : (
            <>
              <Stack>
                <UploadText variant='body2' style={{ color: theme.palette.error.main }}>
                  Произошла ошибка при загрузке файла!
                </UploadText>
                <UploadText variant='body2' style={{ fontWeight: 500, color: theme.palette.error.main }}>
                  Всего строк с ошибками {response.error.length}
                </UploadText>
              </Stack>
              <div>
                <ShowErrorButton type='button' onClick={onResultsDrawerOpen} disableRipple>
                  Посмотреть ошибки
                </ShowErrorButton>
              </div>
            </>
          )}
        </Stack>

        {reloadButton}
      </>
    )
  }, [])

  const getArchiveUploadResponse = useCallback((response: ExcelUploadResponse) => {
    const { create, skip } = response
    let title: string = 'Успешно обработано!'

    if (create && skip) title = 'Есть пропущенные файлы'
    if (!create) title = 'Файлы не добавлены'

    const showWarningIcon = title !== 'Успешно обработано!'

    return (
      <>
        {showWarningIcon ? (
          <WarningAmberIcon color='warning' sx={{ fontSize: '48px !important' }} />
        ) : (
          <CheckCircleIcon sx={{ fontSize: '48px !important', color: theme.palette.legends.lightgreen }} />
        )}

        <Stack sx={{ px: 2.5, width: '100%' }} alignItems='center'>
          <UploadText variant='body2'>{title}</UploadText>
          <UploadText variant='body2'>Добавлено файлов: {create}</UploadText>
          <UploadText variant='body2'>Пропущено файлов: {skip}</UploadText>
          <ShowResultsButton onClick={onResultsDrawerOpen} disableRipple>
            Посмотреть список
          </ShowResultsButton>
          {reloadButton}
        </Stack>
      </>
    )
  }, [])

  const getResultsElement = (data: NonNullable<typeof responseData>) => {
    return onlySuccess
      ? getArchiveUploadResponse(data as ExcelUploadResponse)
      : getExcelUploadResponse(data as ExcelUploadResponse)
    // return null
    // if (isMultiResponse(data)) return getMultiUploadResponse(data)
    //
    // return isExcelResponse(data)
    //   ? getExcelUploadResponse(data)
    //   : getSingleUploadResponse(data)
  }

  useEffect(() => {
    const errors: string[] = fileRejections
      .map((file) => {
        return file.errors.map((error) => {
          return error.code
        })
      })
      .flat()

    switch (errors[0]) {
      case 'file-invalid-type':
        setError('common:errors.file_format')
        break
      case 'too-many-files':
        setError(t('common:errors.too_many_files', { maxFiles: mode === 'single' ? 1 : MAX_FILES_UPLOAD }))
        break
      case 'file-too-large':
        setError(t('common:errors.file_max_size', { maxSize: MAX_FILE_SIZE_IN_MB }))
        break
    }
  }, [fileRejections])

  return (
    <Box>
      <DragWrapper drag={isDragActive} {...getRootProps()}>
        {!responseData ? <input {...getInputProps()} /> : null}

        <UploadWrapper justifyContent='center' alignItems='center' spacing={1} isPointer={!responseData}>
          {responseData ? (
            getResultsElement(responseData as NonNullable<typeof responseData>)
          ) : (
            <>
              <CloudUploadIcon sx={{ fontSize: '48px !important', color: theme.palette.secondary.gray }} />
              {!isError && formValue?.length ? (
                mode === 'single' ? (
                  <>
                    <UploadTitle variant='body2'>Файл загружен</UploadTitle>
                    <UploadText variant='body2'>{formValue[0].name}</UploadText>
                  </>
                ) : (
                  <>
                    <UploadTitle variant='body2'>Файлы загружены</UploadTitle>
                    <UploadText variant='body2'>
                      Всего {getWordWithEnding(formValue.length, 'файл', 'файла', 'файлов')}
                    </UploadText>
                  </>
                )
              ) : (
                <>
                  <UploadTitle variant='body2'>{title}</UploadTitle>
                  <UploadText variant='body2'>{text}</UploadText>
                </>
              )}
            </>
          )}
        </UploadWrapper>
      </DragWrapper>

      {isError ? (
        <FormHelperText error={isError} style={{ paddingLeft: 8 }}>
          {t(error as string)}
        </FormHelperText>
      ) : null}
    </Box>
  )
}
