import { Stack, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { MandatoryNotice } from './FieldFormLabel.styles'
import { IFieldFormLabelProps } from './FieldFormLabel.types'

export const FieldFormLabel: FC<IFieldFormLabelProps> = ({ label, icon = null, isRequired, children, ...props }) => {
  return (
    <Stack direction='column' width='100%' spacing={0.25} {...props}>
      <Box textAlign='left' display='flex' alignItems='center'>
        {icon}
        <Typography variant='body2' marginLeft={1} fontSize={12} lineHeight='inherit'>
          {label}
          {isRequired && <MandatoryNotice>&nbsp;*</MandatoryNotice>}
        </Typography>
      </Box>
      {children}
    </Stack>
  )
}
