import { NewAuditItem } from '../NewAuditItem'
import { AuditInnerInfo } from '../NewAuditItem/components/AuditInfoItem/AuditInfoItem.types'
import { AuditRemarkItemProps } from './AuditRemarkSecondaryItem.types'
import { Person as PersonIcon, InsertDriveFile as FileIcon, EventAvailable as EventIcon } from '@mui/icons-material'
import { RemarkSecondaryAuditAction } from 'api/remarks/types'
import { userRolesEnToRu } from 'core/types/user'
import { FC } from 'react'
import { connectNames } from 'utils/connectNames'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const AuditRemarkSecondaryItem: FC<AuditRemarkItemProps> = ({ remarkSecondaryAudit }) => {
  const { action, timestamp, user: userData, prescription, copyNumber, dateEnd } = remarkSecondaryAudit || {}
  const { role, user: user } = userData

  const remarkAuditTitleByAction: Record<RemarkSecondaryAuditAction, string> = {
    CREATE: `Повторное замечание (${copyNumber})`,
  }

  const userText = `${connectNames(user, true)}, ${userRolesEnToRu[role]}`

  const remarkInfoData: AuditInnerInfo[] = [
    {
      icon: <PersonIcon fontSize='medium' color='secondary' />,
      text: 'Автор',
      value: userText,
      textWidth: '138px',
    },
    {
      icon: <EventIcon fontSize='medium' color='secondary' />,
      text: 'Установленная дата устранения',
      value: parseResponseDate(dateEnd).fullDate,
      textWidth: '138px',
    },
    {
      icon: <FileIcon fontSize='medium' color='secondary' />,
      text: 'Предписание',
      value: `№ ${prescription}`,
      hidden: !prescription,
      textWidth: '138px',
    },
  ]

  return (
    <NewAuditItem
      auditData={{
        title: remarkAuditTitleByAction[action],
        timestamp,
        info: remarkInfoData,
      }}
    />
  )
}
