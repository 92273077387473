import {
  DYNAMIC_WIDGET_CHART_BAR_THICKNESS,
  DYNAMIC_WIDGET_CHART_BORDER_RADIUS,
  DynamicWidgetChartProps,
} from './DynamicWidgetChart.types'
import { useGetPrescriptionDynamicStatsQuery } from 'api/analytics'
import { StatisticsDataItem } from 'api/projects/types'
import { Chart, registerables, TooltipItem } from 'chart.js'
import { Progress } from 'components/Progress'
import { useRemarkStatus } from 'hooks/useCustomStatus'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'
import { formatFloat } from 'utils/formatFloat'
import { getMonthName } from 'utils/getMonthName'

Chart.register(...registerables)

export const DynamicWidgetChart: FC<DynamicWidgetChartProps> = ({ analyticsQueryData }) => {
  const { queryBody, skip } = analyticsQueryData
  const { role } = useTypedSelector(profileSelector)
  const { data: prescriptionDynamicStats, isFetching } = useGetPrescriptionDynamicStatsQuery(queryBody, skip)
  const { data: prescriptionDynamicData } = prescriptionDynamicStats || {}
  const { measurements, timeUnit } = prescriptionDynamicData || {}
  const customRemarkStatus = useRemarkStatus()

  const getLabelsByUnit = () => {
    const labels = measurements?.map((item) => {
      const date = new Date(item.timestamp)

      return generateLabel(date)
    })

    return labels
  }

  const generateLabel = (date: Date) => {
    let label = ''

    switch (timeUnit) {
      case 'DAY':
        // let dayNumber = String(date.getDay())
        let dayNumber = String(date.getDate())

        label = dayNumber.length === 2 ? dayNumber : `0${dayNumber}`
        break

      case 'MONTH':
        let shortMonthName = getMonthName(date).split('').slice(0, 3).join('')
        let shortYear = String(date.getFullYear()).split('').slice(-2).join('')

        label = `${shortMonthName}, ${shortYear}`
        break

      case 'YEAR':
        label = String(date.getFullYear())

        break

      default:
        break
    }

    return label
  }

  const generateData = (item: StatisticsDataItem | null, total: number, timestamp: string) => {
    return {
      value: item?.data || null,
      percent: formatFloat(item?.percent || 0),
      total,
      tooltipTitle: generateTooltipTitle(timestamp),
    }
  }

  const generateTooltipTitle = (timestamp: string) => {
    let date = new Date(timestamp)
    let title = ''

    switch (timeUnit) {
      case 'YEAR':
        title = `${date.getFullYear()} год`
        break

      case 'MONTH':
        title = `${getMonthName(date)}, ${date.getFullYear()}`
        break

      case 'DAY':
        let months = [
          'января',
          'февраля',
          'марта',
          'апреля',
          'мая',
          'июня',
          'июля',
          'августа',
          'сентября',
          'октября',
          'ноября',
          'декабря',
        ]

        title = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
        break

      default:
        break
    }

    return title
  }

  const data = {
    labels: getLabelsByUnit(),
    datasets: [
      {
        label: customRemarkStatus.COMPLETE,
        data:
          measurements?.map((item) =>
            generateData(item.prescriptions.complete, item.prescriptions.total, item.timestamp),
          ) || [],
        backgroundColor: theme.palette.legends[PRESCRIPTION_COMPLETE_STATUS_COLOR],
        barThickness: DYNAMIC_WIDGET_CHART_BAR_THICKNESS,
        borderRadius: DYNAMIC_WIDGET_CHART_BORDER_RADIUS,
      },
      {
        label: customRemarkStatus.DISCARDED,
        data:
          measurements?.map((item) =>
            generateData(item.prescriptions.discarded, item.prescriptions.total, item.timestamp),
          ) || [],
        backgroundColor: theme.palette.legends[PRESCRIPTION_DISCARDED_STATUS_COLOR],
        barThickness: DYNAMIC_WIDGET_CHART_BAR_THICKNESS,
        borderRadius: DYNAMIC_WIDGET_CHART_BORDER_RADIUS,
      },
      {
        label: customRemarkStatus.SENT,
        data:
          measurements?.map((item) =>
            generateData(item.prescriptions.sent, item.prescriptions.total, item.timestamp),
          ) || [],
        backgroundColor: theme.palette.legends[PRESCRIPTION_SENT_STATUS_COLOR],
        barThickness: DYNAMIC_WIDGET_CHART_BAR_THICKNESS,
        borderRadius: DYNAMIC_WIDGET_CHART_BORDER_RADIUS,
      },

      {
        label: customRemarkStatus.CREATED,
        data:
          measurements?.map((item) =>
            generateData(item.prescriptions.created, item.prescriptions.total, item.timestamp),
          ) || [],
        backgroundColor: theme.palette.legends[PRESCRIPTION_CREATED_STATUS_COLOR],
        barThickness: DYNAMIC_WIDGET_CHART_BAR_THICKNESS,
        borderRadius: DYNAMIC_WIDGET_CHART_BORDER_RADIUS,
        hidden: role === 'contractor',
      },
    ],
  }

  const options = {
    parsing: {
      yAxisKey: 'value',
      xAxisKey: 'value',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.palette.bg.white,
        titleColor: theme.palette.secondary.gray,
        titleMarginBottom: 12,
        bodyColor: theme.palette.secondary.gray,
        borderColor: theme.palette.bg.gray,
        borderWidth: 2,
        padding: 16,
        bodySpacing: 8,
        usePointStyle: true,
        itemSort: () => -1,

        callbacks: {
          // @ts-ignore
          title: (context: TooltipItem<'bar'>[]) => context[0].raw.tooltipTitle,
          afterTitle: (context: TooltipItem<'bar'>[]) => {
            // @ts-ignore
            return `Предписаний: ${context[0].raw?.total || 0}`
          },
          label: (context: TooltipItem<'bar'>) => {
            const { dataset, raw } = context

            // @ts-ignore
            return raw?.value ? ` ${dataset.label}: ${raw?.value || 0} /  ${raw?.percent || 0} %` : ''
          },
        },
      },
    },
    interaction: {
      mode: 'index',
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        grace: '5%',
        min: 0,
        max: measurements?.length ? undefined : 100,
        ticks: {
          callback: (value: number) => (value % 1 === 0 ? value : null),
          stepSize: measurements?.length ? null : 5,
        },
      },
      x: {
        stacked: true,
        grid: { display: false },
      },
    },
    animation: {
      duration: 0,
    },

    maintainAspectRatio: false,
  }

  // @ts-ignore
  return isFetching ? <Progress /> : <Bar data={data} options={options} />
}
