import { remarkStatuses, TRemarkStatus } from 'api/remarks/types'

export type SortableColumn =
  | 'number'
  | 'status'
  | 'period_dateStart'
  | 'sender_company'
  | 'contractor'
  | 'contractorStatus'
  | 'period_dateEnd'
  | 'period_dateDone'
  | 'prescription'
  | 'type'

export const sortableColumns: SortableColumn[] = [
  'number',
  'status',
  'period_dateStart',
  'sender_company',
  'contractor',
  'contractorStatus',
  'period_dateEnd',
  'period_dateDone',
  'prescription',
  'type',
]

export type RemarkFilterableColumn =
  | 'number'
  | 'status'
  | 'period_dateStart'
  | 'sender_company'
  | 'sender_user'
  | 'contractor'
  | 'contractorStatus'
  | 'period_dateEnd'
  | 'period_dateDone'
  | 'prescription'
  | 'type'
  | 'assignment_type'
  | 'author'
  | 'responsible'
  | 'object'

export const filterableColumns: RemarkFilterableColumn[] = [
  'number',
  'status',
  'sender_company',
  'sender_user',
  'contractor',
  'contractorStatus',
  'period_dateStart',
  'period_dateEnd',
  'period_dateDone',
  'prescription',
  'type',
  'assignment_type',
  'author',
  'responsible',
  'object',
]

export const rangingDates: RemarkFilterableColumn[] = ['period_dateStart', 'period_dateEnd', 'period_dateDone']

export const getRemarkStatusesForMenu = (selectedStatus: TRemarkStatus, prescriptionNumber?: string) => {
  return remarkStatuses.filter(
    (menuStatus) =>
      menuStatus !== selectedStatus && (menuStatus !== 'COMPLETE' || (menuStatus === 'COMPLETE' && prescriptionNumber)),
  )
}
