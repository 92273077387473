import { Stack } from '@mui/material'
import styled from 'styled-components'

export const StatusSettingsSectionWrapper = styled(Stack)`
  width: 50%;
  border: 1px solid ${(props) => props.theme.palette.bg.gray};
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  text-align: start;
  gap: 20px;

  @media (max-width: 1605px) {
    width: 100%;
  }
`
