import { FC, useMemo, useState } from 'react'
import { IProjectIntegrationSettingsProps } from './ProjectIntegrationSettings.types'
import { Stack, Typography } from '@mui/material'
import { Progress } from 'components/Progress'
import { useGetIntegrationStatusByProjectQuery } from 'api/integrations'
import { IntergationModuleCard } from './ProjectIntegrationSettings.types'
import { IntegrationModuleCard, IntegrationModuleStatusWrapper } from './ProjectIntegrationSettings.styles'
import { theme } from 'styles/theme'
import { IasMonitoringCard } from '../IasMonitoringCard'
import { IntegrationProjectSlide } from '../IntegrationProjectSlide'
import { IntegrationRequestSlide } from '../IntegrationRequestSlide'
import {
  integrationModuleStatusColor,
  integrationModuleStatusRuByEn,
  PragmaModule,
  pragmaModuleIcon,
  pragmaModuleLabel,
} from 'api/integrations/types'

export const ProjectIntegrationSettings: FC<IProjectIntegrationSettingsProps> = ({
  project,
  isConfirmDialogOpened,
  openedSlider,
  onSliderOpen,
  onSliderClose,
}) => {
  const { id: projectId } = project
  const [selectedModule, setSelectedModule] = useState<PragmaModule>('PragmaDesign')

  const { data: projectIntegrationStatus, isLoading } = useGetIntegrationStatusByProjectQuery({ projectId })

  const intergationModuleCards = useMemo((): IntergationModuleCard[] => {
    let result = projectIntegrationStatus?.data.global.map((item) => ({
      value: item.module,
      label: pragmaModuleLabel[item.module],
      status: item.status,
      icon: pragmaModuleIcon[item.module],
    }))

    return result || []
  }, [projectIntegrationStatus])

  const onModuleClick = (moduleCard: IntergationModuleCard) => {
    const sliderType = moduleCard.status === 'available' ? 'request' : 'integration'

    if (!openedSlider) {
      onSliderOpen(sliderType)
      setSelectedModule(moduleCard.value)
    }
  }

  if (isLoading)
    return (
      <Stack height='100%'>
        <Progress />
      </Stack>
    )

  return (
    <Stack direction='row' justifyContent='space-between' padding='8px 20px' flexWrap='wrap' gap={2}>
      {intergationModuleCards.map((module) => (
        <IntegrationModuleCard onClick={() => onModuleClick(module)}>
          <IntegrationModuleStatusWrapper bgColor={integrationModuleStatusColor[module.status]}>
            <Typography variant='body2' fontSize={12} color={theme.palette.text.light} lineHeight='inherit'>
              {integrationModuleStatusRuByEn[module.status].toUpperCase()}
            </Typography>
          </IntegrationModuleStatusWrapper>
          {module.icon}
          <Typography variant='body2' fontWeight='700' color={theme.palette.text.dark}>
            {module.label}
          </Typography>
        </IntegrationModuleCard>
      ))}

      {!isLoading && <IasMonitoringCard />}

      <IntegrationProjectSlide
        isOpen={openedSlider === 'integration'}
        onClose={(dirty, immediately) => onSliderClose('closeSlider', dirty, immediately)}
        isConfirmDialogOpened={isConfirmDialogOpened}
        project={project}
        module={selectedModule}
      />

      <IntegrationRequestSlide
        isOpen={openedSlider === 'request'}
        onClose={(dirty, immediately) => onSliderClose('closeSlider', dirty, immediately)}
        isConfirmDialogOpened={isConfirmDialogOpened}
        projectId={projectId}
        module={selectedModule}
      />
    </Stack>
  )
}
