import { Documents as PrescriptionDocuments } from 'pages/Prescriptions/components/PrescriptionsForm/components/Documents'
import { ItemWrapper } from 'pages/Remarks/components/RemarkForm/RemarkForm.styles'
import { FC } from 'react'
import { MAX_FILE_SIZE_IN_MB } from 'utils/constants'
import { IDocumentsProps } from './Documents.types'
import { callMediaFileExtensions } from 'core/types/file'

export const Documents: FC<IDocumentsProps> = ({ readOnly }) => {
  return (
    <ItemWrapper flex={1} minHeight='240px'>
      <PrescriptionDocuments
        viewingOnly={readOnly}
        acceptedExtensions={callMediaFileExtensions}
        tooltipTitle={`Размер файла не должен превышать ${MAX_FILE_SIZE_IN_MB} МБ.`}
      />
    </ItemWrapper>
  )
}
