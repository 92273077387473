import { UserFormData } from '../UserForm/UserForm.types'
import { StyledSelectMenuItem } from './UserRoleSelection.styles'
import { ROLE_SELECT_WIDTH, UserRoleSelectionProps } from './UserRoleSelection.types'
import { FormControl, Stack, Typography } from '@mui/material'
import { FieldForm } from 'components/FieldForm'
import { UserRoles, userRolesArray, userRolesEnToRu } from 'core/types/user'
import { useFormikContext } from 'formik'
import React from 'react'
import { theme } from 'styles/theme'

export const UserRoleSelection: React.FC<UserRoleSelectionProps> = () => {
  const { setFieldValue } = useFormikContext<UserFormData>()

  return (
    <Stack spacing={0.5}>
      <Typography variant='subtitle1' color={theme.palette.text.dark} textAlign='start'>
        Роль пользователя:
      </Typography>

      <FormControl
        style={{ width: '100%' }}
        sx={{
          '& .MuiSelect-outlined': {
            padding: '10px 16px !important',
            fontSize: '16px !important',
            lineHeight: '23px !important',
            textAlign: 'left !important',
            color: `${theme.palette.text.dark} !important`,
          },
          ' & .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(92, 110, 140, 0.3) !important',
            borderRadius: '6px !important',
          },
          '& .MuiSvgIcon-root': {
            right: '14px !important',
          },
        }}
      >
        <FieldForm
          version='select'
          name='role'
          onChange={(e) => {
            const role: UserRoles = e.target.value as UserRoles

            setFieldValue('role', role)
            setFieldValue('projects', [])
            setFieldValue('companies', [])
            setFieldValue('allCompanies', [])

            if (role === 'contractor' || role === 'none') setFieldValue('allProjects', false)
            else setFieldValue('allProjects', true)
          }}
        >
          {userRolesArray.map((role) => (
            <StyledSelectMenuItem value={role} key={role}>
              {userRolesEnToRu[role]}
            </StyledSelectMenuItem>
          ))}
        </FieldForm>
      </FormControl>
    </Stack>
  )
}
