import { Stack } from '@mui/material'
import styled from 'styled-components'
import { IRepresentativeStatusProps } from './RepresentativeStatus.types'

interface IRepresentativeStatusWrapperProps extends IRepresentativeStatusProps {}

export const RepresentativeStatusWrapper = styled(Stack)<IRepresentativeStatusWrapperProps>`
  gap: 4px;
  padding: 4px 9px 4px 4px;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  margin: 0 auto;
  background-color: ${(props) => (props.type === 'user' ? 'rgba(25, 118, 210, 0.08)' : props.theme.palette.bg.gray)};

  & .MuiTypography-root {
    color: ${(props) => (props.type === 'user' ? props.theme.palette.primary.main : props.theme.palette.text.disabled)};
    line-height: normal;
  }
`
