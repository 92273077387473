import { DrawerTopBar } from '../../../Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { ColumnsDrawerWrapper } from './ColumnsDrawer.styles'
import { ColumnsDrawerFormData, ColumnsDrawerProps } from './ColumnsDrawer.types'
import { ColumnsDrawerGlobalSection } from './ColumnsDrawerGlobalSection'
import { ColumnsDrawerSection } from './ColumnsDrawerSection'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Drawer, Stack, Typography } from '@mui/material'
import { useSetColumnSettingsMutation } from 'api/prescriptions'
import { PrescriptionColumn, prescriptionColumns, prescriptionDefaultColumns } from 'api/prescriptions/types'
import { useSetRemarksColumnSettingsMutation } from 'api/remarks'
import { RemarkColumn, remarkColumns, remarkDefaultColumns, RemarkGlobalColumn } from 'api/remarks/types'
import { Divider } from 'components/Divider'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { ColumnSettings } from 'pages/Remarks/components/RemarksLegend'
import { FC, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const ColumnsDrawer: FC<ColumnsDrawerProps> = ({ open, columnsData, columnSettings, onClose }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  // const { data: columnsData } = useGetColumnSettingsQuery({ projectId })
  const setColumnSettingsQueries: Record<ColumnSettings, any> = {
    prescriptions: useSetColumnSettingsMutation,
    remarks: useSetRemarksColumnSettingsMutation,
  }
  const setQuery = setColumnSettingsQueries[columnSettings]

  const columns: Record<ColumnSettings, PrescriptionColumn[] | RemarkColumn[]> = {
    prescriptions: prescriptionColumns as PrescriptionColumn[],
    remarks: remarkColumns as RemarkColumn[],
  }

  const currentColumns = columns[columnSettings]

  const [setColumnSettings, setColumnSettingsResponse] = setQuery({ fixedCacheKey: 'columns' })

  const initialValues: ColumnsDrawerFormData = useMemo(
    () => ({
      columns: columnsData?.columns || [],
      showSecondary: columnsData?.showSecondary,
    }),
    [columnsData],
  )

  useEffect(() => {
    if (!open) resetForm()
  }, [open])

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (columnSettings === 'remarks') {
        setColumnSettings({ projectId, body: { columns: values.columns, showSecondary: values.showSecondary } })
      } else {
        setColumnSettings({ projectId, body: { columns: values.columns } })
      }

      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const defaultColumns: Record<ColumnSettings, PrescriptionColumn[] | RemarkColumn[]> = {
    prescriptions: prescriptionDefaultColumns as PrescriptionColumn[],
    remarks: remarkDefaultColumns as RemarkColumn[],
  }

  const { values, setFieldValue, dirty, resetForm } = formik

  // @ts-ignore
  const unselectedColumns: PrescriptionColumn[] | RemarkColumn[] = (currentColumns || []).filter(
    // @ts-ignore
    (column) => !values.columns.includes(column),
  )
  // const disableClearButton = !!columnsData?.default && !dirty

  const disableClearButton = isEqual(
    { columns: values.columns, showSecondary: values.showSecondary },
    {
      columns: defaultColumns[columnSettings],
      showSecondary: columnsData?.showSecondary,
    },
  )

  // const resetColumnSettingsQueries: Record<ColumnSettings, any> = {
  //   'prescriptions': useResetColumnSettingsMutation,
  //   'remarks': useResetRemarksColumnSettingsMutation,
  // }
  // const resetQuery = resetColumnSettingsQueries[columnSettings]

  // const [resetColumnSettings, resetColumnSettingsResponse] = resetQuery()

  const onClearClick = () => {
    setFieldValue('columns', defaultColumns[columnSettings])
    setFieldValue('showSecondary', columnSettings === 'remarks' ? true : columnsData?.showSecondary)
    // resetColumnSettings({ projectId })
  }

  useMutationHandlers(setColumnSettingsResponse, () => {
    onClose(false)
  })

  return (
    <Drawer anchor='right' open={open} onClose={() => onClose(dirty)}>
      <FormikProvider value={formik}>
        <Stack component={Form} flex={1} overflow='auto'>
          <ColumnsDrawerWrapper>
            <DrawerTopBar>
              <Typography variant='h1' color={theme.palette.primary.main}>
                Настройки отображения
              </Typography>
            </DrawerTopBar>

            <Divider />

            <Stack flex={1} justifyContent='space-between' overflow='auto'>
              <Stack overflow='auto'>
                <Stack direction='row' justifyContent='end' alignItems='center' p={1}>
                  <Button
                    onClick={onClearClick}
                    startIcon={<CloseIcon fontSize='medium' color={disableClearButton ? 'disabled' : 'error'} />}
                    disabled={disableClearButton}
                    variant='text'
                    color='error'
                    style={{ textTransform: 'initial' }}
                  >
                    Сбросить все настройки
                  </Button>
                </Stack>
                <ColumnsDrawerGlobalSection
                  columnSettings={columnSettings}
                  showSecondary={values.showSecondary}
                  columns={['showSecondary'] as RemarkGlobalColumn[]}
                />
                <Divider className='divider--small' />
                <ColumnsDrawerSection
                  columnSettings={columnSettings}
                  title='Сейчас отображаются колонки'
                  columns={values.columns}
                  draggable
                />
                <Divider className='divider--small' />
                <ColumnsDrawerSection
                  title='Скрытые колонки'
                  columnSettings={columnSettings}
                  columns={unselectedColumns}
                />
              </Stack>

              <Button type='submit' sx={{ m: 2.5 }} color='success' disabled={!dirty}>
                Применить
              </Button>
            </Stack>
          </ColumnsDrawerWrapper>
        </Stack>
      </FormikProvider>
    </Drawer>
  )
}
