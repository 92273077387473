import { Menu, Stack } from '@mui/material'
import { LegendColors } from 'core/types/global'
import styled from 'styled-components'

export const ELIMINATION_STATUS_WIDTH = 330

interface EliminationStatusWrapperProps {
  isReadOnly: boolean
}

export const EliminationStatusWrapper = styled(Stack)<EliminationStatusWrapperProps>`
  width: ${ELIMINATION_STATUS_WIDTH}px;
  padding: 0 8px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    props.isReadOnly
      ? `
    pointer-events: none;
  `
      : `
    &:hover {
      cursor: pointer;
    }
  `}
`

interface StatusMarkerProps {
  markerColor: LegendColors
}

export const StatusMarker = styled(Stack)<StatusMarkerProps>`
  min-width: 12px;
  height: 12px;
  aspect-ratio: 1;
  background-color: ${(props) => props.theme.palette.legends[props.markerColor]};
  border-radius: 50%;
  margin-right: 8px;
`

export const EliminationMenu = styled(Menu)`
  & .MuiPaper-root {
    width: ${ELIMINATION_STATUS_WIDTH}px;
  }
`
