import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { StatusSettingsFormWrapper, StatusSettingsSectionAdaptiveWrapper } from './StatusSettingsForm.styles'
import { StatusSettingsInfo } from '../StatusSettingsInfo'
import { StatusSettingsSection } from './components/StatusSettingsSection'
import { initialStatusSettings, IStatusSettingsFormProps, statusSettingsData } from './StatusSettingsForm.types'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { Stack } from '@mui/material'
import { IStatusSettings } from 'core/types/settings'
import { useGetStatusSettingsQuery } from 'api/settings'
import { Progress } from 'components/Progress'
import { DirtyFormForGlobalConfirm } from 'core/types/global'
import { getObjectReplacedValues } from 'utils/getObjectReplacedValues'
import { statusSettingsValidationSchema } from './StatusSettingsForm.validation'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'hooks/useSnackbar'

export const StatusSettingsForm: FC<IStatusSettingsFormProps> = ({ resetStatusSettingsResponse }) => {
  const { data: statusSettings, isLoading: isStatusSettingsLoading } = useGetStatusSettingsQuery()
  const { showSnackbar } = useSnackbar()

  const initialValues = useMemo(() => {
    const data: IStatusSettings = getObjectReplacedValues({
      data: resetStatusSettingsResponse.data || statusSettings || initialStatusSettings,
      from: null,
      to: '',
    })

    return data
  }, [isStatusSettingsLoading, resetStatusSettingsResponse])

  const { formik } = useForm({
    validationSchema: statusSettingsValidationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {},
  })

  const { setValues } = formik
  const [dirtyCount, setDirtyCount] = useState<number>(0)

  useEffect(() => {
    const dirtyFormType: DirtyFormForGlobalConfirm = 'settings'
    if (dirtyCount > 0) localStorage.setItem('dirtyForm', dirtyFormType)
    else localStorage.removeItem('dirtyForm')
  }, [dirtyCount])

  useMutationHandlers(
    resetStatusSettingsResponse,
    (data) => {
      showSnackbar('Статусы успешно восстановлены.', { variant: 'success' })
      setValues(initialStatusSettings)
    },
    () => {
      showSnackbar('Произошла ошибка при восстановлении статусов.', { variant: 'error' })
    },
  )

  if (isStatusSettingsLoading) return <Progress />

  return (
    <FormikProvider value={formik}>
      <Stack component={Form} height='100%'>
        <StatusSettingsFormWrapper>
          <StatusSettingsSectionAdaptiveWrapper width='50%' pr={1.25}>
            <StatusSettingsInfo />
          </StatusSettingsSectionAdaptiveWrapper>

          <StatusSettingsSectionAdaptiveWrapper height='100%'>
            {statusSettingsData.map((item) => (
              <StatusSettingsSection
                title={item.title}
                statusData={item.statusData}
                sectionName={item.sectionName}
                setDirtyCount={setDirtyCount}
              />
            ))}
          </StatusSettingsSectionAdaptiveWrapper>
        </StatusSettingsFormWrapper>
      </Stack>
    </FormikProvider>
  )
}
