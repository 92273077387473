import { projectChartColors } from '../../ProjectWidget.types'
import { ProjectChartWrapper, TotalCountWrapper } from './ProjectChart.styles'
import { ProjectChartProps } from './ProjectChart.types'
import { Stack, Typography } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { theme } from 'styles/theme'
import { PRESCRIPTION_COMPLETE_STATUS_COLOR } from 'utils/constants'

export const ProjectChart: FC<ProjectChartProps> = ({ chartData }) => {
  const { outerData = [], outerColors = [], total = 0 } = chartData || {}

  const data = {
    labels: ['Section 1', 'Section 2', 'Section 3'],
    datasets: [
      {
        data: [...outerData].reverse(),
        backgroundColor: [...outerColors].reverse(),
        // hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
      {
        data: [],
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    events: [],
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    rotation: 0,
  }

  return (
    <ProjectChartWrapper>
      <Doughnut data={data} options={options} />

      <TotalCountWrapper spacing={0}>
        <Typography variant='subtitle2' fontSize={16} color={theme.palette.text.dark} lineHeight='100%'>
          {total}
        </Typography>
        <Typography variant='subtitle2' fontSize={12} color={theme.palette.secondary.main}>
          всего
        </Typography>
      </TotalCountWrapper>
    </ProjectChartWrapper>
  )
}
