import { CurrentSortableColumn, PrescriptionFilters } from '../PrescriptionsTable'
import { PrescriptionSenderCompany, PrescriptionSenderUser, RequestBindChoice } from 'api/prescriptions/types'
import { PublicUserProfile, UserProfile } from 'api/profile/types'
import { ContractorRespUser, ResponsibleRepresentative } from 'api/projects/types'
import { RemarkFull } from 'api/remarks/types'
import { CustomSelectValue } from 'components/CustomSelect/CustomSelect.types'
import { Media } from 'core/types/global'
import {
  prescriptionDirectionRuByEn,
  PrescriptionInspection,
  prescriptionInspectionRuByEn,
  TPrescriptionStatus,
  PrescriptionType,
  prescriptionTypeRuByEn,
  TPrescriptionDirection,
} from 'core/types/prescription'
import { OptionsObject, SnackbarMessage, VariantType } from 'notistack'
import { CustomSelectValueWithType } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { connectNames } from 'utils/connectNames'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export interface PrescriptionFormData {
  status: TPrescriptionStatus
  number: string
  dateStart: Date
  direction: CustomSelectValue
  inspectionType: CustomSelectValue | null
  type: CustomSelectValue
  comment: string

  senderUser: CustomSelectValueWithType | null
  senderCompany: CustomSelectValueWithType | null
  contractor: CustomSelectValue | null
  receiver: CustomSelectValue | null
  executionControl: CustomSelectValue | null
  dateEnd: Date | null
  dateDone: Date | null
  files: Media[]
  filesForCreate: File[]
  filesIdsToDelete: number[]

  remarks: RemarkFull[]
  choice: RequestBindChoice | null
  automaticDateEnd: boolean
  // remarksIdsToDelete: number[]
  author: UserProfile | null
}

export interface PrescriptionLocationState {
  sortableColumn: CurrentSortableColumn
  filterData: PrescriptionFilters
  prescriptionValues: PrescriptionFormData
  currentPage: number
  backUrl?: string
  backState?: Object
  transaction?: boolean
}

export interface PrescriptionAutofilledData {
  contractor: CustomSelectValue | null
}

export type PrescriptionFormDialogTrigger = 'delete' | 'exit' | 'closeBind' | 'notification' | 'eliminationResult'

export type PrescriptionDrawerType = 'remarks' | 'eliminationResult' | 'audit'

export const getInitialSenderCompany = (
  profile: PublicUserProfile,
  senderCompany?: PrescriptionSenderCompany,
  itemId?: number,
): CustomSelectValueWithType | null => {
  let data = null

  if (!senderCompany && !profile.company.userCompanyName) return data

  if (itemId) {
    data = {
      id: senderCompany?.company || senderCompany?.projectMember?.id,
      value: senderCompany?.company || senderCompany?.projectMember?.shortName,
      type: senderCompany?.projectMember ? 'projectMember' : 'company',
    }

    return data as CustomSelectValueWithType
  }

  data = {
    id: profile.company.userCompanyName,
    value: profile.company.userCompanyName,
    type: 'company',
  }

  return data
}

export const getInitialSenderUser = (
  profile: PublicUserProfile,
  senderUser?: PrescriptionSenderUser,
  itemId?: number,
) => {
  let data = null

  if (!senderUser?.representative && !senderUser?.user && !profile.company.userCompanyName) return data

  if (itemId) {
    data = {
      id: senderUser?.representative?.id || senderUser?.user?.id,
      value: senderUser?.representative?.fullName || connectNames(senderUser?.user),
      type: senderUser?.representative ? 'representative' : 'engineerQC',
      subtext: senderUser?.representative?.position || senderUser?.user?.position,
    }

    return data as CustomSelectValueWithType
  }

  data = {
    id: profile?.id,
    value: connectNames(profile),
    type: 'engineerQC',
    subtext: profile.company.userPosition,
  }

  return data
}

export const getInitialDirection = (direction?: TPrescriptionDirection) => {
  const data = {
    id: direction || 'OUTGOING',
    value: prescriptionDirectionRuByEn[direction || 'OUTGOING'],
  }

  return data
}

export const getInitialInspectionType = (inspectionType?: PrescriptionInspection) => {
  if (!inspectionType) return null

  const data = {
    id: inspectionType,
    value: prescriptionInspectionRuByEn[inspectionType],
  }

  return data
}

export const getInitialType = (type?: PrescriptionType) => {
  const data = {
    id: type || 'CONTINUE_WORK',
    value: prescriptionTypeRuByEn[type || 'CONTINUE_WORK'],
  }

  return data
}

export const getInitialContractor = (contractor?: ContractorRespUser) => {
  if (!contractor) return null

  const data = {
    id: contractor.id,
    value: contractor.shortName,
  }

  return data
}

export const getInitialReceiverOrExecutionControl = (info?: ResponsibleRepresentative) => {
  if (!info) return null

  const data = {
    id: info?.id,
    value: info?.fullName,
    subtext: `${info?.position || '—'}, ${info.projectMember.shortName || '—'}`,
  }

  return data
}

export const getDataForRequest = (values: PrescriptionFormData, initialValues: PrescriptionFormData) => {
  const {
    status,
    dateStart,
    dateEnd,
    dateDone,
    inspectionType,
    type,
    comment,
    senderCompany,
    senderUser,
    contractor,
    receiver,
    executionControl,
    automaticDateEnd,
    direction,
  } = values

  const data = {
    number: initialValues.number === values.number ? null : values.number,
    status,
    period: {
      dateStart: formatDateForServer(dateStart),
      dateEnd: formatDateForServer(dateEnd),
      dateDone: dateDone ? formatDateForServer(dateDone) : null,
      automaticDateEnd,
    },
    inspectionType: inspectionType?.id,
    type: type?.id,
    comment: comment,
    senderCompany: {
      company: senderCompany?.type === 'company' ? senderCompany.value : null,
      projectMember: senderCompany?.type === 'projectMember' ? senderCompany.id : null,
    },
    senderUser: {
      engineerQC: senderUser?.type === 'engineerQC' ? senderUser.id : null,
      representative: senderUser?.type === 'representative' ? senderUser.id : null,
    },
    contractor: contractor?.id,
    receiver: receiver?.id,
    executionControl: executionControl?.id,
    direction: direction.id,
  }

  return data
}
