import { PrescriptionAutofilledData, PrescriptionFormData } from '../../PrescriptionsForm.types'
import { Wrapper } from './Control.styles'
import { ControlProps, automaticDateEndInfo } from './Control.types'
import { EventAvailable as EventAvailableIcon, InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  useGetDropdownCompanySendersQuery,
  useGetDropdownPrescriptionContractorsQuery,
  useLazyGetDropdownExecutionControlQuery,
  useLazyGetDropdownReceiversQuery,
  useLazyGetDropdownSendersQuery,
} from 'api/prescriptions'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { Tooltip } from 'components/Tooltip'
import { useFormikContext } from 'formik'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { CustomSelectValueWithType } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export const Control: FC<ControlProps> = ({ viewingOnly, setIsLocalValid, formMode, setAutofilledData }) => {
  const { values, setFieldValue, initialValues, errors } = useFormikContext<PrescriptionFormData>()
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const [isDateDoneRequired, setIsDateDoneRequired] = useState<boolean>(false)
  const isAddingMode = formMode === 'add'
  const [isDateDoneFocused, setIsDateDoneFocused] = useState(false)

  const { data: companySendersDropdown } = useGetDropdownCompanySendersQuery({ projectId })
  const { data: contractorsDropdown } = useGetDropdownPrescriptionContractorsQuery({ projectId })

  const [sendersDropdownRequest, { data: sendersDropdown }] = useLazyGetDropdownSendersQuery()
  const [receiversDropdownRequest, { data: receiversDropdown }] = useLazyGetDropdownReceiversQuery()
  const [executionControlDropdownRequest, { data: executionControlDropdown }] =
    useLazyGetDropdownExecutionControlQuery()

  useEffect(() => {
    const { id, value, type } = values.senderCompany || {}

    if (value && !sendersDropdown?.data.length) {
      sendersDropdownRequest({
        projectId,
        company: type === 'company' ? value : null,
        member: type === 'projectMember' ? Number(id) : null,
      })
    }
  }, [values.senderCompany])

  useEffect(() => {
    const { id } = values.contractor || {}

    if (id && !receiversDropdown?.data.length && !executionControlDropdown?.data.length) {
      receiversDropdownRequest({
        projectId,
        contractor: String(id),
      })
      executionControlDropdownRequest({
        projectId,
        contractor: String(id),
      })
    }
  }, [values.contractor])

  useEffect(() => {
    if (viewingOnly) return

    if (formatDateForServer(values.dateDone) && !isDateDoneFocused) {
      setFieldValue('status', 'COMPLETE')
    }
  }, [values.dateDone])

  useEffect(() => {
    if (viewingOnly) return

    if (values.status === 'COMPLETE') {
      if (!values.dateDone) {
        setFieldValue('dateDone', new Date())
      }
      setIsDateDoneRequired(true)
    } else {
      // setFieldValue('dateDone', null)
      setIsDateDoneRequired(false)
    }
  }, [values.status])

  // contractor autofill
  useEffect(() => {
    const contractorsList = contractorsDropdown?.data || []
    const firstContractor = contractorsList[0]
    const { id, text } = firstContractor || {}

    if (isAddingMode && contractorsList.length === 1 && !values.contractor) {
      const contractorData = {
        id: id,
        value: text,
      }
      setFieldValue('contractor', contractorData)

      setAutofilledData((prev: PrescriptionAutofilledData) => ({ ...prev, contractor: contractorData }))
    }
  }, [contractorsDropdown, initialValues])

  const changeDateDoneFocus = (value: boolean) => {
    setIsDateDoneFocused(value)
  }

  const handleSenderCompanySelection = (data: CustomSelectValueWithType) => {
    const { id, value, type } = data

    setFieldValue('senderUser', null)
    setIsLocalValid(false)

    sendersDropdownRequest({
      projectId,
      company: type === 'company' ? String(value) : null,
      member: type === 'projectMember' ? Number(id) : null,
    })
  }

  const handleContractorSelection = (data: CustomSelectValueWithType) => {
    const { id } = data

    setFieldValue('receiver', null)
    setFieldValue('executionControl', null)
    setIsLocalValid(false)

    receiversDropdownRequest({
      projectId,
      contractor: String(id),
    })
    executionControlDropdownRequest({
      projectId,
      contractor: String(id),
    })
  }

  return (
    <Wrapper>
      <Stack spacing={3}>
        <Stack>
          <Typography marginBottom={1.5} variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
            Строительный контроль
          </Typography>
          <Stack spacing={1.5}>
            <CustomSelect
              name='senderCompany'
              list={
                companySendersDropdown?.data?.map((item) => ({
                  ...item,
                  group: item.data === 'company' ? 'Компании:' : 'Участники проекта:',
                })) || []
              }
              isSearch={true}
              placeholder='Выберите из списка'
              label='Организация, выдавшая предписание'
              style={{
                textAlign: 'start',
              }}
              handleValueSelection={handleSenderCompanySelection}
              readOnly={viewingOnly}
              isRequired={true}
              isGroup={true}
            />
            <CustomSelect
              name='senderUser'
              list={sendersDropdown?.data || []}
              isSearch={true}
              placeholder='Выберите из списка'
              label='Предписание выдал'
              isSubtext={true}
              style={{
                textAlign: 'start',
              }}
              isDisabled={!values?.senderCompany?.value}
              readOnly={viewingOnly}
              isRequired={true}
              // isGroup={true}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
            Ответственный
          </Typography>
          <Stack spacing={1.5}>
            <CustomSelect
              name='contractor'
              list={contractorsDropdown?.data || []}
              handleValueSelection={handleContractorSelection}
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Организация, получившая предписание'
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
            />
            <CustomSelect
              name='receiver'
              list={
                receiversDropdown?.data.map(({ id, text, subtext, data }) => ({
                  id,
                  text,
                  subtext: `${subtext || '—'}, ${data?.shortName || '—'}`,
                })) || []
              }
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Предписание получил'
              isSubtext={true}
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
              isDisabled={!values?.contractor?.value}
            />
            <CustomSelect
              name='executionControl'
              list={
                executionControlDropdown?.data.map(({ id, text, subtext, data }) => ({
                  id,
                  text,
                  subtext: `${subtext || '—'}, ${data?.shortName || '—'}`,
                })) || []
              }
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Контроль за устранением нарушений'
              isSubtext={true}
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
              isDisabled={!values?.contractor?.value}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
            Сроки
          </Typography>
          <Stack spacing={1.25}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' marginRight={-1.5}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Tooltip
                  title={automaticDateEndInfo}
                  bgColor={theme.palette.bg.white}
                  textColor={theme.palette.text.dark}
                >
                  <InfoIcon fontSize='inherit' color='action' />
                </Tooltip>
                <Typography variant='body2' color={theme.palette.text.dark}>
                  Автозаполнение Уст. даты устранения
                </Typography>
              </Stack>

              <FieldForm
                version='switch'
                name=''
                checkboxData={{
                  checked: values.automaticDateEnd,
                  onChange: (e) => setFieldValue('automaticDateEnd', e.target.checked),
                  label: '',
                }}
                disabled={viewingOnly}
              />
            </Stack>
            <FieldItem
              title='Установленная дата устранения'
              icon={<EventAvailableIcon fontSize='medium' color='secondary' />}
              isRequired={true}
            >
              <FieldForm
                version='date'
                name='dateEnd'
                placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                helperText=''
                dataValue={values.dateEnd}
                onDataChange={(value: Date | null) => setFieldValue('dateEnd', value)}
                style={{ maxWidth: '144px', width: '100%' }}
                dateFieldProps={{
                  minDate: values.dateStart || undefined,
                  readOnly: viewingOnly,
                  disabled: values.automaticDateEnd,
                }}
              />
            </FieldItem>
            {!!prescriptionId && (
              <FieldItem
                title='Фактическая дата устранения'
                icon={<EventAvailableIcon fontSize='medium' color='secondary' />}
                isRequired={isDateDoneRequired}
              >
                <FieldForm
                  version='date'
                  name='dateDone'
                  placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                  helperText=''
                  dataValue={values.dateDone}
                  onFocus={() => changeDateDoneFocus(true)}
                  onBlur={() => changeDateDoneFocus(false)}
                  onDataChange={(value: Date | null) => setFieldValue('dateDone', value)}
                  style={{ maxWidth: '144px', width: '100%' }}
                  dateFieldProps={{
                    minDate: values.dateStart || undefined,
                    maxDate: new Date() || undefined,
                    readOnly: viewingOnly,
                  }}
                />
              </FieldItem>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  )
}
