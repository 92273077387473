import { ActsTooltip } from '../ActsTooltip'
import { DocumentRemark, DocumentRemarkHeader } from './DocumentRemarkItem.styles'
import { DocumentRemarkItemProps } from './DocumentRemarkItem.types'
import { InsertDriveFile as FileIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { remarkStatusColorByEn } from 'api/remarks/types'
import { ColoredTitle } from 'components/ColoredTitle'
import { Tooltip } from 'components/Tooltip'
import { eliminationStatusRuByEn, eliminationStatusesColor } from 'core/types/elimination'
import { useRemarkStatus } from 'hooks/useCustomStatus'
import { StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { RemarkMediaItemButton } from 'pages/Remarks/components/RemarkForm/Media/components/RemarkMediaItem/RemarkMediaItem.styles'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const DocumentRemarkItem: FC<DocumentRemarkItemProps> = ({ remark, isSelectable }) => {
  const { number, status, contractorStatus, period, completionActs } = remark || {}
  const parsedDateEnd = parseResponseDate(period.dateEnd).fullDate || '—'
  const parsedDateDone = parseResponseDate(period.dateDone).fullDate || '—'
  const customRemarkStatus = useRemarkStatus()

  return (
    <DocumentRemark spacing={1} isSelectable={isSelectable}>
      <DocumentRemarkHeader>
        <Typography variant='h6' fontSize={14} color={theme.palette.text.dark}>
          Замечание № {number}
        </Typography>

        <Stack direction='row' spacing={1} alignItems='center'>
          <ColoredTitle body={customRemarkStatus[status]} color={remarkStatusColorByEn[status]} />
          {!!completionActs.length && isSelectable && (
            <Tooltip
              title={<ActsTooltip completionActs={completionActs} />}
              bgColor={theme.palette.bg.white}
              textColor={theme.palette.text.dark}
            >
              <Stack>
                <RemarkMediaItemButton type='button' style={{ pointerEvents: 'none' }}>
                  <FileIcon color='primary' fontSize='medium' />
                </RemarkMediaItemButton>
              </Stack>
            </Tooltip>
          )}
        </Stack>
      </DocumentRemarkHeader>
      <Stack spacing={0.5}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.secondary}>
            Статус устранения:
          </Typography>

          <Stack direction='row' alignItems='center'>
            <StatusMarker markerColor={eliminationStatusesColor[contractorStatus]} />
            <Typography variant='body2' textAlign='start' lineHeight='initial' fontSize={12}>
              {eliminationStatusRuByEn[contractorStatus]}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.secondary}>
            Установленная дата устранения:
          </Typography>

          <Typography variant='body2' fontWeight={500} fontSize={12} color={theme.palette.text.dark}>
            {parsedDateEnd}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.secondary}>
            Фактическая дата устранения:
          </Typography>

          <Typography variant='body2' fontWeight={500} fontSize={12} color={theme.palette.text.dark}>
            {parsedDateDone}
          </Typography>
        </Stack>
      </Stack>
    </DocumentRemark>
  )
}
