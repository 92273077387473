import { CellContext } from '@tanstack/react-table'
import { Prescription } from 'api/prescriptions/types'
import { ReactNode } from 'react'
import { theme } from 'styles/theme'
import { TaskOutlined as FileWithMarkIcon, DescriptionOutlined as FileIcon } from '@mui/icons-material'
import { EditDocIcon } from 'assets/icons/EditDocIcon'
import { RemarkFull } from 'api/remarks/types'
import { ICallFull } from 'api/calls/types'
import { StackProps } from '@mui/material'

export interface ICountCellProps {
  data: ICountCellData[]
  stackProps?: StackProps
}

export interface ICountCellData {
  value: number | null
  icon: ReactNode
  tooltipText: string
  color: string
}

export const getPrescriptionCountCellData = (info: CellContext<Prescription, unknown>) => {
  const { shortcomings, completionActs, attachments } = info.row.original.count
  const countCellData: ICountCellData[] = [
    {
      value: shortcomings,
      tooltipText: 'Замечания',
      icon: <EditDocIcon fontSize='medium' color='warning' />,
      color: theme.palette.warning.main,
    },
    {
      value: completionActs,
      tooltipText: 'Акты об устранении нарушений',
      icon: <FileWithMarkIcon fontSize='medium' />,
      color: theme.palette.legends.blue,
    },
    {
      value: attachments,
      tooltipText: 'Файлы в предписании',
      icon: <FileIcon fontSize='medium' />,
      color: theme.palette.secondary.gray || '',
    },
  ]

  return countCellData
}

export const getRemarkCountCellData = (info: CellContext<RemarkFull, unknown>) => {
  const { media, contractorMedia } = info.row.original.count || {}
  const countCellData: ICountCellData[] = [
    {
      value: media,
      tooltipText: 'Файлы в замечании',
      icon: <FileIcon fontSize='medium' />,
      color: theme.palette.secondary.gray || '',
    },
    {
      value: contractorMedia,
      tooltipText: 'Файлы в устранении',
      icon: <FileIcon fontSize='medium' />,
      color: theme.palette.legends.paleGreen || '',
    },
  ]

  return countCellData
}

export const getCallCountCellData = (data?: ICallFull) => {
  const { attachments = null } = data?.count || {}
  const countCellData: ICountCellData[] = [
    {
      value: attachments,
      tooltipText: 'Файлы в вызове',
      icon: <FileIcon fontSize='medium' />,
      color: theme.palette.secondary.gray || '',
    },
  ]

  return countCellData
}
