import { api } from '../api'
import {
  CreateProjectRequest,
  DeleteImagesFromProjectRequest,
  DeleteProjectRequest,
  EditProjectRequest,
  GetCompaniesDropdownForProjectRequest,
  GetCompaniesDropdownForProjectResponse,
  GetCompaniesDropdownRequest,
  GetCompaniesDropdownResponse,
  GetProjectByIdRequest,
  GetProjectByIdResponse,
  GetProjectDashboardRequest,
  GetProjectDashboardResponse,
  GetProjectsOverallRequest,
  GetProjectsOverallResponse,
  GetProjectsRequest,
  GetProjectsResponse,
  GetResponsibleDropdownRequest,
  GetResponsibleDropdownResponse,
  IGetDashboardSettingsRequest,
  IGetProjectNotificationSettingsRequest,
  ISetDashboardSettingsRequest,
  ISetProjectNotificationSettingsRequest,
  ProjectBaseResponse,
  ProjectBaseWithImagesResponse,
  UploadImagesToProjectRequest,
} from './api.types'
import { IDashboardSettings, IProjectNotificationSettings, StatisticsDataItem } from 'api/projects/types'

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<GetProjectsResponse, GetProjectsRequest>({
      query: ({ limit, offset }) => ({
        url: '/project/list',
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['Projects', { type: 'Projects', id: 'LIST' }],
    }),
    getProjectsOverall: build.query<GetProjectsOverallResponse, GetProjectsOverallRequest>({
      query: ({ limit, offset }) => ({
        url: '/project/overall',
        params: { limit, offset },
        method: 'GET',
      }),
      providesTags: ['Projects', { type: 'Projects', id: 'LIST' }],
    }),
    getCompaniesDropdown: build.query<GetCompaniesDropdownResponse[], GetCompaniesDropdownRequest>({
      query: ({ projectId }) => ({
        url: `/project/add/dropdown/company`,
        params: projectId ? { project: projectId, page: 1, num: 99999 } : { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getResponsibleDropdown: build.query<GetResponsibleDropdownResponse[], GetResponsibleDropdownRequest>({
      query: ({ projectId }) => ({
        url: `/project/add/dropdown/responsible`,
        params: projectId ? { project: projectId, page: 1, num: 99999 } : { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getCompaniesDropdownForProject: build.query<
      GetCompaniesDropdownForProjectResponse[],
      GetCompaniesDropdownForProjectRequest
    >({
      query: ({ companyID, userID, projectId }) => ({
        url: `/company/${companyID}/admin/${userID}/update-access/dropdown/contractors`,
        params: { project: projectId, page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    createProject: build.mutation<ProjectBaseResponse, CreateProjectRequest>({
      query: (body) => ({
        url: `/project/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Projects'],
    }),
    getProjectById: build.query<GetProjectByIdResponse, GetProjectByIdRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/get`,
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getProjectDashboard: build.query<GetProjectDashboardResponse, GetProjectDashboardRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/dashboard`,
        method: 'GET',
      }),
      transformResponse: (response: GetProjectDashboardResponse) => {
        const { shortcoming, prescription } = response.data.statistics

        const shortcomingTotalInWork: StatisticsDataItem = {
          data: shortcoming.active.data,
          percent: shortcoming.active.percent,
        }
        const shortcomingTotalInWorkExpired: StatisticsDataItem = {
          data: shortcoming.expired.active.data,
          percent: shortcoming.expired.active.percent,
        }

        const primaryTotalInWork: StatisticsDataItem = {
          data: shortcoming.primary.active.data,
          percent: shortcoming.primary.active.percent,
        }
        const secondaryTotalInWork: StatisticsDataItem = {
          data: shortcoming.secondary.active.data,
          percent: shortcoming.secondary.active.percent,
        }

        const prescriptionAllTotalInWork: StatisticsDataItem = {
          data: prescription.all.active.data,
          percent: prescription.all.active.percent,
        }
        const prescriptionAllTotalInWorkExpired: StatisticsDataItem = {
          data: prescription.all.expired.sent.data,
          percent: prescription.all.expired.sent.percent,
        }
        const prescriptionIncomingTotalInWork: StatisticsDataItem = {
          data: prescription.incoming.active.data,
          percent: prescription.incoming.active.percent,
        }
        const prescriptionIncomingTotalInWorkExpired: StatisticsDataItem = {
          data: prescription.incoming.expired.sent.data,
          percent: prescription.incoming.expired.sent.percent,
        }
        const prescriptionOutgoingTotalInWork: StatisticsDataItem = {
          data: prescription.outgoing.active.data,
          percent: prescription.outgoing.active.percent,
        }
        const prescriptionOutgoingTotalInWorkExpired: StatisticsDataItem = {
          data: prescription.outgoing.expired.sent.data,
          percent: prescription.outgoing.expired.sent.percent,
        }

        // const callTotalInWork: StatisticsDataItem = {
        //   data: call.created.data,
        //   percent: call.created.percent,
        // }
        // const callTotalInWorkExpired: StatisticsDataItem = {
        //   data: call.expired.created.data,
        //   percent: call.expired.created.percent,
        // }

        const responseWithTotalInWork = { ...response }

        responseWithTotalInWork.data.statistics.shortcoming.totalInWork = shortcomingTotalInWork
        responseWithTotalInWork.data.statistics.shortcoming.expired.totalInWork = shortcomingTotalInWorkExpired
        responseWithTotalInWork.data.statistics.shortcoming.primary.totalInWork = primaryTotalInWork
        responseWithTotalInWork.data.statistics.shortcoming.secondary.totalInWork = secondaryTotalInWork
        responseWithTotalInWork.data.statistics.prescription.all.totalInWork = prescriptionAllTotalInWork
        responseWithTotalInWork.data.statistics.prescription.all.expired.totalInWork = prescriptionAllTotalInWorkExpired
        responseWithTotalInWork.data.statistics.prescription.incoming.totalInWork = prescriptionIncomingTotalInWork
        responseWithTotalInWork.data.statistics.prescription.incoming.expired.totalInWork =
          prescriptionIncomingTotalInWorkExpired
        responseWithTotalInWork.data.statistics.prescription.outgoing.totalInWork = prescriptionOutgoingTotalInWork
        responseWithTotalInWork.data.statistics.prescription.outgoing.expired.totalInWork =
          prescriptionOutgoingTotalInWorkExpired
        // responseWithTotalInWork.data.statistics.call.totalInWork = callTotalInWork
        // responseWithTotalInWork.data.statistics.call.expired.totalInWork = callTotalInWorkExpired

        return responseWithTotalInWork
      },
      providesTags: ['Projects', 'ProjectsDashboard'],
    }),
    editProject: build.mutation<ProjectBaseResponse, EditProjectRequest>({
      query: ({ id, ...body }) => ({
        url: `/project/${id}/update`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Projects'],
      // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data: updatedProject } = await queryFulfilled
      //
      //     const patchResult = dispatch(
      //       projectsApi.util.updateQueryData('getProjects', {}, (draft) => {
      //         const changedProject = draft.data.findIndex(project => project.id === updatedProject.data.id)
      //         draft.data.splice(changedProject, 1, updatedProject.data)
      //       })
      //     )
      //
      //     const patchResult2 = dispatch(
      //       projectsApi.util.updateQueryData('getProjectById', { id }, (draft) => {
      //         Object.assign(draft, updatedProject)
      //       })
      //     )
      //
      //     const patchResult3 = dispatch(
      //       projectsApi.util.updateQueryData('getProjectsOverall', {}, (draft) => {
      //         Object.assign(draft.data.find(project => project.project.id === updatedProject.data.id)?.project, updatedProject.data)
      //       })
      //     )
      //   } catch {
      //   }
      // },
    }),
    deleteProject: build.mutation<ProjectBaseWithImagesResponse, DeleteProjectRequest>({
      query: ({ id }) => ({
        url: `/project/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
    }),
    uploadImagesToProject: build.mutation<ProjectBaseWithImagesResponse, UploadImagesToProjectRequest>({
      query: ({ id, file }) => {
        const formData = new FormData()
        file.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${id}/images/upload`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Projects'],
    }),
    deleteImagesFromProject: build.mutation<ProjectBaseWithImagesResponse, DeleteImagesFromProjectRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/images/delete`,
        params: { img: params.img },
        method: 'DELETE',
      }),
      invalidatesTags: ['Projects'],
    }),
    getProjectNotificationSettings: build.query<IProjectNotificationSettings, IGetProjectNotificationSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/settings/notify`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Projects', id: 'PROJECT_NOTIFICATION_SETTINGS' }],
    }),
    setProjectNotificationSettings: build.mutation<
      IProjectNotificationSettings,
      ISetProjectNotificationSettingsRequest
    >({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/settings/notify`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'PROJECT_NOTIFICATION_SETTINGS' }],
    }),
    getDashboardSettings: build.query<IDashboardSettings, IGetDashboardSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/dashboard/preferences`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Projects', id: 'PROJECT_DASHBOARD_SETTINGS' }],
    }),
    setDashboardSettings: build.mutation<void, ISetDashboardSettingsRequest>({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/dashboard/preferences`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'PROJECT_DASHBOARD_SETTINGS' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetProjectsQuery,
  useGetProjectsOverallQuery,
  useGetCompaniesDropdownQuery,
  useGetResponsibleDropdownQuery,
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectDashboardQuery,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useUploadImagesToProjectMutation,
  useDeleteImagesFromProjectMutation,
  useGetCompaniesDropdownForProjectQuery,
  useGetProjectNotificationSettingsQuery,
  useSetProjectNotificationSettingsMutation,
  useGetDashboardSettingsQuery,
  useSetDashboardSettingsMutation,
} = projectsApi
