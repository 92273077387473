import { Tooltip } from 'components/Tooltip'
import { StyledBox } from './ColoredTitle.styles'
import { ColoredTitleProps } from './ColoredTitle.types'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { theme } from 'styles/theme'

export const ColoredTitle: React.FC<ColoredTitleProps> = ({ body, icon, color, tooltipTitle, ...props }) => {
  return (
    <StyledBox bgColor={color} className='colored-title' {...props}>
      <Typography variant='tooltip' lineHeight='normal !important' flex={1}>
        {body}
      </Typography>

      {icon && (
        <Tooltip title={tooltipTitle} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
          <Stack>{icon}</Stack>
        </Tooltip>
      )}
    </StyledBox>
  )
}
