import { uiContext } from '../../contexts/ui'
import { Container, ContainerHeader } from './RegulationDoc.styles'
import { RegulationDocProps } from './RegulationDoc.types'
import { DocNavBar } from './components/DocNavBar'
import { DocInfoRow } from './components/DocNavBar/DocInfoRow'
import { HeaderDocument } from './components/HeaderDocument/HeaderDocument'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { SpecialZoomLevel } from '@react-pdf-viewer/core'
import { useGetRegulationDocQuery } from 'api/regulations'
import { Button } from 'components/Button'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Header } from 'components/Header'
import PdfViewer from 'components/PdfViewer/PdfViewer'
import { Progress } from 'components/Progress'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { InnerContainer, MainContainer } from 'layouts/MainLayout'
import { getEmptyPageData } from 'pages/Projects'
import { RegulationLocationState } from 'pages/Regulations/Regulations.types'
import { DocumentManagment } from 'pages/Regulations/RegulationsDrawers/DocumentManagment'
import { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { downloadMedia } from 'utils/downloadMedia'

export const RegulationDoc: FC<RegulationDocProps> = () => {
  const cUI = useContext(uiContext)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const navigate = useNavigate()

  const { sectionId: sectionIdStr, docId: docIdStr } = useParams()
  const sectionId = parseInt(sectionIdStr!)
  const docId = parseInt(docIdStr!)

  const { data: doc, isFetching } = useGetRegulationDocQuery({ sectionId, docId })
  const fileLink = doc?.file?.link || ''
  let fileName: any = doc?.file?.key.split('/')
  fileName = fileName?.[fileName.length - 1]

  // Location
  const location = useLocation()
  const locationState: Pick<RegulationLocationState, 'currentPage' | 'currentTab'> =
    (location?.state as RegulationLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { currentPage, currentTab } = savedLocationState || {}

  const onDownloadFile = () => {
    downloadMedia(fileLink)
  }

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => cUI.setShowSearch(true)
  }, [])

  useBreadcrumbs([{ title: 'НТД' }, { title: doc?.name || '' }], [doc])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(<>Файл отсутствует.</>)

  const [regulationDrawerOpen, setRegulationDrawerOpen] = useState<boolean>(false)

  const onRegulationDrawerOpen = () => {
    setRegulationDrawerOpen(true)
  }

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) setRegulationDrawerOpen(false)
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onRegulationDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    dirty || immediately ? openConfirm() : setRegulationDrawerOpen(false)
  }, [])

  const onReturnClick = () => {
    navigate('/regulations', { state: { currentPage, rowId: currentPage && doc?.id, currentTab } })
  }

  return isFetching ? (
    <Progress />
  ) : (
    <>
      <DocNavBar doc={doc!} onDrawerOpen={onRegulationDrawerOpen} onReturnClick={onReturnClick} />
      <MainContainer>
        <Header hideLogo />
        <Container>
          {(fileLink || isUserHighAccess) && (
            <HeaderDocument onReturnClick={onReturnClick} onEditClick={onRegulationDrawerOpen}>
              <Stack maxWidth='300px'>{fileLink && <DocInfoRow title='Ссылка' text={fileLink || ''} isLink />}</Stack>
            </HeaderDocument>
          )}
          {fileLink ? (
            <PdfViewer
              fileUrl={fileLink}
              title={fileName || ''}
              onDownload={onDownloadFile}
              onDelete={() => {}}
              rootViewerProps={{ defaultScale: SpecialZoomLevel.ActualSize }}
              disableDelete
            />
          ) : (
            <Stack height='100%' flexDirection='row' width='100%'>
              <EmptyPage data={emptyFilteredData} forFilter />
            </Stack>
          )}
        </Container>
      </MainContainer>

      <DocumentManagment
        open={regulationDrawerOpen}
        onClose={onRegulationDrawerClose}
        sectionId={doc?.section?.id!}
        regulation={doc}
      />
      <ConfirmDialog />
    </>
  )
}
