import { GENERAL_WIDGET_CHART_BAR_THICKNESS, GeneralWidgetChartProps, labelByRowType } from './GeneralWidgetChart.types'
import { useGetPrescriptionGeneralStatsQuery } from 'api/analytics'
import { PrescriptionGeneralStats, PrescriptionMeasurementItem } from 'api/analytics/types'
import { StatisticsDataItem } from 'api/projects/types'
import { ChartData, ChartOptions, TooltipItem } from 'chart.js'
import { Progress } from 'components/Progress'
import { useRemarkStatus } from 'hooks/useCustomStatus'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'
import { formatFloat } from 'utils/formatFloat'

export const GeneralWidgetChart: FC<GeneralWidgetChartProps> = ({ analyticsQueryData }) => {
  const { role } = useTypedSelector(profileSelector)
  const { queryBody, skip } = analyticsQueryData
  const { data: prescriptionGeneralStats, isFetching } = useGetPrescriptionGeneralStatsQuery(queryBody, skip)
  const { data: prescriptionGeneralData } = prescriptionGeneralStats || {}
  const customRemarkStatus = useRemarkStatus()

  const generateLabels = () => {
    const labels = []

    for (let key in prescriptionGeneralData || labelByRowType) {
      // @ts-ignore
      const { total, percent } = prescriptionGeneralData?.[key as keyof PrescriptionGeneralStats] || {}

      labels.push({
        text: labelByRowType[key as keyof typeof labelByRowType],
        total: total || 0,
        percent: percent || 0,
      })
    }

    return labels
  }

  let labels = generateLabels()

  const generateData = (type: keyof PrescriptionMeasurementItem) => {
    const dataList: {
      value: number
      percent: string
      tooltipTitle: string
      expired: { data: number; percent: string }
    }[] = []

    for (let key in prescriptionGeneralData) {
      const typedKey = key as keyof PrescriptionGeneralStats
      if (typedKey === 'completionActs') continue

      // @ts-ignore
      const { data, percent } = prescriptionGeneralData[typedKey][type] || {}
      const total = prescriptionGeneralData[typedKey].total
      let expired: any = { data: 0, percent: 0 }

      if (typedKey === 'workContinues' || typedKey === 'workPaused') {
        const { data, percent } = prescriptionGeneralData[typedKey].expired || {}

        expired = { data: data || null, percent: formatFloat(percent || 0) }
      }

      dataList.push({
        value: data,
        percent: formatFloat(percent || 0),
        tooltipTitle: `${labelByRowType[key as keyof typeof labelByRowType]} ${total}`,
        expired,
      })
    }

    return dataList
  }

  const generateCompletionActsData = () => {
    const dataList: any[] = []

    for (let key in prescriptionGeneralData) {
      const total = prescriptionGeneralData[key as keyof PrescriptionGeneralStats].total

      dataList.push({
        value: null,
        percent: null,
        tooltipTitle: `${labelByRowType[key as keyof typeof labelByRowType]} ${total}`,
        expired: { data: 0, percent: 0 },
      })
    }

    dataList.push({
      value: prescriptionGeneralData?.completionActs.total || null,
      percent: null,
      tooltipTitle: 'Акты об устранении нарушений',
      expired: { data: 0, percent: 0 },
    })

    return dataList
  }

  const data: ChartData<'bar'> = {
    labels: labels.map((item) => item.text),
    datasets: [
      {
        label: customRemarkStatus.COMPLETE,
        // @ts-ignore
        data: generateData('complete'),
        backgroundColor: theme.palette.legends[PRESCRIPTION_COMPLETE_STATUS_COLOR],
        barThickness: GENERAL_WIDGET_CHART_BAR_THICKNESS,
      },
      {
        label: customRemarkStatus.DISCARDED,
        // @ts-ignore
        data: generateData('discarded'),

        backgroundColor: theme.palette.legends[PRESCRIPTION_DISCARDED_STATUS_COLOR],
        barThickness: GENERAL_WIDGET_CHART_BAR_THICKNESS,
      },
      {
        label: customRemarkStatus.SENT,
        // @ts-ignore
        data: generateData('sent'),
        backgroundColor: theme.palette.legends[PRESCRIPTION_SENT_STATUS_COLOR],
        barThickness: GENERAL_WIDGET_CHART_BAR_THICKNESS,
      },
      {
        label: customRemarkStatus.CREATED,
        // @ts-ignore
        data: generateData('created'),
        backgroundColor: theme.palette.legends[PRESCRIPTION_CREATED_STATUS_COLOR],
        barThickness: GENERAL_WIDGET_CHART_BAR_THICKNESS,
        hidden: role === 'contractor',
      },
      {
        label: 'Всего',
        // @ts-ignore
        data: generateCompletionActsData(),
        // data: generateData('completionActs'),
        backgroundColor: theme.palette.bg.darkGray,
        barThickness: GENERAL_WIDGET_CHART_BAR_THICKNESS,
        barPercentage: 0,
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    parsing: {
      yAxisKey: 'value',
      xAxisKey: 'value',
    },
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.palette.bg.white,
        titleColor: theme.palette.secondary.gray,
        titleMarginBottom: 12,
        bodyColor: theme.palette.secondary.gray,
        borderColor: theme.palette.bg.gray,
        borderWidth: 2,
        padding: 16,
        bodySpacing: 8,
        usePointStyle: true,
        itemSort: () => -1,

        callbacks: {
          // @ts-ignore
          title: (context: TooltipItem<'bar'>[]) => context[0].raw.tooltipTitle,

          label: (context: TooltipItem<'bar'>) => {
            const { dataset, raw } = context
            // @ts-ignore
            if (context.label === labelByRowType['completionActs']) return ` ${dataset.label}: ${raw.value}`
            // @ts-ignore
            return raw?.value ? ` ${dataset.label}: ${raw?.value || 0} /  ${raw?.percent || 0} %` : ''
          },

          afterBody: (context: TooltipItem<'bar'>[]) => {
            // @ts-ignore
            const { data = 0, percent = 0 } = context.filter((item) => item.raw?.expired?.data)?.[0]?.raw?.expired || {}

            if (!data) return ''

            // if (context.dataset.label === 'Просрочено' && context.raw?.value) {
            return ['- - - - - - - - - - - - - - - - - - - - - - - -', `в т.ч. Просрочено: ${data} / ${percent} %`]
            // }
          },
        },
      },
    },
    interaction: {
      mode: 'index',
    },
    scales: {
      x: {
        stacked: true,
        grace: '5%',
        max: prescriptionGeneralData?.total?.total ? undefined : 100,
        ticks: {
          stepSize: prescriptionGeneralData?.total?.total ? undefined : 5,
          callback: (value: number | string) => (Number(value) % 1 === 0 ? value : null),
        },
      },
      y: {
        stacked: true,
        grid: { display: false },
        ticks: {
          crossAlign: 'far',
          callback: (value, index, values) => {
            const { text, total, percent } = labels[index]
            const percentText = percent ? `(${percent} %)` : ''

            return [text, `${total} ${percentText}`]
          },
        },
      },
    },
    animation: {
      duration: 0,
    },

    maintainAspectRatio: false,
  }

  return isFetching ? <Progress /> : <Bar style={{ zIndex: '10' }} data={data} options={options} />
}
