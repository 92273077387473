import { FC } from 'react'
import { IAuditButtonProps } from './AuditButton.types'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'
import { Button } from 'components/Button'
import { History as HistoryIcon } from '@mui/icons-material'

export const AuditButton: FC<IAuditButtonProps> = ({ onButtonClick }) => {
  return (
    <Tooltip title='История изменений' bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
      <div>
        <Button onClick={onButtonClick} icon color='secondary'>
          <HistoryIcon fontSize='medium' color='secondary' />
        </Button>
      </div>
    </Tooltip>
  )
}
