import { ChartInfoValue } from './ProjectChartInfo.styles'
import { ProjectChartInfoProps } from './ProjectChartInfo.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const ProjectChartInfo: FC<ProjectChartInfoProps> = ({ chartDataInfo }) => {
  return (
    <Stack spacing={2}>
      {chartDataInfo.map((item) => (
        <Stack direction='row' alignItems='center' spacing={2}>
          <ChartInfoValue borderColor={item.color}>
            <Typography variant='body2' color={theme.palette.text.dark}>
              {item.value}
            </Typography>
          </ChartInfoValue>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.dark} textAlign='start'>
            {item.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}
