import { AnalyticsWidgetTitle } from './WidgetHeader.styles'
import { WidgetHeaderProps } from './WidgetHeader.types'
import { PrescriptionStatusLegend } from './components/PrescriptionStatusLegend'
import { HelpOutline as HelpIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { Tooltip } from 'components/Tooltip'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const WidgetHeader: FC<WidgetHeaderProps> = ({ title, tooltipTitle, showLegend = true }) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' flexWrap='wrap' gap={0.5}>
      <AnalyticsWidgetTitle variant='body2'>{title}</AnalyticsWidgetTitle>

      {showLegend && <PrescriptionStatusLegend />}

      <Tooltip
        style={{ position: 'absolute', right: 30, top: 24 }}
        title={tooltipTitle}
        bgColor={theme.palette.bg.white}
        textColor={theme.palette.text.dark}
      >
        <HelpIcon color='disabled' fontSize='large' />
      </Tooltip>
    </Stack>
  )
}
