import { inspectionStatuses, TInspectionStatus } from 'core/types/call'
import { formatDateInInput } from 'utils/dates/formatDateInInput'
import * as yup from 'yup'

const today = new Date()

export const inspectionValidationSchema = yup.object({
  callCreatedDate: yup.date().transform(formatDateInInput).nullable(),
  status: yup.string().required().oneOf(inspectionStatuses),
  responsible: yup.object().required(),
  phone: yup.string().required('common:errors.required'),
  planDate: yup
    .date()
    .transform(formatDateInInput)
    .when('status', {
      is: 'PLANNED',
      then: yup.date().transform(formatDateInInput).min(yup.ref('callCreatedDate')).required('common:errors.required'),
      otherwise: yup.date().transform(formatDateInInput).nullable(),
    }),
  planTime: yup
    .date()
    .test((planTime) => typeof planTime === 'object' || planTime === null)
    .nullable(),
  factDate: yup
    .date()
    .transform(formatDateInInput)
    .when('status', {
      is: 'COMPLETED',
      then: yup
        .date()
        .transform(formatDateInInput)
        .min(yup.ref('callCreatedDate'))
        .max(today)
        .required('common:errors.required'),
      otherwise: yup.date().transform(formatDateInInput).nullable(),
    }),
  description: yup.string().when('status', {
    is: (status: TInspectionStatus) => status === 'COMPLETED' || status === 'REJECTED',
    then: yup.string().required('common:errors.required'),
    otherwise: yup.string().nullable(),
  }),
})
