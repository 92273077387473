import { Name, ProjectNameWrapper } from '../../ContractorAccessesSelection.styles'
import { CompanySelect } from '../CompanySelect'
import { ContractorAccessItemProps } from './ContractorAccessItem.types'
import {
  Delete as DeleteIcon,
  DescriptionOutlined as ProjectIcon,
  MapsHomeWork as CompanyIcon,
  ModeEdit as EditIcon,
} from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { UserFormData } from 'components/UserManagement/components/UserForm'
import { useFormikContext } from 'formik'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'
import { Typography as CustomTypography } from 'components/Typography'

export const ContractorAccessItem: FC<ContractorAccessItemProps> = ({ project }) => {
  const { values: formValues, setFieldValue, errors } = useFormikContext<UserFormData>()
  const isCompaniesSelected = !!formValues.companies?.filter((company) => company.projectId === project.id).length
  const isAllCompaniesSelected = formValues.allCompanies.includes(project.id)
  const [selectHidden, setSelectHidden] = useState<boolean>(isCompaniesSelected || isAllCompaniesSelected)
  const [open, setOpen] = useState<number | null>(null)
  const isProjectsSelected = !!formValues.projects?.length

  const onDeleteProject = (projectId: number) => {
    let localProjects = [...formValues.projects]
    localProjects = localProjects.filter((project) => project.id !== projectId)

    let localCompanies = [...formValues.companies]
    localCompanies = localCompanies.filter((company) => company.projectId !== projectId)

    setFieldValue('projects', localProjects)
    setFieldValue('companies', localCompanies)
    setFieldValue(
      'allCompanies',
      [...formValues.allCompanies].filter((itemId) => itemId !== project.id),
    )
  }

  const onAddCompanyClick = useCallback((projectId: number) => {
    setSelectHidden(false)
    setOpen(projectId)
  }, [])

  return (
    <Stack spacing={0.5}>
      <Stack spacing={2} padding={2.5} bgcolor={theme.palette.bg.shades} width='100%' borderRadius={1}>
        <Stack alignItems='flex-end'>
          <DeleteIcon
            onClick={() => onDeleteProject(project.id)}
            style={{ fill: theme.palette.error.main, cursor: 'pointer' }}
          />
        </Stack>
        <Stack spacing={0.5}>
          <Stack alignItems='center' spacing={1} direction='row' width='115px' padding='6px 0'>
            <ProjectIcon fontSize='medium' color='action' />
            <Typography variant='subtitle1' color={theme.palette.secondary.dark}>
              Проект:
            </Typography>
          </Stack>
          <ProjectNameWrapper bgcolor={theme.palette.bg.white}>
            <CustomTypography lines={2}>{project.name}</CustomTypography>
          </ProjectNameWrapper>
        </Stack>

        <Stack spacing={0.5}>
          <Stack height='fit-content' alignItems='center' spacing={1} direction='row' width='115px' padding='6px 0'>
            <CompanyIcon fontSize='medium' color='action' />
            <Typography variant='subtitle1' color={theme.palette.secondary.dark}>
              Компании:
            </Typography>
          </Stack>
          <Stack spacing={1}>
            {isCompaniesSelected && (
              <Stack width='100%' gap={1} direction='row' flexWrap='wrap'>
                {formValues.companies
                  ?.filter((company) => company.projectId === project.id)
                  .map((company) => (
                    <ProjectNameWrapper key={company.id} bgcolor={theme.palette.bg.white}>
                      <CustomTypography lines={2}>{company.name}</CustomTypography>
                    </ProjectNameWrapper>
                    // <ProjectNameWrapper key={company.id} bgcolor={theme.palette.bg.white}>
                    //   <Name maxLines={1}>alo {company.name}</Name>
                    // </ProjectNameWrapper>
                  ))}
              </Stack>
            )}

            {isAllCompaniesSelected && (
              <ProjectNameWrapper bgcolor={theme.palette.bg.white}>
                <Name maxLines={1} color={theme.palette.primary.main}>
                  Доступ ко всем компаниям
                </Name>
              </ProjectNameWrapper>
            )}

            {!selectHidden && <CompanySelect open={open} projectId={project.id} setSelectHidden={setSelectHidden} />}
          </Stack>
        </Stack>

        {(isProjectsSelected || isAllCompaniesSelected) && selectHidden && (
          <Stack alignItems='flex-end'>
            <Button
              startIcon={<EditIcon />}
              variant='text'
              bgColor={theme.palette.bg.shades}
              onClick={() => onAddCompanyClick(project.id)}
              style={{ width: '100%', height: '40px', marginTop: '-8px' }}
              sx={{
                '& .MuiButton-startIcon': { paddingTop: '1px' },
              }}
            >
              Изменить компании
            </Button>
          </Stack>
        )}
      </Stack>
      {errors.companies?.includes(String(project.id)) && (
        <Typography color={theme.palette.error.main} textAlign='start' paddingLeft={1}>
          В проекте не назначены доступы.
        </Typography>
      )}
    </Stack>
  )
}
