/**
 * @author Mr_FabiozZz [mr.fabiozzz@gmail.com]
 */
import { Block, Field } from '../ChoreDrawer.style'
import { IInitialWork } from '../ChoreDrawer.types'
import { Stack, Typography } from '@mui/material'
import { useGetDropdownContractorQuery } from 'api/ksg'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

/**
 * Блок общей информации
 * @constructor
 */
const CommonInformation: FC<{ viewingOnly: boolean }> = ({ viewingOnly }) => {
  const { projectId: projectString } = useParams()
  const projectId = Number(projectString)

  /* Данные с БД о подрядчиках */
  const { data: contractorData } = useGetDropdownContractorQuery({ projectId })

  const { values } = useFormikContext<IInitialWork>()

  /* Перебор подрядчиков для селекта, не уверен, что, это лучшая реализация */
  const contractorList = useMemo(() => {
    return (
      contractorData?.data
        ?.filter((_) => _.data !== 'company')
        .map((item) => ({
          ...item,
          group: item.data === 'company' ? 'Компании:' : 'Подрядчики:',
        })) || []
    )
  }, [contractorData])

  return (
    <Block>
      <Typography variant='h1'>Общая информация</Typography>
      <Block inner>
        <Field title='Наименование работы' direction='column' gap={0} isRequired>
          <FieldForm
            name='name'
            dataValue={values.name || ''}
            multiline
            version='project'
            helperText=''
            placeholder='Наименование работы'
            required
            inputProps={{
              readOnly: viewingOnly,
            }}
          />
        </Field>
        <CustomSelect
          list={contractorList}
          name='contractor'
          placeholder={!viewingOnly ? 'Выберите из списка' : ''}
          free
          readOnly={viewingOnly}
          label='Подрядчик'
          style={{
            textAlign: 'start',
          }}
          labelProps={{
            style: { paddingLeft: 0 },
          }}
        />
        <Stack>
          <Field textPercent={43} title='Ед. измерения' isRequired>
            <FieldForm
              name='capacity.unit'
              InputProps={{ style: { height: 30 } }}
              inputProps={{ style: { textAlign: 'right' }, readOnly: viewingOnly }}
              dataValue={values.capacity.unit || ''}
              version='project'
              helperText=''
              placeholder='Введите значение'
              required
            />
          </Field>
        </Stack>
        <Field title='Общий объем' textPercent={43}>
          <FieldForm
            name='capacity.total'
            version='project'
            type='number'
            dataValue={values.capacity.total}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === 'e' || e.key === '.') {
                e.preventDefault()
              }
            }}
            helperText=''
            InputProps={{ style: { height: 30 } }}
            inputProps={{ style: { textAlign: 'right' }, readOnly: viewingOnly }}
            placeholder='Введите число'
          />
        </Field>
      </Block>
    </Block>
  )
}

export default CommonInformation
