import { MandatoryNotice } from './FieldItem.styles'
import { FieldItemProps } from './FieldItem.types'
import { Stack, Box, Typography } from '@mui/material'
import { FC } from 'react'

export const FieldItem: FC<FieldItemProps> = ({ title, icon = null, isRequired, children, ...props }) => {
  return (
    <Stack direction='row' justifyContent='space-between' width='100%' spacing={1} {...props}>
      <Box textAlign='left' display='flex' alignItems='center'>
        {icon}
        <Typography variant='body2' marginLeft={icon ? 1 : 0} lineHeight='inherit'>
          {title}
          {isRequired && <MandatoryNotice>&nbsp;*</MandatoryNotice>}
        </Typography>
      </Box>
      {children}
    </Stack>
  )
}
