import { FC, useEffect, useRef, useState } from 'react'
import { ITooltipCellMultiplyProps } from './TooltipCellMultiply.types'
import { Tooltip } from 'components/Tooltip'
import { theme } from 'styles/theme'
import { Stack } from '@mui/material'
import { TypographyEllipsis } from '../../CallTable.styles'
import { isTextOverflowed } from '../TooltipCell/TooltipCell.types'

export const TooltipCellMultiply: FC<ITooltipCellMultiplyProps> = ({ value, subvalue, column, ...props }) => {
  const valueRef = useRef<HTMLParagraphElement | null>(null)
  const subvalueRef = useRef<HTMLParagraphElement | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const columnWidth = column?.getActualWidth()

  useEffect(() => {
    setShowTooltip(isTextOverflowed(valueRef) || isTextOverflowed(subvalueRef))
  }, [valueRef, subvalueRef, value, subvalue, columnWidth])

  const getContent = (isTooltip?: boolean) => (
    <Stack>
      <TypographyEllipsis
        ref={isTooltip ? null : valueRef}
        variant='body2'
        color={theme.palette.text.dark}
        fontSize={isTooltip ? 12 : 14}
      >
        {value}
      </TypographyEllipsis>
      <TypographyEllipsis ref={isTooltip ? null : subvalueRef} variant='body2' fontSize={isTooltip ? 12 : 14}>
        {subvalue}
      </TypographyEllipsis>
    </Stack>
  )

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          style: {
            padding: '8px 12px',
            marginTop: 6,
            backgroundColor: theme.palette.bg.white,
            boxShadow: '0px 2px 15px 0px rgba(46, 77, 187, 0.25)',
            width: `${columnWidth}px`,
            maxWidth: 'unset',
          },
        },
        arrow: {
          style: {
            color: theme.palette.bg.white,
          },
        },
      }}
      title={showTooltip ? getContent(true) : ''}
      textColor={theme.palette.text.dark}
    >
      {getContent()}
    </Tooltip>
  )
}
