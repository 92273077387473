import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const MailSuccessIcon: FC<IconProps> = (props) => {
  return (
    <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.8 0H1.2C0.54 0 0.00599999 0.542763 0.00599999 1.20614L0 8.44298C0 9.10636 0.54 9.64912 1.2 9.64912H6.24922C6.0885 9.24279 6 8.79864 6 8.33333C6 6.39549 7.53502 4.82456 9.42857 4.82456C10.4526 4.82456 11.3718 5.284 12 6.01243V1.20614C12 0.542763 11.46 0 10.8 0ZM6 5.42763L10.8 2.41228V1.20614L6 4.22149L1.2 1.20614V2.41228L6 5.42763Z'
        fill='#2E7D32'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.0369 6.71053L11.5714 7.2315L8.7309 10L7.28571 8.59147L7.82328 8.07122L8.73095 8.95587L11.0369 6.71053Z'
        fill='#2E7D32'
      />
    </svg>
  )
}
