import { Stack } from '@mui/material'
import styled from 'styled-components'

export const StyledConfirmWindow = styled(Stack)`
  width: 400px;
  gap: 12px;
  padding: 30px;

  text-align: center;
`
