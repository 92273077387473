import { FC, useState } from 'react'
import { Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { IAS_MONITORING } from './IasMonitoringCard.types'
import { IasInfo, Wrapper } from './IasMonitoringCard.styles'
import { Link } from 'components/Link'
import {
  IntegrationModuleCard,
  IntegrationModuleStatusWrapper,
} from '../ProjectIntegrationSettings/ProjectIntegrationSettings.styles'
import { integrationModuleStatusColor, integrationModuleStatusRuByEn } from 'api/integrations/types'

export const IasMonitoringCard: FC = () => {
  const [isInfoShown, setIsInfoShown] = useState<boolean>(false)

  const onModuleClick = () => {
    setIsInfoShown((p) => !p)
  }

  return (
    <Wrapper>
      <IntegrationModuleCard onClick={onModuleClick}>
        <IntegrationModuleStatusWrapper bgColor={integrationModuleStatusColor['available']}>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.light} lineHeight='inherit'>
            {integrationModuleStatusRuByEn['available'].toUpperCase()}
          </Typography>
        </IntegrationModuleStatusWrapper>
        {IAS_MONITORING}
        <Typography variant='body2' fontWeight='700' color={theme.palette.text.dark}>
          ИАС «Мониторинг»
        </Typography>
      </IntegrationModuleCard>

      {isInfoShown && (
        <IasInfo>
          <Typography variant='body2'>
            Выбор проекта доступен при наличии авторизации в аккаунте ИАС «Мониторинг». Для удаленной авторизации в
            системе перейдите на вкладку <Link to='/administration/settings'>Настройки</Link>.
          </Typography>
        </IasInfo>
      )}
    </Wrapper>
  )
}
