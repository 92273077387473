import { TooltipProps } from './Tooltip.types'
import { Tooltip as MuiTooltip, Typography } from '@mui/material'
import { FC, SyntheticEvent } from 'react'
import { theme } from 'styles/theme'
import { isIOS, isMobile } from 'utils/isIOS'

export const Tooltip: FC<TooltipProps> = ({
  title,
  customTitle,
  children,
  bgColor,
  textColor,
  fontSize = 12,
  arrow = true,
  ...props
}) => {
  if (!(title || customTitle)) return <>{children}</>

  const preventOnClickOnMobileIos = (e: SyntheticEvent) => {
    if (isIOS() && isMobile()) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <MuiTooltip
      onClick={preventOnClickOnMobileIos}
      title={
        customTitle ? (
          customTitle
        ) : (
          <Typography
            variant='body2'
            style={{ textTransform: 'initial', color: textColor || theme.palette.text.light }}
            fontSize={fontSize}
          >
            {title}
          </Typography>
        )
      }
      arrow={arrow}
      componentsProps={{
        tooltip: {
          style: {
            padding: '8px 12px',
            marginTop: 6,
            backgroundColor: bgColor || theme.palette.secondary.dark,
            boxShadow: '0px 2px 15px 0px rgba(46, 77, 187, 0.25)',
          },
        },
        arrow: {
          style: {
            color: bgColor || theme.palette.secondary.dark,
          },
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}
