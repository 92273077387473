import { SnackbarCloseReason as MUISnackbarCloseReason } from '@mui/material/Snackbar/Snackbar'
import { LegendColors } from 'core/types/global'
import { theme } from 'styles/theme'

export const DATE_FORMAT = 'dd.MM.yyyy'

export const XXL_FOR_LAYOUT = '2300px'
export const XXL_FOR_HOME_CARDS = '1920px'
export const XXXL_FOR_HOME_CARDS = '2300px'

export const CODE_LENGTH = 6
export const MIN_PASSWORD_LENGTH_VALIDATION = 4
export const DEFAULT_DISPLAY_PASSWORD_VALUE = ''
// export const DEFAULT_DISPLAY_PASSWORD_VALUE = '* * * * * *'

export const MAX_WIDTH_USER_MANAGEMENT_BLOCK = '476px'
export const MIN_WIDTH_USER_MANAGEMENT_BLOCK = '396px'

export const MAX_FILES_UPLOAD = 50
export const MAX_FILE_SIZE_IN_MB = 256
export const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024

export const COMMON_DOCS_NUMBER = '000'

export const NUMBER_OF_USERS_TO_FETCH = 40
export const NUMBER_OF_TABLE_ITEMS_TO_FETCH = 40
export const NUMBER_OF_LIST_ITEMS_TO_FETCH = 20
export const NUMBER_OF_FULL_LIST = 999999

export const REMARK_CREATED_STATUS_COLOR: LegendColors = 'purple'
export const REMARK_SENT_STATUS_COLOR: LegendColors = 'blue'
export const REMARK_DISCARDED_STATUS_COLOR: LegendColors = 'gray'
export const REMARK_COMPLETE_STATUS_COLOR: LegendColors = 'lightgreen'

export const PRESCRIPTION_CREATED_STATUS_COLOR: LegendColors = 'purple'
export const PRESCRIPTION_SENT_STATUS_COLOR: LegendColors = 'blue'
export const PRESCRIPTION_DISCARDED_STATUS_COLOR: LegendColors = 'gray'
export const PRESCRIPTION_COMPLETE_STATUS_COLOR: LegendColors = 'lightgreen'

export const REMARK_ELIMINATION_CREATED_STATUS_COLOR: LegendColors = 'disabled'
export const REMARK_ELIMINATION_SENT_STATUS_COLOR: LegendColors = 'yellow'
export const REMARK_ELIMINATION_IN_PROGRESS_STATUS_COLOR: LegendColors = 'blue'
export const REMARK_ELIMINATION_COMPLETE_STATUS_COLOR: LegendColors = 'lightgreen'
export const REMARK_ELIMINATION_DISCARDED_STATUS_COLOR: LegendColors = 'gray'
export const REMARK_ELIMINATION_RETURNED_STATUS_COLOR: LegendColors = 'red'

export const CALL_CREATED_STATUS_COLOR: LegendColors = 'purple'
export const CALL_SENT_STATUS_COLOR: LegendColors = 'blue'
export const CALL_CANCELLED_STATUS_COLOR: LegendColors = 'gray'
export const CALL_COMPLETE_STATUS_COLOR: LegendColors = 'lightgreen'

export const INSPECTION_EMPTY_STATUS_COLOR: LegendColors = 'disabled'
export const INSPECTION_PLANNING_STATUS_COLOR: LegendColors = 'gray'
export const INSPECTION_PLANNED_STATUS_COLOR: LegendColors = 'purple'
export const INSPECTION_REJECTED_STATUS_COLOR: LegendColors = 'red'
export const INSPECTION_COMPLETED_STATUS_COLOR: LegendColors = 'lightgreen'

export const SEARCH_QUERY_DELAY = 500

export const ERRORS_PHONE = ['company.user_creation.invalid_phone', 'profile.invalid_phone']

export const ERRORS_COINCIDENCE = {
  'company.user_creation.phone_taken': 'phone' as const,
  'company.user_creation.email_taken': 'email' as const,
  'company.user_creation.login_taken': 'login' as const,
  'profile.phone_taken': 'phone' as const,
  'profile.email_taken': 'email' as const,
  'profile.login_taken': 'login' as const,
}

export const dadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs'
export const dadataToken = '74de3eea4ab677c0251ff914f42d32cd01800ca0'
export const dadataSecret = '9781f3b99c4f14b4a4414bc9c886bba45a68264f'
export const googleKey = 'AIzaSyDU_CJGCtatX5LY7gFYoitl15tqcrp2Ujg'

export type SnackbarCloseReason = 'click' | MUISnackbarCloseReason
