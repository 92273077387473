import { callStatusColorByEn, callStatusDescriptionByEn, callStatuses, callStatusRuByEn } from 'core/types/call'
import { TCallStatusLowercase, TCallStatusRu } from 'api/calls/types'
import {
  remarkStatusColorByEn,
  remarkStatusDescriptionByEn,
  remarkStatuses,
  remarkStatusRuByEn,
  TRemarkStatusLowercase,
  TRemarkStatusRu,
} from 'api/remarks/types'
import { IStatusSetting, IStatusSettings, IStatusSettingsData } from 'core/types/settings'

export interface IStatusSettingsFormProps {
  resetStatusSettingsResponse: Record<any, any>
}

export const callStatusData: IStatusSetting<TCallStatusLowercase, TCallStatusRu>[] = callStatuses.map((status) => ({
  name: status.toLowerCase() as TCallStatusLowercase,
  label: callStatusRuByEn[status],
  color: callStatusColorByEn[status],
  description: callStatusDescriptionByEn[status],
}))

export const remarkStatusData: IStatusSetting<TRemarkStatusLowercase, TRemarkStatusRu>[] = remarkStatuses.map(
  (status) => ({
    name: status.toLowerCase() as TRemarkStatusLowercase,
    label: remarkStatusRuByEn[status],
    color: remarkStatusColorByEn[status],
    description: remarkStatusDescriptionByEn[status],
  }),
)

export const statusSettingsData: IStatusSettingsData[] = [
  {
    title: 'Список статусов вызова',
    statusData: callStatusData,
    sectionName: 'callStatuses',
  },
  {
    title: 'Список статусов замечаний и предписаний',
    statusData: remarkStatusData,
    sectionName: 'remarkAndPrescriptionStatuses',
  },
]

export const defaultStatusSettings: IStatusSettings = {
  callStatuses: {
    created: callStatusRuByEn.CREATED,
    sent: callStatusRuByEn.SENT,
    cancelled: callStatusRuByEn.CANCELLED,
    complete: callStatusRuByEn.COMPLETE,
  },
  remarkAndPrescriptionStatuses: {
    created: remarkStatusRuByEn.CREATED,
    sent: remarkStatusRuByEn.SENT,
    complete: remarkStatusRuByEn.COMPLETE,
    discarded: remarkStatusRuByEn.DISCARDED,
  },
}

export const initialStatusSettings: IStatusSettings = {
  callStatuses: {
    created: '',
    sent: '',
    cancelled: '',
    complete: '',
  },
  remarkAndPrescriptionStatuses: {
    created: '',
    sent: '',
    complete: '',
    discarded: '',
  },
}
