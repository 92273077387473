import { Stack } from '@mui/material'
import { Button } from 'components/Button'
import styled from 'styled-components'
import { theme } from 'styles/theme'

interface ISubmitIconButtonProps {
  _disabled: boolean
  _small?: boolean
}

export const NotificationSwitchWrapper = styled(Stack)`
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 2px 10px 10px 10px;
  gap: 6px;
  border-radius: 8px;
`

export const SubmitIconButton = styled(Button)<ISubmitIconButtonProps>`
  background-color: ${(props) => (props._disabled ? theme.palette.bg.shades : '#8DD4C826')};
  pointer-events: ${(props) => (props._disabled ? 'none' : 'auto')};
  border: 1px solid ${(props) => (props._disabled ? theme.palette.bg.darkGray : theme.palette.success.main)};

  ${(props) =>
    props._small &&
    `
    min-width: 36px;
    height: 36px;
    `}

  &:hover {
    background-color: #8dd4c826;
  }
`
