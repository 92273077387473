import { Stack } from '@mui/material'
import { useGetCallColumnSettingsQuery, useGetCallsQuery, useSetCallColumnSettingsMutation } from 'api/calls'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CellClickedEvent,
  ClientSideRowModelModule,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  RowClassParams,
  ValidationModule,
} from 'ag-grid-community'
import {
  callTableColumnByValue,
  callTableColumns,
  ICallColumnSetting,
  ICallFull,
  TCallTableColumn,
} from 'api/calls/types'
import { StyledCallTable } from './CallTable.styles'
import { Progress } from 'components/Progress'
import { EmptyPage } from 'components/EmptyPage'
import { ModuleRegistry, ClientSideRowModelApiModule, ScrollApiModule, RowStyleModule } from 'ag-grid-community'
import useSearchParams from 'hooks/useSearchParams'
import { isNumber } from 'lodash'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import { countColumn } from './CallTable.types'

// important
ModuleRegistry.registerModules([
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ScrollApiModule,
  RowStyleModule,
  ValidationModule,
])

export const CallTable: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const navigate = useNavigate()
  const searchParams = useSearchParams()

  const { data, isFetching: isCallsFetching } = useGetCallsQuery({ projectId })
  const { data: columnSettings, isLoading: isColumnSettingsLoading } = useGetCallColumnSettingsQuery()
  const isFetching = isCallsFetching || isColumnSettingsLoading
  const [setCallColumnSettings] = useSetCallColumnSettingsMutation()

  const [localColumnSettings, setLocalColumnSettings] = useState<ICallColumnSetting[]>([])

  useEffect(() => {
    if (!isColumnSettingsLoading)
      setLocalColumnSettings(columnSettings || callTableColumns.map((item) => ({ name: item, size: null })))
  }, [isColumnSettingsLoading])

  const columnDefs = useMemo(() => {
    const tempColumns: ColDef[] = []
    const isCountColumnExists = localColumnSettings.find((item) => item.name === 'COUNT')

    localColumnSettings.forEach((column) => {
      if (!isCountColumnExists && column.name === 'AUDIT') {
        tempColumns.push({
          ...countColumn,
          width: 68,
        })
      }

      tempColumns.push({
        ...callTableColumnByValue[column.name],
        width: column.size || callTableColumnByValue[column.name].width,
      })
    })

    return tempColumns
  }, [localColumnSettings])

  // const rowData: ICallFull[] = data?.data || []
  const rowData: ICallFull[] = useMemo(() => {
    return data?.data || []
  }, [isFetching, projectId])

  const getRowId = useCallback(({ data }: GetRowIdParams<ICallFull>) => {
    return String(data.id)
  }, [])

  const gridOptions: GridOptions = {
    headerHeight: 64,
    rowHeight: TABLE_CELL_HEIGHT,
    enableCellTextSelection: true,
    columnHoverHighlight: true,
    rowClassRules: {
      'ag-row-hover': ({ data }: RowClassParams<ICallFull>) => {
        const id = searchParams.get('id')

        return data?.id === Number(id)
      },
      'ag-row-inspection-rejected': ({ data }: RowClassParams<ICallFull>) => {
        return data?.inspection.status === 'REJECTED'
      },
    },
  }

  const onRowClick = (e: CellClickedEvent<ICallFull>) => {
    const { data, column } = e
    const customClickCells: TCallTableColumn[] = ['STATUS', 'PHONE', 'AUDIT', 'INSPECTION_PHONE']

    if (!customClickCells.includes(column.getColId() as TCallTableColumn)) navigate(`edit/${data?.id}`)
  }

  const onGridReady = ({ api }: GridReadyEvent) => {
    const id = searchParams.get('id')
    const { rowIndex } = api.getRowNode(String(id)) || {}

    if (isNumber(rowIndex)) {
      api.ensureIndexVisible(rowIndex!, 'middle')
    }

    searchParams.del('id')
  }

  const onColumnSettingsChanged = useCallback(
    (event: ColumnResizedEvent | ColumnMovedEvent) => {
      const { finished, column, type } = event

      // if (finished && column) {
      if (
        column &&
        ((finished && (type === 'columnResized' || (type === 'columnMoved' && event.toIndex !== 0))) ||
          (!finished && type === 'columnMoved' && event.toIndex === 0))
      ) {
        const columnData: ICallColumnSetting = {
          name: column.getColId() as TCallTableColumn,
          size: column.getActualWidth(),
        }

        let newTableColumnSettings: ICallColumnSetting[] = []

        if (type === 'columnResized') {
          newTableColumnSettings = localColumnSettings.map((item) =>
            item.name !== column?.getColId() ? item : columnData,
          )
        }

        if (type === 'columnMoved') {
          newTableColumnSettings = localColumnSettings.filter((item) => item.name !== column?.getColId())
          newTableColumnSettings.splice(event.toIndex!, 0, columnData)
        }

        console.log('alo', newTableColumnSettings)

        setLocalColumnSettings(newTableColumnSettings)
        setCallColumnSettings({ columnSettings: newTableColumnSettings })
      }
    },
    [localColumnSettings],
  )

  if (isFetching) return <Progress />

  if (!isFetching && !rowData.length) return <EmptyPage data={{ text: 'Вызовы отсутствуют.', buttons: [] }} fullPage />

  return (
    <Stack width='100%' pr={2.5} height='100%'>
      {/* @ts-ignore */}
      <StyledCallTable
        columnDefs={columnDefs}
        rowData={rowData}
        onCellClicked={onRowClick}
        suppressCellFocus={true}
        gridOptions={gridOptions}
        getRowId={getRowId} // important
        onGridReady={onGridReady}
        suppressDragLeaveHidesColumns={true}
        onColumnResized={onColumnSettingsChanged}
        onColumnMoved={onColumnSettingsChanged}
      />
    </Stack>
  )
}
