import { Stack } from '@mui/material'
import styled from 'styled-components'
import { hexToRgba } from 'utils/hexToRgba'

interface ICountItemProps {
  color: string
}

export const CountCellWrapper = styled(Stack)`
  background-color: ${(props) => props.theme.palette.bg.white};
  box-shadow: rgba(0, 36, 95, 0.1) -1px -1px 0px inset;
  flex-direction: row;
  gap: 4px;
  width: fit-content;
  align-items: center;
  padding: 8px;
`

export const CountItem = styled(Stack)<ICountItemProps>`
  flex-direction: row;
  padding: 4px;
  border-radius: 6px;
  align-items: center;
  background-color: ${(props) => hexToRgba(props.color, 0.15)};
  gap: 4px;
  height: fit-content;
  min-width: 48px;
`
