import { FC } from 'react'
import { InfoIconWrapper, InfoWrapper } from './StatusSettingsInfo.styles'
import { Typography } from '@mui/material'
import { StatusSettingsInfoText } from './StatusSettingsInfo.text'
import { theme } from 'styles/theme'
import { InfoIcon } from 'assets/icons/InfoIcon'

export const StatusSettingsInfo: FC = () => {
  return (
    <InfoWrapper>
      <InfoIconWrapper>
        <InfoIcon fontSize='small' />
      </InfoIconWrapper>

      <Typography variant='body2' color={theme.palette.text.dark}>
        {StatusSettingsInfoText}
      </Typography>
    </InfoWrapper>
  )
}
