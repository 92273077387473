import { Stack } from '@mui/material'
import styled from 'styled-components'

export const StatusSettingsFormWrapper = styled(Stack)`
  gap: 20px;
  height: 100%;
`

export const StatusSettingsSectionAdaptiveWrapper = styled(Stack)`
  gap: 20px;
  flex-direction: row;

  @media (max-width: 1605px) {
    flex-direction: column;
    width: 100%;
  }
`
