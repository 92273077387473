/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { InnerChart, OuterChart } from './Chart.styles'
import { IDoughnut } from './Chart.types'
import { ChartData } from 'chart.js'
import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { theme } from 'styles/theme'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
  REMARK_COMPLETE_STATUS_COLOR,
  REMARK_CREATED_STATUS_COLOR,
  REMARK_DISCARDED_STATUS_COLOR,
  REMARK_SENT_STATUS_COLOR,
} from 'utils/constants'

export const DoughnutChart: React.FC<IDoughnut> = ({ inner, outer, mode }) => {
  const options = {
    maintainAspectRatio: false,
    events: [],
    cutout: '70%',
    animation: {
      animateRotate: false,
      animateScale: false,
    },
  }

  /**
   * Схема получается такая:
   * имеются процент для внешнего круга допустим это 26% для созданных замечаний (например)
   * для внотреннего круга приходит 50% от 26% (просчет идет в родителе, сюда отдаются готовые данные)
   * цвета чередуются таким образом чтобы можно было окрасить 50% от 26% (это 13%) крсным цветом,
   * а оставшуюся часть закрасить белым
   * и так далее, при этом имеются места где красить впринципе нельзя (например "снято" для заммечаний)
   * в таком случае передается столько проценов сколько во внешнем круге, а цвет задается как белый
   *
   * надеюсь получилось не очень кустарно =)
   */
  const bgColors = useMemo(() => {
    return mode === 'remarks'
      ? ['#F46B6B', 'white', '#F46B6B', 'white', 'white', '#F46B6B', 'white']
      : ['white', '#F46B6B', 'white', 'white', '#F46B6B', 'white']
  }, [mode])

  const remarkOuterColors = [
    theme.palette.legends[REMARK_CREATED_STATUS_COLOR],
    theme.palette.legends[REMARK_SENT_STATUS_COLOR],
    theme.palette.legends[REMARK_DISCARDED_STATUS_COLOR],
    theme.palette.legends[REMARK_COMPLETE_STATUS_COLOR],
  ]

  const prescriptionOuterColors = [
    theme.palette.legends[PRESCRIPTION_CREATED_STATUS_COLOR],
    theme.palette.legends[PRESCRIPTION_SENT_STATUS_COLOR],
    theme.palette.legends[PRESCRIPTION_DISCARDED_STATUS_COLOR],
    theme.palette.legends[PRESCRIPTION_COMPLETE_STATUS_COLOR],
  ]

  /**
   * Здесь идет упаковка данных для передачи ChartJS
   */
  const { innerData, outerData } = useMemo(() => {
    const defaultData: ChartData<'doughnut'> = {
      labels: [],
      datasets: [],
    }
    const returnData = {
      innerData: { ...defaultData },
      outerData: { ...defaultData },
    }
    returnData.outerData.datasets = [
      {
        data: outer,
        backgroundColor:
          outer.length > 1 ? [...(mode === 'remarks' ? remarkOuterColors : prescriptionOuterColors)] : ['#F6F7FB'],
      },
    ]
    returnData.innerData.datasets = [
      {
        data: inner,
        backgroundColor: inner.length > 1 ? bgColors : ['#F6F7FB'],
      },
    ]
    return returnData
  }, [bgColors, inner, outer])

  return (
    <OuterChart>
      <Doughnut width='130px' height='130px' data={outerData} options={options} />
      <InnerChart>
        <Doughnut width='86px' height='86px' data={innerData} options={options} />
      </InnerChart>
    </OuterChart>
  )
}
