import {
  StatisticsCardItemWrapper,
  StatisticsWrapper,
  StatisticsWrapperColumnTitle,
  StatisticsTabsWrapper,
  StatisticsTabItem,
  StatisticsTabDivider,
} from './StatisticsCardItem.styles'
import { StatisticsCardItemProps } from './StatisticsCardItem.types'
import { StatisticsCardItemRow } from './StatisticsCardItemRow'
import { Box, Stack, Typography } from '@mui/material'
import { useGetDashboardSettingsQuery, useSetDashboardSettingsMutation } from 'api/projects'
import { TPrescriptionDirection } from 'core/types/prescription'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { theme } from 'styles/theme'

export const StatisticsCardItem: FC<StatisticsCardItemProps> = ({
  id,
  title,
  tabs,
  link,
  disabled,
  data,
  columns,
  rows,
  style,
}) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const navigate = useNavigate()
  const { data: dashboardSettings } = useGetDashboardSettingsQuery({ projectId })
  const [activeTab, setActiveTab] = useState<string>('')
  const [setDashboardSettings] = useSetDashboardSettingsMutation()

  useEffect(() => {
    dashboardSettings && setActiveTab(dashboardSettings.prescription.activeTab)
  }, [dashboardSettings])

  const onChangeTab = (tab: string) => () => {
    setActiveTab(tab)
    setDashboardSettings({ projectId, body: { prescription: { activeTab: tab as 'ALL' | TPrescriptionDirection } } })
  }

  const onCardClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!disabled && link) navigate(link)
  }

  const formattedData = id === 'prescription' ? data?.[activeTab.toLowerCase() as keyof typeof data] : data

  return (
    <StatisticsCardItemWrapper onClick={onCardClick} disabled={disabled} style={style}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography textAlign='left' color={theme.palette.primary.main}>
          {title}
        </Typography>

        {tabs && (
          <StatisticsTabsWrapper
            onClick={(e) => e.stopPropagation()}
            direction='row'
            spacing={0.25}
            divider={<StatisticsTabDivider orientation='vertical' />}
          >
            {tabs.map((item) => (
              <StatisticsTabItem onClick={onChangeTab(item.id)} actived={item.id === activeTab}>
                <Typography variant='body2' color={theme.palette.primary.main}>
                  {item.text}
                </Typography>
              </StatisticsTabItem>
            ))}
          </StatisticsTabsWrapper>
        )}
      </Stack>

      <StatisticsWrapper>
        <Stack direction='row' justifyContent='end'>
          {columns.map((column) => (
            <Box width={column.width}>
              <StatisticsWrapperColumnTitle variant='body2'>{column.title}</StatisticsWrapperColumnTitle>
            </Box>
          ))}
        </Stack>

        <Stack pt={1}>
          {rows.map(
            (row) => !row?.hidden && <StatisticsCardItemRow columns={columns} data={formattedData} row={row} />,
          )}
        </Stack>

        <Stack>
          <StatisticsCardItemRow
            columns={columns}
            data={formattedData}
            row={{
              title: 'Итого',
              fieldName: 'total',
              isSum: true,
              dividerOnTop: true,
            }}
          />
        </Stack>
      </StatisticsWrapper>
    </StatisticsCardItemWrapper>
  )
}
