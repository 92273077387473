import { MenuItem } from '@mui/material'
import styled from 'styled-components'

interface StyledSelectMenuItemProps {
  checked?: boolean
  remark?: boolean
}

export const StyledSelectMenuItem = styled(MenuItem)<StyledSelectMenuItemProps>`
  background-color: ${(props) => props.checked && props.theme.palette.bg.gray};
  font-size: ${(props) => props.remark && '14px'};
  white-space: normal;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.bg.shades};
  }

  &.Mui-focusVisible {
    background-color: ${(props) => props.theme.palette.bg.gray};
  }

  &.select-item-button {
    color: ${(props) => props.theme.palette.text.light};
    background-color: ${(props) => props.theme.palette.primary.light};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      background-color: ${(props) => props.theme.palette.primary.main};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`

export const DisabledSelectMenuItem = styled(StyledSelectMenuItem)`
  &.Mui-disabled {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`
