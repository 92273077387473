import { StatisticalTableCell, StyledStatisticalTable } from './StatisticalTable.styles'
import {
  StatisticalTableProps,
  prescriptionStatusColorByStatus,
  statisticalTableTitles,
} from './StatisticalTable.types'
import { Stack, Typography } from '@mui/material'
import { PrescriptionStatisticalItem } from 'api/analytics/types'
import { TPrescriptionStatus, prescriptionStatuses } from 'core/types/prescription'
import { usePrescriptionStatus } from 'hooks/useCustomStatus'
import { StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { formatFloat } from 'utils/formatFloat'

export const StatisticalTable: FC<StatisticalTableProps> = ({ data, role }) => {
  const customPrescriptionStatus = usePrescriptionStatus()

  const getRowData = (status: TPrescriptionStatus, totalRow = false) => {
    if (role === 'contractor' && status === 'CREATED') return

    let localStatus = status.toLocaleLowerCase() as TPrescriptionStatus

    const localData = {
      status: totalRow ? (
        <Typography variant='subtitle2' fontSize={12} color={theme.palette.secondary.main} textAlign='start'>
          В работе / Всего
        </Typography>
      ) : (
        <Stack direction='row' alignItems='center'>
          <StatusMarker markerColor={prescriptionStatusColorByStatus[status]}></StatusMarker>
          <Typography variant='body2' fontSize={12} color={theme.palette.secondary.main}>
            {customPrescriptionStatus[status]}
          </Typography>
        </Stack>
      ),
      total: getCellData(data?.total, localStatus, true, totalRow),
      expired: getCellData(data?.expired, localStatus, true, totalRow, theme.palette.error.main),
      inPrescription: getCellData(data?.shortcomingsInPrescriptions, localStatus, false, totalRow),
      primary: getCellData(data?.primaryShortcomings, localStatus, false, totalRow),
      secondary: getCellData(data?.secondaryShortcomings, localStatus, false, totalRow),
    }

    return localData
  }

  const getCellData = (
    itemData: PrescriptionStatisticalItem | undefined,
    status: TPrescriptionStatus,
    showPercent: boolean = true,
    isTotalRow: boolean = false,
    fontColor = theme.palette.secondary.main,
  ) => {
    const { active = 0, total = 0 } = itemData || {}
    const { data, percent } = itemData?.[status] || {}

    const leftValue = data ? `${data} ${showPercent ? ' /' : ''}` : 0
    const rightValue = (showPercent && percent && ` ${formatFloat(percent || 0)} %`) || ''

    const totalLeftValue = total ? `${active} /` : ''
    const totalRightValue = ` ${total}`

    return (
      <Typography variant='subtitle2' fontSize={12} color={fontColor}>
        <span>{isTotalRow ? totalLeftValue : leftValue}</span>
        <span style={{ whiteSpace: 'nowrap' }}>{isTotalRow ? totalRightValue : rightValue}</span>
      </Typography>
    )
  }

  const showData = (data: any) => {
    const dataList = []
    for (let key in data) {
      dataList.push(data[key])
    }

    return dataList
  }

  return (
    <StyledStatisticalTable>
      <tr>
        {statisticalTableTitles.map((title) => (
          <th style={{ width: '17%' }}>
            <Typography variant='subtitle2' fontSize={12} color={theme.palette.secondary.main}>
              {title}
            </Typography>
          </th>
        ))}
      </tr>
      {prescriptionStatuses.map((status) => (
        <tr>
          {showData(getRowData(status)).map((item) => (
            <StatisticalTableCell>{item}</StatisticalTableCell>
          ))}
        </tr>
      ))}
      <tr>
        {showData(getRowData('COMPLETE', true)).map((item) => (
          <StatisticalTableCell>{item}</StatisticalTableCell>
        ))}
      </tr>
    </StyledStatisticalTable>
  )
}
