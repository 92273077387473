import lodash from 'lodash'

export interface IGetObjectReplacedValuesProps<T> {
  data: T
  from: any
  to: any
}

// example getObjectReplacedValues({ data: { a: null, b: '123' }, null, 'replaced' }) returns { a: 'replaced, b: '123' }
export const getObjectReplacedValues = <T>({ data, from, to }: IGetObjectReplacedValuesProps<T>) => {
  const localData: T = lodash.cloneDeep(data)

  for (let key in localData) {
    const value: any = localData[key]

    if (value?.constructor === Object) localData[key] = getObjectReplacedValues({ data: value, from, to })
    else if (value === from || from === undefined) localData[key] = to
  }

  return localData
}
