import { FC } from 'react'
import { ICallDateCellProps } from './CallDateCell.types'
import { DeviationElement, DeviationWrapper } from './CallDateCell.styles'
import { Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { parseResponseTime } from 'utils/dates/parseResponseTime'

export const CallDateCell: FC<ICallDateCellProps> = ({ date, time, deviation }) => {
  const parsedDate = parseResponseDate(date || '').fullDate || '—'
  const parsedTime = parseResponseTime(time).timeShort

  return (
    <DeviationWrapper>
      <Stack direction='row' spacing={1.25} justifyContent='center'>
        <Typography variant='body2' color={theme.palette.text.dark}>
          {parsedDate}
        </Typography>

        {parsedTime && (
          <Typography variant='body2' color={theme.palette.text.secondary}>
            {parsedTime}
          </Typography>
        )}
      </Stack>

      {!!deviation && <DeviationElement>+{deviation}</DeviationElement>}
    </DeviationWrapper>
  )
}
