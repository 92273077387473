import { uiContext } from '../../contexts/ui'
import { RemarksLegend } from '../Remarks/components/RemarksLegend'
import { defaultPrescriptionFilters } from './Prescriptions.types'
import { PrescriptionLocationState } from './components/PrescriptionsForm/PrescriptionsForm.types'
import { CurrentSortableColumn, PrescriptionFilters, PrescriptionsTable } from './components/PrescriptionsTable'
import { Stack } from '@mui/material'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const Prescriptions: FC = () => {
  const cUI = useContext(uiContext)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const navigate = useNavigate()
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)

  // Location
  const location = useLocation()
  const locationState: PrescriptionLocationState = (location?.state as PrescriptionLocationState) || {}
  const savedLocationState = useMemo(() => locationState, [])
  const { sortableColumn: locationSortableColumn, filterData: locationFilterData } = savedLocationState || {}

  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const [sortableColumn, setSortableColumn] = useState<CurrentSortableColumn>(
    locationSortableColumn || { column: null, order: null },
  )
  const [filterData, setFilterData] = useState<PrescriptionFilters>(locationFilterData || defaultPrescriptionFilters)

  const onMenuItemClickBefore = useCallback(() => {
    setSortableColumn({ column: null, order: null })
    setFilterData(defaultPrescriptionFilters)
  }, [])

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/prescriptions',
    onMenuItemClickBefore,
  })

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => cUI.setShowSearch(true)
  }, [])

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Предписания' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  return (
    <Stack flex={1} maxWidth='100%'>
      <RemarksLegend
        accessToAdd={isUserHighAccess}
        createButtonData={{
          text: 'Создать предписание',
          href: `/project/${projectId}/prescriptions/add`,
          hrefState: { state: { sortableColumn, filterData } },
        }}
        columnSettings='prescriptions'
        sortableColumn={sortableColumn}
        filterData={filterData}
      />

      <PrescriptionsTable
        key={projectId}
        sort={{ sortableColumn, setSortableColumn }}
        filter={{ filterData, setFilterData }}
      />

      {ProjectsMenu}
    </Stack>
  )
}
