import { ClearText, Search, SearchIcon, SearchWrapper } from './FilterDrawer.styles'
import { FilterDrawerProps } from './FilterDrawer.types'
import { FilterList } from './components/FilterList'
import { Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { isEqual } from 'lodash'
import {
  DrawerTopBar,
  DrawerWrapper,
} from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useState, useEffect, useMemo, useCallback } from 'react'
import {
  chosenTypesIdsLocalSelector,
  chosenTypesIdsSelector,
  clearChosenTypesIdsIdLocal,
  submitFilter,
  undoFilterChanges,
} from 'store/slices/documents'
import { useAppDispatch } from 'store/store'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'

export const FilterDrawer: FC<FilterDrawerProps> = ({ open, onClose }) => {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useAppDispatch()
  const chosenTypesIdsLocal = useTypedSelector(chosenTypesIdsLocalSelector)
  const chosenTypesIds = useTypedSelector(chosenTypesIdsSelector)

  useEffect(() => {
    if (!open) setSearchValue('')
  }, [open])

  const isDirty = useMemo((): boolean => {
    return !isEqual(
      [...chosenTypesIdsLocal].sort((a, b) => a - b),
      [...chosenTypesIds].sort((a, b) => a - b),
    )
  }, [chosenTypesIdsLocal, chosenTypesIds])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
      // onFilterClear()
      dispatch(undoFilterChanges())
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onSubmitFilter = () => {
    dispatch(submitFilter())
    onClose()
  }

  const onFilterClear = () => {
    dispatch(clearChosenTypesIdsIdLocal())
  }

  const onDrawerClose = () => {
    isDirty ? openConfirm() : onClose()
  }

  return (
    <Drawer anchor='left' open={open} onClose={() => onDrawerClose()}>
      <DrawerWrapper>
        <Stack overflow='auto'>
          <DrawerTopBar>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Фильтрация
            </Typography>
          </DrawerTopBar>

          <Divider />

          <DrawerTopBar>
            <Typography variant='subtitle2' color={theme.palette.text.dark}>
              Вид документа
            </Typography>
          </DrawerTopBar>

          <Divider />

          <SearchWrapper>
            <Stack position='relative'>
              <Search placeholder='Поиск' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />

              <SearchIcon />
            </Stack>
          </SearchWrapper>

          <Stack padding='12px 20px'>
            <ClearText onClick={onFilterClear} variant='subtitle2'>
              Сбросить все
            </ClearText>
          </Stack>

          <FilterList searchValue={searchValue} />
        </Stack>

        <FormButtonWrapper padding={2.5} spacing={1} direction='row'>
          <Stack direction='row' spacing={2} width='100%'>
            <Button
              disabled={!isDirty}
              onClick={onSubmitFilter}
              color='success'
              size='medium'
              fullWidth
              // loading={isFormLoading}
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onDrawerClose()}>
              Закрыть
            </Button>
          </Stack>
        </FormButtonWrapper>
      </DrawerWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
