import { OptionsObject, SnackbarMessage, useSnackbar as useSnackbarNotistack, VariantType } from 'notistack'
import { useEffect, useState } from 'react'

export interface ISnackbarData {
  message: SnackbarMessage
  variant?: VariantType
}

export interface IUseSnackbarProps {
  snackbarTrigger?: any
  fullSuccessText?: string
}

export const useSnackbar = ({ snackbarTrigger, fullSuccessText }: IUseSnackbarProps = {}) => {
  const [snackbarData, setSnackbarData] = useState<ISnackbarData[]>([])
  const { enqueueSnackbar: showSnackbar } = useSnackbarNotistack()

  useEffect(() => {
    if (snackbarTrigger && snackbarData.length) showSnackbarData()
  }, [snackbarTrigger])

  const showSnackbarData = () => {
    const countOfFail: number = snackbarData.filter((item) => item.variant === 'error').length

    if (!countOfFail) showSnackbar(fullSuccessText, { variant: 'success' })
    else if (countOfFail === snackbarData.length && snackbarData.length !== 1) {
      showSnackbar('Произошла ошибка.', { variant: 'error' })
    } else {
      snackbarData.map((data) =>
        showSnackbar(data.message, { variant: data.variant, autoHideDuration: 1500 * snackbarData.length }),
      )
    }

    setSnackbarData([])
  }

  const enqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => {
    setSnackbarData((p) => [...p, { message, variant: options?.variant }])
  }

  return { showSnackbar, enqueueSnackbar }
}
