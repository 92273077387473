import { FC } from 'react'
import { IPrescriptionNumberCellProps } from './PrescriptionNumberCell.types'
import { Prescription } from 'api/prescriptions/types'
import { TypographyLink } from './PrescriptionNumberCell.styles'

export const PrescriptionNumberCell: FC<IPrescriptionNumberCellProps> = ({ info, onPrescriptionNumberClick }) => {
  const prescriptionNumber = info.getValue<Prescription>()?.number

  if (!prescriptionNumber) return <>—</>

  return (
    <TypographyLink onClick={onPrescriptionNumberClick} variant='body2'>
      {prescriptionNumber}
    </TypographyLink>
  )
}
