import { AnalyticsFilterLegendWrapper } from './AnalyticsFilterLegend.styles'
import { AnalyticsFilterLegendProps } from './AnalyticsFilterLegend.types'
import { FilterAlt as FilterIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { IconButtonWrapper } from 'pages/Documents/components/DocumentsTable/DocumentsTable.styles'
import { FC, useMemo } from 'react'
import { analyticsSettingsSelector } from 'store/slices/analytics/selectors'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const AnalyticsFilterLegend: FC<AnalyticsFilterLegendProps> = ({ onFilterDrawerOpen }) => {
  const analyticsSettings = useTypedSelector(analyticsSettingsSelector)
  const { filters } = analyticsSettings || {}
  const { after, before, projects, contractors, directions } = filters || {}

  const getPeriod = useMemo(() => {
    const parsedDateAfter = parseResponseDate(after || undefined).fullDate
    const parsedDateBefore = parseResponseDate(before || undefined).fullDate

    const afterText = parsedDateAfter && `c ${parsedDateAfter} `
    const beforeText = parsedDateBefore && `по ${parsedDateBefore}`

    if (parsedDateAfter === parsedDateBefore) return parsedDateAfter

    return afterText + beforeText
  }, [after, before])

  const data = [
    { label: 'Период', id: 'period', value: getPeriod || 'за всё время' },
    { label: 'Проект', id: 'project', value: projects?.length ? `выбрано ${projects?.length}` : 'все проекты' },
    {
      label: 'Организация, получившая предписание',
      id: 'contractor',
      value: contractors?.length ? `выбрано ${contractors?.length}` : 'все компании',
    },
    {
      label: 'Вид предписания',
      id: 'direction',
      value: directions?.length ? `выбрано ${directions?.length}` : 'все виды',
    },
  ]

  const analyticsFiltersAplied = useMemo((): boolean => {
    return !!Object.values(filters).filter((item) => item).length
  }, [filters])

  return (
    <AnalyticsFilterLegendWrapper onClick={onFilterDrawerOpen} pr='20px'>
      <IconButtonWrapper>
        <FilterIcon fontSize='medium' color={analyticsFiltersAplied ? 'primary' : 'disabled'} />
      </IconButtonWrapper>

      <Stack direction='row' gap='8px 24px' flexWrap='wrap'>
        {data.map((item) => (
          <Stack key={item.id} direction='row' spacing={1}>
            <Typography variant='body2' textAlign='start'>
              {item.label}:
            </Typography>
            <Typography className='value' color={theme.palette.primary.main} variant='body2' textAlign='start'>
              {item.value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </AnalyticsFilterLegendWrapper>
  )
}
