import { Typography } from '@mui/material'
import styled from 'styled-components'

interface ITypographyLinkProps {
  disabled?: boolean
}

export const TypographyLink = styled(Typography)<ITypographyLinkProps>`
  color: ${(props) =>
    props.disabled ? props.theme.palette.text.disabled : props.theme.palette.primary.main} !important;
  width: fit-content;
  margin: 0 auto;

  ${(props) =>
    !props.disabled &&
    `
    &:hover {
    cursor: pointer;
    text-decoration: underline;
  }`}
`
