import { EliminationDrawerWrapper } from './EliminationDrawer.styles'
import { EliminationDrawerProps } from './EliminationDrawer.types'
import { EliminationForm } from './components/EliminationForm'
import { Drawer, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FC, useCallback, useState } from 'react'
import { theme } from 'styles/theme'

export const EliminationDrawer: FC<EliminationDrawerProps> = ({
  isOpen,
  onClose,
  remarkStatus,
  remarkDateStart,
  primaryId,
}) => {
  const [dirty, setDirty] = useState<boolean>(false)

  const onFormChange = useCallback((dirty: boolean) => {
    setDirty(dirty)
  }, [])

  return (
    <Drawer anchor='right' open={isOpen} onClose={() => onClose(dirty)}>
      <EliminationDrawerWrapper>
        <DrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            Устранение замечания
          </Typography>
        </DrawerTopBar>

        <Divider />

        <EliminationForm
          onFormChange={onFormChange}
          onClose={onClose}
          remarkStatus={remarkStatus}
          remarkDateStart={remarkDateStart}
          primaryId={primaryId}
        />
      </EliminationDrawerWrapper>
    </Drawer>
  )
}
