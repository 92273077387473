import { Cards, CardsFullWrapper } from './ProjectReferences.styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Stack, Typography } from '@mui/material'
import { useGetProjectDashboardQuery } from 'api/projects'
import { useGetProjectReferencesQuery } from 'api/references'
import { ReferenceBackendIds } from 'api/references/types'
import { Button } from 'components/Button'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress/Progress'
import { ReferenceTabLabel, referenceTabLabels } from 'core/types/reference'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useSearch } from 'hooks/useSearch'
import { Reference, basicReferences } from 'pages/Administration/References'
import { ReferenceCard } from 'pages/Administration/References/ReferenceCard'
import { ReferenceContent } from 'pages/Administration/References/ReferenceContent'
import { AdminLegend } from 'pages/Administration/components/AdminLegend'
import { getEmptyPageData } from 'pages/Projects'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { filterByFieldNames } from 'utils/filterByFieldNames'

export const ProjectReferences: FC = () => {
  const [currentTab, setCurrentTab] = useState<ReferenceTabLabel>('Все справочники')
  const { projectId: projectIdString, referenceId } = useParams()
  const projectId = Number(projectIdString)
  const navigate = useNavigate()
  const { role } = useTypedSelector(profileSelector)
  const { searchValue } = useSearch()

  const { data: projectData, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: dashboardProject } = projectData || {}
  const { project } = dashboardProject || {}

  const { onProjectBreadClick, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/references',
  })

  const { data: referencesData, isFetching: isReferencesFetching } = useGetProjectReferencesQuery(
    {
      projectId: projectId!,
    },
    {
      skip: !projectId,
    },
  )

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      {
        title: referenceId
          ? basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0].name
          : 'Справочники проекта',
      },
    ],
    [isMenuOpen, project, referenceId],
    isFetching,
  )

  const renderReferenceCard = (item: Reference) => {
    const access = item?.access ? item?.access === role : true

    return access ? (
      <ReferenceCard
        key={item.id}
        item={item}
        modified={referencesData?.[ReferenceBackendIds[item.id as keyof typeof ReferenceBackendIds]]}
      />
    ) : null
  }

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: ReferenceTabLabel) => {
    setCurrentTab(tabValue)
  }, [])

  const filteredBySearch = useMemo(() => {
    return searchValue ? filterByFieldNames<Reference>(basicReferences, ['name'], searchValue) : basicReferences
  }, [basicReferences, searchValue, referencesData])

  const filteredByStatus = useMemo(() => {
    const modified: boolean = currentTab === 'Модифицированные'
    return currentTab === 'Все справочники'
      ? filteredBySearch
      : filteredBySearch?.filter(
          (reference) =>
            modified === referencesData?.[ReferenceBackendIds[reference.id as keyof typeof ReferenceBackendIds]],
        )
  }, [filteredBySearch, currentTab, referencesData])

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют справочники, соответствующие результатам запроса.</>,
  )

  const onReturnClick = useCallback(() => {
    navigate(`/project/${projectId}`)
  }, [projectId])

  return (
    <Stack flex={1} overflow={referenceId ? 'auto' : 'hidden'}>
      {referenceId ? (
        <ReferenceContent />
      ) : isReferencesFetching ? (
        <Progress />
      ) : (
        <>
          <Stack direction='row' alignItems='center' spacing={1.5}>
            <Button onClick={onReturnClick} startIcon={<ArrowBackIcon color='secondary' />} variant='text'>
              <Typography variant='buttonMedium'>Назад</Typography>
            </Button>
            {/* <Button icon onClick={onReturnClick}><ArrowBack /></Button> */}
            <AdminLegend<ReferenceTabLabel>
              currentTab={currentTab}
              tabsData={referenceTabLabels.map((tabName) => ({ value: tabName, label: tabName }))}
              onTabChange={onTabChange}
            />
          </Stack>
          {filteredByStatus?.length ? (
            <CardsFullWrapper>
              <Cards style={{ padding: '0 10px 10px 10px' }}>
                {filteredByStatus.map((item) => renderReferenceCard(item))}
              </Cards>
            </CardsFullWrapper>
          ) : (
            <EmptyPage data={emptyFilteredData} forFilter />
          )}
        </>
      )}
      {ProjectsMenu}
    </Stack>
  )
}
