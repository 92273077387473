import { Stack } from '@mui/material'
import styled from 'styled-components'
import { ROLE_SELECT_WIDTH } from '../UserRoleSelection'

export const SelectedProjectItem = styled(Stack)`
  justify-content: center;
  min-height: 44px;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 6px;
  max-width: ${ROLE_SELECT_WIDTH}px;
`
