import { Stack } from '@mui/material'
import { PhoneFieldForm } from 'components/PhoneFieldForm'
import styled from 'styled-components'

export const SlideWrapper = styled(Stack)`
  z-index: 1;
  gap: 20px;
  padding: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color ${(props) => props.theme.palette.bg.white};
  box-shadow: 0px -36px 85px 0px rgba(34, 60, 80, 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const StyledPhoneFieldForm = styled(PhoneFieldForm)`
  // & .MuiFilledInput-root {
  //   border: 1px solid rgba(92, 110, 140, 0.1);

  //   border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};
  // }

  & .MuiInputBase-root {
    height: 34px;

    & .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  & .MuiInputBase-input {
    border: ${(props) => props.error && `1px solid ${props.theme.palette.error.main}`};
    background-color: ${(props) => props.theme.palette.bg.shades};
    border: 1px solid rgba(92, 110, 140, 0.1);
    border-radius: 8px;
    padding: 5px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-transform: ${(props) => props.theme.typography.subtitle1.textTransform};
    color: ${(props) => props.theme.palette.text.dark};
    text-overflow: ellipsis;

    &[aria-invalid='true'] {
      border: ${(props) => `1px solid ${props.theme.palette.error.main}`};
    }

    &::placeholder {
      font-weight: ${(props) => props.theme.typography.body2.fontWeight};
      font-size: ${(props) => props.theme.typography.body2.fontSize};
      letter-spacing: 0.17px;
      text-transform: ${(props) => props.theme.typography.body2.textTransform};
      color: ${(props) => props.theme.palette.disabled};
      opacity: 1;
    }
  }
`
