import { Project } from 'api/projects/types'
import { DesignModuleIcon } from 'assets/icons/DesignModuleIcon'
import { DesignRmIcon } from 'assets/icons/DesignRmIcon'
import { ReactElement } from 'react'

import { theme } from 'styles/theme'

export interface ProjectIntegration {
  global: ProjectIntegrationModule[]
}

export interface ProjectIntegrationModule {
  module: PragmaModule
  status: IntegrationModuleStatus
}

export interface RequestIntegration {
  dashboardModule: PragmaModuleLabel
  email: string
  externalModule: PragmaModuleLabel
  message: string | null
  name: string
  phone: string
}

export interface IntegrationDesignProject extends Omit<Project, 'type' | 'finType'> {}

export interface IntegrationRmProject {
  id: number
  title: string
  description: string
  abbr: string
  status: string
  reportStatus: string
  startDate: string
  endDate: string
}

export interface IntegratedProject {
  id: number
  module: PragmaModule
  name: string
}

export interface IntegratedModuleInfo {
  error: IntegratedModuleError | null
  url: string
}

export type IntegratedModuleError = 'module_not_binded' | 'module_offline' | 'access_denied'

export type IntegrationModuleStatus = 'available' | 'binded' | 'active'
export type IntegrationModuleStatusRu = 'доступно' | 'подключено' | 'активно'

export const integrationModuleStatusRuByEn: Record<IntegrationModuleStatus, IntegrationModuleStatusRu> = {
  available: 'доступно',
  binded: 'подключено',
  active: 'активно',
}

export const integrationModuleStatusEnByRu: Record<IntegrationModuleStatusRu, IntegrationModuleStatus> = {
  доступно: 'available',
  подключено: 'binded',
  активно: 'active',
}

export const integrationModuleStatusColor: Record<IntegrationModuleStatus, string> = {
  available: theme.palette.legends.blue,
  binded: theme.palette.legends.lightOrange,
  active: theme.palette.legends.lightgreen,
}

export type PragmaModule =
  | 'PragmaQC'
  | 'PragmaProcurement'
  | 'PragmaDesign'
  | 'PragmaRM'
  | 'PragmaFinance'
  | 'PragmaOffer'

export type PragmaModuleLabel =
  | 'Pragma.QC'
  | 'Pragma.Procurement'
  | 'Pragma.Design'
  | 'Pragma.RM'
  | 'Pragma.Finance'
  | 'Pragma.Offer'

export const pragmaModuleLabel: Record<PragmaModule, PragmaModuleLabel> = {
  PragmaQC: 'Pragma.QC',
  PragmaProcurement: 'Pragma.Procurement',
  PragmaDesign: 'Pragma.Design',
  PragmaRM: 'Pragma.RM',
  PragmaFinance: 'Pragma.Finance',
  PragmaOffer: 'Pragma.Offer',
}

export const pragmaModuleIcon: Record<PragmaModule, ReactElement> = {
  PragmaQC: <></>,
  PragmaProcurement: <></>,
  PragmaDesign: <DesignModuleIcon />,
  PragmaRM: <DesignRmIcon />,
  PragmaFinance: <></>,
  PragmaOffer: <></>,
}

// если есть url, то модуль подключен
// если нет url и нет error, то "Нет связанного проекта"
// "Нет связанного проекта" - ошибка 404
export const integratedModuleInfo: Record<IntegratedModuleError | IntegrationModuleStatus, IntegratedModuleData> = {
  module_not_binded: {
    tooltipData: {
      title: 'Проект не найден',
      text: 'В случае если проект в модуле был пересоздан, необходимо обновить связь с проектом на экране управления проектами. Обратитесь к администратору модуля.',
    },
    indicatorColor: theme.palette.legends.gray,
    disabled: true,
  },
  module_offline: {
    tooltipData: {
      title: 'Модуль временно недоступен',
      text: 'Модуль находится на техническом обслуживании. Повторите запрос позже.',
    },
    indicatorColor: theme.palette.warning.main,
    disabled: false,
  },
  access_denied: {
    tooltipData: {
      title: 'Нет доступа',
      text: 'Вам не предоставлены права доступа для просмотра проекта в модуле. Обратитесь к администратору компании.',
    },
    indicatorColor: theme.palette.legends.yellow,
    disabled: false,
  },

  available: {
    tooltipData: {
      title: 'Модуль не подключен',
      text: 'Модуль доступен для подключения. Обратитесь к администратору компании.',
    },
    indicatorColor: theme.palette.legends.gray,
    disabled: true,
  },
  binded: {
    tooltipData: {
      title: 'Нет связанного проекта',
      text: 'Настроить переход в соответствующий проект в модуле можно в разделе администрирования на странице Управление проектами.',
    },
    indicatorColor: theme.palette.legends.purple,
    disabled: true,
  },
  active: {
    tooltipData: null,
    indicatorColor: theme.palette.primary.main,
    disabled: false,
  },
}

export interface IntegratedModuleData {
  tooltipData: IntegratedModuleTooltip | null
  indicatorColor: string
  disabled: boolean
}

export interface IntegratedModuleTooltip {
  title: string
  text: string
}
