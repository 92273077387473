import { AuditStatusGraduationProps } from './AuditStatusGraduation.types'
import { ArrowRightAlt as ArrowIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const AuditStatusGraduation: FC<AuditStatusGraduationProps> = ({ previous, current }) => {
  return (
    <Stack direction='row' spacing={0.5}>
      {previous && (
        <>
          <Typography variant='body1' fontSize={14} color={theme.palette.secondary.gray}>
            {previous}
          </Typography>

          <ArrowIcon fontSize='medium' color='disabled' />
        </>
      )}

      <Typography variant='body1' fontSize={14} color={theme.palette.text.dark}>
        {current}
      </Typography>
    </Stack>
  )
}
