import { RepresentativesDialogTrigger } from '../../Representatives.types'
import { RepresentativesFormData, RepresentativesManagmentFormProps } from './RepresentativesManagmentForm.types'
import { validationRepresentatives } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'
import {
  useCreateRepresentativeByProjectMutation,
  useCreateRepresentativeMutation,
  useDeleteRepresentativeByProjectMutation,
  useDeleteRepresentativeMutation,
  useEditRepresentativeByProjectMutation,
  useEditRepresentativeMutation,
  useGetDropdownProjectMembersByProjectQuery,
  useGetDropdownProjectMembersQuery,
  useLazyGetDropdownRepresentativesByProjectQuery,
  useLazyGetDropdownRepresentativesQuery,
} from 'api/references/representatives'
import { RepresentativeShort } from 'api/references/representatives/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import {
  ProjectMemberRole,
  ProjectMemberRoleRu,
  projectMemberRoleEnByRu,
  projectMemberRoleRuByEn,
} from 'core/types/projectMember'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { RepresentativeStatus } from '../RepresentativeStatus'

export const RepresentativesManagmentForm: FC<RepresentativesManagmentFormProps> = ({
  onFormChange,
  representative,
  onClose,
}) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const { data: dropdownProjectMembers, isLoading: isDropdownProjectMembersLoading } =
    useGetDropdownProjectMembersQuery(undefined, { skip: isProjectMode })
  const { data: dropdownProjectMembersByProject, isLoading: isDropdownProjectMembersByProjectLoading } =
    useGetDropdownProjectMembersByProjectQuery({ projectId }, { skip: !isProjectMode })
  const dropdownProjectMembersList = isProjectMode
    ? dropdownProjectMembersByProject?.data
    : dropdownProjectMembers?.data
  const { enqueueSnackbar } = useSnackbar()

  const [getDropdownRepresentatives, { data: dropdownRepresentatives }] = useLazyGetDropdownRepresentativesQuery()
  const [getDropdownRepresentativesByProject, { data: dropdownRepresentativesByProject }] =
    useLazyGetDropdownRepresentativesByProjectQuery()
  const dropdownRepresentativesList =
    (isProjectMode ? dropdownRepresentativesByProject?.data : dropdownRepresentatives?.data) || []

  const [createRepresentative, { isLoading: isCreating, ...createRepresentativeResponse }] =
    useCreateRepresentativeMutation()
  const [createRepresentativeByProject, { isLoading: isCreatingByProject, ...createRepresentativeByProjectResponse }] =
    useCreateRepresentativeByProjectMutation()

  const [editRepresentative, { isLoading: isEditing, ...editRepresentativeResponse }] = useEditRepresentativeMutation()
  const [editRepresentativeByProject, { isLoading: isEditingByProject, ...editRepresentativeByProjectResponse }] =
    useEditRepresentativeByProjectMutation()

  const [deleteRepresentative, { isLoading: isDeleting, ...deleteRepresentativeResponse }] =
    useDeleteRepresentativeMutation()
  const [deleteRepresentativeByProject, { isLoading: isDeletingByProject, ...deleteRepresentativeByProjectResponse }] =
    useDeleteRepresentativeByProjectMutation()

  const isQuerying = isCreating || isCreatingByProject || isEditing || isEditingByProject
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { projectMember, fullName, basis, position, email, note, user } = representative || {}

  const initialValues: RepresentativesFormData = useMemo(
    () => ({
      projectMember: projectMember
        ? {
            id: projectMember.id,
            value: projectMember.shortName,
            subtext: projectMemberRoleRuByEn[projectMember.role],
          }
        : null,
      fullName: fullName
        ? {
            id: 0,
            value: fullName || '',
            type: { type: user ? 'user' : 'projectMember' },
          }
        : null,
      basis: basis || '',
      position: position || '',
      email: email || '',
      note: note || '',
    }),
    [representative],
  )

  const onSubmit = useCallback(
    (values: RepresentativesFormData) => {
      const { projectMember, fullName, basis, position, email, note } = values

      const dataForRequest: RepresentativeShort = {
        projectMember: projectMember?.id as number,
        fullName: fullName?.value || '',
        basis,

        position,
        // @ts-ignore
        email: email || null,
        note,
        user: Number(fullName?.id) || null,
      }

      if (representative) {
        isProjectMode
          ? editRepresentativeByProject({ projectId, representativeId: representative.id, body: dataForRequest })
          : editRepresentative({ representativeId: representative.id, body: dataForRequest })
      } else {
        isProjectMode
          ? createRepresentativeByProject({ projectId, body: dataForRequest })
          : createRepresentative({ body: dataForRequest })
      }
    },
    [representative],
  )

  const { formik } = useForm({
    validationSchema: validationRepresentatives,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    const { value, subtext } = values.projectMember || {}

    if (value && !dropdownRepresentativesList?.length) {
      const role = projectMemberRoleEnByRu[subtext as ProjectMemberRoleRu] as ProjectMemberRole
      isProjectMode ? getDropdownRepresentativesByProject({ role, projectId }) : getDropdownRepresentatives({ role })
    }
  }, [values.projectMember])

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const handleProjectMemberSelection = (data: any) => {
    const role = projectMemberRoleEnByRu[data.subtext as ProjectMemberRoleRu] as ProjectMemberRole
    isProjectMode ? getDropdownRepresentativesByProject({ role, projectId }) : getDropdownRepresentatives({ role })
  }

  const handleFullNameSelection = (data: any) => {
    setFieldValue('email', data.type.email)
    setFieldValue('position', data.subtext)
  }

  const onDelete = () => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }

  const handleDeleteProjectmember = useCallback((confirm: boolean) => {
    if (confirm) {
      isProjectMode
        ? deleteRepresentativeByProject({ projectId, representativeId: representative!.id })
        : deleteRepresentative({ representativeId: representative!.id })
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<RepresentativesDialogTrigger>('delete')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeManagment: { handleConfirm: handleDeleteProjectmember },
    delete: {
      handleConfirm: handleDeleteProjectmember,
      title: 'Удалить представителя участника проекта?',
      body: 'Представитель участника проекта будет удалён.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    isProjectMode ? createRepresentativeByProjectResponse : createRepresentativeResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Представитель участника проекта успешно добавлен.', { variant: 'success' })
      onClose(dirty, true)
      // onChangeTab('view', true)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar('Данный представитель участника проекта уже существует.', { variant: 'error' })
      }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? editRepresentativeByProjectResponse : editRepresentativeResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Представитель участника проекта успешно изменён.', { variant: 'success' })
      onClose(dirty, true)
      // onChangeTab('view', true)
    },
    (data) => {
      // @ts-ignore
      if (data?.status === 409) {
        enqueueSnackbar('Данный представитель участника проекта уже существует.', { variant: 'error' })
      }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  useMutationHandlers(
    isProjectMode ? deleteRepresentativeByProjectResponse : deleteRepresentativeResponse,
    (data) => {
      // if (!data.success) {
      //   enqueueSnackbar(data.description, { variant: 'error' })
      //   return
      // }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Представитель участника проекта успешно удалён.', { variant: 'success' })
      onClose(dirty, true)
    },
    (data) => {
      // @ts-ignore
      // if (data?.status === 409) {
      //   enqueueSnackbar('Участник проекта с данной ролью уже существует.', { variant: 'error' })
      // }
      // @ts-ignore
      handleError(data?.status !== 409)
    },
  )

  return (
    <Stack height='100%' overflow='auto'>
      <FormikProvider value={formik}>
        <Stack component={Form} height='100%' justifyContent='space-between' overflow='auto'>
          <Stack spacing={2.5} height='100%' overflow='auto'>
            <FormWrapper spacing={1.5} height='100%'>
              <CustomSelect
                name='projectMember'
                list={dropdownProjectMembersList || []}
                placeholder='Участник проекта'
                isSubtext={true}
                // readOnly={viewingOnly}
                isSearch={true}
                style={{
                  textAlign: 'start',
                }}
                handleValueSelection={handleProjectMemberSelection}
                // width={216}
              />

              <Stack direction='row' alignItems='center' spacing={1}>
                <CustomSelect
                  name='fullName'
                  // @ts-ignore
                  list={dropdownRepresentativesList || []}
                  placeholder='ФИО'
                  free={true}
                  // readOnly={viewingOnly}
                  isSearch={true}
                  style={{
                    textAlign: 'start',
                  }}
                  isDisabled={!values?.projectMember?.id}
                  handleValueSelection={handleFullNameSelection}
                  // width={216}
                />
                {/* @ts-ignore */}
                <RepresentativeStatus type={values?.fullName?.type?.type || 'projectMember'} />
                {/* <RepresentativeStatus type={values.fullName?.type as 'user' | 'projectMember'} /> */}
              </Stack>

              <FieldForm
                version='project'
                name='basis'
                placeholder='Основание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                  },
                }}
              />

              <FieldForm
                version='project'
                name='position'
                placeholder='Должность'
                multiline
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='email'
                placeholder='E-mail'
                helperText=''
                fullWidth={false}
                inputProps={{
                  style: { padding: '5px 8px' },
                }}
              />

              <FieldForm
                version='project'
                name='note'
                placeholder='Примечание'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    minHeight: '106px',
                  },
                }}
              />
            </FormWrapper>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width='100%'>
                {representative?.id && (
                  <Button
                    onClick={onDelete}
                    size='medium'
                    color='error'
                    width='fit-content'
                    loading={isDeleting || isDeletingByProject}
                    icon
                  >
                    <DeleteIcon fontSize='medium' style={{ fill: theme.palette.bg.white }} />
                  </Button>
                )}
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isFormLoading}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>

      <ConfirmDialog />
    </Stack>
  )
}
