import { AuditTooltipMailsProps } from './AuditTooltipMails.types'
import { Divider, Stack, Typography } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { theme } from 'styles/theme'
import { MailStatusIconWrapper } from './AuditTooltipMails.styles'
import { AuditMails } from 'core/types/global'
import { MailSuccessIcon } from 'assets/icons/MailSuccessIcon'
import { MailFailedIcon } from 'assets/icons/MailFailedIcon'
import { MailUninformativeIcon } from 'assets/icons/MailUninformativeIcon'
import { Tooltip } from 'components/Tooltip'

export const AuditTooltipMails: FC<AuditTooltipMailsProps> = ({ mailRecipients }) => {
  const getMailStatusIcon = (emailInfo: AuditMails) => {
    const value: string = String(emailInfo.delivered)

    const data: Record<string, any> = {
      true: {
        tooltipText: 'Письмо успешно отправлено. Доставка письма не подтверждена.',
        icon: (
          <MailStatusIconWrapper bgColor='#8DD4C826'>
            <MailSuccessIcon />
          </MailStatusIconWrapper>
        ),
      },
      false: {
        tooltipText: 'Произошел сбой при отправлении письма.',
        icon: (
          <MailStatusIconWrapper bgColor={theme.palette.legends.lightRed}>
            <MailFailedIcon />
          </MailStatusIconWrapper>
        ),
      },
      null: {
        tooltipText: 'Нет информации об успешном отправлении письма.',
        icon: (
          <MailStatusIconWrapper bgColor={theme.palette.bg.shades}>
            <MailUninformativeIcon />
          </MailStatusIconWrapper>
        ),
      },
    }

    return (
      <Tooltip title={data[value].tooltipText} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
        <div>{data[value].icon}</div>
      </Tooltip>
    )
  }

  return (
    <Stack spacing={1}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography variant='subtitle2' fontSize={12} color={theme.palette.text.dark}>
          Уведомление на e-mail отправлено:
        </Typography>
      </Stack>

      <Divider />

      {mailRecipients.map((emailInfo) => (
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' minWidth={280}>
          <Stack>
            <Typography variant='body1' fontSize={12} color={theme.palette.text.dark}>
              {emailInfo.fullName || '—'}
            </Typography>
            <Typography
              variant='body1'
              fontSize={12}
              color={theme.palette.secondary.gray}
              style={{ wordBreak: 'break-all' }}
            >
              {emailInfo.email || '—'}
            </Typography>
          </Stack>

          {getMailStatusIcon(emailInfo)}
        </Stack>
      ))}
    </Stack>
  )
}
