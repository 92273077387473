import { IInvalidatedTag, TTagType } from 'api/api.types'
import {
  EliminationShort,
  RemarkAudit,
  RemarkColumn,
  RemarkElimination,
  RemarkFull,
  RemarkSecondaryShort,
  RemarkSecondaryAudit,
  RemarkShort,
  TRemarkStatus,
  RemarkCopyShort,
} from './types'
import { CurrentSortableColumn, RemarksFilters } from './types'
import { EliminationStatus } from 'core/types/elimination'
import { FileWithId, OtherPaginationProps, QueryObserverProps, StatusResponse } from 'core/types/global'
import { ScrollByIndexResponse } from 'hooks/useObserver'

export interface GetDropdownDataRequest {
  projectId: number
}

export interface GetDropdownData {
  id: number
  text: string
}

export interface GetDropdownContractorsData extends GetDropdownData {
  data: 'company' | 'projectMember'
  subtext: string
}

export interface GetDropdownResponsibleData extends GetDropdownData {
  data: 'representative' | 'user'
}

export interface GetDropdownBasisData extends GetDropdownData {
  data: 'rd' | 'nd'
}

export interface GetDropdownDataResponse {
  data: GetDropdownData[]
}

export interface GetDropdownResponsibleRequest extends GetDropdownDataRequest {
  contractor: string | null
  company: string | null
}

export interface GetResponsibleEliminationRequest extends GetDropdownDataRequest {
  remarkId: number
}

export interface GetDropdownObjectsResponse extends StatusResponse, GetDropdownDataResponse {}

export interface GetDropdownActivityTypesResponse extends StatusResponse, GetDropdownDataResponse {}

export interface IGetDescriptionsForRemarkResponse extends StatusResponse {
  data: any
}
export interface IGetDescriptionsForRemarkRequest {
  projectId: number
  assignmentId?: number
}

export interface GetDropdownContractorsResponse extends StatusResponse {
  data: GetDropdownContractorsData[]
}

export interface GetDropdownResponsibleResponse extends StatusResponse {
  data: GetDropdownResponsibleData[]
}

export interface GetDropdownBasisResponse extends StatusResponse {
  data: GetDropdownBasisData[]
}

export interface RequestResult {
  description: string
  success: boolean
}

export interface GetRemarksRequest extends OtherPaginationProps {
  projectId: number
  query?: string
  sort?: CurrentSortableColumn | null
  filter?: RemarksFilters
}

export interface GetRemarksByPrevIdRequest extends QueryObserverProps {
  projectId: number
  returnScrollPosition?: (value: number) => void
  scrollByIndex?: (dataList: RemarkFull[]) => ScrollByIndexResponse

  query?: string
  sort?: CurrentSortableColumn | null
  filter?: RemarksFilters
}

export interface GetRemarksResponse extends StatusResponse {
  data: RemarkFull[]
}

export interface GetRemarkResponse extends RemarkFull {}

export interface GetRemarkRequest {
  projectId: number
  remarkId: number
}

export interface CreateRemarkResponse extends RequestResult {
  data: RemarkFull
}

export interface CreateRemarkRequest {
  projectId: number
  body: RemarkShort
  shouldUpdateCacheWhenSuccess?: boolean
  shouldUpdateCacheWhenError?: boolean
}

export interface EditRemarkResponse extends RequestResult {
  data: RemarkFull
}

export interface EditRemarkCopyResponse extends EditRemarkResponse {}

// createRemarkSecondary
export interface CreateRemarkSecondaryRequest {
  projectId: number
  remarkId: number
  body: RemarkSecondaryShort
}

export interface CreateRemarkSecondaryResponse extends RequestResult {
  data: RemarkFull & { primary: RemarkFull }
  total: number
}

export const createRemarkSecondaryInvalidateTags: (IInvalidatedTag | TTagType)[] = [
  'ProjectsDashboard',
  { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' },
  'Remarks',
]

//
export interface EditRemarkRequest {
  projectId: number
  remarkId: number
  newRemarkId?: number
  body: RemarkShort
  loadMedia: boolean
  notification: boolean
}

export interface EditRemarkCopyRequest {
  projectId: number
  primaryRemarkId: number
  remarkId: number
  body: RemarkCopyShort
}

// editRemarkStatus
export interface EditRemarkStatusRequest {
  projectId: number
  remarkId: number
  status: TRemarkStatus
  notification: boolean
  secondaryId: number | null
  dateDone?: string

  sort?: CurrentSortableColumn
  filter?: RemarksFilters
}

export interface EditRemarkStatusResponse extends EditRemarkResponse {}

// deleteRemark
export interface DeleteRemarkResponse extends RequestResult {
  data: null
}
export interface DeleteRemarkRequest {
  projectId: number
  remarkId: number
}

export const deleteRemarkInvalidateTags: (IInvalidatedTag | TTagType)[] = [
  'Remarks',
  'ProjectsDashboard',
  'Prescriptions',
]

// deleteRemarkCopy
export interface DeleteRemarkCopyRequest extends DeleteRemarkRequest {
  primaryRemarkId: number
}

export const deleteRemarkCopyInvalidateTags: (IInvalidatedTag | TTagType)[] = [
  'Remarks',
  'ProjectsDashboard',
  'Prescriptions',
]

//
export interface ChangeRemarkNotificationRequest {
  projectId: number
  remarkId: number
  enabled: boolean
}

export interface ChangeRemarkNotificationResponse extends RequestResult {}

export interface UploadImagesToRemarkRequest {
  projectId: number
  remarkId: number
  files: FileWithId[]
  shouldUpdateCacheWhenSuccess?: boolean
  shouldUpdateCacheWhenError?: boolean
}

export interface UploadImagesToRemarkResponse extends StatusResponse {
  data: UploadImagesToRemarkData
}

export interface UploadImagesToRemarkData {
  shortcoming: RemarkFull
  addedMediaId: number[]
}

export interface DeleteImagesFromRemarkRequest {
  projectId: number
  remarkId: number
  imagesIds: number[]
}

export interface GetRemarkAuditRequest extends OtherPaginationProps {
  projectId: number
  remarkId: number
}

export interface GetRemarkAuditResponse {
  data: RemarkAudit[]
  number: string
}

export interface GetRemarkSecondaryAuditResponse {
  data: RemarkSecondaryAudit[]
  total: number | null
  success: boolean
  description: string
}

export interface GetRemarkEliminationRequest {
  projectId: number
  remarkId: number
}

export interface GetRemarkEliminationResponse extends StatusResponse {
  data: RemarkElimination
}

export interface ChangeRemarkEliminationRequest {
  projectId: number
  remarkId: number

  body: EliminationShort

  status: EliminationStatus
  notification: boolean
}

export interface ChangeRemarkEliminationResponse extends StatusResponse {
  data: any
}

export interface UploadMediaToEliminationResponse extends StatusResponse {
  data: UploadMediaToEliminationData
}

export interface UploadMediaToEliminationData {
  contractorInfo: RemarkElimination
  addedMediaId: number[]
}

export interface UploadMediaToEliminationRequest {
  projectId: number
  remarkId: number
  files: FileWithId[]
}

export interface DeleteMediaFromEliminationRequest {
  projectId: number
  remarkId: number
  mediaIds: number[]
}

export interface CheckRemarkForFilterSatisfactionResponse {
  pass: boolean
  shortcoming: RemarkFull
}

export interface CheckRemarkForFilterSatisfactionRequest {
  projectId: number
  remarkId: number
  filter: RemarksFilters
  sort: CurrentSortableColumn
}

export interface GetRemarksColumnSettingsResponse {
  columns: RemarkColumn[]
  default: boolean
  showSecondary: boolean
}

export interface SetRemarksColumnSettingsResponse {
  columns: RemarkColumn[]
  default: boolean
  showSecondary: boolean
}

export interface ResetRemarksColumnSettingsResponse {
  columns: RemarkColumn[]
  default: boolean
  showSecondary: boolean
}

/****************************************** Check remark for email notification from Remark Form *************************************************/

export interface CheckRemarkForEmailNotificationResponse {
  data: boolean
  description: string
  success: boolean
}

export interface CheckRemarkForEmailNotificationRequest {
  projectId: number
  remarkId: number
  secondaryId: number | null
  body: RemarkShort
}

/****************************************** Check remark for email notification from Table *************************************************/

export interface CheckRemarkStatusForEmailNotificationResponse {
  data: boolean
  description: string
  success: boolean
}

export interface CheckRemarkStatusForEmailNotificationRequest {
  projectId: number
  remarkId: number
  secondaryId: number | null
  status: TRemarkStatus
  completion?: string
}
