import { EMAIL_USER_MAX_LENGTH } from 'components/UserManagement/UserManagement.validation'
import { i18n } from 'i18n/index'
import * as yup from 'yup'

export const validationRepresentatives = yup.object({
  projectMember: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  fullName: yup
    .object({
      id: yup.string(),
      value: yup.string(),
    })
    .required('common:errors.required'),
  basis: yup.string().trim(),
  position: yup.string().trim(),
  email: yup
    .string()
    .email('common:errors.email')
    .test((email) => !/[а-яА-ЯЁё]/.test(email || ''))
    .max(EMAIL_USER_MAX_LENGTH, i18n.t('common:errors.max', { maxLength: EMAIL_USER_MAX_LENGTH })),
  note: yup.string().trim(),
})
