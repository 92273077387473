import { DocumentFormData, DocumentManagmentFormProps } from './DocumentManagmentForm.types'
import { validationDocument } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  useCreateDocumentMutation,
  useGetWorksDropdownForDocsQuery,
  useEditDocumentMutation,
  useUploadFileToDocumentMutation,
  useDeleteDocumentMutation,
} from 'api/documents'
import { DocumentShort } from 'api/documents/types'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { Divider } from 'components/Divider'
import { FieldForm } from 'components/FieldForm'
import { FileUpload } from 'components/FileUpload'
import { Form, FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import {
  FormButtonWrapper,
  FormWrapper,
} from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { chosenTypesIdsSelector } from 'store/slices/documents'
import { useAppDispatch, useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const DocumentManagmentForm: FC<DocumentManagmentFormProps> = ({
  document,
  onFormChange,
  onClose,
  viewingOnly,
}) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { data: worksDropdown } = useGetWorksDropdownForDocsQuery({ projectId })
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [createDocument, createDocumentResponse] = useCreateDocumentMutation()
  const [editDocument, editDocumentResponse] = useEditDocumentMutation()
  const [uploadFileToDocument, uploadFileToDocumentResponse] = useUploadFileToDocumentMutation()
  const [deleteDocument, deleteDocumentResponse] = useDeleteDocumentMutation()
  const chosenTypesIds = useTypedSelector(chosenTypesIdsSelector)

  const { type, number, date, comment, file } = document || {}

  const initialValues: DocumentFormData = useMemo(
    () => ({
      type: type
        ? {
            id: type.id,
            value: type.title,
          }
        : null,
      number: number || '',
      date: parseResponseDate(date).date,
      comment: comment || '',
      file: file || null,
      fileForCreate: null,
    }),
    [document],
  )

  const onSubmit = useCallback(
    (values: DocumentFormData) => {
      if (isFormLoading) return
      else setIsFormLoading(true)

      if (values.fileForCreate?.length) setIsFileLoading(true)

      const { type, number, date, comment } = values

      const dataForRequest: DocumentShort = {
        type: Number(type?.id),
        number,
        date: formatDateForServer(date),
        comment,
        file: null,
      }

      if (document) {
        editDocument({ projectId, documentId: document.id, body: dataForRequest, chosenTypesIds })
      } else createDocument({ projectId, body: dataForRequest })
    },
    [document, isFormLoading],
  )

  const { formik } = useForm({
    validationSchema: validationDocument,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isSubmitting, isValid } = formik

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        deleteDocument({ projectId, documentId: document!.id })
      }
    },
    [values],
  )

  const dataForDeleteConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleDeleteConfirm,
    title: 'Удалить документ?',
    body: 'Документ будет удалён.',
  }

  const { ConfirmDialog: DeleteConfirmDialog, openConfirm: openDeleteConfirm } =
    useConfirmDialog(dataForDeleteConfirmDialog)

  useMutationHandlers(createDocumentResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (values.fileForCreate?.[0]) {
      uploadFileToDocument({
        projectId,
        documentId: data.data.id,
        file: values.fileForCreate[0],
        chosenTypesIds,
      })
      return
    }

    // setIsFormLoading(false)
    enqueueSnackbar('Документ успешно добавлен.', { variant: 'success' })
    onClose(false)
  })

  useMutationHandlers(editDocumentResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (!file && values.fileForCreate?.[0]) {
      uploadFileToDocument({
        projectId,
        documentId: data.data.id,
        file: values.fileForCreate[0],
        chosenTypesIds,
      })
      return
    }

    enqueueSnackbar('Документ успешно изменён.', { variant: 'success' })
    onClose(false)
  })

  useMutationHandlers(uploadFileToDocumentResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    // setIsFormLoading(false)
    enqueueSnackbar(document ? 'Документ успешно изменён.' : 'Документ успешно добавлен.', { variant: 'success' })
    onClose(false)
  })

  useMutationHandlers(deleteDocumentResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    enqueueSnackbar('Документ успешно удалён.', { variant: 'success' })
    navigate(`/project/${projectId}/documents`)
    onClose(false)
  })

  return (
    <Stack height='100%' overflow='auto'>
      <FormikProvider value={formik}>
        <Stack component={Form} height='100%' justifyContent='space-between' overflow='auto'>
          <FormWrapper spacing={1.5} height='100%'>
            <CustomSelect
              name='type'
              variant='textarea'
              list={worksDropdown?.data || []}
              placeholder='Выберите из списка'
              isSearch={true}
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              label='Вид документа'
              isRequired={true}
            />
            <FieldItem title='Номер документа' isRequired paddingLeft={1.25}>
              <FieldForm
                version='project'
                placeholder='Введите значение'
                name='number'
                helperText=''
                dataValue={values.number}
                fullWidth={false}
                inputProps={{
                  readOnly: viewingOnly,
                  style: { padding: '4px 8px', width: '201px', textAlign: 'end' },
                }}
              />
            </FieldItem>
            <FieldItem title='Дата документа' isRequired paddingLeft={1.25}>
              <FieldForm
                version='date'
                name='date'
                placeholder='Выберите дату'
                helperText=''
                dataValue={values.date}
                onDataChange={(value: Date | null) => setFieldValue('date', value)}
                style={{ maxWidth: '217px', width: '100%' }}
                inputProps={{
                  style: { textAlign: 'end', height: '30px' },
                }}
                dateFieldProps={{
                  readOnly: viewingOnly,
                }}
              />
            </FieldItem>

            <Divider color={theme.palette.bg.gray} />

            <Stack spacing={0.5}>
              <Typography variant='body2' lineHeight='inherit' paddingLeft={1.25}>
                Комментарий:
              </Typography>
              <FieldForm
                version='project'
                name='comment'
                multiline
                placeholder={!viewingOnly ? 'Комментарий' : ''}
                inputProps={{
                  readOnly: viewingOnly,
                  style: {
                    minHeight: '140px',
                  },
                }}
              />
            </Stack>

            {!file && !viewingOnly && (
              <>
                <Divider color={theme.palette.bg.gray} />
                <FileUpload
                  mode='single'
                  name='fileForCreate'
                  uploadData={{
                    title: 'Загрузить PDF',
                    text: 'Чтобы добавить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
                    formats: ['application/pdf'],
                  }}
                  loading={isFileLoading}
                />
              </>
            )}
          </FormWrapper>

          <FormButtonWrapper padding={2.5} spacing={1}>
            <Stack direction='row' spacing={2} width='100%'>
              <Button
                type='submit'
                disabled={!dirty || !isValid}
                color='success'
                size='medium'
                fullWidth
                loading={isFormLoading}
              >
                Сохранить
              </Button>
              <Button size='medium' fullWidth onClick={() => onClose()}>
                Закрыть
              </Button>
            </Stack>
            {document && !viewingOnly && (
              <Button onClick={openDeleteConfirm} icon={true} size='medium' color='error' variant='text'>
                <DeleteIcon style={{ fill: theme.palette.error.main }} />
                <Typography color={theme.palette.error.main} marginLeft={1}>
                  Удалить документ
                </Typography>
              </Button>
            )}
          </FormButtonWrapper>
        </Stack>
      </FormikProvider>

      <DeleteConfirmDialog />
    </Stack>
  )
}
