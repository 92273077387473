import { FilterDrawer } from '../FilterDrawer'
import {
  DOCUMENTS_TABLE_CELL_HEIGHT,
  IconButtonWrapper,
  TableCell,
  TableCellWrapper,
  TableContainer,
} from './DocumentsTable.styles'
import { DocumentsLocationState } from './DocumentsTable.types'
import { FilterAlt as FilterIcon } from '@mui/icons-material/'
import { Stack, Typography } from '@mui/material'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { documentsApi, useGetDocumentsQuery } from 'api/documents'
import { DocumentFull, DocumentType } from 'api/documents/types'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { PdfViewerProps } from 'components/PdfViewer'
import { Progress } from 'components/Progress'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useSearch } from 'hooks/useSearch'
import { PdfViewerPopup } from 'pages/Prescriptions/components/PrescriptionsForm/components/PdfViewerPopup'
import { getEmptyPageData } from 'pages/Projects'
import { AttachmentCell } from 'pages/Regulations/components/RegulationsTable/cells/AttachmentCell'
import { AuditDrawer } from 'pages/Remarks/components/AuditDrawer'
import {
  CustomColumnDef,
  RemarksTableBody,
  RemarksTableCell,
  RemarksTableHead,
  RemarksTableRow,
} from 'pages/Remarks/components/RemarksTable'
import { AuditCell } from 'pages/Remarks/components/RemarksTable/cells/AuditCell'
import { FC, MouseEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { chosenTypesIdsSelector } from 'store/slices/documents'
import { documentsShouldResetPageSelector, setShouldResetPage } from 'store/slices/infiniteScroll'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const DocumentsTable: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { searchValue } = useSearch({ preventClearOnUnmount: true })
  const navigate = useNavigate()
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const chosenTypesIds = useTypedSelector(chosenTypesIdsSelector)
  const cellRef = useRef()

  // Location
  const location = useLocation()
  const locationState: DocumentsLocationState = (location?.state as DocumentsLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { locationRowId, locationPage } = savedLocationState || {}

  const onRowClick = (docId: number, docPosition: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`${docId}`, { state: { locationPage: currentPage, locationDocPosition: docPosition } })
  }

  // Filter
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false)

  const onFilterDrawerOepn = () => {
    setIsFilterDrawerOpen(true)
  }

  const onFilterDrawerClose = () => {
    setIsFilterDrawerOpen(false)
  }

  // Audit
  const [openedAuditId, setOpenedAuditId] = useState<number | null>(null)

  const onAuditOpen = (id: number) => {
    setOpenedAuditId(id)
  }

  const onAuditClose = () => {
    setOpenedAuditId(null)
  }

  const columnHelper = createColumnHelper<DocumentFull>()

  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)

  const openViewer = (fileUrl: string, title: string, onDownload: () => void) => {
    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      disableDelete: true,
      onDelete: () => {},
    })
  }

  const columns: CustomColumnDef<DocumentFull>[] = [
    {
      accessorKey: 'type',
      header: 'Вид документа',
      size: 565,
      minSize: 250,
      cell: (info) => {
        const { title } = info.getValue<DocumentType>() || {}
        return title || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'number',
      header: 'Номер документа',
      size: 565,
      minSize: 150,
      cell: (info) => {
        const number = info.getValue<string>()
        return number || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'date',
      header: 'Дата документа',
      size: 565,
      minSize: 90,
      maxSize: 565,
      cell: (info) => {
        const date = info.getValue<string>()
        return (date && parseResponseDate(date).fullDate) || '—'
      },
    },
    {
      accessorKey: 'file',
      header: 'PDF',
      minSize: 90,
      size: 113,
      maxSize: 113,
      // @ts-ignore
      cell: (info) => <AttachmentCell info={info} variant='file' onFileClick={openViewer} />,
      isCustomCell: true,
    },
    ...((isUserHighAccess
      ? [
          {
            ...columnHelper.display({
              id: 'actions',
              minSize: 67,
              size: 67,
              maxSize: 67,
              // @ts-ignore
              cell: (info) => <AuditCell info={info} onAuditOpen={onAuditOpen} />,
            }),
            isCustomCell: true,
          },
        ]
      : []) as CustomColumnDef<DocumentFull>[]),
  ]

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { queryData, onScrollWithInfiniteLoad, currentPage } = useInfiniteScroll({
    refElement: tableContainerRef,
    api: documentsApi,
    tagName: 'Documents',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetDocumentsQuery,
    shouldResetPageSelector: documentsShouldResetPageSelector,
    startPage: locationPage || 1,
    arg: {
      projectId,
      query: searchValue || undefined,
      body: chosenTypesIds,
    },
    table: 'documents',
  })

  const { data = [], total } = queryData.data || {}
  const { isLoading } = queryData || {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => DOCUMENTS_TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  const onViewerClose = () => {
    setOpenedPdfData(null)
  }

  const emptyPageData: EmptyPageData = getEmptyPageData(
    searchValue ? <>Отсутствуют документы, соответствующие результатам запроса.</> : <>Данные отсутствуют.</>,
  )

  // Positioning
  const [selectedId, setSelectedId] = useState<number | null>(null)

  useEffect(() => {
    if (locationRowId) {
      const rowIndex = data.findIndex((regulation) => regulation.id === locationRowId)

      if (rowIndex >= 0) {
        const rowPosition = rowIndex * TABLE_CELL_HEIGHT

        const scrollValue = rowPosition - (tableContainerRef.current?.offsetHeight || 0) / 2.5
        tableContainerRef.current?.scrollTo({ top: scrollValue, behavior: 'auto' })

        setSelectedId(locationRowId)
      }
    }
  }, [locationRowId])

  const onHoverRow = (rowId: number) => {
    if (rowId === selectedId) setSelectedId(null)
  }

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!data.length && (
        <TableContainer onScroll={onScrollWithInfiniteLoad} ref={tableContainerRef}>
          <RemarksTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <RemarksTableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (header.id === 'type')
                    return (
                      <RemarksTableCell
                        key={header.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flex: 1,
                          minWidth: header.column.columnDef.minSize,
                          width: header.column.columnDef.size,
                          maxWidth: header.column.columnDef.maxSize,
                          minHeight: '60px',
                          height: '60px',
                        }}
                      >
                        <div></div>
                        <Typography variant='body2'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>

                        <IconButtonWrapper onClick={onFilterDrawerOepn}>
                          {chosenTypesIds.length ? (
                            <FilterIcon style={{ fill: theme.palette.text.light }} fontSize='inherit' />
                          ) : (
                            <FilterIcon style={{ fill: theme.palette.text.light, opacity: 0.5 }} fontSize='inherit' />
                          )}
                        </IconButtonWrapper>
                      </RemarksTableCell>
                    )

                  return (
                    <RemarksTableCell
                      key={header.id}
                      style={{
                        flex: 1,
                        minWidth: header.column.columnDef.minSize,
                        width: header.column.columnDef.size,
                        maxWidth: header.column.columnDef.maxSize,
                        minHeight: '60px',
                        height: '60px',
                      }}
                    >
                      <Typography variant='body2'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </RemarksTableCell>
                  )
                })}
              </RemarksTableRow>
            ))}
          </RemarksTableHead>

          <RemarksTableBody style={{ height: virtualizer.getTotalSize() }}>
            {virtualizer.getVirtualItems().map((row) => {
              const docId = data[row.index].id

              return (
                <RemarksTableRow
                  onClick={onRowClick(docId, row.start - virtualizer.options.scrollMargin || 1)}
                  showAnimation={docId === selectedId}
                  onMouseEnter={() => onHoverRow(docId)}
                  key={row.key}
                  data-index={row.index}
                  ref={virtualizer.measureElement}
                  style={{
                    width: '100%',
                    transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                  }}
                >
                  {columns.map((column, columnIndex) => {
                    const cell = rows[row.index].getVisibleCells()[columnIndex]
                    const { minSize, size, maxSize, textAlign } = column

                    if (column.isCustomCell) {
                      return flexRender(cell.column.columnDef.cell, cell.getContext())
                    }

                    return (
                      <TableCellWrapper
                        key={column.id}
                        style={{
                          flex: 1,
                          minWidth: minSize,
                          width: size,
                          maxWidth: maxSize,
                        }}
                      >
                        <TableCell className='cell'>
                          <Typography variant='body2' width='100%' textAlign={textAlign}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Typography>
                        </TableCell>
                      </TableCellWrapper>
                    )
                  })}
                </RemarksTableRow>
              )
            })}
          </RemarksTableBody>

          <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={onViewerClose} />

          <AuditDrawer variant='document' openedAuditId={openedAuditId} onClose={onAuditClose} />

          <FilterDrawer open={isFilterDrawerOpen} onClose={onFilterDrawerClose} />
        </TableContainer>
      )}

      {!isLoading && !data.length && <EmptyPage data={emptyPageData} fullPage />}
    </>
  )
}
