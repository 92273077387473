import { ImageItemDeleteButton, ImageItemWrapper, ImageWrapper, PdfWrapper } from './ImageItem.styles'
import { ImageItemProps } from './ImageItem.types'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import { Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'styles/theme'
import { downloadMedia } from 'utils/downloadMedia'

export const ImageItem: FC<ImageItemProps> = ({ url, onClick, download, canDelete = true, format = 'image', name }) => {
  const isImage = format?.includes('image')
  const isPdf = format === 'application/pdf'

  return (
    <ImageItemWrapper>
      {isImage && <img src={url} width={277} height={305} alt='Фото проекта' />}

      {isPdf && (
        <PdfWrapper>
          <Typography variant='body2' color={theme.palette.primary.main}>
            {name || ''}
          </Typography>
        </PdfWrapper>
      )}

      <ImageWrapper direction='row' spacing={1}>
        {download && (
          <ImageItemDeleteButton onClick={() => downloadMedia(url)}>
            <DownloadIcon color='primary' />
          </ImageItemDeleteButton>
        )}
        {canDelete && (
          <ImageItemDeleteButton onClick={() => onClick()}>
            <DeleteIcon color='error' />
          </ImageItemDeleteButton>
        )}
      </ImageWrapper>
    </ImageItemWrapper>
  )
}
