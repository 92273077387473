import { SlideWrapper } from '../IntegrationRequestSlide/IntegrationRequestSlide.styles'
import {
  IntegrationProjectItem,
  IntegrationProjectValue,
  IntegrationProjectsWrapper,
} from './IntegrationProjectSlide.styles'
import { IntegrationProjectSlideProps } from './IntegrationProjectSlide.types'
import { ClickAwayListener, Radio, Slide, Stack, Typography } from '@mui/material'
import {
  useBindIntegrationProjectMutation,
  useGetIntegratedProjectsQuery,
  useGetIntegrationProjectsByDesignModuleQuery,
  useGetIntegrationProjectsByRmModuleQuery,
  useUnbindIntegrationProjectMutation,
} from 'api/integrations'
import {
  IntegratedProject,
  IntegrationDesignProject,
  IntegrationRmProject,
  pragmaModuleLabel,
} from 'api/integrations/types'
import { Button } from 'components/Button'
import { Search } from 'components/FilterDrawer/components/Search'
import { Progress } from 'components/Progress'
import { Tooltip } from 'components/Tooltip'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { isTextOverflowed } from 'pages/Calls/components/CallTable/cells/TooltipCell/TooltipCell.types'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { memo, MouseEvent, useMemo, useRef } from 'react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { theme } from 'styles/theme'

export const IntegrationProjectSlide: FC<IntegrationProjectSlideProps> = ({
  isOpen,
  onClose,
  isConfirmDialogOpened,
  project,
  module,
}) => {
  const { id: projectId, shortName } = project
  const { enqueueSnackbar } = useSnackbar()
  const [searchValue, setSearchValue] = useState<string>('')
  const [queryParams, setQueryParams] = useState({
    limit: 1000,
    offset: 0,
  })

  const [bindIntegrationProject, { isLoading: isBinding, ...bindIntegrationProjectResponse }] =
    useBindIntegrationProjectMutation()
  const [unbindIntegrationProject, { isLoading: isUnbinding, ...unbindIntegrationProjectResponse }] =
    useUnbindIntegrationProjectMutation()

  const isQuerying = isBinding || isUnbinding
  const [isFormLoading, setIsFormLoading] = useState(false)

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const { data: integratedProjectsData } = useGetIntegratedProjectsQuery({ projectId }, { skip: !isOpen })
  const { data: integratedProjectsList = [] } = integratedProjectsData || {}

  const initialIntegratedProject = useMemo((): IntegratedProject | null => {
    return integratedProjectsList.filter((integration) => integration.module === module)[0] || null
  }, [integratedProjectsList, module])

  const [selectedProject, setSelectedProject] = useState<IntegratedProject | null>(null)

  const dirty = initialIntegratedProject?.id !== selectedProject?.id

  useEffect(() => {
    if (isOpen) setSearchValue('')
  }, [isOpen])

  useEffect(() => {
    setSelectedProject(initialIntegratedProject)
  }, [initialIntegratedProject, module, isOpen])

  const queryByModule: any = {
    PragmaDesign: useGetIntegrationProjectsByDesignModuleQuery,
    PragmaRM: useGetIntegrationProjectsByRmModuleQuery,
  }

  const query = queryByModule[module]

  const { data: projectsData, isFetching } = query({ projectId, ...queryParams }, { skip: !isOpen })
  const { data: projectsList = [] } = projectsData || {}

  const onSubmit = () => {
    if (initialIntegratedProject && !selectedProject) {
      unbindIntegrationProject({ projectId, module })
    }

    if (selectedProject && selectedProject.id !== initialIntegratedProject?.id) {
      bindIntegrationProject({ projectId, module, body: selectedProject })
    }
  }

  const onRadioChange = (project: IntegratedProject) => {
    setSelectedProject(project)
  }

  const onClearIntegration = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setSelectedProject(null)
  }

  const filtredKey = {
    PragmaDesign: 'shortName',
    PragmaRM: 'title',
  }

  const filtredBySearch = useMemo(() => {
    const result: any[] = []

    projectsList.forEach((project: IntegrationDesignProject | IntegrationRmProject) => {
      // @ts-ignore
      const name = project[filtredKey[module]]

      if (name.toLowerCase().includes(searchValue.toLowerCase())) {
        result.push({ id: project.id, name, module })
      }
    })

    return result
  }, [searchValue, projectsList])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    bindIntegrationProjectResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Связь с проектом успешно настроена.', { variant: 'success' })
      setIsFormLoading(false)
      onClose(false, true)
    },
    () => handleError(),
  )

  useMutationHandlers(
    unbindIntegrationProjectResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar('Связь с проектом успешно разорвана.', { variant: 'success' })
      setIsFormLoading(false)
      onClose(false, true)
    },
    () => handleError(),
  )

  const onClickAway = (e: any) => {
    if (isOpen && !isConfirmDialogOpened) onClose(dirty)
  }

  return (
    <ClickAwayListener mouseEvent='onMouseUp' touchEvent='onTouchStart' onClickAway={onClickAway}>
      <Slide in={isOpen} direction='up' timeout={300}>
        <SlideWrapper height='700px'>
          <Typography variant='body2' fontSize={14} color={theme.palette.text.dark} textAlign='center'>
            Выберите проект в модуле <b>{pragmaModuleLabel[module!]}</b>, чтобы связать с проектом “<b>{shortName}</b>”.
          </Typography>

          <Search key={String(isOpen)} searchValue={searchValue} setValue={setSearchValue} style={{ padding: '0' }} />

          <IntegrationProjectsWrapper spacing={1.5}>
            {isFetching ? (
              <Progress />
            ) : (
              filtredBySearch.map((project: IntegratedProject) => (
                <IntegrationProjectItem onClick={() => onRadioChange(project)}>
                  <Radio checked={selectedProject?.id === project.id} />
                  <IntegrationProjectSlideValue value={project.name} />
                  {initialIntegratedProject?.id === project.id && project.id === selectedProject?.id && (
                    <Button
                      style={{ padding: '7px 10px', height: 'fit-content', minWidth: '84px' }}
                      onClick={onClearIntegration}
                      size='medium'
                      color='error'
                      variant='outlined'
                    >
                      <Typography variant='body2' color={theme.palette.error.main}>
                        Отвязать
                      </Typography>
                    </Button>
                  )}
                </IntegrationProjectItem>
              ))
            )}
          </IntegrationProjectsWrapper>

          <FormButtonWrapper padding={2.5} spacing={1} margin='-20px'>
            <Stack direction='row' spacing={2} width='100%'>
              <Button
                onClick={onSubmit}
                disabled={!dirty || isFetching}
                loading={isFormLoading}
                color='success'
                size='medium'
                fullWidth
              >
                Сохранить
              </Button>
              <Button size='medium' fullWidth onClick={() => onClose(dirty)}>
                Закрыть
              </Button>
            </Stack>
          </FormButtonWrapper>
        </SlideWrapper>
      </Slide>
    </ClickAwayListener>
  )
}

const IntegrationProjectSlideValue: FC<{ value: string }> = memo((props) => {
  const tagValueRef = useRef<HTMLElement | null>(null)
  const [overflow, setOverflow] = useState(false)
  const theme = useTheme()

  const checkOverflow = useCallback(() => {
    if (tagValueRef.current) setOverflow(isTextOverflowed(tagValueRef))
  }, [])

  useEffect(() => {
    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [checkOverflow, props])

  return (
    <Tooltip
      bgColor={theme.palette.bg.white}
      textColor={theme.palette.text.dark}
      title={props.value}
      disableHoverListener={!overflow}
    >
      <IntegrationProjectValue ref={tagValueRef} variant='body2' color={theme.palette.text.dark}>
        {props.value}
      </IntegrationProjectValue>
    </Tooltip>
  )
})
