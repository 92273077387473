import { TabData } from 'components/Tabs'
import { DescriptionOutlined as StatusTabIcon } from '@mui/icons-material'
import { ModuleIntegrationIcon } from 'assets/icons/ModuleIntegrationIcon'

export type TAdminSettingsTab = 'status' | 'integration'
export type TAdminSettingsTabLabel = 'Статусы' | 'Интеграции'

export const adminSettingsTabs: TAdminSettingsTab[] = ['status', 'integration']

export const adminSettingsTabsData: TabData<TAdminSettingsTab>[] = [
  {
    value: 'status',
    label: 'Статусы',
    icon: <StatusTabIcon fontSize='medium' />,
  },
  {
    value: 'integration',
    label: 'Интеграции',
    icon: <ModuleIntegrationIcon fontSize='small' />,
  },
]

export type TAdminSettingsDialogTrigger = 'exit' | 'resetStatusSettings'
