import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { CallStatusCell } from './cells/CallStatusCell'
import { callTypeRuByEn, ICallCount, ICallFull, IInspection, IInspectionResponsible, TCallType } from 'api/calls/types'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { CallDateCell } from './cells/CallDateCell'
import { Stack, Typography } from '@mui/material'
import { connectNames } from 'utils/connectNames'
import { UserProfile } from 'api/profile/types'
import { PrescriptionSenderCompany, PrescriptionSenderUser } from 'api/prescriptions/types'
import { CallPhoneCell } from './cells/CallPhoneCell'
import { getInspectionStatusData } from '../CallForm/components/Inspection/Inspection.types'
import { StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { TInspectionStatus } from 'core/types/call'
import { CallAuditCell } from './cells/CallAuditCell'
import { TooltipCell } from './cells/TooltipCell'
import { TooltipCellMultiply } from './cells/TooltipCellMultiply'
import { CountCell } from 'pages/Remarks/components/RemarksTable/cells/CountCell'
import { getCallCountCellData } from 'pages/Remarks/components/RemarksTable/cells/CountCell/CountCell.types'
import { CustomColumnDef } from 'components/Table/Table.types'

export const numberColumn: ColDef = {
  field: 'number',
  colId: 'NUMBER',
  headerName: '№',
  initialWidth: 64,
  minWidth: 64,
}

export const statusColumn: ColDef = {
  field: 'status',
  colId: 'STATUS',
  headerName: 'Статус вызова',
  cellRenderer: CallStatusCell,
  initialWidth: 172,
  minWidth: 172,
}

export const typeColumn: ColDef = {
  field: 'type',
  colId: 'TYPE',
  headerName: 'Тип вызова',
  valueFormatter: ({ value }: ValueFormatterParams<ICallFull, TCallType>) => callTypeRuByEn[value as TCallType],
  initialWidth: 212,
  minWidth: 212,
}

export const workDoneByColumn: ColDef = {
  field: 'workDoneBy',
  colId: 'WORK_DONE_BY',
  headerName: 'Работы выполнены по',
  cellRenderer: TooltipCell,
  initialWidth: 224,
  minWidth: 224,
}

export const descriptionColumn: ColDef = {
  field: 'description',
  colId: 'DESCRIPTION',
  headerName: 'Описание',
  cellRenderer: TooltipCell,
  initialWidth: 300,
  minWidth: 300,
}

export const createdDateColumn: ColDef = {
  field: 'createdDate',
  colId: 'CREATED_DATE',
  headerName: 'Дата вызова',
  valueFormatter: ({ value }: ValueFormatterParams<ICallFull, string | null>) =>
    parseResponseDate(value || '').fullDate,
  initialWidth: 200,
  minWidth: 155,
}

export const expectedDateColumn: ColDef = {
  field: 'expectedDate',
  colId: 'EXPECTED_DATE',
  headerName: 'Предполагаемая дата осмотра',
  cellRenderer: ({ value, data }: ICellRendererParams<ICallFull, string>) => (
    <CallDateCell
      date={value}
      deviation={value && !data?.inspection.factDate && !data?.inspection.planDate ? data?.deviation : null}
    />
  ),
  initialWidth: 200,
  minWidth: 165,
}

export const authorColumn: ColDef = {
  field: 'author',
  colId: 'AUTHOR',
  headerName: 'Автор вызова',
  initialWidth: 300,
  minWidth: 150,
  cellRenderer: ({ value, ...props }: ICellRendererParams<ICallFull, UserProfile>) => (
    <TooltipCellMultiply value={connectNames(value)} subvalue={value?.company.userPosition} {...props} />
  ),
}

export const responsibleCompanyColumn: ColDef = {
  field: 'responsibleCompany',
  colId: 'RESPONSIBLE_COMPANY',
  headerName: 'Организация — инициатор вызова',
  cellRenderer: ({ value, ...props }: ICellRendererParams<ICallFull, PrescriptionSenderCompany>) => (
    <TooltipCell value={value?.company || value?.projectMember?.shortName} {...props} />
  ),
  initialWidth: 300,
  minWidth: 150,
}

export const responsibleUserColumn: ColDef = {
  field: 'responsibleUser',
  colId: 'RESPONSIBLE_USER',
  headerName: 'Представитель инициатора вызова',
  cellRenderer: ({ value, ...props }: ICellRendererParams<ICallFull, PrescriptionSenderUser>) => (
    <TooltipCellMultiply
      value={value?.user ? connectNames(value?.user) : value?.representative?.fullName}
      subvalue={value?.user?.position || value?.representative?.position}
      {...props}
    />
  ),
  initialWidth: 300,
  minWidth: 150,
}

export const phoneColumn: ColDef = {
  field: 'phone',
  colId: 'PHONE',
  headerName: 'Телефон представителя',
  cellRenderer: CallPhoneCell,
  initialWidth: 193,
  minWidth: 193,
}

export const inspectionStatusColumn: ColDef = {
  field: 'inspection.status',
  colId: 'INSPECTION_STATUS',
  headerName: 'Статус осмотра',
  cellRenderer: ({ value }: ICellRendererParams<ICallFull, TInspectionStatus>) => {
    const inspectionStatusData = getInspectionStatusData(value)

    return (
      <Stack direction='row' alignItems='center'>
        <StatusMarker markerColor={inspectionStatusData.color} />
        <Typography variant='body2'>{inspectionStatusData.body}</Typography>
      </Stack>
    )
  },
  initialWidth: 186,
  minWidth: 186,
}

export const inspectionPlanDateColumn: ColDef = {
  field: 'inspection',
  colId: 'INSPECTION_PLAN_DATE',
  headerName: 'Планируемая дата осмотра',
  cellRenderer: ({ value, data }: ICellRendererParams<ICallFull, IInspection>) => (
    <CallDateCell
      date={value?.planDate}
      time={value?.planTime}
      deviation={value?.planDate && !data?.inspection.factDate ? data?.deviation : null}
    />
  ),
  initialWidth: 210,
  minWidth: 210,
}

export const inspectionFactDateColumn: ColDef = {
  field: 'inspection.factDate',
  colId: 'INSPECTION_FACT_DATE',
  headerName: 'Фактическая дата осмотра',
  cellRenderer: ({ value, data }: ICellRendererParams<ICallFull, string>) => (
    <CallDateCell date={value} deviation={value ? data?.deviation : null} />
  ),
  initialWidth: 200,
  minWidth: 165,
}

export const inspectionResponsibleColumn: ColDef = {
  field: 'inspection.responsible',
  colId: 'INSPECTION_RESPONSIBLE',
  headerName: 'Ответственный за осмотр',
  initialWidth: 300,
  minWidth: 150,
  cellRenderer: ({ value, ...props }: ICellRendererParams<ICallFull, IInspectionResponsible>) =>
    value ? (
      <TooltipCellMultiply
        value={value.user ? connectNames(value.user) : value.representative?.fullName}
        subvalue={value.company}
        {...props}
      />
    ) : (
      <Typography textAlign='center'>—</Typography>
    ),
}

export const inspectionPhoneColumn: ColDef = {
  field: 'inspection.phone',
  colId: 'INSPECTION_PHONE',
  headerName: 'Телефон ответственного',
  cellRenderer: CallPhoneCell,
  initialWidth: 193,
  minWidth: 193,
}

export const auditColumn: ColDef = {
  field: 'audit',
  colId: 'AUDIT',
  headerName: '',
  width: 64,
  resizable: false,
  cellRenderer: CallAuditCell,
}

export const countColumn: ColDef = {
  field: 'count',
  colId: 'COUNT',
  headerName: '',
  resizable: false,
  cellRenderer: ({ data }: ICellRendererParams<ICallFull, ICallCount>) => (
    <CountCell data={getCallCountCellData(data)} stackProps={{ p: '0 !important' }} />
  ),
}
