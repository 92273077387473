import { Stack } from '@mui/material'
import { LegendColors } from 'core/types/global'
import styled from 'styled-components'

export const ProjectIntegrationWrapper = styled(Stack)`
  width: 400px;
  height: 100%;
  position: relative;
  overflow: hidden;
`
