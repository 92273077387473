import { FC } from 'react'
import { IStatusSettingsSectionProps } from './StatusSettingsSection.types'
import { StatusSettingsSectionWrapper } from './StatusSettingsSection.styles'
import { Divider, Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { StatusSettingsItem } from '../StatusSettingsItem'

export const StatusSettingsSection: FC<IStatusSettingsSectionProps> = ({
  title,
  statusData,
  sectionName,
  setDirtyCount,
}) => {
  return (
    <StatusSettingsSectionWrapper divider={<Divider />}>
      <Typography variant='subtitle2' fontSize={16} color={theme.palette.text.dark}>
        {title}
      </Typography>

      <Stack spacing={2.5}>
        {statusData.map((item) => (
          <StatusSettingsItem key={item.name} data={item} sectionName={sectionName} setDirtyCount={setDirtyCount} />
        ))}
      </Stack>
    </StatusSettingsSectionWrapper>
  )
}
