import { Stack } from '@mui/material'
import styled from 'styled-components'

export const InfoWrapper = styled(Stack)`
  padding: 10px;
  gap: 12px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 10px;
  text-align: start;
  flex-direction: row;
  width: fit-content;
`

export const InfoIconWrapper = styled(Stack)`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: fit-content;
  aspect-ratio: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;

  & svg {
    fill: ${(props) => props.theme.palette.bg.white};
  }
`
