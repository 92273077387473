import { api } from '../api'
import {
  IGetCallColumnSettingsResponse,
  IChangeCallStatusRequest,
  IChangeCallStatusResponse,
  ICheckCallForEmailNotificationInFormRequest,
  ICheckCallForEmailNotificationInTableRequest,
  ICheckCallForEmailNotificationResponse,
  ICreateCallRequest,
  ICreateCallResponse,
  IDeleteCallRequest,
  IDeleteFilesFromCallRequest,
  IEditCallRequest,
  IEditCallResponse,
  IEditInspectionRequest,
  IEditInspectionResponse,
  IGetCallByIdRequest,
  IGetCallByIdResponse,
  IGetCallsRequest,
  IGetCallsResponse,
  IGetDropdownAssignmentTypeRequest,
  IGetDropdownAssignmentTypeResponse,
  IGetDropdownInspectionResponsibleResponse,
  IGetDropdownResponsibleCompaniesRequest,
  IGetDropdownResponsibleCompaniesResponse,
  IGetDropdownResponsibleUserRequest,
  IGetDropdownResponsibleUserResponse,
  IImportFilesToCallRequest,
  IImportFilesToCallResponse,
  IRepeatInspectionRequest,
  ISetCallColumnColumnSettingsRequest,
  ISetCallColumnColumnSettingsResponse,
} from './api.types'
import { GetCallAuditRequest, GetCallAuditResponse } from './calls.types'
import { ICallColumnSetting } from './types'

export const callsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalls: build.query<IGetCallsResponse, IGetCallsRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/call`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Calls'],
    }),
    getNextCallNumber: build.query<string, number>({
      query: (projectId) => ({
        // url: `/call/${projectId}/create/number`,
        url: `/project/${projectId}/call/number`,
        method: 'GET',
        responseHandler: 'text',
      }),
      providesTags: ['Calls', { type: 'Calls', id: 'CALL_NEXT_NUMBER' }],
    }),
    getDropdownResponsibleCompanies: build.query<
      IGetDropdownResponsibleCompaniesResponse,
      IGetDropdownResponsibleCompaniesRequest
    >({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/call/dropdown/company`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects', 'References'],
    }),
    getDropdownResponsibleUser: build.query<IGetDropdownResponsibleUserResponse, IGetDropdownResponsibleUserRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/call/dropdown/initiator`,
        params: { page: 1, num: 99999, ...params },
        method: 'GET',
      }),
      providesTags: ['Users', 'References'],
    }),
    getDropdownAssignmentType: build.query<IGetDropdownAssignmentTypeResponse, IGetDropdownAssignmentTypeRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/call/dropdown/assignment-type`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    createCall: build.mutation<ICreateCallResponse, ICreateCallRequest>({
      query: ({ projectId, body }) => ({
        url: `/project/${projectId}/call`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ projectId, shouldUpdateCache = true, ...patch }, { dispatch, queryFulfilled }) {
        if (!shouldUpdateCache) return
        try {
          const { data: createdData } = await queryFulfilled
          const createdItem = createdData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              draft.data.unshift(createdItem)
            }),
          )

          dispatch(callsApi.util.invalidateTags(['ProjectsDashboard', { type: 'Calls', id: 'CALL_NEXT_NUMBER' }]))
        } catch {}
      },
    }),
    importFilesToCall: build.mutation<IImportFilesToCallResponse, IImportFilesToCallRequest>({
      query: ({ projectId, callId, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/project/${projectId}/call/${callId}/attachment`,
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted(
        { projectId, callId, shouldUpdateCache = true, newCall, ...patch },
        { dispatch, queryFulfilled },
      ) {
        if (!shouldUpdateCache) return
        try {
          const { data: createdData } = await queryFulfilled
          const createdItem = createdData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              draft.data.unshift(createdItem)
            }),
          )
        } catch {
          if (newCall) {
            dispatch(
              callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
                draft.data.unshift(newCall)
              }),
            )
          }
        } finally {
          dispatch(callsApi.util.invalidateTags(['ProjectsDashboard', { type: 'Calls', id: 'CALL_NEXT_NUMBER' }]))
        }
      },
    }),
    deleteFilesFromCall: build.mutation<IImportFilesToCallResponse, IDeleteFilesFromCallRequest>({
      query: ({ projectId, callId, filesIds }) => ({
        url: `/project/${projectId}/call/${callId}/attachment`,
        method: 'DELETE',
        params: { img: filesIds },
      }),
    }),
    getCallById: build.query<IGetCallByIdResponse, IGetCallByIdRequest>({
      query: ({ projectId, callId }) => ({
        url: `/project/${projectId}/call/${callId}`,
        method: 'GET',
      }),
      providesTags: ['Calls', { type: 'Calls', id: 'CALL_BY_ID' }],
    }),
    editCall: build.mutation<IEditCallResponse, IEditCallRequest>({
      query: ({ projectId, callId, body, ...params }) => ({
        url: `/project/${projectId}/call/${callId}`,
        method: 'PUT',
        params,
        body,
      }),
      async onQueryStarted({ projectId, callId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled
          const updatedItem = updatedData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)
              draft.data[updatedItemIndex] = updatedItem
            }),
          )

          dispatch(
            callsApi.util.updateQueryData('getCallById', { projectId, callId }, (draft) => {
              draft.data = updatedItem
            }),
          )
        } catch {
          dispatch(callsApi.util.invalidateTags([{ type: 'Calls', id: 'CALL_BY_ID' }]))
        }
      },
      invalidatesTags: ['ProjectsDashboard', 'CallsAudit'],
    }),
    editCallStatus: build.mutation<IChangeCallStatusResponse, IChangeCallStatusRequest>({
      query: ({ projectId, callId, status, ...params }) => ({
        url: `/project/${projectId}/call/${callId}/status`,
        method: 'POST',
        params,
        body: { status },
      }),
      async onQueryStarted({ projectId, callId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled
          const updatedItem = updatedData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)
              draft.data[updatedItemIndex] = updatedItem
            }),
          )

          dispatch(
            callsApi.util.updateQueryData('getCallById', { projectId, callId }, (draft) => {
              draft.data = updatedItem
            }),
          )
        } catch {}
      },
      invalidatesTags: ['ProjectsDashboard', 'CallsAudit'],
    }),
    deleteCall: build.mutation<any, IDeleteCallRequest>({
      query: ({ projectId, callId }) => ({
        url: `/project/${projectId}/call/${callId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectId, callId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              draft.data = draft.data.filter((item) => item.id !== callId)
            }),
          )
        } catch {}
      },
      invalidatesTags: ['ProjectsDashboard'],
    }),
    getCallAudit: build.query<GetCallAuditResponse, GetCallAuditRequest>({
      query: ({ projectId, callId, ...params }) => ({
        url: `audit/${projectId}/call/${callId}`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          callId: queryArgs.callId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['CallsAudit'],
    }),
    //
    getDropdownInspectionResponsible: build.query<
      IGetDropdownInspectionResponsibleResponse,
      IGetDropdownResponsibleUserRequest
    >({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/call/dropdown/responsible`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Users', 'References'],
    }),
    editInspection: build.mutation<IEditInspectionResponse, IEditInspectionRequest>({
      query: ({ projectId, callId, body }) => ({
        url: `/project/${projectId}/call/${callId}/inspection/update`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ projectId, callId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled
          const updatedItem = updatedData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)
              draft.data[updatedItemIndex] = updatedItem
            }),
          )

          dispatch(
            callsApi.util.updateQueryData('getCallById', { projectId, callId }, (draft) => {
              draft.data = updatedItem
            }),
          )
        } catch {}
      },
      invalidatesTags: ['ProjectsDashboard', 'CallsAudit'],
    }),
    repeatInspection: build.mutation<IEditInspectionResponse, IRepeatInspectionRequest>({
      query: ({ projectId, callId, body, ...params }) => ({
        url: `/project/${projectId}/call/${callId}/inspection/repeat`,
        method: 'POST',
        params,
        body: { description: body.description },
      }),
      async onQueryStarted({ projectId, callId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled
          const updatedItem = updatedData.data

          dispatch(
            callsApi.util.updateQueryData('getCalls', { projectId }, (draft) => {
              const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)
              draft.data[updatedItemIndex] = updatedItem
            }),
          )

          dispatch(
            callsApi.util.updateQueryData('getCallById', { projectId, callId }, (draft) => {
              draft.data = updatedItem
            }),
          )
        } catch {}
      },
      invalidatesTags: ['ProjectsDashboard', 'CallsAudit'],
    }),
    checkCallForEmailNotificationInTable: build.query<
      ICheckCallForEmailNotificationResponse,
      ICheckCallForEmailNotificationInTableRequest
    >({
      query: ({ projectId, callId, body }) => ({
        url: `/project/${projectId}/call/${callId}/email-check/call-status`,
        method: 'POST',
        body,
      }),
    }),
    checkCallForEmailNotificationInForm: build.query<
      ICheckCallForEmailNotificationResponse,
      ICheckCallForEmailNotificationInFormRequest
    >({
      query: ({ projectId, callId, body }) => ({
        url: `/project/${projectId}/call/${callId}/email-check/call-update`,
        method: 'POST',
        body,
      }),
    }),
    getCallColumnSettings: build.query<ICallColumnSetting[], void>({
      query: () => ({
        url: '/users/my/stored-data/get',
        method: 'GET',
        params: { key: 'callColumnSettings' },
      }),
      transformResponse: (response: IGetCallColumnSettingsResponse | undefined) => {
        return response ? JSON.parse(response.value) : undefined
      },
      providesTags: ['CallColumnSettings'],
    }),
    setCallColumnSettings: build.mutation<ISetCallColumnColumnSettingsResponse, ISetCallColumnColumnSettingsRequest>({
      query: ({ columnSettings }) => ({
        url: '/users/my/stored-data/save',
        method: 'POST',
        params: { key: 'callColumnSettings' },
        body: { value: JSON.stringify(columnSettings) },
      }),
      invalidatesTags: ['CallColumnSettings'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCallsQuery,
  useGetNextCallNumberQuery,
  useGetDropdownResponsibleCompaniesQuery,
  useLazyGetDropdownResponsibleUserQuery,
  useGetDropdownAssignmentTypeQuery,
  useCreateCallMutation,
  useImportFilesToCallMutation,
  useDeleteFilesFromCallMutation,
  useGetCallByIdQuery,
  useEditCallMutation,
  useDeleteCallMutation,
  useGetCallAuditQuery,
  useEditCallStatusMutation,
  useGetDropdownInspectionResponsibleQuery,
  useEditInspectionMutation,
  useRepeatInspectionMutation,
  useLazyCheckCallForEmailNotificationInTableQuery,
  useLazyCheckCallForEmailNotificationInFormQuery,
  useGetCallColumnSettingsQuery,
  useSetCallColumnSettingsMutation,
} = callsApi
