import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const MailFailedIcon: FC<IconProps> = (props) => {
  return (
    <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.8 0H1.2C0.54 0 0.00599999 0.538043 0.00599999 1.19565L0 8.36957C0 9.02717 0.54 9.56522 1.2 9.56522H6.24922C6.0885 9.16242 6 8.72213 6 8.26087C6 6.33988 7.53502 4.78261 9.42857 4.78261C10.4526 4.78261 11.3718 5.23805 12 5.96015V1.19565C12 0.538043 11.46 0 10.8 0ZM6 5.38043L10.8 2.3913V1.19565L6 4.18478L1.2 1.19565V2.3913L6 5.38043Z'
        fill='#F46B6B'
      />
      <path
        d='M11.1429 7.00523L9.90516 8.26087L11.1429 9.51651L10.6663 10L9.42857 8.74436L8.19087 10L7.71429 9.51651L8.95199 8.26087L7.71429 7.00523L8.19087 6.52174L9.42857 7.77738L10.6663 6.52174L11.1429 7.00523Z'
        fill='#F46B6B'
      />
    </svg>
  )
}
