import { DocumentSideBar } from './components/DocumentSideBar'
import { Stack } from '@mui/material'
import { SpecialZoomLevel } from '@react-pdf-viewer/core'
import { useGetDocumentByIdQuery } from 'api/documents'
import { useGetProjectByIdQuery } from 'api/projects'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Header } from 'components/Header'
import PdfViewer from 'components/PdfViewer/PdfViewer'
import { Progress } from 'components/Progress'
import { uiContext } from 'contexts/ui'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { MainContainer } from 'layouts/MainLayout'
import { DocumentManagment } from 'pages/Documents/components/DocumentManagment'
import { DocumentsLocationState } from 'pages/Documents/components/DocumentsTable/DocumentsTable.types'
import { getEmptyPageData } from 'pages/Projects'
import { Container } from 'pages/RegulationDoc/RegulationDoc.styles'
import { HeaderDocument } from 'pages/RegulationDoc/components/HeaderDocument/HeaderDocument'
import { FC, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { downloadMedia } from 'utils/downloadMedia'

export const Document: FC = () => {
  const cUI = useContext(uiContext)
  const { projectId: projectIdString, docId: docIdString } = useParams()
  const projectId = Number(projectIdString)
  const docId = Number(docIdString)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { data: project } = useGetProjectByIdQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )

  // Location
  const location = useLocation()
  const locationState: Pick<DocumentsLocationState, 'locationPage'> = (location?.state as DocumentsLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { locationPage } = savedLocationState || {}

  const { data: doc, isFetching } = useGetDocumentByIdQuery({ projectId, docId })
  const fileLink = doc?.file?.link || ''
  let fileName: any = doc?.file?.key.split('/')
  fileName = fileName?.[fileName.length - 1]

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => cUI.setShowSearch(true)
  }, [])

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      { title: project?.data.shortName || '' },
      { title: 'Документы' },
      { title: `${doc?.type.title} ${doc?.number}` || '' },
    ],
    [doc, project],
  )

  const onDownloadFile = () => {
    downloadMedia(fileLink)
  }

  const emptyFilteredData: EmptyPageData = getEmptyPageData(<>Файл отсутствует.</>)

  const onDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const onReturnClick = () => {
    navigate(`/project/${projectId}/documents`, { state: { locationRowId: docId, locationPage } })
  }

  return isFetching ? (
    <Progress />
  ) : (
    <>
      <DocumentSideBar doc={doc!} onDrawerOpen={onDrawerOpen} onReturnClick={onReturnClick} />
      <MainContainer>
        <Header hideLogo />
        <Container>
          {isUserHighAccess && <HeaderDocument onReturnClick={onReturnClick} onEditClick={onDrawerOpen} />}
          {fileLink ? (
            <PdfViewer
              fileUrl={fileLink}
              title={fileName}
              onDownload={onDownloadFile}
              onDelete={() => {}}
              rootViewerProps={{ defaultScale: SpecialZoomLevel.ActualSize }}
              disableDelete
            />
          ) : (
            <Stack height='100%' flexDirection='row' width='100%'>
              <EmptyPage data={emptyFilteredData} forFilter />
            </Stack>
          )}
        </Container>

        <DocumentManagment open={isDrawerOpen} onClose={onDrawerClose} document={doc} />
      </MainContainer>
    </>
  )
}
