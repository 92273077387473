import { StatisticalChartProps } from './StatisticalChart.types'
import { Stack } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { theme } from 'styles/theme'
import {
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
} from 'utils/constants'

export const StatisticalChart: FC<StatisticalChartProps> = ({ chartData }) => {
  const { outerData, innerData } = chartData || {}

  const data = {
    labels: ['Создано', 'Снято', 'Направлено', 'Исполнено'],
    datasets: [
      {
        label: ' Всего',
        data: outerData,
        backgroundColor: [
          theme.palette.legends[PRESCRIPTION_CREATED_STATUS_COLOR],
          theme.palette.legends[PRESCRIPTION_DISCARDED_STATUS_COLOR],
          theme.palette.legends[PRESCRIPTION_SENT_STATUS_COLOR],
          theme.palette.legends[PRESCRIPTION_COMPLETE_STATUS_COLOR],
        ],
      },
      {
        label: ' Просрочено',
        data: innerData,
        backgroundColor: [theme.palette.error.main, 'transparent'],
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    events: [],
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
  }

  return (
    <Stack height='160px' width='160px'>
      <Doughnut data={data} options={options} />
    </Stack>
  )
}
