import { api } from '../api'
import {
  BindRemarksRequest,
  BindRemarksResponse,
  CheckPrescriptionForEmailNotificationRequest,
  CheckPrescriptionForEmailNotificationResponse,
  CheckPrescriptionForFilterSatisfactionRequest,
  CheckPrescriptionForFilterSatisfactionResponse,
  CheckPrescriptionStatusForEmailNotificationRequest,
  CheckPrescriptionStatusForEmailNotificationResponse,
  CreateEliminationResultRequest,
  CreateEliminationResultResponse,
  CreatePrescriptionRequest,
  CreatePrescriptionResponse,
  DeleteEliminationResultsRequest,
  DeleteEliminationResultsResponse,
  DeleteFilesFromActsRequest,
  DeleteFilesFromActsResponse,
  DeleteFilesFromPrescriptionRequest,
  DeleteFilesFromPrescriptionResponse,
  DeletePrescriptionRequest,
  DeletePrescriptionResponse,
  DownloadPrescriptionsTableRequest,
  DownloadPrescriptionsTableResponse,
  EditPrescriptionRequest,
  EditPrescriptionResponse,
  EditPrescriptionStatusRequest,
  EditPrescriptionStatusResponse,
  FormPrescriptionRequest,
  FormPrescriptionResponse,
  GetBindObjectsRequest,
  GetBindObjectsResponse,
  GetBindRemarksRequest,
  GetBindRemarksResponse,
  GetColumnSettingsRequest,
  GetColumnSettingsResponse,
  GetDropdownCompanySendersResponse,
  GetDropdownExecutionControlRequest,
  GetDropdownExecutionControlResponse,
  GetDropdownPrescriptionContractorsResponse,
  GetDropdownReceiversResponse,
  GetDropdownRecieversRequest,
  GetDropdownSendersRequest,
  GetDropdownSendersResponse,
  GetEliminationResultsRequest,
  GetEliminationResultsResponse,
  GetNextEliminationResultNumberRequest,
  GetObjectsLinkExampleResponse,
  GetPrescriptionAuditRequest,
  GetPrescriptionAuditResponse,
  GetPrescriptionByIdRequest,
  GetPrescriptionByIdResponse,
  GetPrescriptionFiltredlistRequest,
  GetPrescriptionFiltredlistResponse,
  GetPrescriptionsLinkExampleRequest,
  GetPrescriptionsRequest,
  GetPrescriptionsResponse,
  GetPrescriptionsTableExportResultRequest,
  GetPrescriptionsTableExportResultResponse,
  GetRemarksByActIdRequest,
  GetRemarksByActIdResponse,
  GetSelectableRemarksRequest,
  GetSelectableRemarksResponse,
  ICheckPrescriptionNumberForUniquenessRequest,
  ICheckPrescriptionNumberForUniquenessResponse,
  IGetImportPrescriptionFilesResultResponse,
  IGetImportPrescriptionsResultRequest,
  IGetImportPrescriptionsResultResponse,
  ImportPrescriptionsRequest,
  ImportPrescriptionsResponse,
  RemoveBoundRemarkRequest,
  RemoveBoundRemarkResponse,
  ResetColumnSettingsRequest,
  ResetColumnSettingsResponse,
  SetColumnSettingsRequest,
  SetColumnSettingsResponse,
  UploadFileRequest,
  UploadFileResponse,
  UploadFilesToActsRequest,
  UploadFilesToActsResponse,
  UploadFilesToPrescriptionRequest,
  UploadFilesToPrescriptionResponse,
} from 'api/prescriptions/api.types'
import { remarksApi } from 'api/remarks'
import { GetDropdownDataRequest } from 'api/remarks/api.types'
import { ButtonColors } from 'components/Button'
import { ExcelUploadResponse } from 'core/types/global'
import { isEqual } from 'lodash'
import { setShouldResetPage } from 'store/slices/infiniteScroll'
import { NUMBER_OF_FULL_LIST } from 'utils/constants'

export const prescriptionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPrescriptions: build.query<GetPrescriptionsResponse, GetPrescriptionsRequest>({
      query: ({ projectId, sort, filter, ...params }) => {
        const appliedFilter = Object.values(filter).some((filterableValue) => filterableValue) ? filter : null
        const appliedSorting = sort?.column ? { columns: [sort] } : null

        return {
          method: 'POST',
          url: `/prescription/${projectId}/list`,
          params: params,
          body: {
            filter: appliedFilter,
            sort: appliedSorting,
          },
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const sortChanged = !isEqual(currentArg?.sort, previousArg?.sort)

        let currentFilter = { ...currentArg?.filter }
        let prevFilter = { ...previousArg?.filter }

        const filterChanged = !isEqual(currentFilter, prevFilter)

        const otherArgsChanged = sortChanged || filterChanged

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'PRESCRIPTIONS_LIST' }],
    }),

    editPrescriptionStatus: build.query<EditPrescriptionStatusResponse, EditPrescriptionStatusRequest>({
      query: ({ projectId, prescriptionId, status, dateDone, notification }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/update/status`,
        method: 'get',
        params: { status, completion: dateDone, notification },
      }),
      async onQueryStarted(
        { projectId, prescriptionId, sort, filter, ...patch },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const { data: updatedPrescription } = await queryFulfilled
          const state = getState()

          // getPrescriptions
          dispatch(
            prescriptionsApi.util.updateQueryData('getPrescriptions', { projectId, sort, filter }, (draft) => {
              const updatedPrescriptionIndex = draft.data.findIndex((remark) => remark.id === updatedPrescription.id)

              if (sort?.column !== 'status') {
                if (!filter?.status?.length || filter?.status.includes(updatedPrescription.status))
                  draft.data[updatedPrescriptionIndex] = updatedPrescription
                else draft.data.splice(updatedPrescriptionIndex, 1)
              } else {
                draft.data.splice(updatedPrescriptionIndex, 1)
                const sameIndexByStatus = draft.data.findIndex(
                  (prescription) =>
                    prescription.id !== updatedPrescription.id && prescription.status === updatedPrescription.status,
                )

                if (sameIndexByStatus >= 0) {
                  draft.data.splice(sameIndexByStatus, 0, updatedPrescription)
                }
              }
            }),
          )

          // getPrescription
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))

          // getPrescriptionAudit
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTIONS_AUDIT' }]))

          // getPrescriptionFiltredlist
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTIONS_FILTER' }]))

          /* добавил для обновления дашборда проекта а так же в методы создания и удаления */
          dispatch(prescriptionsApi.util.invalidateTags(['ProjectsDashboard']))

          // Remarks
          dispatch(remarksApi.util.invalidateTags(['Remarks']))

          // Analytics
          dispatch(remarksApi.util.invalidateTags(['Analytics']))
        } catch {}
      },
      providesTags: ['Prescriptions'],
    }),
    getPrescriptionAudit: build.query<GetPrescriptionAuditResponse, GetPrescriptionAuditRequest>({
      query: ({ projectId, prescriptionId, ...params }) => ({
        url: `audit/${projectId}/prescription/${prescriptionId}`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          prescriptionId: queryArgs.prescriptionId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'PRESCRIPTIONS_AUDIT' }],
    }),
    getPrescriptionById: build.query<GetPrescriptionByIdResponse, GetPrescriptionByIdRequest>({
      query: ({ projectId, prescriptionId }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/get`,
        method: 'GET',
      }),
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }],
    }),
    getNextPrescriptionNumber: build.query<string, number>({
      query: (projectId) => ({
        url: `/prescription/${projectId}/create/number`,
        method: 'GET',
        responseHandler: 'text',
      }),
      providesTags: ['Prescriptions', 'References', { type: 'Prescriptions', id: 'PRESCRIPTION_NUMBER' }],
    }),
    checkPrescriptionNumberForUniqueness: build.query<boolean, ICheckPrescriptionNumberForUniquenessRequest>({
      query: ({ projectId, prescriptionNumber }) => ({
        url: `/prescription/${projectId}/create/is-number-taken`,
        method: 'POST',
        body: { number: prescriptionNumber },
      }),
    }),
    getDropdownCompanySenders: build.query<GetDropdownCompanySendersResponse, GetDropdownDataRequest>({
      query: ({ projectId }) => ({
        url: `/prescription/${projectId}/create/dropdown/sender-company`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['Projects'],
    }),
    getDropdownSenders: build.query<GetDropdownSendersResponse, GetDropdownSendersRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/prescription/${projectId}/create/dropdown/sender`,
        params: { page: 1, num: 99999, ...params },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDropdownPrescriptionContractors: build.query<GetDropdownPrescriptionContractorsResponse, GetDropdownDataRequest>(
      {
        query: ({ projectId }) => ({
          url: `/prescription/${projectId}/create/dropdown/contractor`,
          params: { page: 1, num: 99999 },
          method: 'GET',
        }),
        providesTags: ['References'],
      },
    ),
    getDropdownReceivers: build.query<GetDropdownReceiversResponse, GetDropdownRecieversRequest>({
      query: ({ projectId, contractor }) => ({
        url: `/prescription/${projectId}/create/dropdown/receiver`,
        params: { page: 1, num: 99999, contractor },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getDropdownExecutionControl: build.query<GetDropdownExecutionControlResponse, GetDropdownExecutionControlRequest>({
      query: ({ projectId, contractor }) => ({
        url: `/prescription/${projectId}/create/dropdown/execution-control`,
        params: { page: 1, num: 99999, contractor },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    createPrescription: build.mutation<CreatePrescriptionResponse, CreatePrescriptionRequest>({
      query: ({ projectId, body }) => ({
        url: `/prescription/${projectId}/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Analytics'],
      // invalidatesTags: ['ProjectsDashboard', 'Analytics'],
    }),
    editPrescription: build.mutation<EditPrescriptionResponse, EditPrescriptionRequest>({
      query: ({ projectId, prescriptionId, body, notification }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/update`,
        method: 'PUT',
        params: { notification },
        body,
      }),
      async onQueryStarted(
        { projectId, prescriptionId, sort, filter, ...patch },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const { data } = await queryFulfilled
          const { data: updatedPrescription } = data || {}

          // getPrescriptions
          dispatch(
            prescriptionsApi.util.updateQueryData('getPrescriptions', { projectId, sort, filter }, (draft) => {
              const updatedPrescriptionIndex = draft.data.findIndex(
                (prescription) => prescription.id === updatedPrescription.id,
              )
              draft.data[updatedPrescriptionIndex] = updatedPrescription
            }),
          )

          // Remarks
          dispatch(remarksApi.util.invalidateTags(['Remarks']))

          // getPrescriptionAudit
          dispatch(
            prescriptionsApi.util.invalidateTags([
              { type: 'Prescriptions', id: 'PRESCRIPTIONS_AUDIT' },
              { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' },
            ]),
          )

          dispatch(remarksApi.util.invalidateTags(['Analytics']))
          dispatch(prescriptionsApi.util.invalidateTags(['ProjectsDashboard']))
        } catch {}
      },
    }),
    uploadFilesToPrescription: build.mutation<UploadFilesToPrescriptionResponse, UploadFilesToPrescriptionRequest>({
      query: ({ projectId, prescriptionId, files }) => {
        const formData = new FormData()
        files.forEach((file) => {
          formData.append('file', file)
        })

        return {
          url: `/prescription/${projectId}/${prescriptionId}/update/attachments/add`,
          method: 'POST',
          body: formData,
        }
      },
      // invalidatesTags: ['Prescriptions'],
    }),
    deleteFilesFromPrescription: build.mutation<
      DeleteFilesFromPrescriptionResponse,
      DeleteFilesFromPrescriptionRequest
    >({
      query: ({ projectId, prescriptionId, filesIds }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/update/attachments/remove`,
        params: { img: filesIds },
        method: 'DELETE',
      }),
      // invalidatesTags: ['Prescriptions'],
    }),
    deletePrescription: build.mutation<DeletePrescriptionResponse, DeletePrescriptionRequest>({
      query: ({ projectId, prescriptionId }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Prescriptions', 'ProjectsDashboard', 'Remarks'],
    }),
    formPrescription: build.query<FormPrescriptionResponse, FormPrescriptionRequest>({
      query: ({ projectId, prescriptionId }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/print`,
        method: 'GET',
      }),
      providesTags: ['Prescriptions'],
    }),

    getBindObjects: build.query<GetBindObjectsResponse, GetBindObjectsRequest>({
      query: ({ projectId, body, preloadShortcomings, prescriptionId, ...params }) => ({
        url: `/prescription/${projectId}/create/shortcoming/object`,
        method: 'POST',
        params: {
          id: prescriptionId,
          'preload-shortcomings': NUMBER_OF_FULL_LIST,
          ...params,
        },
        body,
      }),
      providesTags: ['Remarks', 'Prescriptions'],
    }),
    getBindRemarks: build.query<GetBindRemarksResponse, GetBindRemarksRequest>({
      query: ({ projectId, body, prescriptionId, ...params }) => ({
        url: `/prescription/${projectId}/create/shortcoming`,
        method: 'POST',
        params: {
          id: prescriptionId,
          page: 1,
          num: 99999,
          ...params,
        },
        body,
      }),
      providesTags: ['Remarks', 'Prescriptions'],
    }),
    bindRemarks: build.mutation<BindRemarksResponse, BindRemarksRequest>({
      query: ({ projectId, prescriptionId, body, ...params }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/update/shortcoming/add`,
        method: 'PUT',
        params,
        body,
      }),
      // async onQueryStarted({ ...patch }, { dispatch, queryFulfilled, getState }) {
      //   try {
      //     // Remarks
      //     dispatch(remarksApi.util.invalidateTags(['Remarks']))

      //     dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_NUMBER' }]))
      //   } catch {}
      // },
    }),
    // removeBoundRemark: build.mutation<RemoveBoundRemarkResponse, RemoveBoundRemarkRequest>({
    //   query: ({ projectId, prescriptionId, body }) => ({
    //     url: `/prescription/${projectId}/${prescriptionId}/update/shortcoming/remove`,
    //     method: 'PUT',
    //     body
    //   }),
    //   invalidatesTags: ['Prescriptions'],
    // }),

    getColumnSettings: build.query<GetColumnSettingsResponse, GetColumnSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/prescription/${projectId}/list/view/load`,
        method: 'GET',
      }),
      providesTags: ['PrescriptionsTableColumns'],
    }),
    setColumnSettings: build.mutation<SetColumnSettingsResponse, SetColumnSettingsRequest>({
      query: ({ projectId, body }) => ({
        url: `/prescription/${projectId}/list/view/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PrescriptionsTableColumns'],
    }),
    resetColumnSettings: build.mutation<ResetColumnSettingsResponse, ResetColumnSettingsRequest>({
      query: ({ projectId }) => ({
        url: `/prescription/${projectId}/list/view/reset`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PrescriptionsTableColumns'],
    }),

    downloadPrescriptionsTable: build.mutation<DownloadPrescriptionsTableResponse, DownloadPrescriptionsTableRequest>({
      query: ({ projectId, date, sort, filter }) => {
        const appliedFilter = Object.values(filter || {}).some((filterableValue) => filterableValue) ? filter : null
        const appliedSorting = sort?.column ? { columns: [sort] } : null

        return {
          url: `/prescription/${projectId}/export`,
          method: 'POST',
          body: {
            timestamp: date,
            options:
              appliedFilter || appliedSorting
                ? {
                    filter: appliedFilter,
                    sort: appliedSorting,
                  }
                : null,
          },
        }
      },
    }),
    getPrescriptionsTableExportResult: build.query<
      GetPrescriptionsTableExportResultResponse,
      GetPrescriptionsTableExportResultRequest
    >({
      query: ({ projectId, exportId }) => ({
        url: `/prescription/${projectId}/export/result`,
        params: { id: exportId },
        method: 'GET',
      }),
    }),
    getPrescriptionFiltredlist: build.query<GetPrescriptionFiltredlistResponse, GetPrescriptionFiltredlistRequest>({
      query: ({ projectId, columnId, filter, ...params }) => {
        return {
          url: `/prescription/${projectId}/list/filters/${columnId}`,
          method: 'POST',
          params: params,
          body: filter,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        currentCache.total = newData.total

        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query
        const columnIdChanged = currentArg?.columnId !== previousArg?.columnId
        // const filterChanged = !isEqual([...currentArg?.filter[currentArg?.columnId] || []].sort(), [...previousArg?.filter[previousArg?.columnId] || []].sort())

        const filterChanged = JSON.stringify(currentArg?.filter) !== JSON.stringify(previousArg?.filter)

        const otherArgsChanged = queryChanged || columnIdChanged || filterChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || queryChanged || columnIdChanged || filterChanged
        // return (pageChanged && !filterChanged) || queryChanged || columnIdChanged
      },
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'PRESCRIPTIONS_FILTER' }],
    }),
    checkPrescriptionStatusForEmailNotification: build.query<
      CheckPrescriptionStatusForEmailNotificationResponse,
      CheckPrescriptionStatusForEmailNotificationRequest
    >({
      query: ({ projectId, prescriptionId, status, completion }) => {
        return {
          url: `/prescription/${projectId}/${prescriptionId}/update/status/email-check`,
          method: 'GET',
          params: { status, completion },
        }
      },
    }),
    checkPrescriptionForEmailNotification: build.query<
      CheckPrescriptionForEmailNotificationResponse,
      CheckPrescriptionForEmailNotificationRequest
    >({
      query: ({ projectId, prescriptionId, body }) => {
        return {
          url: `/prescription/${projectId}/${prescriptionId}/update/email-check`,
          method: 'PUT',
          body: body,
        }
      },
    }),
    checkPrescriptionForFilterSatisfaction: build.query<
      CheckPrescriptionForFilterSatisfactionResponse,
      CheckPrescriptionForFilterSatisfactionRequest
    >({
      query: ({ projectId, prescriptionId, filter }) => {
        return {
          url: `/prescription/${projectId}/${prescriptionId}/get`,
          method: 'POST',
          body: filter,
        }
      },
      async onQueryStarted(
        { projectId, prescriptionId, filter, sort, ...patch },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const { data } = await queryFulfilled
          const { prescription: updatedPrescription, pass } = data || {}

          // getPrescriptions
          dispatch(
            prescriptionsApi.util.updateQueryData('getPrescriptions', { projectId, sort, filter }, (draft) => {
              if (pass) return

              const updatedPrescriptionIndex = draft.data.findIndex(
                (prescription) => prescription.id === updatedPrescription.id,
              )
              draft.data.splice(updatedPrescriptionIndex, 1)
            }),
          )
        } catch {}
      },
    }),

    // Elimination Result
    getEliminationResults: build.query<GetEliminationResultsResponse, GetEliminationResultsRequest>({
      query: ({ projectId, prescriptionId, ...params }) => ({
        method: 'GET',
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/list`,
        params: params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          prescriptionId: queryArgs.prescriptionId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const otherArgsChanged = false

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    getNextEliminationResultNumber: build.query<string, GetNextEliminationResultNumberRequest>({
      query: ({ projectId, prescriptionId }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/create/number`,
        method: 'GET',
        responseHandler: 'text',
      }),
      providesTags: ['Prescriptions', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    createEliminationResult: build.mutation<CreateEliminationResultResponse, CreateEliminationResultRequest>({
      query: ({ projectId, prescriptionId, body }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/create`,
        method: 'POST',
        body,
      }),
    }),
    deleteEliminationResults: build.mutation<DeleteEliminationResultsResponse, DeleteEliminationResultsRequest>({
      query: ({ projectId, prescriptionId, docIds }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/delete`,
        params: { ids: docIds },
        method: 'DELETE',
      }),
    }),
    getEliminationResultSelectableRemarks: build.query<GetSelectableRemarksResponse, GetSelectableRemarksRequest>({
      query: ({ projectId, prescriptionId, ...params }) => ({
        method: 'GET',
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/create/shortcomings`,
        params: params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          prescriptionId: queryArgs.prescriptionId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const otherArgsChanged = false

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Remarks', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    getRemarksByActId: build.query<GetRemarksByActIdResponse, GetRemarksByActIdRequest>({
      query: ({ projectId, prescriptionId, actId, ...params }) => ({
        method: 'GET',
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/${actId}/get/shortcomings`,
        params: params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          actId: queryArgs.actId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const otherArgsChanged = false

        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Remarks', { type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }],
    }),
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequest>({
      query: ({ type, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/media/save`,
          method: 'POST',
          params: { type },
          body: formData,
        }
      },
    }),
    uploadFilesToActs: build.mutation<UploadFilesToActsResponse, UploadFilesToActsRequest>({
      query: ({ projectId, prescriptionId, internalFilesForCreate }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/attachment/add/batch`,
        method: 'POST',
        body: {
          actToMediaList: internalFilesForCreate,
        },
      }),
    }),
    deleteFilesFromActs: build.mutation<DeleteFilesFromActsResponse, DeleteFilesFromActsRequest>({
      query: ({ projectId, prescriptionId, filesIdsToDelete }) => ({
        url: `/prescription/${projectId}/${prescriptionId}/completion-act/attachment/delete/batch`,
        method: 'POST',
        body: {
          actToMediaList: filesIdsToDelete,
        },
      }),
    }),
    getPrescriptionsLinkExample: build.query<GetObjectsLinkExampleResponse, GetPrescriptionsLinkExampleRequest>({
      query: ({ projectId }) => ({
        url: `/prescription/${projectId}/import/example/link`,
        method: 'GET',
      }),
    }),
    importPrescriptions: build.mutation<ImportPrescriptionsResponse, ImportPrescriptionsRequest>({
      query: ({ projectId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/prescription/${projectId}/import`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    getImportPrescriptionsResult: build.query<ExcelUploadResponse, IGetImportPrescriptionsResultRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/prescription/${projectId}/import/result`,
        method: 'GET',
        params,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(setShouldResetPage({ table: 'prescriptions', shouldResetPage: true }))
          dispatch(setShouldResetPage({ table: 'remarks', shouldResetPage: true }))
        } catch {}
      },
    }),
    // if prescriptions exist return true, else return false
    getPrescriptionsHavingStatus: build.query<boolean, number>({
      query: (projectId) => ({
        url: `/prescription/${projectId}/list/experimental?num=1`,
        method: 'POST',
        body: {
          filter: null,
          sort: null,
        },
      }),
      transformResponse: (response: GetPrescriptionsResponse) => {
        return !!response.data.length
      },
      providesTags: ['Prescriptions'],
    }),
    importPrescriptionFiles: build.mutation<ImportPrescriptionsResponse, ImportPrescriptionsRequest>({
      query: ({ projectId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/project/${projectId}/import/batch/upload`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    getImportPrescriptionFilesResult: build.query<ExcelUploadResponse, IGetImportPrescriptionsResultRequest>({
      query: ({ ...params }) => ({
        url: `/project/import/batch/upload/result`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: IGetImportPrescriptionFilesResultResponse) => {
        return {
          create: response.create,
          error: response.details.map((item) => ({
            iconColor: item.level.toLocaleLowerCase() as ButtonColors,
            col: null,
            message: `${item.source.type === 'FILE' ? 'Файл' : 'Папка'}: ${item.source.value}`,
            row: null,
            title: item.description,
            type: 'unknown',
          })),
          success: true,
          skip: response.skip,
          update: 0,
        }
      },
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))
          dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Remarks', id: 'REMARK_BY_ID' }]))
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPrescriptionsQuery,
  useLazyEditPrescriptionStatusQuery,
  useGetPrescriptionAuditQuery,
  useGetPrescriptionByIdQuery,
  useGetNextPrescriptionNumberQuery,
  useLazyCheckPrescriptionNumberForUniquenessQuery,
  useGetDropdownCompanySendersQuery,
  useLazyGetDropdownSendersQuery,
  useGetDropdownPrescriptionContractorsQuery,
  useLazyGetDropdownReceiversQuery,
  useLazyGetDropdownExecutionControlQuery,
  useCreatePrescriptionMutation,
  useEditPrescriptionMutation,
  useUploadFilesToPrescriptionMutation,
  useDeleteFilesFromPrescriptionMutation,
  useDeletePrescriptionMutation,
  useLazyFormPrescriptionQuery,
  useLazyCheckPrescriptionForFilterSatisfactionQuery,
  useGetNextEliminationResultNumberQuery,
  useCreateEliminationResultMutation,
  useGetEliminationResultsQuery,
  useDeleteEliminationResultsMutation,
  useGetEliminationResultSelectableRemarksQuery,
  useGetRemarksByActIdQuery,
  useUploadFileMutation,
  useUploadFilesToActsMutation,
  useDeleteFilesFromActsMutation,

  useGetBindObjectsQuery,
  useGetBindRemarksQuery,
  useBindRemarksMutation,

  useGetColumnSettingsQuery,
  useSetColumnSettingsMutation,
  useResetColumnSettingsMutation,

  useDownloadPrescriptionsTableMutation,
  useLazyGetPrescriptionsTableExportResultQuery,
  useLazyCheckPrescriptionForEmailNotificationQuery,
  useLazyCheckPrescriptionStatusForEmailNotificationQuery,
  useGetPrescriptionFiltredlistQuery,
  useLazyGetPrescriptionsLinkExampleQuery,
  useImportPrescriptionsMutation,
  useLazyGetImportPrescriptionsResultQuery,
  useGetPrescriptionsHavingStatusQuery,
  useImportPrescriptionFilesMutation,
  useLazyGetImportPrescriptionFilesResultQuery,
} = prescriptionsApi
