import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react'
import { IProjectNotificationSettingsProps } from './ProjectNotificationSettings.types'
import { Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { NotificationSwitchWrapper, SubmitIconButton } from './ProjectNotificationSettings.styles'
import { Check as CheckIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Button } from 'components/Button'
import { FieldForm } from 'components/FieldForm'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { projectNotificationValidationSchema } from './ProjectNotificationSettings.validation'
import { IProjectNotificationSettings } from 'api/projects/types'
import { useGetProjectNotificationSettingsQuery, useSetProjectNotificationSettingsMutation } from 'api/projects'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { Progress } from 'components/Progress'

export const ProjectNotificationSettings: FC<IProjectNotificationSettingsProps> = ({
  project,
  setDirty,
  onResetSettings,
}) => {
  const { id: projectId } = project
  const { data: projectNotificationSettings, isLoading } = useGetProjectNotificationSettingsQuery({ projectId })
  const [
    setProjectNotificationSettings,
    { isLoading: isSetSettingsLoading, ...setProjectNotificationSettingsResponse },
  ] = useSetProjectNotificationSettingsMutation()

  const { enqueueSnackbar } = useSnackbar()

  const { email, notifyCalls = false } = projectNotificationSettings || {}

  const initialValues = useMemo(() => {
    const data: Pick<IProjectNotificationSettings, 'email'> = { email: email || '' }

    return data
  }, [projectNotificationSettings])

  const onSubmit = useCallback((values: Pick<IProjectNotificationSettings, 'email'>, notifyCalls: boolean) => {
    setProjectNotificationSettings({ projectId, body: { email: values.email, notifyCalls } })
  }, [])

  const { formik } = useForm({
    validationSchema: projectNotificationValidationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => onSubmit(values, notifyCalls),
  })

  const { values, dirty, isValid, setFieldValue, resetForm } = formik

  const submitButtonDisabled = !dirty || !isValid

  const onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSubmit(values, e.target.checked)
  }

  const onResetSettingsClick = () => {
    onResetSettings(() => onSubmit({ email: null }, false))
  }

  useEffect(() => {
    setDirty(dirty)
  }, [dirty])

  useEffect(() => {
    resetForm()
  }, [])

  useMutationHandlers(
    setProjectNotificationSettingsResponse,
    (data) => {
      enqueueSnackbar('Данные успешно изменены.', { variant: 'success' })
      if (!data.email) setFieldValue('email', '')
    },
    () => enqueueSnackbar('Произошла ошибка.', { variant: 'error' }),
  )

  if (isLoading)
    return (
      <Stack height='100%'>
        <Progress />
      </Stack>
    )

  return (
    <FormikProvider value={formik}>
      <Stack component={Form} padding='0 20px' spacing={2.5}>
        <Stack spacing={1.25}>
          <Typography variant='body2' color={theme.palette.text.dark} textAlign='center'>
            Для активации переключателя укажите и сохраните e-mail.
          </Typography>

          <FieldItem title='E-mail' isRequired={true} direction='column' spacing={0.5}>
            <Stack direction='row' spacing={1.25}>
              <FieldForm
                version='project'
                name='email'
                placeholder='Укажите e-mail'
                multiline
                helperText=''
                inputProps={{
                  style: {
                    padding: '10px 8px',
                  },
                }}
              />

              <SubmitIconButton
                icon
                type='submit'
                color='success'
                loading={isSetSettingsLoading}
                _disabled={submitButtonDisabled}
              >
                {!isSetSettingsLoading && <CheckIcon color={submitButtonDisabled ? 'disabled' : 'success'} />}
              </SubmitIconButton>

              {email && (
                <Button onClick={onResetSettingsClick} icon color='error' variant='outlined'>
                  <DeleteIcon fontSize='medium' color='error' />
                </Button>
              )}
            </Stack>
          </FieldItem>
        </Stack>

        <NotificationSwitchWrapper>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='body2' color={theme.palette.text.dark} fontWeight={500}>
              Уведомления о вызовах на осмотр
            </Typography>

            <FieldForm
              version='switch'
              name=''
              checkboxData={{
                checked: notifyCalls,
                onChange: onSwitchChange,
                label: '',
              }}
              disabled={dirty || !isValid || !values.email}
            />
          </Stack>

          <Typography variant='body2' color={theme.palette.secondary.dark}>
            Направление уведомлений о создании и изменении статуса вызова при отсутствии Ответственного за осмотр.
          </Typography>
        </NotificationSwitchWrapper>
      </Stack>
    </FormikProvider>
  )
}
