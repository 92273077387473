import { FC } from 'react'
import { IconProps } from '../types/iconProps'
import { SvgIcon } from '@mui/material'

export const MailUninformativeIcon: FC<IconProps> = (props) => {
  return (
    <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.8 0H1.2C0.54 0 0.00599999 0.538043 0.00599999 1.19565L0 8.36956C0 9.02717 0.54 9.56522 1.2 9.56522H6.24922C6.0885 9.16242 6 8.72213 6 8.26087C6 6.33988 7.53502 4.78261 9.42857 4.78261C10.4526 4.78261 11.3718 5.23805 12 5.96015V1.19565C12 0.538043 11.46 0 10.8 0ZM6 5.38043L10.8 2.3913V1.19565L6 4.18478L1.2 1.19565V2.3913L6 5.38043Z'
        fill='#7890B2'
      />
      <path
        d='M9.42857 6.52174C9.74472 6.52174 10.0547 6.61043 10.3243 6.77802C10.5938 6.9456 10.8125 7.18554 10.956 7.47132C11.0995 7.7571 11.1624 8.07757 11.1376 8.39732C11.1128 8.71707 11.0013 9.02362 10.8155 9.2831C10.6296 9.54258 10.3767 9.74488 10.0846 9.86762C9.79251 9.99036 9.47266 10.0288 9.1604 9.97859C8.84814 9.92841 8.55564 9.79161 8.31523 9.58331C8.07483 9.37501 7.89589 9.10333 7.79819 8.79829L8.61338 8.52958C8.66223 8.6821 8.7517 8.81794 8.8719 8.92209C8.9921 9.02624 9.13836 9.09464 9.29449 9.11973C9.45061 9.14481 9.61054 9.12561 9.75659 9.06424C9.90263 9.00287 10.0291 8.90173 10.122 8.77199C10.2149 8.64225 10.2707 8.48897 10.2831 8.32909C10.2955 8.16922 10.2641 8.00898 10.1923 7.86609C10.1205 7.72321 10.0112 7.60323 9.87643 7.51944C9.74164 7.43565 9.58665 7.3913 9.42857 7.3913V6.52174Z'
        fill='#7890B2'
      />
    </svg>
  )
}
