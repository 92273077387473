import { ContractorAccessesSelection } from '../../ContractorAccessesSelection'
import { UserAccessesSelection } from '../../UserAccessesSelection'
import { UserRoleSelection } from '../../UserRoleSelection'
import { UserFormData } from '../UserForm.types'
import { AccessStatusBlock } from './UserAccesses.styles'
import { UserAccessesProps } from './UserAccesses.types'
import { Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'
import { theme } from 'styles/theme'
import { MAX_WIDTH_USER_MANAGEMENT_BLOCK, MIN_WIDTH_USER_MANAGEMENT_BLOCK } from 'utils/constants'

export const UserAccesses: React.FC<UserAccessesProps> = ({ isEditUser }) => {
  const { values: formValues, isSubmitting } = useFormikContext<UserFormData>()
  const role = formValues.role
  const isProjectsSelected = !!formValues.projects?.length

  const renderAccessesBlock = useCallback(() => {
    switch (role) {
      case 'admin':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock>
              <Typography variant='body1'>Доступ ко всем проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        )
      case 'contractor':
        return <ContractorAccessesSelection />
      case 'client':
      case 'engineer_qc':
        return <UserAccessesSelection />
      case 'none':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock color={theme.palette.error.main}>
              <Typography variant='body1'>Нет доступа к проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        )
    }
  }, [role])

  return (
    <Stack
      maxWidth={MAX_WIDTH_USER_MANAGEMENT_BLOCK}
      minWidth={MIN_WIDTH_USER_MANAGEMENT_BLOCK}
      flex={1}
      spacing={4.375}
    >
      <UserRoleSelection />

      {role === 'contractor' && isProjectsSelected ? (
        <ContractorAccessesSelection />
      ) : (
        <Stack spacing={0.5}>
          <Typography variant='subtitle1' color={theme.palette.text.dark} pt={1} textAlign='start'>
            Проекты:
          </Typography>
          {renderAccessesBlock()}
        </Stack>
      )}

      <Button type='submit' disabled={isSubmitting} color='success' width='100%' style={{ alignSelf: 'end' }}>
        {isEditUser ? 'Сохранить' : 'Создать'}
      </Button>
    </Stack>
  )
}
