import { BottomBox, BottomItemButton, LogoWrapper, QcLogo, StyledDrawer, TopBox } from './AdminNavBar.styles'
import { NavBarItem } from './AdminNavBar.types'
import { AdminNavBarItem } from './AdminNavBarItem'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Divider, List, ListItemIcon, ListItemText } from '@mui/material'
import { EditDocIcon } from 'assets/icons/EditDocIcon'
import { ReferenceIcon } from 'assets/icons/ReferenceIcon'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import React, { MouseEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Person as PersonIcon, SettingsOutlined as SettingsIcon } from '@mui/icons-material'

const items: NavBarItem[] = [
  {
    label: 'Пользователи',
    icon: PersonIcon,
    link: '/administration/users',
  },
  {
    label: 'Проекты',
    icon: EditDocIcon,
    link: '/administration/projects',
  },
  {
    label: 'Справочники',
    icon: ReferenceIcon,
    link: '/administration/references',
  },
  {
    label: 'Настройки',
    icon: SettingsIcon,
    link: '/administration/settings?tab=status',
  },
]

export const AdminNavBar: React.FC = () => {
  const navigate = useNavigate()

  const _navbarIsOpen = localStorage.getItem('navBarIsOpen') === 'true'
  const [open, setOpen] = useState(_navbarIsOpen)
  const [overrideOpen, setOverrideOpen] = useState(_navbarIsOpen)

  const mouseHandler = (value: boolean) => {
    if (overrideOpen) return
    setOpen(value)
  }

  const onOverride = () => {
    setOverrideOpen((value) => {
      localStorage.setItem('navBarIsOpen', String(!value))
      return !value
    })
    setOpen(true)
  }

  const style = {
    width: overrideOpen ? '236px' : '80px',
    '> .MuiPaper-root': {
      width: open ? '236px' : '80px',
    },
  }

  const handleExitConfirm = (confirm: boolean, link: string) => {
    !overrideOpen && open && setOpen(false)

    if (confirm) {
      navigate(link)
    }
  }

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: handleExitConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onLinkClick = (e: MouseEvent, link: string) => {
    // if (location.pathname === link) return

    const isDirtyForm = !!localStorage.getItem('dirtyForm')

    if (isDirtyForm) {
      e.preventDefault()
      openConfirm(link)
    }
  }

  return (
    <StyledDrawer
      variant='permanent'
      className='navbar'
      onMouseMove={() => mouseHandler(true)}
      onMouseEnter={() => mouseHandler(true)}
      onMouseLeave={() => mouseHandler(false)}
      sx={style}
    >
      <TopBox>
        <Link to='/projects' onClick={(e) => onLinkClick(e, '/projects')}>
          <LogoWrapper>
            <QcLogo />
          </LogoWrapper>
        </Link>

        <List>
          {items.map((item) => (
            <AdminNavBarItem {...item} onClick={onLinkClick} key={item.label} />
          ))}
        </List>
      </TopBox>

      <BottomBox>
        <List>
          <Divider />
          <BottomItemButton onClick={onOverride}>
            {overrideOpen ? (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '35px !important' }} />
                </ListItemIcon>
                <ListItemText primary='Открепить меню' />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowRightIcon sx={{ fontSize: '35px !important' }} />
                </ListItemIcon>
                <ListItemText primary='Закрепить меню' />
              </>
            )}
          </BottomItemButton>
        </List>
      </BottomBox>

      <ConfirmDialog />
    </StyledDrawer>
  )
}
