import { FC } from 'react'
import { IntegrationSettingsForm } from './components/IntegrationSettingsForm'
import { IntegrationSettingsInfo } from './components/IntegrationSettingsInfo'
import { IntegrationSettingsContent } from './IntegrationSettings.styles'
import { Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'

export const IntegrationSettings: FC = () => {
  return (
    <Stack>
      <Typography
        lineHeight='normal'
        textAlign='start'
        fontSize={32}
        padding='25px 30px'
        color={theme.palette.text.secondary}
      >
        Настройки интеграции по API
      </Typography>

      <IntegrationSettingsContent>
        <IntegrationSettingsForm />

        <IntegrationSettingsInfo />
      </IntegrationSettingsContent>
    </Stack>
  )
}
