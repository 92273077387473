import { projectMediaFileExtensions } from 'core/types/file'
import { ProjectFormData } from '../../ProjectManagement.types'
import { ImageField } from '../ImageField'
import { ImageItem } from '../ImageItem'
import { ImagesTitledWrapper } from './FormRightBlock.styles'
import { Stack } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React, { FC } from 'react'

export const FormRightBlock: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFormData>()

  return (
    <Stack flex={1}>
      <ImagesTitledWrapper title='Визуализация объекта' variant='primary' contentWrapper='stack' bordered='border'>
        <FieldArray
          name='imagesForCreate'
          render={({ insert, remove }) => (
            <>
              {formValues.initialImages.length + formValues.imagesForCreate.length < 3 && (
                <ImageField insert={insert} acceptedExtensions={projectMediaFileExtensions} />
              )}
              {formValues.imagesForCreate.map((file, index) => (
                <ImageItem url={URL.createObjectURL(file)} onClick={() => remove(index)} />
              ))}
            </>
          )}
        />
        <FieldArray
          name='initialImages'
          render={({ remove }) => (
            <>
              {formValues.initialImages.map((image, index) => (
                <ImageItem
                  url={image.imageFull}
                  onClick={() => {
                    setFieldValue('imagesIdsToDelete', [...formValues.imagesIdsToDelete, image.id])
                    remove(index)
                  }}
                />
              ))}
            </>
          )}
        />
      </ImagesTitledWrapper>
    </Stack>
  )
}
