import { api } from 'api/api'
import { IStatusSettings } from 'core/types/settings'
import { ISetCustomStatusRequest } from './api.types'

export const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStatusSettings: build.query<IStatusSettings, void>({
      query: () => ({
        url: `/customize/statuses`,
        method: 'GET',
      }),
      providesTags: ['StatusSettings'],
    }),
    setCustomStatus: build.mutation<IStatusSettings, ISetCustomStatusRequest>({
      query: ({ body, ...params }) => ({
        url: `/customize/statuses`,
        method: 'POST',
        params,
        body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(settingsApi.util.invalidateTags(['StatusSettings']))
        } catch {}
      },
    }),
    resetStatusSettings: build.mutation<IStatusSettings, void>({
      query: () => ({
        url: `/customize/statuses`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(settingsApi.util.invalidateTags(['StatusSettings']))
        } catch {}
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetStatusSettingsQuery, useSetCustomStatusMutation, useResetStatusSettingsMutation } = settingsApi
