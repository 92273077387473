import {
  UploadDrawerAttention,
  UploadDrawerFormWrapper,
  UploadDrawerText,
  UploadTextWrapper,
} from './UploadDrawerForm.styles'
import { UploadDrawerFormProps } from './UploadDrawerForm.types'
import { getUploadValidation } from './UploadDrawerForm.validation'
import DownloadIcon from '@mui/icons-material/Download'
import { Box, Stack } from '@mui/material'
import { Button } from 'components/Button'
import { FileUpload } from 'components/FileUpload'
import { UploadFormData } from 'components/FileUpload'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import React, { useCallback, useEffect, useMemo } from 'react'

export const UploadDrawerForm: React.FC<UploadDrawerFormProps> = ({
  mode,
  onSubmit,
  onChange,
  onClose,
  onResultsDrawerOpen,
  text,
  attentionText,
  uploadData,
  responseData,
  loading,
  getExampleLinkQuery,
  getExampleLinkQueryParams,
  ContentBeforeFileUpload,
  showUpdatedItems,
  addedItemsText,
  updatedItemsText,
  onlySuccess,
}) => {
  const [getExampleLink, getExampleLinkResponse] = getExampleLinkQuery?.() || [{}, {}]

  const onDownload = useCallback(() => {
    getExampleLink?.(getExampleLinkQueryParams)
  }, [])

  const initialValues: UploadFormData = useMemo(
    () => ({
      files: null,
    }),
    [],
  )

  const validationUpload = useMemo(() => {
    return getUploadValidation(uploadData.formats)
  }, [uploadData])

  const { formik } = useForm({
    validationSchema: validationUpload,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      if (values?.files?.length && values.files.every((file) => file instanceof File)) {
        onSubmit(values.files)
      }

      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, dirty, isSubmitting } = formik

  useEffect(() => {
    onChange(dirty)
  }, [values])

  useMutationHandlers(getExampleLinkResponse, (data) => {
    window.location.href = data.data
  })

  return (
    <UploadDrawerFormWrapper>
      <FormikProvider value={formik}>
        <Stack spacing={5} component={Form} height='100%'>
          <UploadTextWrapper spacing={3.5}>
            <Box>
              <UploadDrawerText variant='body2'>{text}</UploadDrawerText>
            </Box>

            {attentionText && (
              <Stack>
                <UploadDrawerAttention variant='body2'>Внимание!</UploadDrawerAttention>
                <UploadDrawerText variant='body2'>{attentionText}</UploadDrawerText>
              </Stack>
            )}
          </UploadTextWrapper>

          <Stack spacing={2} height='100%' justifyContent='space-between'>
            {ContentBeforeFileUpload}

            <Stack>
              <FileUpload<null>
                name='files'
                uploadData={uploadData}
                onResultsDrawerOpen={onResultsDrawerOpen}
                loading={loading}
                responseData={responseData}
                mode={mode}
                showUpdatedItems={showUpdatedItems}
                addedItemsText={addedItemsText}
                updatedItemsText={updatedItemsText}
                onlySuccess={onlySuccess}
              />

              {getExampleLinkQuery ? (
                <Button
                  onClick={() => onDownload()}
                  variant='text'
                  startIcon={<DownloadIcon fontSize='small' style={{ marginTop: 1 }} />}
                  width='100%'
                  style={{
                    maxWidth: '100%',
                  }}
                >
                  Скачать форму документа
                </Button>
              ) : null}
            </Stack>

            <Stack direction='row' spacing={2}>
              {!responseData?.success ? (
                <Button
                  type='submit'
                  disabled={!values.files?.length || !!responseData}
                  color='success'
                  size='medium'
                  loading={loading}
                  fullWidth
                >
                  Обработать
                </Button>
              ) : null}

              <Button size='medium' fullWidth onClick={() => onClose(false)} style={{ maxWidth: '100%' }}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </UploadDrawerFormWrapper>
  )
}
