import { Stack } from '@mui/material'
import styled from 'styled-components'

interface IMailStatusIconWrapperProps {
  bgColor: string
}

export const MailStatusIconWrapper = styled(Stack)<IMailStatusIconWrapperProps>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  background-color: ${(props) => props.bgColor};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
