import { FC } from 'react'
import { IRepresentativeStatusProps } from './RepresentativeStatus.types'
import { HowToReg as PersonOnIcon, PersonOff as PersonOffIcon } from '@mui/icons-material'
import { RepresentativeStatusWrapper } from './RepresentativeStatus.styles'
import { Tooltip } from 'components/Tooltip'
import { Typography } from '@mui/material'
import { theme } from 'styles/theme'

export const RepresentativeStatus: FC<IRepresentativeStatusProps> = ({ type, ...props }) => {
  const tooltipTitle = type === 'user' ? 'Является пользователем модуля.' : 'Не является пользователем модуля.'

  return (
    <Tooltip title={tooltipTitle} bgColor={theme.palette.bg.white} textColor={theme.palette.text.dark}>
      <RepresentativeStatusWrapper type={type} {...props}>
        {type === 'user' ? (
          <PersonOnIcon fontSize='small' color='primary' />
        ) : (
          <PersonOffIcon fontSize='small' color='disabled' />
        )}
        <Typography variant='subtitle1' fontSize={14}>
          QC
        </Typography>
      </RepresentativeStatusWrapper>
    </Tooltip>
  )
}
