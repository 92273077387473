import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ITypographyProps } from './Typography.types'

export interface IStyledTypographyProps extends Pick<ITypographyProps, 'lines'> {}

export const StyledTypography = styled(Typography)<IStyledTypographyProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: start;
`
