import { MenuItemProps } from '@mui/material/MenuItem/MenuItem'
import { CellContext } from '@tanstack/react-table'
import { CreateWork } from 'api/ksg/api.types'
import { WorkFull } from 'api/ksg/types'
import { Prescription } from 'api/prescriptions/types'
import { RegulationDoc, RegulationShort } from 'api/regulations/types'
import { RemarkFull, TRemarkStatus } from 'api/remarks/types'
import { ColoredTitleProps } from 'components/ColoredTitle'
import { WorkStatuses } from 'core/types/ksg'
import { TPrescriptionStatus } from 'core/types/prescription'
import { RegulationStatus } from 'core/types/regulation'

export interface RemarksCellProps {
  info: CellContext<RemarkFull, unknown> | CellContext<Prescription, unknown>
}

type StatusCellVariant = 'remarks' | 'prescriptions' | 'regulations' | 'works'

export type StatusCellProps =
  | RemarksStatusCellProps
  | PrescriptionsStatusCellProps
  | RegulationsStatusCellProps
  | WorksStatusCellProps

export type CommonStatusCellProps = {
  statusData: ColoredTitleProps
}

export type RemarksStatusCellProps = CommonStatusCellProps & {
  variant: 'remarks'
  info: CellContext<RemarkFull, unknown>
  onSelect: (projectId: number, remarkId: number, status: TRemarkStatus) => void
  itemsData: StatusCellMenuItemProps<TRemarkStatus>[]
  editingAvailable?: boolean
}

export type PrescriptionsStatusCellProps = CommonStatusCellProps & {
  variant: 'prescriptions'
  info: CellContext<Prescription, unknown>
  onSelect: (projectId: number, remarkId: number, status: TPrescriptionStatus) => void
  itemsData: StatusCellMenuItemProps<TPrescriptionStatus>[]
  editingAvailable?: boolean
}

export type RegulationsStatusCellProps = CommonStatusCellProps & {
  variant: 'regulations'
  info: CellContext<RegulationDoc, unknown>
  onSelect: (sectionId: number, docId: number, body: RegulationShort) => void
  itemsData: StatusCellMenuItemProps<RegulationStatus>[]
  editingAvailable?: boolean
}

export type WorksStatusCellProps = CommonStatusCellProps & {
  variant: 'works'
  info: CellContext<WorkFull, unknown>
  onSelect: (projectId: number, workId: number, body: CreateWork) => void
  itemsData: StatusCellMenuItemProps<WorkStatuses>[] | false
  editingAvailable?: boolean
}

export interface StatusCellMenuItemProps<T> extends MenuItemProps {
  valueForOnClick: T
  checked: boolean
}

export const isRegulationsStatusCell = (
  item: RemarkFull | Prescription | RegulationDoc | WorkFull,
): item is RegulationDoc => {
  return !!(item as RegulationDoc)?.section
}

export const isWorksStatusCell = (item: RemarkFull | Prescription | RegulationDoc | WorkFull): item is WorkFull => {
  return !!(item as WorkFull)?.id
}
