import { FC, useEffect, useState } from 'react'
import { IStatusSettingsItemProps } from './StatusSettingsItem.types'
import { Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { SubmitIconButton } from 'pages/Administration/components/ProjectIntegrationDrawer/components/ProjectNotificationSettings/ProjectNotificationSettings.styles'
import { Check as CheckIcon, Refresh as ResetIcon } from '@mui/icons-material'
import { useFormikContext } from 'formik'
import { Button } from 'components/Button'
import { useSnackbar } from 'hooks/useSnackbar'
import { IStatusSettings } from 'core/types/settings'
import { StyledFieldForm } from './StatusSettingsItem.styles'
import { useSetCustomStatusMutation } from 'api/settings'
import { ISetCustomStatusRequestBody } from 'api/settings/api.types'
import { TCallStatus } from 'api/calls/types'
import { TRemarkStatus } from 'api/remarks/types'
import { TPrescriptionStatus } from 'core/types/prescription'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { Tooltip } from 'components/Tooltip'

export const StatusSettingsItem: FC<IStatusSettingsItemProps> = ({ data, sectionName, setDirtyCount }) => {
  const { color, label, description } = data
  const name = data.name as keyof typeof valuesBySection
  const { values, setFieldValue, initialValues, errors } = useFormikContext<IStatusSettings>()
  const { showSnackbar } = useSnackbar()
  const valueName: string = `${sectionName}.${name}`
  const [setCustomStatus, { isLoading, ...setCustomStatusResponse }] = useSetCustomStatusMutation()
  const [initialValue, setInitialValue] = useState<string>('')

  const valuesBySection = values[sectionName]
  const value: string = values[sectionName][name] || ''
  const isError: boolean = !!errors?.[sectionName]?.[name]

  const isValueExists: boolean = !!value?.trim()
  const isValueDirty: boolean = initialValue !== value
  const isSubmitDisabled: boolean = !isValueExists || !isValueDirty || isError

  useEffect(() => {
    if (isValueDirty) setDirtyCount((p) => ++p)
    else setDirtyCount((p) => (p > 0 ? --p : 0))
  }, [isValueDirty])

  useEffect(() => {
    setInitialValue(initialValues[sectionName][name] || '')
  }, [initialValues])

  const onResetValueClick = () => {
    setFieldValue(valueName, '')
    onSubmitClick(null)
  }

  const onSubmitClick = (customName: string | null) => {
    const dataForRequest: ISetCustomStatusRequestBody = {
      customName,
      status: name.toUpperCase() as TCallStatus | TRemarkStatus | TPrescriptionStatus,
    }

    setCustomStatus({ scope: sectionName, body: dataForRequest })
  }

  useMutationHandlers(
    setCustomStatusResponse,
    (data) => {
      showSnackbar(value ? 'Статус успешно изменен.' : 'Статус успешно восстановлен.', { variant: 'success' })
      setInitialValue(value)
    },
    () => {
      showSnackbar('Произошла ошибка при изменении статуса.', { variant: 'error' })
    },
  )

  return (
    <Stack direction='row' spacing={2.5} justifyContent='space-between'>
      <Stack spacing={0.5} color={theme.palette.secondary.dark}>
        <Stack direction='row' alignItems='center'>
          <StatusMarker markerColor={color} />
          <Typography variant='subtitle2' fontSize={14} color={theme.palette.text.dark} lineHeight='normal'>
            {label}
          </Typography>
        </Stack>

        <Typography variant='body2'>{description}</Typography>
      </Stack>

      <Stack direction='row' spacing={1.25} height='fit-content'>
        <StyledFieldForm
          version='project'
          name={valueName}
          value={value}
          placeholder='Кастомное наименование'
          isErrorWithTouched={false}
          inputProps={{
            style: {
              padding: '8px',
            },
          }}
          style={{
            minWidth: '282px',
            maxWidth: '282px',
          }}
        />

        <Tooltip
          title={isValueExists ? 'Восстановить название по умолчанию.' : ''}
          bgColor={theme.palette.bg.white}
          textColor={theme.palette.text.dark}
        >
          <div>
            <Button
              onClick={onResetValueClick}
              icon
              color='secondary'
              variant='outlined'
              disabled={!isValueExists}
              style={{ minWidth: '36px', height: '36px' }}
              loading={false}
            >
              <ResetIcon fontSize='small' color={!isValueExists ? 'disabled' : 'primary'} />
            </Button>
          </div>
        </Tooltip>

        <Tooltip
          title={isSubmitDisabled ? '' : 'Сохранить название.'}
          bgColor={theme.palette.bg.white}
          textColor={theme.palette.text.dark}
        >
          <div>
            <SubmitIconButton
              onClick={() => onSubmitClick(value)}
              icon
              type='submit'
              color='success'
              loading={false}
              _disabled={isSubmitDisabled}
              _small
            >
              <CheckIcon color={isSubmitDisabled ? 'disabled' : 'success'} fontSize='small' />
            </SubmitIconButton>
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
