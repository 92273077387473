import { FC, useCallback, useEffect, useState, MouseEvent } from 'react'
import { ItemWrapper } from 'pages/Remarks/components/RemarkForm/RemarkForm.styles'
import { IconButton, Menu, Stack, Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { StatusCellContent } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { ColoredTitle } from 'components/ColoredTitle'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { NumberIcon } from 'assets/icons/NumberIcon'
import { FieldForm } from 'components/FieldForm'
import { useFormikContext } from 'formik'
import { CustomSelect } from 'components/CustomSelect'
import { MapsHomeWork as BuildingIcon, Person as PersonIcon, Smartphone as PhoneIcon } from '@mui/icons-material'
import { StyledPhoneFieldForm } from 'pages/Administration/components/ProjectIntegrationDrawer/components/IntegrationRequestSlide/IntegrationRequestSlide.styles'
import { useGetDropdownResponsibleCompaniesQuery, useLazyGetDropdownResponsibleUserQuery } from 'api/calls'
import { useParams } from 'react-router-dom'
import { ICallFormData } from '../../CallForm.types'
import { CustomSelectValueWithType } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { TCallStatus } from 'api/calls/types'
import { callStatusColorByEn, callStatuses, callStatusRuByEn, getCallStatusesForMenu } from 'core/types/call'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { KeyboardArrowDown as ArrowIcon, EventAvailable as CalendarIcon } from '@mui/icons-material'
import { GetDropdownResponsibleUsersData } from 'api/calls/api.types'
import { IGeneralInfoProps } from './GeneralInfo.types'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { useCallStatus } from 'hooks/useCustomStatus'

export const GeneralInfo: FC<IGeneralInfoProps> = ({ readOnly, callData }) => {
  const { projectId: projectIdString, callId: callIdString } = useParams()
  const projectId = Number(projectIdString)
  const callId = Number(callIdString)
  const { values, setFieldValue } = useFormikContext<ICallFormData>()
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const profile = useTypedSelector(profileSelector)
  const callStatusesForMenu = getCallStatusesForMenu(values.status, profile, callData)
  const isStatusChangeAvailable: boolean = !!callId && !!callStatusesForMenu.length
  // const isStatusChangeAvailable: boolean = !!callId && !readOnly && !!callStatusesForMenu.length
  const customCallStatus = useCallStatus()

  const { data: companySendersDropdown } = useGetDropdownResponsibleCompaniesQuery({ projectId }, { skip: readOnly })
  const [companyUsersDropdownRequest, { data: companyUsersDropdown }] = useLazyGetDropdownResponsibleUserQuery()

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback(
    (status: TCallStatus) => {
      setMenuAnchor(null)
      setFieldValue('status', status)
    },
    [projectId],
  )

  useEffect(() => {
    const { id, value, type } = values.responsibleCompany || {}

    if (value && !companyUsersDropdown?.data.length) {
      companyUsersDropdownRequest({
        projectId,
        company: type === 'company' ? value : undefined,
        member: type === 'projectMember' ? Number(id) : undefined,
      })
    }
  }, [values.responsibleCompany])

  const handleResponsibleCompanySelection = (data: CustomSelectValueWithType) => {
    const { id, value, type } = data

    setFieldValue('responsibleUser', null)
    setFieldValue('phone', '')

    companyUsersDropdownRequest({
      projectId,
      company: type === 'company' ? value : undefined,
      member: type === 'projectMember' ? Number(id) : undefined,
    })
  }

  const handleResponsibleUserSelection = (data: CustomSelectValueWithType, root: GetDropdownResponsibleUsersData) => {
    setFieldValue('phone', root?.data.phone || '')
  }

  return (
    <ItemWrapper minHeight={578} spacing={3}>
      <Stack spacing={2.5}>
        <Typography variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
          Общая информация
        </Typography>

        <FieldItem title='Статус'>
          <StatusCellContent onClick={onStatusCellClick} display='flex' alignItems='center'>
            <ColoredTitle body={customCallStatus[values.status]} color={callStatusColorByEn[values.status]} />

            {isStatusChangeAvailable && (
              <IconButton>
                <ArrowIcon fontSize='small' />
              </IconButton>
            )}
          </StatusCellContent>
          {isStatusChangeAvailable && (
            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
              {callStatusesForMenu.map((menuStatus) => {
                const valueForOnClick = menuStatus
                const checked = values.status === valueForOnClick

                return (
                  <StyledSelectMenuItem
                    onClick={() => onMenuItemClick(valueForOnClick)}
                    style={{ width: '100%', maxWidth: '100%' }}
                    value={menuStatus}
                    checked={checked}
                    key={menuStatus}
                  >
                    {customCallStatus[menuStatus]}
                  </StyledSelectMenuItem>
                )
              })}
            </Menu>
          )}
        </FieldItem>

        <FieldItem title='Номер вызова' icon={<NumberIcon fontSize='medium' color='secondary' />} isRequired={true}>
          <FieldForm
            version='project'
            placeholder='-'
            name='number'
            dataValue={values.number}
            fullWidth={false}
            // disabled={true}
            inputProps={{
              readOnly: true,
              style: { padding: '5px 8px', width: '120px', textAlign: 'end' },
            }}
          />
        </FieldItem>

        {!!callId && (
          <FieldItem title='Дата вызова' icon={<CalendarIcon fontSize='medium' color='secondary' />} isRequired={true}>
            <FieldForm
              version='date'
              name='createdDate'
              placeholder='дд.мм.гггг'
              helperText=''
              dataValue={values.createdDate}
              style={{ maxWidth: '144px', width: '100%' }}
              dateFieldProps={{
                readOnly: true,
              }}
            />
          </FieldItem>
        )}
      </Stack>

      <Stack spacing={1.25}>
        <Typography variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
          Инициатор
        </Typography>

        <CustomSelect
          name='responsibleCompany'
          list={
            companySendersDropdown?.data?.map((item) => ({
              ...item,
              group: item.data === 'company' ? 'Компании:' : 'Участники проекта:',
            })) || []
          }
          isSearch={true}
          placeholder='Выберите из списка'
          readOnly={readOnly}
          isGroup={true}
          label='Организация — инициатор вызова'
          style={{
            textAlign: 'start',
          }}
          isRequired={true}
          icon={<BuildingIcon fontSize='medium' color='secondary' />}
          handleValueSelection={handleResponsibleCompanySelection}
          shouldReposition={false}
        />

        <CustomSelect
          name='responsibleUser'
          list={companyUsersDropdown?.data || []}
          isSearch={true}
          placeholder='Выберите из списка'
          isSubtext={true}
          isDisabled={!values?.responsibleCompany?.value}
          label='Представитель инициатора вызова'
          style={{
            textAlign: 'start',
          }}
          readOnly={readOnly}
          isRequired={true}
          icon={<PersonIcon fontSize='medium' color='secondary' />}
          handleValueSelection={handleResponsibleUserSelection}
        />

        <FieldItem
          title='Телефон представителя'
          icon={<PhoneIcon fontSize='medium' color='secondary' />}
          isRequired={true}
          direction='column'
          spacing={0.5}
        >
          <StyledPhoneFieldForm
            name='phone'
            placeholder='Укажите номер телефона'
            helperText=''
            inputProps={{
              readOnly,
            }}
          />
        </FieldItem>
      </Stack>
    </ItemWrapper>
  )
}
