import { EditAndAddDrawer } from './ObjectDrawers/EditAndAddDrawer'
import { ObjectsTable } from './ObjectsTable'
import { Stack } from '@mui/material'
import { useClearObjectsMutation, useImportObjectsMutation, useLazyGetObjectsLinkExampleQuery } from 'api/objects'
import { useGetProjectDashboardQuery } from 'api/projects'
import { TableManagment } from 'components/TableManagment'
import { UploadDrawer } from 'components/UploadDrawer'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { DrawerData } from 'pages/Administration/References/ReferenceContent'
import { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { importDrawerAttentionText, importDrawerText, importDrawerUploadDataText } from './Objects.text'

export const Objects: FC = () => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [drawerData, setDrawerData] = useState<DrawerData>({
    isEditAndAddDrawerOpen: false,
    isImportDrawerOpen: false,
  })
  const [clearObjects, clearObjectsResponse] = useClearObjectsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [importObjects, importObjectsResponse] = useImportObjectsMutation()

  const navigate = useNavigate()

  const { data: projectData, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: dashboardProject } = projectData || {}
  const { project } = dashboardProject || {}

  const { onProjectBreadClick, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/objects/{projectId}',
  })

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Объекты' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  const onReturnClick = useCallback(() => {
    navigate(`/project/${projectId}`)
  }, [projectId])

  const onClearObjects = useCallback(() => {
    clearObjects({ projectId })
  }, [])

  const onDrawerOpen = useCallback((drawerType: keyof typeof drawerData) => {
    setDrawerData((prev) => ({ ...prev, [drawerType]: true }))
  }, [])

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setDrawerData({
        isEditAndAddDrawerOpen: false,
        isImportDrawerOpen: false,
      })
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((drawerType: keyof typeof drawerData, dirty: boolean) => {
    dirty ? openConfirm() : setDrawerData((prev) => ({ ...prev, [drawerType]: false }))
  }, [])

  useMutationHandlers(clearObjectsResponse, () => {
    enqueueSnackbar('Объекты успешно очищены.', { variant: 'success' })
  })

  return (
    <Stack flex={1} maxWidth='100%'>
      <Stack pr={2.5}>
        <TableManagment
          onReturnClick={onReturnClick}
          onClearClick={onClearObjects}
          onImportClick={() => onDrawerOpen('isImportDrawerOpen')}
          onAddClick={() => onDrawerOpen('isEditAndAddDrawerOpen')}
          buttonManagmentTitle='Управление'
        />
      </Stack>

      <ObjectsTable />

      <EditAndAddDrawer
        open={drawerData.isEditAndAddDrawerOpen}
        onClose={(dirty, immediately) => onDrawerClose('isEditAndAddDrawerOpen', dirty)}
        target={null}
      />

      <UploadDrawer
        open={drawerData.isImportDrawerOpen}
        mode='single'
        onSubmit={(file) => importObjects({ projectId, file })}
        importResponse={importObjectsResponse}
        onClose={() => onDrawerClose('isImportDrawerOpen', false)}
        title='Загрузка объектов'
        text={importDrawerText}
        attentionText={importDrawerAttentionText}
        uploadData={{
          title: 'Загрузить xls(х)',
          text: importDrawerUploadDataText,
          formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
        }}
        getExampleLinkQuery={useLazyGetObjectsLinkExampleQuery}
        getExampleLinkQueryParams={{ projectId }}
      />

      {ProjectsMenu}

      <ConfirmDialog />
    </Stack>
  )
}
