import { ImportDrawer } from '../ReferenceDrawers/ImportDrawer'
import { ProjectMembersDialogTrigger, ProjectMembersDrawerType, ProjectMembersProps } from './ProjectMembers.types'
import { ProjectMembersManagment } from './components/ProjectMembersManagment'
import { Stack } from '@mui/material'
import {
  projectMembersApi,
  useClearProjectMembersByProjectMutation,
  useClearProjectMembersMutation,
  useGetProjectMembersByProjectQuery,
  useGetProjectMembersQuery,
  useLazyExportProjectMembersByProjectQuery,
  useLazyExportProjectMembersQuery,
  useLazyGetProjectMembersExportResultByProjectQuery,
  useLazyGetProjectMembersExportResultQuery,
} from 'api/references/projectMembers'
import { ProjectMember } from 'api/references/projectMembers/types'
import { Table } from 'components/Table'
import { CustomColumnDef } from 'components/Table/Table.types'
import { TableManagment } from 'components/TableManagment'
import { ProjectMemberRole, projectMemberRoleRuByEn } from 'core/types/projectMember'
import { UserRoles, userRolesEnToRu } from 'core/types/user'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSearch } from 'hooks/useSearch'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isUserHighAccessSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { downloadMedia } from 'utils/downloadMedia'

export const ProjectMembers: FC<ProjectMembersProps> = () => {
  const { projectId: projectIdString } = useParams()
  const { searchValue } = useSearch()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const queryForTable = isProjectMode ? useGetProjectMembersByProjectQuery : useGetProjectMembersQuery
  const queryArgs = isProjectMode ? { projectId, query: searchValue } : { query: searchValue }
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const navigate = useNavigate()
  const [openedDrawer, setOpenedDrawer] = useState<ProjectMembersDrawerType | null>(null)
  const [selectedProjectMember, setSelectedProjectMember] = useState<ProjectMember | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const [exportProjectMembers, exportProjectMembersResult] = useLazyExportProjectMembersQuery()
  const [getProjectMembersExportResult, projectMembersExportResult] = useLazyGetProjectMembersExportResultQuery()

  const [exportProjectMembersByProject, exportProjectMembersResultByProject] =
    useLazyExportProjectMembersByProjectQuery()
  const [getProjectMembersExportResultByProject, projectMembersExportResultByProject] =
    useLazyGetProjectMembersExportResultByProjectQuery()

  const [clearProjectMembers, { isLoading: isClearing, ...clearProjectMembersResponse }] =
    useClearProjectMembersMutation()
  const [clearProjectMembersByProject, { isLoading: isClearingByProject, ...clearProjectMembersByProjectResponse }] =
    useClearProjectMembersByProjectMutation()

  const columns: CustomColumnDef<ProjectMember>[] = [
    {
      accessorKey: 'shortName',
      header: 'Краткое наименование',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'fullName',
      header: 'Полное наименование',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'basis',
      header: 'Основание',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'role',
      header: 'Роль',
      textAlign: 'left',
      minSize: 150,

      cell: (info) => {
        const role = info.getValue<ProjectMemberRole>()
        return projectMemberRoleRuByEn[role] || '—'
      },
    },
    {
      accessorKey: 'supervisorName',
      header: 'ФИО руководителя',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'supervisorPosition',
      header: 'Должность руководителя',
      textAlign: 'left',
      minSize: 150,
    },
    {
      accessorKey: 'note',
      header: 'Примечание',
      textAlign: 'left',
      minSize: 150,
    },
  ]

  const onRowClick = (projectMember: ProjectMember) => {
    setSelectedProjectMember(projectMember)
    setOpenedDrawer('managment')
  }

  const onDrawerOpen = useCallback((drawerType: ProjectMembersDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback(
    (dialogType: ProjectMembersDialogTrigger, dirty: boolean, immediately?: boolean) => {
      if (immediately || !dirty) {
        setOpenedDrawer(null)
        setSelectedProjectMember(null)
      } else {
        setConfirmDialogTrigger(dialogType)
        openConfirm()
      }
    },
    [],
  )

  const handleCloseManagment = useCallback((confirm: boolean) => {
    if (confirm) {
      setOpenedDrawer(null)
      setSelectedProjectMember(null)
    }
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<ProjectMembersDialogTrigger>('closeManagment')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    closeManagment: { handleConfirm: handleCloseManagment },
    delete: { handleConfirm: handleCloseManagment },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onClearReference = useCallback(() => {
    isProjectMode ? clearProjectMembersByProject({ projectId }) : clearProjectMembers()
  }, [])

  const onReturnClick = useCallback(() => {
    projectId ? navigate(`/project/${projectId}/references`) : navigate(`/administration/references`)
  }, [projectId])

  const onExportClick = () => {
    isProjectMode ? exportProjectMembersByProject({ projectId }) : exportProjectMembers()
  }

  useMutationHandlers(isProjectMode ? exportProjectMembersResultByProject : exportProjectMembersResult, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    isProjectMode
      ? getProjectMembersExportResultByProject({ projectId, id: data.data })
      : getProjectMembersExportResult({ id: data.data })
  })

  useMutationHandlers(isProjectMode ? projectMembersExportResultByProject : projectMembersExportResult, (data) => {
    downloadMedia(data.link)
    enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
  })

  useMutationHandlers(isProjectMode ? clearProjectMembersByProjectResponse : clearProjectMembersResponse, () => {
    enqueueSnackbar('Справочник успешно очищен.', { variant: 'success' })
  })

  return (
    <Stack height='100%'>
      <Stack paddingRight='20px'>
        <TableManagment
          onReturnClick={onReturnClick}
          onClearClick={onClearReference}
          onImportClick={!viewingOnly ? () => onDrawerOpen('import') : undefined}
          onAddClick={!viewingOnly ? () => onDrawerOpen('managment') : undefined}
          onExportClick={onExportClick}
          buttonManagmentTitle='Управление'
        />
      </Stack>

      <Table
        api={projectMembersApi}
        columns={columns}
        query={queryForTable}
        tags={[{ type: 'References', id: 'PROJECT_MEMBERS' }]}
        onRowClick={onRowClick}
        queryArgs={queryArgs}
      />

      <ProjectMembersManagment
        isOpen={openedDrawer === 'managment'}
        onClose={(dirty, immediately) => onDrawerClose('closeManagment', dirty, immediately)}
        projectMember={selectedProjectMember}
      />

      <ImportDrawer isOpen={openedDrawer === 'import'} onClose={() => onDrawerClose('closeManagment', false, false)} />

      <ConfirmDialog />
    </Stack>
  )
}
