import { Box } from '@mui/material'
import { isLegendColor, LegendColors } from 'core/types/global'
import styled from 'styled-components'

interface StyledBoxProps {
  bgColor: LegendColors | 'status' | 'primary'
}

export const StyledBox = styled(Box)<StyledBoxProps>`
  display: flex;
  width: fit-content;
  // padding: 4px 8px 3px;
  padding: 0 8px;
  height: 23px;
  background-color: ${(props) => isLegendColor(props.bgColor) && props.theme.palette.legends[props.bgColor]};
  background-color: ${(props) => props.bgColor === 'status' && 'rgba(25, 118, 210, 0.08)'};
  background-color: ${(props) => props.bgColor === 'primary' && props.theme.palette.primary.main};
  border-radius: 4px;
  gap: 4px;
  align-items: center;

  & .MuiTypography-root {
    font-weight: ${(props) => (props.bgColor === 'status' ? 400 : 500)};
    color: ${(props) =>
      props.bgColor === 'status' ? props.theme.palette.primary.main : props.theme.palette.text.light};
  }
`
