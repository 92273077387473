import { Repeat } from './SimpleHeader.styles'
import { SimpleHeaderProps } from './SimpleHeader.types'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { FC } from 'react'
import { theme } from 'styles/theme'

export const SimpleHeader: FC<SimpleHeaderProps> = ({ title, onClose, repeat, repeatText, children }) => {
  return (
    <>
      <Stack padding='12px 20px' direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Button icon onClick={onClose} color='secondary'>
            <ArrowBackIcon />
          </Button>
          <Typography variant='h1' textAlign='start' color={theme.palette.primary.main}>
            {title}
          </Typography>
          {repeat && (
            <Repeat>
              <Typography variant='body2' color={theme.palette.primary.main}>
                {repeatText || 'Повторное'}
              </Typography>
            </Repeat>
          )}
        </Stack>
        {children}
      </Stack>
      <Divider color='#D1D8FA' />
    </>
  )
}
