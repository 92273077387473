import {
  PrescriptionInspection,
  PrescriptionInspectionRu,
  PrescriptionType,
  PrescriptionTypeRu,
  TPrescriptionDirection,
  TPrescriptionDirectionRu,
} from 'core/types/prescription'

export interface InfoProps {
  viewingOnly: boolean
  formMode: 'add' | 'edit'
  isStatusChangeAvailable: boolean
}

export interface IMenuItem<T, K> {
  id: T
  text: K
}

export const inspectionTypeDropdown: IMenuItem<PrescriptionInspection, PrescriptionInspectionRu>[] = [
  { id: 'CURRENT', text: 'Текущее' },
  { id: 'PLANNED', text: 'Плановое' },
  { id: 'INSPECTION', text: 'Инспекционное' },
  { id: 'TARGET', text: 'Целевое' },
]

export const typeDropdown: IMenuItem<PrescriptionType, PrescriptionTypeRu>[] = [
  { id: 'CONTINUE_WORK', text: 'Без остановки работ' },
  { id: 'PAUSE_WORK', text: 'С остановкой работ' },
]

export const directionDropdown: IMenuItem<TPrescriptionDirection, TPrescriptionDirectionRu>[] = [
  { id: 'OUTGOING', text: 'Исходящее' },
  { id: 'INCOMING', text: 'Входящее' },
]
