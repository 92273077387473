import { Stack } from '@mui/material'
import { Button } from 'components/Button'
import styled from 'styled-components'

export const SettingsWrapper = styled(Stack)`
  width: 100%;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 8px;
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
`

export const TabsWrapper = styled(Stack)`
  position: relative;
`

export const ResetButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`
