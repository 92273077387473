import { Add as AddIcon } from '@mui/icons-material'
import { FormControl, Stack, Typography } from '@mui/material'
import { useGetProjectsQuery } from 'api/projects'
import { GetProjectsResponse } from 'api/projects/api.types'
import { Button } from 'components/Button'
import { FieldForm } from 'components/FieldForm'
import { UserFormData } from 'components/UserManagement/components/UserForm'
import { ROLE_SELECT_WIDTH, StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { useFormikContext } from 'formik'
import { FC, useMemo, useState } from 'react'
import { theme } from 'styles/theme'
import { Typography as CustomTypography } from 'components/Typography'

export const ProjectSelect: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>()
  const { data } = useGetProjectsQuery({})
  const { data: projects } = data || ({} as GetProjectsResponse)
  const [projectSelectHidden, setProjectSelectHidden] = useState<boolean>(true)
  const [isProjectOpen, setIsProjectOpen] = useState<boolean>(false)

  const filtredProjects = useMemo(() => {
    if (!projects) return []

    return !formValues.projects.length
      ? projects
      : projects.filter((project) => !formValues.projects.map((project) => project.id).includes(project.id))
  }, [projects, formValues.projects])

  const onProjectSelectOpen = () => {
    setProjectSelectHidden(false)
    setIsProjectOpen(true)
  }

  const onProjectSelectClose = () => {
    setIsProjectOpen(false)
    setProjectSelectHidden(true)
  }

  return (
    <>
      {projectSelectHidden && formValues.projects?.length ? (
        <Stack alignItems='flex-end'>
          <Button
            startIcon={<AddIcon />}
            variant='text'
            bgColor={theme.palette.bg.shades}
            onClick={onProjectSelectOpen}
            style={{ width: '100%', height: '44px' }}
            sx={{
              '& .MuiButton-startIcon': { paddingTop: '1px' },
            }}
          >
            Добавить проект
          </Button>
        </Stack>
      ) : (
        <Stack alignItems='flex-end' width='100%'>
          <Stack width='100%'>
            <FormControl
              style={{ width: '100%', maxWidth: '100%' }}
              sx={{
                '& .MuiSelect-outlined': {
                  padding: '10px 16px !important',
                  fontSize: '16px !important',
                  lineHeight: '23px !important',
                  textAlign: 'left !important',
                  color: `${theme.palette.text.dark} !important`,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid rgba(92, 110, 140, 0.3) !important',
                  borderRadius: '6px !important',
                },
                '& .MuiSvgIcon-root': {
                  right: '14px !important',
                },
              }}
            >
              {/* <AutocompleteField
                fieldName='projects'
                placeholder='Выберите проект'
                data={filtredProjects.map((project) => ({
                  value: project.id,
                  label: project.shortName,
                  key: project.id,
                }))}
                onChange={(option) => {
                  const projectId = option.value

                  const data = {
                    id: +projectId,
                    name: projects?.find((project) => project.id === +projectId)!.shortName,
                  }

                  setFieldValue(`projects`, [...formValues.projects, data])
                }}
              /> */}
              <FieldForm
                version='select'
                name='projects'
                onChange={(e) => {
                  const projectId = e.target.value
                  setFieldValue(`projects`, [
                    ...formValues.projects,
                    {
                      id: +projectId,
                      name: projects?.find((project) => project.id === +projectId)!.shortName,
                    },
                  ])
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: () => <Typography component='span'>Выберите проект</Typography>,
                  open: isProjectOpen,
                  onOpen: onProjectSelectOpen,
                  onClose: onProjectSelectClose,
                  MenuProps: {
                    PaperProps: {
                      style: { maxWidth: ROLE_SELECT_WIDTH },
                    },
                  },
                }}
              >
                {filtredProjects.map((project) => (
                  <StyledSelectMenuItem value={project.id} key={project.id}>
                    <CustomTypography lines={2}>{project.shortName}</CustomTypography>
                  </StyledSelectMenuItem>
                ))}
              </FieldForm>
            </FormControl>
          </Stack>
        </Stack>
      )}
    </>
  )
}
