import { format, parse } from 'date-fns'

export interface ITimeResponse {
  date: Date | null
  timeShort: string
}

export const emptyTimeResponse: ITimeResponse = {
  date: null,
  timeShort: '',
}

// '12:30:00' to Date
export const parseResponseTime = (responseTime?: string | null): ITimeResponse => {
  if (!responseTime) return emptyTimeResponse

  const date = parse(responseTime, 'HH:mm:ss', new Date())
  const timeShort = format(date, 'HH:mm')

  return { date, timeShort }
}
