import { LinkItemWrapper } from './LinkItem.styles'
import { LinkItemProps } from './LinkItem.types'
import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const LinkItem: FC<LinkItemProps> = ({ text, link, Icon }) => {
  return (
    <LinkItemWrapper disableLink={!link}>
      <Link to={link}>
        <Stack direction='row' alignItems='center' spacing={0.5} justifyContent='space-between' width='100%'>
          <Typography whiteSpace='nowrap'>{text}</Typography>
          <Icon />
        </Stack>
      </Link>
    </LinkItemWrapper>
  )
}
