import { UploadResultItem } from './UploadResultItem'
import { UploadResponseItem } from './UploadResultItem/UploadResultItem.types'
import {
  UploadResultsButtonWrapper,
  UploadResultsDrawerTopBar,
  UploadResultsDrawerWrapper,
  UploadResultsEntireWrapper,
  UploadResultsWrapper,
} from './UploadResultsDrawer.styles'
import { UploadResultsDrawerProps } from './UploadResultsDrawer.types'
import CancelIcon from '@mui/icons-material/Cancel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Drawer, IconButton, Typography } from '@mui/material'
import { Divider } from 'components/Divider'
import { errorTextByType } from 'core/types/global'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'
import { isNumber } from 'lodash'

export const UploadResultsDrawer: React.FC<UploadResultsDrawerProps> = ({
  title,
  mode,
  open,
  onClose,
  responseData,
}) => {
  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [pageScrollable, setPageScrollable] = useState<boolean>(false)

  const handleScrollButton = (direction: 'up' | 'down') => {
    if (rootElement) {
      const scrollAmount = direction === 'down' ? 200 : -200
      rootElement.scrollBy({ top: scrollAmount, behavior: 'smooth' })
    }
  }

  const listenToScroll = (element: HTMLDivElement) => {
    if (element) {
      const scrollHeight = element.scrollTop

      const height = element.scrollHeight - element.clientHeight

      const scrolled = scrollHeight / height

      setScrollPosition(scrolled)
    }
  }

  useEffect(() => {
    setScrollPosition(0)
  }, [open])

  useEffect(() => {
    rootElement?.addEventListener('scroll', () => listenToScroll(rootElement))
    if (rootElement) {
      setPageScrollable(rootElement?.scrollHeight > rootElement?.clientHeight)
    }

    return () => {
      rootElement?.removeEventListener('scroll', () => listenToScroll(rootElement))
    }
  }, [rootElement])

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setRootElement(node)
    }
  }, [])

  const dataForStatistics: UploadResponseItem[] | null = useMemo(() => {
    if (!responseData) return null

    return responseData.error?.map(({ title, type, message, row, iconColor }) => ({
      title: title || errorTextByType[type],
      icon:
        iconColor === 'success' ? (
          <CheckCircleIcon fontSize='medium' color='success' />
        ) : (
          <WarningAmberIcon fontSize='medium' color={iconColor || 'error'} />
        ),
      text: message,
      optionalInfo: isNumber(row) ? `Строка ${row}` : '',
    }))
  }, [responseData])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { overflow: 'hidden' },
      }}
    >
      <UploadResultsDrawerWrapper>
        <UploadResultsDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {title || (mode === 'single' ? 'Информация об ошибках' : 'Информация о загрузке')}
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'fixed', top: 18, right: 20, p: 0.5 }} disableTouchRipple>
            <CancelIcon color='secondary' />
          </IconButton>
        </UploadResultsDrawerTopBar>

        <Divider />

        <UploadResultsEntireWrapper ref={measuredRef}>
          {pageScrollable ? (
            <UploadResultsButtonWrapper
              onClick={() => scrollPosition > 0 && handleScrollButton('up')}
              style={{ top: 0, boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)' }}
              disabled={scrollPosition === 0}
            >
              <KeyboardArrowUpIcon />
            </UploadResultsButtonWrapper>
          ) : null}

          {responseData ? (
            <UploadResultsWrapper divider={<Divider />}>
              {dataForStatistics?.map((item) => <UploadResultItem data={item} />)}
            </UploadResultsWrapper>
          ) : null}

          {pageScrollable ? (
            <UploadResultsButtonWrapper
              onClick={() => scrollPosition < 1 && handleScrollButton('down')}
              style={{ bottom: 0, boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.05)' }}
              disabled={scrollPosition === 1}
            >
              <KeyboardArrowDownIcon />
            </UploadResultsButtonWrapper>
          ) : null}
        </UploadResultsEntireWrapper>
      </UploadResultsDrawerWrapper>
    </Drawer>
  )
}
