export type TFileImageExtension = '.png' | '.jpg' | '.jpeg' | '.bmp' | '.tiff' | '.heic' | '.heif'
export type TFileExtension = TFileImageExtension | '.pdf' | '.docx'

export const fileImageExtensions: TFileImageExtension[] = ['.png', '.jpg', '.jpeg', '.bmp', '.tiff', '.heic', '.heif']

// Projects
export const projectMediaFileExtensions: TFileExtension[] = ['.png', '.jpg', '.jpeg', '.bmp']

// Remarks
export const remarkMediaFileExtensions: TFileExtension[] = [
  '.png',
  '.jpg',
  '.jpeg',
  '.bmp',
  '.pdf',
  '.tiff',
  '.heic',
  '.heif',
]

// Prescripitons
export const prescriptionMediaFileExtensions: TFileExtension[] = ['.pdf', '.docx']

// Eliminations
export const eliminationMediaFileExtensions: TFileExtension[] = ['.pdf', '.docx']

// Calls
export const callMediaFileExtensions: TFileExtension[] = []
